import API from '../api';
import { endpoints } from '../utils/endPoints';

const unitService = {
    list: () => API.get(endpoints.central.unit.list),
    options: () => API.get(endpoints.central.unit.options),
    selectUnit: (form: { unit: number }) => API.post(endpoints.central.unit.select, form),
};

export default unitService;

import axios, { AxiosError } from 'axios';
import { history, store } from '../store';
import { decrement, increment } from '../store/loading';
import { getJWTToken, logout } from '../utils/AuthHelper';

//TODO: ez csak a responseType-ban tér el a másik axios instance-tól, javítható
const instance = axios.create({
    headers: {
        //     'Access-Control-Allow-Origin': '*', sajnos cors errort okoz
        'Content-Type': 'application/json',
        //    'X-Requested-With': 'XMLHttpRequest', sajnos cors errort okoz
    },
    responseType: 'blob',
    withCredentials: true,
});

instance.interceptors.request.use(
    (config) => {
        store.dispatch(increment(config.url || ''));
        if (!config.headers?.Authorization) {
            const AUTH_TOKEN = getJWTToken();
            if (AUTH_TOKEN)
                config.headers = Object.assign({}, config.headers, {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                });

            const XSwitchUser = localStorage.getItem('X-Switch-User');
            if (XSwitchUser)
                config.headers = Object.assign({}, config.headers, {
                    'X-Switch-User': XSwitchUser,
                });
        }

        return config;
    },
    (error) => Promise.reject(error),
);

const handleError = (status?: number): string => {
    if (!status) return '';
    switch (status) {
        case 403:
            return '/403';
        case 404:
            return '/404';
        case 500:
            return '/500';
        default:
            return '';
    }
};

// Add a response interceptor
instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        store.dispatch(decrement(response.config.url || ''));
        return response;
    },
    // remember to make this async as the store action will
    // need to be awaited
    async function (error: AxiosError) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        store.dispatch(decrement(error.config.url || ''));
        if (error?.response?.status === 401) {
            logout();
            history.push('/login');

            return Promise.reject(error);
        } else {
            const redirect = handleError(error.response?.status);
            if (redirect) {
                history.push(redirect, { error });
            }
        }

        return Promise.reject(error);
    },
);

instance.interceptors.request.use((config) => {
    if (config.method && config.method?.toLocaleUpperCase() === 'PATCH') {
        config.headers = {
            ...config.headers,
            'Content-Type': 'application/merge-patch+json',
        };
    }
    return config;
});

export default instance;

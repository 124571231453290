import { GridRenderCellParams } from '@mui/x-data-grid';

const ValueCellRenderer = ({ params }: { params: GridRenderCellParams }) => {
    switch (params.row.key) {
        case 'email_attached_images':
            return <>{params.value == 1 ? 'igen' : 'nem'}</>;

        default:
            return <>{params.value}</>;
    }
};

export default ValueCellRenderer;

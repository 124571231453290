import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Accordion, CancelButton, Loading } from '@silinfo/front-end-template';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { onlyCommonCampaignCondition } from '../../../../utils/AccessManagementHelper';
import { showCommonMessageFieldsCondition, showOwnMessageFieldsCondition } from './BaseForm/MessageFieldsDialog';
import { DialogProps, IMessageFieldsDialogButtonProps } from './MessageFieldsDialogButton';
import MessageFieldsDialogTable from './MessageFieldsDialogTable';
import useFilterFlaggedMessageFields from './useFilterFlaggedMessageFields';

export default function MessageFieldsDialog(props: IMessageFieldsDialogButtonProps & DialogProps) {
    const { open, handleClose } = props;
    const { ownMessageFields, commonMessageFields } = useSelector((state: RootState) => state.messageTemplates);
    const { user } = useSelector((state: RootState) => state.auth);
    const flaggedMessageFields = useFilterFlaggedMessageFields();

    if (!user) {
        return <Loading />;
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Üzenetmező beszúrása</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12} style={{ marginTop: '8px' }}>
                        <Accordion title={`Rendszerszintű üzenetmezők`} defaultExpanded={false}>
                            <MessageFieldsDialogTable {...props} messageFields={flaggedMessageFields} />
                        </Accordion>
                    </Grid>
                    {showCommonMessageFieldsCondition(user) && (
                        <Grid item xs={12} style={{ marginTop: '8px' }}>
                            <Accordion title={`Közös üzenetmezők`} defaultExpanded={onlyCommonCampaignCondition()}>
                                <MessageFieldsDialogTable {...props} messageFields={commonMessageFields} />
                            </Accordion>
                        </Grid>
                    )}
                    {showOwnMessageFieldsCondition(user) && (
                        <Grid item xs={12}>
                            <Accordion title={`Saját üzenetmezők`} defaultExpanded={true}>
                                <MessageFieldsDialogTable {...props} messageFields={ownMessageFields} />
                            </Accordion>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container xs={12} spacing={1} justifyContent={'flex-end'}>
                    <Grid item>
                        <CancelButton type="button" onClick={handleClose}>
                            Bezár
                        </CancelButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

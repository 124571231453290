import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';

interface TitleProps {
    title: string;
    formKey: string;
    id: string;
    readonly?: boolean;
}

type DynamicValue = Record<string, { id?: string; tmpId?: string }[]>;

export default function Title<T extends DynamicValue>({ title, formKey, id, readonly }: TitleProps) {
    const formikProps = useFormikContext<T>();
    const value = formikProps.values[formKey];

    return (
        <>
            <Typography>{title}</Typography>
            {readonly ? null : (
                <IconButton
                    aria-label="delete"
                    sx={{ float: 'right', p: 0, ml: 'auto', color: red[500] }}
                    onClick={(e) => {
                        e.stopPropagation();
                        formikProps.setFieldValue(
                            formKey,
                            value?.filter((s) => s.id != id && s.tmpId != id),
                        );
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            )}
        </>
    );
}

//TODO: ezt is kialakaítani, átnézni
/**
 * Alapvetően amit használunk az a Central/Unit felbontás, ezen belül menük, majd almenük
 */
export const clientEndpoints = {
    /**
     * Hibaoldalak
     */

    home: '/',
    server_error: '/500',
    service_unavailable: '/503',
    not_found: '/404',
    access_denied: '/403',

    unsubscribe: '/unsubscribe/:unitId/:token',
    messageView: '/message-view/:unitId/:token',
    /**
     * Central/E-mail cím aktiváció
     */
    central_email_confirmation: '/confirmation/:token',
    central_email_reset_password: '/reset-password/:token',
    /**
     * Central/Felhasználókezelés
     */
    central_users: '/central/users',
    central_users_create: '/central/users/new',
    central_users_edit: '/central/users/edit/:id',

    /**
     * Central/Területi egységek
     */
    central_units: '/central/units',
    central_units_create: '/central/units/new',
    central_units_edit: '/central/units/edit/:id',

    /**
     * Central/Renszerbeállítások
     */
    central_system_settings: '/central/system-settings',
    central_system_settings_edit: '/central/system-settings/:id/edit',

    /**
     * Central/Renszernapló
     */
    central_system_logs: '/central/system-logs',
    central_system_logs_show: '/central/system-logs/:id',

    /**
     * Central/Szuperadmin
     */
    central_super_admin: '/central/super-admin',
    central_super_admin_edit: '/central/super-admin/:id/edit',

    /**
     * Central/Rendszerüzenetek
     */
    central_system_messages: '/central/system-messages',
    central_system_messages_edit: '/central/system-messages/:id/edit',

    /**
     * Central/E-mail napló
     */
    central_email_log: '/central/email-log',
    central_email_log_show: '/central/email-log/:emailPostedId',

    /**
     * Unit/Címek/Címlista
     */
    unit_address_list: '/unit/address-list',
    unit_address_list_create: '/unit/address-list/new',
    unit_address_list_edit: '/unit/address-list/:id/edit',
    unit_address_list_only_group: '/unit/address-list/:groupId/address',
    unit_address_list_import: '/unit/address-list/import',
    unit_own_address_list: '/unit/own-address-list',
    unit_own_address_list_create: '/unit/own-address-list/new',
    unit_own_address_list_edit: '/unit/own-address-list/:id/edit',
    unit_own_address_list_import: '/unit/own-address-list/import',

    /**
     * Unit/Címek/Csoport
     */
    unit_address_groups: '/unit/address-groups',
    unit_address_groups_create: '/unit/address-groups/new',
    unit_address_groups_edit: '/unit/address-groups/:id/edit',
    unit_address_groups_only_group: '/unit/address-groups/:groupId/address',
    unit_own_address_groups: '/unit/own-address-groups',
    unit_own_address_groups_create: '/unit/own-address-groups/new',
    unit_own_address_groups_edit: '/unit/own-address-groups/:id/edit',
    unit_own_address_groups_only_group: '/unit/own-address-groups/:groupId/address',

    /**
     * Unit/Címek/Üzenetmezők
     */
    unit_message_fields: '/unit/message-fields',
    unit_message_fields_create: '/unit/message-fields/new',
    unit_message_fields_edit: '/unit/message-fields/:id/edit',
    unit_own_message_fields: '/unit/own-message-fields',
    unit_own_message_fields_create: '/unit/own-message-fields/new',
    unit_own_message_fields_edit: '/unit/own-message-fields/:id/edit',

    /**
     * Unit/Sablonok/Üzenetsablonok
     */
    unit_message_templates: '/unit/message-templates',
    unit_message_templates_create: '/unit/message-templates/new',
    unit_message_templates_create_complex: '/unit/message-templates/new-complex',
    unit_message_templates_edit: '/unit/message-templates/:id/edit',
    unit_message_templates_show: '/unit/message-templates/:id/show',
    unit_own_message_templates: '/unit/own-message-templates',
    unit_own_message_templates_create: '/unit/own-message-templates/new',
    unit_own_message_templates_create_complex: '/unit/own-message-templates/new-complex',
    unit_own_message_templates_edit: '/unit/own-message-templates/:id/edit',

    /**
     * Unit/Sablonok/Űrlapok
     */
    unit_forms: '/unit/forms',
    unit_forms_create: '/unit/forms/new',
    unit_forms_edit: '/unit/forms/:id/edit',
    unit_forms_show: '/unit/forms/:id/show',
    unit_forms_preview: '/unit/forms/:id/preview',
    unit_own_forms: '/unit/own-forms',
    unit_own_forms_create: '/unit/own-forms/new',
    unit_own_forms_edit: '/unit/own-forms/:id/edit',

    /**
     * Unit/Kampányok/Kampánylista
     */
    unit_campaigns: '/unit/campaign-list',
    unit_campaigns_edit: '/unit/campaign-list/:id/edit',
    unit_campaigns_create: '/unit/campaign-list/new',
    unit_campaigns_view: '/unit/campaign-list/:id/view',
    unit_campaigns_recipients: '/unit/campaign-list/:id/recipients',
    unit_campaigns_recipients_email_log: '/unit/campaign-list/:id/recipients/:emailPostedId',
    unit_campaigns_form_fills: '/unit/campaign-list/:id/forms-filled',
    unit_campaigns_message_templates_show: '/unit/campaign-list/:campaignId/message-templates/:id/show',
    unit_campaigns_forms_show: '/unit/campaign-list/:campaignId/forms/:id/show',

    /**
     * Unit/Kampányok/Új kampány
     */
    unit_new_campaign: '/unit/new-campaign',

    /**
     * Unit/Statisztikák/Összesített
     */
    unit_statistics_aggregate: '/unit/statistics/aggregate',

    /**
     * Unit/Statisztikák/Kampánystatisztika
     */
    unit_statistics_campaign: '/unit/statistics/campaign',
    unit_statistics_campaign_show: '/unit/statistics/campaign/:campaignId',

    /**
     * Unit/Statisztikák/Csoportosított statisztika
     */
    unit_statistics_grouped: '/unit/statistics/grouped',

    /**
     * Unit/Statisztikák/Egyéb statisztika
     */
    unit_statistics_other: '/unit/statistics/other',

    /**
     * Unit/Adminisztráció/E-mail címek
     */
    unit_emails: '/unit/emails',
    unit_emails_create: '/unit/emails/new',
    unit_emails_edit: '/unit/emails/:id/edit',
    unit_own_emails: '/unit/own-emails',
    unit_own_emails_create: '/unit/own-emails/new',
    unit_own_emails_edit: '/unit/own-emails/:id/edit',

    /**
     * Unit/Adminisztráció/E-mail napló
     */
    unit_email_log: '/unit/email-log',
    unit_email_log_show: '/unit/email-log/:emailPostedId',

    /**
     * Unit/Adminisztráció/Beállítások
     */
    unit_settings: '/unit/settings',
    unit_settings_edit: '/unit/settings/:id/edit',

    /**
     * Unit/Adminisztráció/Felhasználókezelés
     */
    unit_users: '/unit/users',
    unit_users_create: '/unit/users/new',
    unit_users_edit: '/unit/users/edit/:id',

    /**
     * Nézetváltás felület
     */
    switch_layout: '/switch-layout',
    select_unit: '/select-unit',
    select_unit_campaign_management: '/select-unit-campaign-management',
    select_owner_campaign_management: '/select-owner-campaign-management',

    /**
     * Login felület
     */
    login: '/login',

    /**
     * Űrlapkitöltő felület
     */
    form_filler: '/form/:unitId/:token',

    /**
     * Űrlapmegtekintő felület
     */
    form_viewer: '/form-view/:id',

    /**
     * Exportlista
     */
    export_list: '/unit/export',
};

export const viewSelectClientEndpointKeys: readonly (keyof typeof clientEndpoints)[] = [
    'switch_layout',
    'select_unit',
    'select_unit_campaign_management',
    'select_owner_campaign_management',
];

export const ownCampaignClientEndpointKeys: readonly (keyof typeof clientEndpoints)[] = [
    'unit_own_address_groups',
    'unit_own_address_groups_create',
    'unit_own_address_groups_edit',
    'unit_own_address_groups_only_group',
    'unit_own_message_fields',
    'unit_own_message_fields_create',
    'unit_own_message_fields_edit',
    'unit_own_address_list',
    'unit_own_address_list_create',
    'unit_own_address_list_edit',
    'unit_own_forms',
    'unit_own_forms_create',
    'unit_own_forms_edit',
    'unit_own_message_templates',
    'unit_own_message_templates_create',
    'unit_own_message_templates_edit',
    'unit_own_message_templates_create_complex',
    'unit_own_emails',
    'unit_own_emails_create',
    'unit_own_emails_edit',
];

export const otherOwnerCampaignClientEndpointKeys: readonly (keyof typeof clientEndpoints)[] = [
    'unit_own_address_groups',
    'unit_own_message_fields',
    'unit_own_address_list',
    'unit_campaigns',
    'unit_own_forms',
    'unit_own_message_templates',
    'unit_statistics_aggregate',
    'unit_statistics_campaign',
    'unit_statistics_grouped',
    'unit_statistics_other',
];

// Clientendpoints key-ek kellenek string formátumban, amit kivételként szeretnénk kezelni
export const permissionFreeClientEndpointKeys: readonly (keyof typeof clientEndpoints)[] = [
    'home',
    'server_error',
    'service_unavailable',
    'not_found',
    'login',
    'central_email_confirmation',
    'central_email_reset_password',
    'unsubscribe',
    'messageView',
    ...viewSelectClientEndpointKeys,
];

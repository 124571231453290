import { makeStyles } from '@mui/styles';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import emailsService from '../../../services/Unit/emails';
import { RootState } from '../../../store';
import { ViewUnitCampaignManagementType } from '../../../store/auth';
import { create } from '../../../store/notification';

const useStyles = makeStyles(() => ({
    button: {
        marginRight: '5px',
    },
}));

type EmailBtnCellRendererProps = {
    params: GridRenderCellParams;
    setRefresh: Dispatch<SetStateAction<boolean>>;
};

const BtnCellRenderer = ({ params, setRefresh }: EmailBtnCellRendererProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { view } = useSelector((state: RootState) => state.auth);

    return (
        <>
            <TableEditButton
                tooltip={'Szerkesztés'}
                className={classes.button}
                target={'{id}/edit'.replace('{id}', params.row.id.toString())}
            />
            {view.unitCampaignManagement !== ViewUnitCampaignManagementType.Own && (
                <TableDeleteButton
                    tooltip={'Törlés'}
                    deleteAction={() => {
                        emailsService
                            .delete(params.row.id)
                            .then(() => {
                                dispatch(create({ type: 'success', message: 'Sikeres törlés.' }));
                                setRefresh((prev) => !prev);
                            })
                            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen törlés.' })));
                    }}
                    confirmationMessage={'Biztosan törlöd a következő elemet:'}
                    confirmationElement={params.row.name}
                    className={classes.button}
                />
            )}
        </>
    );
};

export default BtnCellRenderer;

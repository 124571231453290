import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Accordion, CancelButton, Loading, Page } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import axios from 'axios';
import campaignsService from '../../../../services/campaigns/campaigns';
import MCCLoading from '../../../../components/MCCLoading';
import emailLogService from '../../../../services/Unit/emailLogService';
import centralEmailLogService from '../../../../services/Central/emailLogService';
import Link from '@mui/material/Link';
import emailPostedAttachments from '../../../../services/Unit/emailPostedAttachments';
import { ICampaign, initialCampaign } from './types';

type RouteParams = {
    id: string;
};

type EmailPostedAttachmentType = {
    id: number;
    fileName: string;
};

interface IEmailLog {
    id: number;
    mailingAddress: string;
    sentDate: string;
    subject: string;
    content: string;
    attachments: EmailPostedAttachmentType[];
}

const initialEmailLog: IEmailLog = {
    id: -1,
    mailingAddress: '',
    sentDate: '',
    subject: '',
    content: '',
    attachments: [],
};

interface IEmailLogShowProps {
    type: 'campaign_email_log' | 'unit_email_log' | 'central_email_log';
}
export default function EmailLogShow(props: IEmailLogShowProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [emailLog, setEmailLog] = useState<IEmailLog>(initialEmailLog);
    const { id, emailPostedId } = useParams<RouteParams>() as { id: string; emailPostedId: string };
    const navigate = useNavigate();
    const [campaign, setCampaign] = useState<ICampaign>(initialCampaign);

    const header: HeaderProps =
        props.type === 'campaign_email_log'
            ? {
                  project: PROJECT_NAME,
                  title: 'Címzettek',
                  breadcrumbs: {
                      addresses: 'Kampányok',
                      [clientEndpoints.unit_campaigns]: 'Kampánylista',
                      [clientEndpoints.unit_campaigns_recipients.replace(':id', id?.toString())]:
                          `${campaign.name}` + ' kampány',
                      recipients: 'Címzettek',
                      recipients_email_log_show: 'E-mail megtekintése',
                  },
              }
            : {
                  project: PROJECT_NAME,
                  title: 'E-mail napló',
                  breadcrumbs: {
                      admin: 'Adminisztráció',
                      ...(props.type === 'central_email_log' && {
                          [clientEndpoints.central_email_log]: 'E-mail napló',
                      }),
                      ...(props.type === 'unit_email_log' && { [clientEndpoints.unit_email_log]: 'E-mail napló' }),
                      recipients_email_log_show: 'E-mail megtekintése',
                  },
              };

    const downloadFile = (id: number, name: string) => {
        emailPostedAttachments.download(id).then((resp) => {
            const blob = resp.data;
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = name;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    useEffect(() => {
        setLoading(true);
        if (props.type === 'campaign_email_log') {
            axios
                .all([emailLogService.get(+emailPostedId), campaignsService.get(+id)])
                .then((res) => {
                    setEmailLog(res[0].data);
                    setCampaign(res[1].data);
                })
                .finally(() => setLoading(false));
        } else if (props.type == 'unit_email_log') {
            emailLogService
                .get(+emailPostedId)
                .then((res) => setEmailLog(res.data))
                .finally(() => setLoading(false));
        } else if (props.type === 'central_email_log') {
            centralEmailLogService
                .get(+emailPostedId)
                .then((res) => setEmailLog(res.data))
                .finally(() => setLoading(false));
        }
    }, [emailPostedId, id, props.type]);

    if (loading) {
        return <MCCLoading />;
    }
    return (
        <Page header={header}>
            <Accordion title="Email napló megjelenítés">
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ overflow: 'auto' }}>
                        {loading ? (
                            <Loading noHeight />
                        ) : !emailLog ? (
                            'Nem található adat.'
                        ) : (
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>E-mail</TableCell>
                                        <TableCell>{emailLog.mailingAddress}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Tárgy</TableCell>
                                        <TableCell>{emailLog.subject}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Kiküldve</TableCell>
                                        <TableCell>{emailLog.sentDate}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Tartalom</TableCell>
                                        <TableCell colSpan={3}>
                                            <div
                                                style={{ width: '100%', wordBreak: 'break-all' }}
                                                dangerouslySetInnerHTML={{ __html: emailLog.content }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {emailLog.attachments?.length > 0 && (
                                        <TableRow>
                                            <TableCell>Csatolmányok</TableCell>
                                            <TableCell>
                                                <Grid container>
                                                    {emailLog.attachments.map((attachment) => (
                                                        <Grid key={attachment.id} item xs={12}>
                                                            <Link
                                                                key={attachment.id}
                                                                component="button"
                                                                variant="body2"
                                                                onClick={() => {
                                                                    downloadFile(attachment.id, attachment.fileName);
                                                                }}
                                                            >
                                                                {attachment.fileName}
                                                            </Link>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <CancelButton
                            onClick={() => {
                                props.type === 'campaign_email_log' &&
                                    navigate(
                                        clientEndpoints.unit_campaigns_recipients
                                            .replace(':id', id?.toString())
                                            .replace(':emailPostedId', emailPostedId),
                                    );
                                props.type === 'unit_email_log' && navigate(clientEndpoints.unit_email_log);
                                props.type === 'central_email_log' && navigate(clientEndpoints.central_email_log);
                            }}
                        >
                            Vissza
                        </CancelButton>
                    </Grid>
                </Grid>
            </Accordion>
        </Page>
    );
}

import { useCallback, useState } from 'react';
import AddressDeleteDialog from './AddressDeleteDialog';
import React from 'react';

function AddressDeleteDialogButton({ opener, objectId }: { opener: JSX.Element; objectId: number }) {
    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            {open && <AddressDeleteDialog open={true} handleClose={handleClose} selected={objectId} />}
        </>
    );
}

export default AddressDeleteDialogButton;

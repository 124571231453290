// import ContentCopy from '@mui/icons-material/ContentCopy';
import { makeStyles } from '@mui/styles';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableEditButton } from '@silinfo/front-end-template';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const useStyles = makeStyles(() => ({
    button: {
        marginRight: '5px',
    },
}));

type BtnCellRendererProps = {
    params: GridRenderCellParams;
};

const BtnCellRenderer = ({ params }: BtnCellRendererProps) => {
    const classes = useStyles();
    return (
        <>
            <TableEditButton
                tooltip={'Szerkesztés'}
                className={classes.button}
                target={clientEndpoints.central_system_messages_edit.replace(':id', params.row.id.toString())}
            />
        </>
    );
};

export default BtnCellRenderer;

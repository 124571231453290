import SystemSettingsPage from '../../pages/Central/SystemSettings';
import SystemSettingsForm from '../../pages/Central/SystemSettings/FormPage';
import { clientEndpoints } from '../../utils/clientEndpoints';

const SystemSettings = {
    path: clientEndpoints.central_system_settings,
    children: [
        {
            path: '',
            element: <SystemSettingsPage />,
            name: 'central_system_settings',
        },
        {
            path: ':id/edit',
            element: <SystemSettingsForm />,
            name: 'central_system_settings_edit',
        },
    ],
};

export default SystemSettings;

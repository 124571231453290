import Grid from '@mui/material/Grid';
import { Page } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from '../../../../../components/Form/Form';
import MCCLoading from '../../../../../components/MCCLoading';
import campaignsService from '../../../../../services/campaigns/campaigns';
import { PROJECT_NAME } from '../../../../../utils/AppConst';
import { clientEndpoints } from '../../../../../utils/clientEndpoints';
import { CampaignFormType, initialCampaignForm } from '../types';
import FormPage from './FormPage';
import { checkPageAccessByEntityOwner } from '../../../../../utils/AccessManagementHelper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../../store/auth';
import { resetFields } from '../../../../../store/Unit/campaignView';

/**
 *
 * @param param
 * @returns
 */
export default function CampaignForm() {
    const [form, setForm] = useState<CampaignFormType>(initialCampaignForm);
    const [loading, setLoading] = useState<boolean>(true);
    const { id } = useParams() as { id: string };
    const { view } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const isInOwnViewLayout =
        view.unitCampaignManagement !== undefined &&
        [ViewUnitCampaignManagementType.Own, ViewUnitCampaignManagementType.OtherOwner].includes(
            view.unitCampaignManagement,
        );

    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Kampány megtekintése',
        breadcrumbs: {
            'campaign': 'Kampányok',
            [clientEndpoints.unit_campaigns]: 'Kampánylista',
            'campaign-view': 'Kampány megtekintése',
            [clientEndpoints.unit_campaigns_view.replace(':id', id)]: form.name,
        },
    };
    const checkEntityAccess = useCallback(
        (res: AxiosResponse) => checkPageAccessByEntityOwner(res, isInOwnViewLayout),
        [isInOwnViewLayout],
    );

    useEffect(() => {
        if (id) {
            setLoading(true);
            const requests: (void | Promise<void>)[] = [
                campaignsService
                    .get(id)
                    .then(checkEntityAccess)
                    .then((response) => setForm(response.data)),
            ];
            axios.all(requests).finally(() => setLoading(false));
        }
    }, [id, checkEntityAccess]);

    useEffect(() => {
        return () => {
            dispatch(resetFields());
        };
    }, [dispatch]);

    if (loading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Grid item xs={12}>
                <Form
                    fields={[]}
                    initialValues={form}
                    onSubmit={() => console.log('implement for edit')}
                    back={clientEndpoints.unit_campaigns}
                    hideButtons
                >
                    {(props) => <FormPage {...props} />}
                </Form>
            </Grid>
        </Page>
    );
}

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { theme } from './theme';
const useStyles = makeStyles(() => ({
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

interface IInfoAlert {
    backText: string;
    backLink: string;
    children: JSX.Element;
}

function InfoAlert({ backText, backLink, children }: IInfoAlert) {
    const classes = useStyles();

    return (
        <Grid item>
            <ThemeProvider theme={createTheme()}>
                <Alert severity="info">
                    <Grid container spacing={2} alignItems="flex-end">
                        <Grid item>{children}</Grid>
                        <Grid item>
                            <Link to={backLink} className={classes.link}>
                                {backText}
                            </Link>
                        </Grid>
                    </Grid>
                </Alert>
            </ThemeProvider>
        </Grid>
    );
}

export { InfoAlert };

import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api';

export interface IAddressGroup {
    author: string;
    authorDeleted: boolean;
    description: string;
    editor: string;
    editorDeleted: boolean;
    id: number;
    insertDate: string;
    name: string;
    prohibitionOfUnsubscription: string;
    unsubscribeUNow: string;
    updateDate: string;
}

const addressGroupsService = {
    ...crudTemplate(endpoints.address.addressGroups.main),
    get2: (id: string | number, readonly: boolean | undefined) =>
        API.get(endpoints.address.addressGroups.main + '/' + id + (readonly ? '?readonly=1' : '')),
};

export default addressGroupsService;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use({
        type: 'postProcessor',
        name: 'customValidatorProcessor',
        process: (value) => {
            return value;
        },
    })
    .init({
        postProcess: ['customValidatorProcessor'],
        fallbackLng: 'hu',
        debug: true,
        load: 'languageOnly',
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: `${process.env.REACT_APP_BACKEND_URL}/translations/{{lng}}`,
        },
        react: {
            useSuspense: true,
        },
    });

export default i18n;

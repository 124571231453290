import api from '../../api';
import { endpoints } from '../../utils/endPoints';

const emailPostedAttachments = {
    download: (id: number) =>
        api.get(endpoints.unit.emailPostedAttachments.download.replace('{id}', id.toString()), {
            responseType: 'blob',
        }),
};

export default emailPostedAttachments;

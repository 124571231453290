import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FormikListPage } from '@silinfo/front-end-template';
import Header, { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SplitButton, { IExportContextWithCampaignId } from '../../../../components/Form/SplitButton';
import MCCLoading from '../../../../components/MCCLoading';
import campaignsService from '../../../../services/campaigns/campaigns';
import campaignsEmailPostedService from '../../../../services/campaigns/campaignsEmailPosted';
import { RootState } from '../../../../store';
import { addFields } from '../../../../store/Unit/campaignFormList';
import { IForm, initialInfo, PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { InfoAlert } from '../../../../utils/Components';
import CampaignFormDialogButton from './CampaignFormDialogButton';
import { ICampaign, initialCampaign } from './types';

function CampaignFormList() {
    const [campaign, setCampaign] = useState<ICampaign>(initialCampaign);
    const { id } = useParams() as { id: string };
    const [info, setInfo] = useState<IForm>(initialInfo());
    const { tableFields } = useSelector((state: RootState) => state.campaignFormList);
    const breadcrumbs = {
        addresses: 'Kampányok',
        [clientEndpoints.unit_campaigns]: 'Kampánylista',
        recipients_name: 'Űrlap kitöltések',
    };

    const headerOnlyGroup: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Űrlap kitöltések',
        breadcrumbs: {},
    };

    const exportContext: IExportContextWithCampaignId = {
        campaignId: +id,
        filter: info,
        initiateExport: campaignsEmailPostedService.initiateExportFormsFilled,
        checkExport: campaignsEmailPostedService.checkExportFormsFilled,
        downloadExport: campaignsEmailPostedService.downloadExportFormsFilled,
    };

    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        axios
            .all([campaignsService.get(+id), campaignsService.columns(+id)])
            .then((res) => {
                setCampaign(res[0].data);
                dispatch(addFields(res[1].data));
            })
            .finally(() => setLoading(false));
    }, [dispatch, id]);

    if (loading) {
        return <MCCLoading />;
    }

    const columns = tableFields.map((item) => ({ ...item }));

    return (
        <Grid container spacing={2}>
            <Header {...{ ...headerOnlyGroup, breadcrumbs }} />
            <InfoAlert backText={'Vissza a kampánylistához'} backLink={clientEndpoints.unit_campaigns}>
                <Box>
                    A(z) <strong>{campaign.name}</strong> kampány űrlap kitöltései.
                    {campaign.submitDate ? ' Kiküldés ideje: ' + new Date(campaign.submitDate).toLocaleString() : ''}
                </Box>
            </InfoAlert>
            <Grid item xs={12}>
                <FormikListPage
                    autoload
                    header={headerOnlyGroup}
                    columns={columns}
                    service={{
                        filter: (form) => {
                            setInfo(form);
                            return campaignsEmailPostedService.filterFilledForms(+id, form);
                        },
                    }}
                    defaultSort={{ email: 'asc' }}
                    filter={{
                        fields: [
                            {
                                name: 'email',
                                label: 'E-mail cím',
                                format: { xs: 12, md: 4, lg: 4 },
                            },
                            {
                                name: 'insertDateFrom',
                                label: 'Kitöltés ideje (-tól)',
                                type: 'datetime-local',
                                format: { xs: 12, md: 4, lg: 4 },
                            },
                            {
                                name: 'insertDateTo',
                                label: 'Kitöltés ideje (-ig)',
                                type: 'datetime-local',
                                format: { xs: 12, md: 4, lg: 4 },
                            },
                        ],
                    }}
                    customButtons={
                        <>
                            <CampaignFormDialogButton />
                            <Grid item>
                                <SplitButton placement="top" exportContext={exportContext} />
                            </Grid>
                        </>
                    }
                    responseKey="data"
                />
            </Grid>
        </Grid>
    );
}

export default CampaignFormList;

import { createTheme } from '@mui/material/styles';

const myTheme = {
    palette: {
        type: 'light',
        primary: {
            main: '#f3ca12',
            contrastText: '#fff',
        },
        secondary: {
            main: '#016e80',
            contrastText: '#fff',
        },
        info: {
            main: '#f3ca12',
            contrastText: '#fff',
        },
        success: {
            main: '#35A899',
            contrastText: '#fff',
        },
    },
};

export const theme = createTheme(myTheme);

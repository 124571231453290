export type Role = 'ROLE_ADMIN';

const ROLES = {
    ADMIN: 'ROLE_ADMIN',
};

export default ROLES;

export enum CentralRole {
    ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
}

export enum UnitRole {
    ROLE_OTHER_HANDLE_OWN_CAMPAIGN = 'ROLE_OTHER_HANDLE_OWN_CAMPAIGN',
    ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS = 'ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS',
    ROLE_OTHER_OTHER_USERS_VIEW_OWN_CAMPAIGN_DATA = 'ROLE_OTHER_OTHER_USERS_VIEW_OWN_CAMPAIGN_DATA',
}

//export type Role = CentralRole & UnitRole;

import Grid from '@mui/material/Grid';
import { Accordion, Page } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import MCCLoading from '../../../components/MCCLoading';
import settingsService from '../../../services/Central/settingsService';
import usersService from '../../../services/Central/userService';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import { title } from './fields';
import UsersForm from './Form';
import { initialForm, initialRoleOptions, IUsersForm, RoleOptions } from './types';

export default function UsersFormPage() {
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [form, setForm] = useState<IUsersForm>(initialForm);
    const [units, setUnits] = useState<Option<number>[]>([]);
    const [roleOptions, setRoleOptions] = useState<RoleOptions>(initialRoleOptions);
    const { id } = useParams();
    const [ownCampaignValidEmailDomains, setOwnCampaignValidEmailDomains] = useState<string[]>([]);

    const header = {
        project: 'MCC Hírlevél- és űrlapkezelő rendszer',
        title: 'Felhasználókezelés',
        breadcrumbs: {
            administration: 'Adminisztráció',
            [clientEndpoints.central_users]: 'Felhasználókezelés',
            form: title(id),
        },
    };

    useEffect(() => {
        if (id) {
            setPageLoading(true);
            axios
                .all([
                    usersService.getRoles().then((response: AxiosResponse) => setRoleOptions(response.data)),
                    usersService.get(id).then((response: AxiosResponse) => setForm(response.data)),
                    usersService.getUnits().then((response: AxiosResponse) => setUnits(response.data)),
                    settingsService
                        .getOwnCampaignValidEmailDomains()
                        .then((res) => setOwnCampaignValidEmailDomains(res.data.value)),
                ])
                .finally(() => setPageLoading(false));
        } else {
            setPageLoading(true);
            axios
                .all([
                    usersService.getRoles().then((response) => setRoleOptions(response.data)),
                    usersService.getUnits().then((response: AxiosResponse) => setUnits(response.data)),
                    settingsService
                        .getOwnCampaignValidEmailDomains()
                        .then((res) => setOwnCampaignValidEmailDomains(res.data.value)),
                ])
                .finally(() => setPageLoading(false));
        }
    }, [id]);

    const handleSubmit = (form: IUsersForm) => (id ? usersService.update(id, form) : usersService.create(form));

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Grid item xs={12}>
                <Accordion title={title(id)} defaultExpanded disableClosing>
                    <Form onSubmit={handleSubmit} initialValues={form} fields={[]} back={clientEndpoints.central_users}>
                        {(props) => (
                            <UsersForm
                                ownCampaignValidEmailDomains={ownCampaignValidEmailDomains}
                                roleOptions={roleOptions}
                                units={units}
                                {...props}
                            />
                        )}
                    </Form>
                </Accordion>
            </Grid>
        </Page>
    );
}

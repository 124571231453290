import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BackToLoginButton from './BackToLoginButton';
interface BackToLoginComponentProps {
    message: string;
}
const BackToLoginComponent = (props: BackToLoginComponentProps) => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography textAlign="center" variant="h5">
                {props.message}
            </Typography>
        </Grid>
        <Grid item xs={12} justifyContent="center" textAlign="center">
            <BackToLoginButton />
        </Grid>
    </Grid>
);

export default BackToLoginComponent;

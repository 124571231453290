import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { GridRenderCellParams } from '@mui/x-data-grid';

const useStyles = makeStyles(() => ({
    truncate: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'black',
    },
    info: {
        margin: '8px',
    },
}));

const KeyCellRenderer = (params: GridRenderCellParams) => {
    const classes = useStyles();

    return (
        <>
            {params.row.keyTranslated}
            <Tooltip className={classes.info} title={params.row.helperText}>
                <div className={classes.truncate}>
                    <Info />
                </div>
            </Tooltip>
        </>
    );
};

export default KeyCellRenderer;

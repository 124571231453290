import GrapesJS from 'grapesjs';
import 'grapesjs-preset-newsletter';
import 'grapesjs-preset-newsletter/dist/grapesjs-preset-newsletter.css';
import 'grapesjs-preset-newsletter/dist/grapesjs-preset-newsletter.min.js';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs/dist/grapes.min.js';
import React, { memo } from 'react';
import instance from '../../api';
import { endpoints } from '../../utils/endPoints';
import './ComplexEditor.scss';
import MessageFieldsDialog from './MessageFieldsDialog';
import hu, { nlTranslations } from './hu';

export interface ReactComplexEditorProps {
    id: HTMLElement['id'];
    setter(html: string): void;
    content: string;
}

export function ReactComplexEditor(props: ReactComplexEditorProps) {
    const { id, setter, content } = props;

    const [editor, setEditor] = React.useState<GrapesJS.Editor>();
    const [component, setComponent] = React.useState<GrapesJS.Component | GrapesJS.RichTextEditor | null>(null);

    React.useEffect(() => {
        if (editor) {
            editor.on('load', function () {
                editor.setComponents(content);

                const parser = new DOMParser();
                const parsedDocument = parser.parseFromString(content, 'text/html');
                const parsedBody = parsedDocument.querySelector('body');
                const domComponents = editor.DomComponents;
                const bodyComponent = domComponents.getWrapper();

                bodyComponent.setAttributes({
                    style: parsedBody?.getAttribute('style'),
                });

                setter(editor.runCommand('gjs-get-inlined-html'));
            });
            editor.on('update', () => {
                setter(editor.runCommand('gjs-get-inlined-html'));
            });

            editor.on('modal:open', () => {
                const importButton = document.querySelector('button.gjs-btn-prim.gjs-btn-import') as HTMLButtonElement;
                if (importButton) {
                    importButton.type = 'button';
                }
            });

            editor.on('component:selected', (component) => {
                if (component.attributes.type === 'text') {
                    const addMessageField = {
                        id: 'add-message-field',
                        attributes: {
                            class: 'fa fa-plus-square',
                            title: 'Üzenetmező beszúrása a végére',
                        },
                        command: () => {
                            setComponent(component);
                        },
                    };

                    const defaultToolbar = component.get('toolbar');
                    const alreadyExists = defaultToolbar.some(
                        (item: Record<string, unknown>) => item.id === addMessageField.id,
                    );
                    if (!alreadyExists) {
                        defaultToolbar.push(addMessageField);
                    }
                    component.set('toolbar', defaultToolbar);
                }
            });

            editor.on('rte:enable', () => {
                const rte = editor.RichTextEditor;
                const alreadyExists = rte
                    .getAll()
                    .some((item: Record<string, unknown>) => item.id === 'addMessageField');
                if (!alreadyExists && rte) {
                    rte.add('addMessageField', {
                        id: 'addMessageField',
                        icon: `<div class="fa fa-plus-square"></div>`,
                        attributes: {
                            class: 'gjs-rte-action',
                            title: 'Üzenetmező beszúrása',
                        },
                        result: (result: GrapesJS.RichTextEditor) => {
                            setComponent(result);
                        },
                    });
                }
            });
        }
    }, [editor, setter, content]);

    React.useEffect(() => {
        const selector = `#${id}`;
        if (!editor) {
            const editorInit = GrapesJS.init({
                container: selector,
                fromElement: true,
                plugins: ['gjs-preset-newsletter'],
                i18n: {
                    locale: 'hu',
                    messages: {
                        hu,
                    },
                },
                colorPicker: {
                    appendTo: 'parent',
                    offset: { top: 26, left: -166 },
                },
                pluginsOpts: {
                    'gjs-preset-newsletter': {
                        //ezt a csodát muszáj lemásolni, mert a hivatkozás nem működik különben :))))))))))))))))
                        ...nlTranslations,
                    },
                },
                assetManager: {
                    upload: endpoints.pureUpload.replace(':type', 'grapesjs'),
                    multiUpload: false,
                    customFetch: async (url, options): Promise<unknown> => {
                        const formData: FormData = options.body;
                        const file = formData.get('files') as File | null;
                        formData.delete('files');
                        if (file) {
                            formData.append('upload', file);
                        }
                        const { data } = await instance.post(url, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });

                        return {
                            data: [data.url, { src: data.url }],
                        };
                    },
                } as GrapesJS.AssetManagerConfig,
                storageManager: false, // ne mentse a dolgokat, kezeljük kívülről
            });
            editorInit.BlockManager.get('text').set({
                content: {
                    ...editorInit.BlockManager.get('text').get('content'),
                    content: 'Szöveg helye',
                },
            });
            setEditor(editorInit);
        }

        return () => {
            if (editor) {
                editor.destroy();
            }
        };
    }, [editor, id]);

    return (
        <>
            <div id={id} className="mccNl"></div>
            <MessageFieldsDialog componentState={[component, setComponent]} />
        </>
    );
}

export default memo(ReactComplexEditor);

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Accordion, Page, Select } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { AxiosResponse } from 'axios';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Buttons from '../../../components/Form/Buttons';
import MCCLoading from '../../../components/MCCLoading';
import { SuperAdminServiceType } from '../../../services/Central/superAdminService';
import { create } from '../../../store/notification';
import colors from '../../../styles/colors';
import { IForm, PROJECT_NAME, transformApiErrors } from '../../../utils/AppConst';
import { onChange } from '../../../utils/form';
import { ErrorResponse } from '../../../utils/Interfaces/interfaces';

export interface ISettingsForm extends IForm {
    id: number;
    key: string;
    value: string;
    keyTranslated: string;
    description: string;
    name: string;
}

const initialForm: ISettingsForm = {
    id: 0,
    key: '',
    value: '',
    keyTranslated: '',
    helperText: '',
    description: '',
    name: '',
};

const useStyles = makeStyles(() => ({
    title: {
        fontSize: 'larger',
        color: 'white',
        marginLeft: '4px',
    },
    smallerTitle: {
        padding: '4px',
        color: 'white',
    },
    xButton: {
        fontWeight: 'bolder',
        color: 'white',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

export type SystemSettingsFormProps = {
    title: string;
    clientUrl: string;
    clientEditUrl: string;
    service: SuperAdminServiceType;
};

export default function SystemSettingsForm({ title, clientUrl, clientEditUrl, service }: SystemSettingsFormProps) {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<ISettingsForm>(initialForm);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [infoOpen, setInfoOpen] = useState<boolean>(true);
    const { id } = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();

    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: title,
        breadcrumbs: {
            admin: 'Adminisztráció',
            [clientUrl]: title,
            [clientEditUrl.replace(':id', String(id))]: 'Szerkesztés',
        },
    };

    useEffect(() => {
        if (id) {
            setLoading(true);
            service
                .get(id)
                .then((response) => setForm(response.data))
                .finally(() => setLoading(false));
        }
    }, [id, service]);

    const handleSubmit = (form: ISettingsForm, { setErrors }: FormikHelpers<ISettingsForm>) => {
        if (id) {
            setFormLoading(true);

            service
                .update(id, form)
                .then((response: AxiosResponse) => {
                    dispatch(
                        create({
                            type: 'success',
                            message: 'Sikeres szerkesztés',
                            redirect: clientUrl,
                        }),
                    );
                    setForm(response.data || {});
                })
                .catch((error: ErrorResponse) => {
                    if (error.response?.status === 422) {
                        setErrors(transformApiErrors(error.response?.data.violations));
                    }
                    dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
                })
                .finally(() => setFormLoading(false));
        }
    };

    if (loading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={header.title}>
                <Formik initialValues={form} onSubmit={handleSubmit}>
                    {({ setFieldValue, errors, touched, values }) => {
                        return (
                            <Form>
                                <Grid container direction="column" spacing={2}>
                                    {infoOpen && values.helperText && (
                                        <Box
                                            component="span"
                                            sx={{
                                                p: 2,
                                                margin: '16px',
                                                backgroundColor: colors.insert,
                                                paddingTop: '10px',
                                            }}
                                        >
                                            <Grid item xs={12} container direction="row">
                                                <Grid item xs={11}>
                                                    <Typography className={classes.title}>Info</Typography>
                                                </Grid>
                                                <Grid container item xs={1} direction="row-reverse">
                                                    <Typography
                                                        className={classes.xButton}
                                                        onClick={() => setInfoOpen(false)}
                                                    >
                                                        X
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Divider />
                                            <Typography className={classes.smallerTitle}>
                                                {values.description}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Grid item xs={12} md={6}>
                                        <Field as="div" field="value">
                                            {values.key === 'email_attached_images' ? (
                                                <Select
                                                    id="value"
                                                    name="value"
                                                    label={values.name}
                                                    error={Boolean(errors.value && touched.value)}
                                                    helperText={touched.value && errors.value}
                                                    value={values.value}
                                                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                                                    options={[
                                                        { label: 'igen', value: 1 },
                                                        { label: 'nem', value: 0 },
                                                    ]}
                                                />
                                            ) : (
                                                <TextField
                                                    name="value"
                                                    label={values.name}
                                                    error={Boolean(errors.value && touched.value)}
                                                    helperText={touched.value && errors.value}
                                                    value={values.value}
                                                    onChange={onChange(setForm, form)}
                                                    fullWidth
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Buttons buttonLoading={formLoading} cancel={clientUrl} />
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Accordion>
        </Page>
    );
}

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { CommonChip, OwnChip } from '../../../../../components/Layout/Chips';
import { HTMLAttributes } from 'react';

const OptionRenderer = (
    liProps: HTMLAttributes<HTMLLIElement>,
    option: {
        label: string;
        value: number;
        own: boolean;
    },
) => (
    <MenuItem {...liProps}>
        <ListItemIcon>{option.own ? <OwnChip /> : <CommonChip />}</ListItemIcon>
        <ListItemText>{option.label}</ListItemText>
    </MenuItem>
);

export default OptionRenderer;

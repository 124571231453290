import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { AxiosResponse } from 'axios';
import API from '../../../api';
import { StatisticsCampaignFilter } from '../../../pages/Unit/Statistics/Campaign/types';
import { endpoints } from '../../../utils/endPoints';
import { crudTemplate, exportTemplate, ICrudTemplate, IExportTemplate } from '../../templates';

export interface IStatisticsService {
    getCampaigns: (ownerId?: number | null) => Promise<AxiosResponse<Option[]>>;
}

export interface IStatisticsAggregateService
    extends IStatisticsService,
        ICrudTemplate<StatisticsCampaignFilter>,
        IExportTemplate {}

const statisticsAggregateService: IStatisticsAggregateService = {
    ...crudTemplate(endpoints.unit.statistics.aggregate.main),
    ...exportTemplate(endpoints.unit.statistics.aggregate.main),
    getCampaigns: (ownerId?: number | null) =>
        API.get(endpoints.unit.statistics.aggregate.getCampaigns + (ownerId ? '?ownerId=' + ownerId.toString() : '')),
};

export default statisticsAggregateService;

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Input } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import React from 'react';
import ActionCellRenderer from '../../../../components/ActionCellRenderer';
import BtnCellRenderer from './BtnCellRenderer';
import { IAddressesField, ResponseField } from './types';

/**
 * The default fields for the listPage
 */
export const defaultFields = (): IAddressesField[] => {
    return [
        { label: 'E-mail cím', name: 'email' },
        { label: 'Csoportok', name: 'groups' },

        { label: 'Import azonosító', name: 'importId' },
        { label: 'Importáló', name: 'importer' },
        { label: 'Létrehozó', name: 'author' },
        { label: 'Létrehozás ideje', name: 'insertDate' },
        { label: 'Módosító', name: 'editor' },
        { label: 'Módosítás ideje', name: 'updateDate' },
        { label: 'Leiratkozás ideje', name: 'unsubscribeDate' },
        { label: 'Műveletek', name: 'operations' },
    ].map((field) => ({ ...field, visible: true, default: true }));
};
const RenderOperationsCell = (params: GridRenderCellParams) => {
    return (
        <ActionCellRenderer>
            <BtnCellRenderer params={params} />
        </ActionCellRenderer>
    );
};

const RenderCell = (params: GridRenderCellParams) => params.value || '-';

const OptimizedOperationsRenderCell = React.memo(RenderOperationsCell, () => true);
const OptimizedRenderCell = React.memo(RenderCell, () => true);

/**
 * The default fields for the listPage table
 */
export const defaultTableFields: GridColDef[] = defaultFields().map((field) => {
    if (field.name === 'operations') {
        return {
            ...field,
            headerName: field.label,
            field: field.name,
            minWidth: 120,
            renderCell: (params) => <OptimizedOperationsRenderCell {...params} />,
        };
    } else {
        return {
            ...field,
            headerName: field.label,
            field: field.name,
            minWidth: field.name === 'insertDate' ? 160 : field.name === 'email' ? 250 : 150,
            renderCell: (params) => <OptimizedRenderCell {...params} />,
        };
    }
});

/**
 * The default fields for the listPage filter.
 */
export const defaultFilterFieldsFunction = (defaultFields: IAddressesField[]): Input[] => {
    const fields: Input[] = [];

    defaultFields.forEach((field) => {
        if (field.name === 'operations') {
            return;
        }

        if (field.name === 'groups') {
            fields.push({
                name: field.name,
                label: field.label,
                format: { xs: 12, md: 6, lg: 3 },
                type: 'multiselect',
                options: [],
            });
        } else if (field.name.includes('Date')) {
            fields.push({
                name: `${field.name}From`,
                label: `${field.label} (-tól)`,
                format: { xs: 12, md: 6, lg: 3 },
                type: 'datetime-local',
            });
            fields.push({
                name: `${field.name}To`,
                label: `${field.label} (-ig)`,
                format: { xs: 12, md: 6, lg: 3 },
                type: 'datetime-local',
            });
        } else {
            if (field.label) {
                fields.push({
                    name: field.name,
                    label: field.label,
                    format: { xs: 12, md: 6, lg: 3 },
                });
            }
        }
    });

    return fields;
};

export const createDefaultFilterFields = (): Input[] => defaultFilterFieldsFunction(defaultFields());

export function transformData(data: ResponseField[]): IAddressesField[] {
    const flaggedFields: ResponseField[] = [];
    const unflaggedFields: ResponseField[] = [];

    data.forEach((field) => {
        if (field.flag) {
            /*            if (field.flag === 1) {
                flaggedFields.push({
                    ...field,
                    key: 'email',
                    label: 'E-mail cím',
                    default: true,
                });
            }*/
            if (field.flag === 4 || field.flag === 5) {
                flaggedFields.push({
                    ...field,
                    visible: true,
                });
            }
        } else {
            unflaggedFields.push(field);
        }
    });

    const fields = [...flaggedFields, ...unflaggedFields];

    return fields.map((item) => {
        return {
            id: item.id,
            name: item.key,
            label: item.label,
            visible: item.default || item.visible,
            flag: item.flag,
            default: item.default,
        };
    });
}

/**
 * The default fields for the form of the addressList
 */
export const defaultFormFields = (addressGroupOptions: Option[]): Input[] => {
    return [
        {
            name: 'email',
            label: 'E-mail cím',
            format: { xs: 12, md: 6, lg: 3 },
            type: 'text',
        },
        {
            name: 'groups',
            label: 'Csoportok',
            format: { xs: 12, md: 6, lg: 3 },
            type: 'multiselect',
            options: addressGroupOptions,
        },
    ];
};

export const createFormFields = (fields: IAddressesField[], addressGroupOptions: Option[]): Input[] => {
    const flaggedFields: Input[] = [];
    const unflaggedFields: Input[] = [];

    fields.forEach((field) => {
        if (!field.default) {
            if (field.flag) {
                if (field.flag === 4 || field.flag === 5) {
                    flaggedFields.push({
                        name: field.name,
                        label: field.label,
                        format: { xs: 12, md: 6, lg: 3 },
                    });
                }
            } else {
                unflaggedFields.push({
                    name: field.name,
                    label: field.label,
                    format: { xs: 12, md: 6, lg: 3 },
                });
            }
        }
    });

    return [
        defaultFormFields(addressGroupOptions)[0], // email
        ...flaggedFields,
        defaultFormFields(addressGroupOptions)[1], // groups
        ...unflaggedFields,
    ];
};

import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { FormikProps } from 'formik';
import { ViewLayoutType } from '../../../store/auth';
import { IUnitsFormType } from '../Units/types';

export type RoleGroupsType = {
    [key: string]: {
        [key: string]: string;
    };
};

export interface IUsersForm
    extends Record<
        string,
        | string
        | number
        | boolean
        | string[]
        | undefined
        | { origFileName: string; uuidFileName: string }
        | RoleGroupsType
        | Record<string, string>
    > {
    id?: number;
    lastName: string;
    firstName: string;
    email: string;
    roles: RoleGroupsType;
    sendActivationEmail: boolean;
    lastLogin?: string;
}

export const initialForm: IUsersForm = {
    lastName: '',
    firstName: '',
    email: '',
    roles: {},
    sendActivationEmail: false,
};

export interface UnitRoles {
    label: string;
    value: Option<string>[];
}

export interface RoleOptions {
    central: Option<string>[];
    unit: UnitRoles[];
}

export const initialUnitRoleOptions: UnitRoles[] = [{ label: '', value: [] }];

export const initialRoleOptions: RoleOptions = {
    central: [],
    unit: [{ label: '', value: [] }],
};

export type UserFormProps = {
    units: Option<number>[];
    roleOptions: RoleOptions;
    rolesView?: boolean;
    layoutType?: ViewLayoutType;
    selectedUnit?: IUnitsFormType;
    ownCampaignValidEmailDomains: string[];
} & FormikProps<IUsersForm>;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import MCCLoading from '../../components/MCCLoading';
import { RootState } from '../../store';
import { AppViewType, isViewSelected } from '../../store/auth';
import { checkAccess } from '../../utils/AccessManagementHelper';
import { NO_REDIRECT_URL_KEYS } from '../../utils/AppConst';
import { isLogin } from '../../utils/AuthHelper';
import { IUser } from '../../utils/Interfaces/interfaces';
import {
    clientEndpoints,
    permissionFreeClientEndpointKeys,
    viewSelectClientEndpointKeys,
} from '../../utils/clientEndpoints';
import api from '../../api';
import { endpoints } from '../../utils/endPoints';
import { setAccessTree, setAccessTreeLoading } from '../../store/accessManagement';
import { AxiosResponse } from 'axios';

export default function AccessChecker() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, view } = useSelector((state: RootState) => state.auth) as { user: IUser; view: AppViewType };
    const { accessTree, accessTreeLoading } = useSelector((state: RootState) => state.accessManagement);
    const [loading, setLoading] = useState<boolean>(true);

    const accessTreeLoadingCondition =
        (Object.keys(accessTree.central).length === 0 && Object.keys(accessTree.unit).length === 0) ||
        accessTreeLoading;

    /**
     * Ez azért kell, mert reload-nál vagy új fül nyitáskor 403-at dobott a rendszer
     */
    useEffect(() => {
        if (accessTreeLoadingCondition) {
            setLoading(true);
            dispatch(setAccessTreeLoading(true));
            api.get(endpoints.accessTree)
                .then((res: AxiosResponse) => {
                    dispatch(setAccessTree(res.data));
                    dispatch(setAccessTreeLoading(false));
                })
                .finally(() => setLoading(false));
        } else {
            /**
             * Get clientEndpoint from location
             */
            const menuKey: keyof typeof clientEndpoints = (Object.keys(clientEndpoints).find((elem) => {
                const endPointSplit = clientEndpoints[elem as keyof typeof clientEndpoints].split('/');
                const locationNameSplit = location.pathname.split('/');
                if (endPointSplit.length !== locationNameSplit.length) return false;

                let endPointString = '';
                let locationNameString = '';

                for (let i = 0; i < endPointSplit.length; i++) {
                    if (endPointSplit[i][0] !== ':') {
                        endPointString += endPointSplit[i];
                        locationNameString += locationNameSplit[i];
                    }
                }

                return endPointString === locationNameString;
            }) || '') as keyof typeof clientEndpoints;

            if (!isViewSelected()) {
                /**
                 * HA nincs beállítva layoutType, de be van jelentkezve a felhasználó, VAGY
                 * HA a layoutType === 'unit', de nincs beállítva selectedUnit,
                 * ÉS a jelenlegi oldal nem a nézetválasztós oldalak egyike, akkor a nézetválasztós oldalra navigálunk.
                 */
                if (
                    isLogin() &&
                    !viewSelectClientEndpointKeys.includes(menuKey) &&
                    !NO_REDIRECT_URL_KEYS.includes(menuKey)
                ) {
                    navigate(clientEndpoints.switch_layout);
                }
            } else if (
                !permissionFreeClientEndpointKeys.includes(menuKey) &&
                !checkAccess(menuKey, accessTree, user, view.layout)
            ) {
                navigate(clientEndpoints.access_denied);
            }

            /**
             FIXME: itt a nézetet be kellene állítani ???? - ha bugol akkor itt keresd
            */
            setLoading(false);
        }
    }, [location.pathname, navigate, accessTree, user, view, loading, accessTreeLoadingCondition, dispatch]);

    if (loading || accessTreeLoading) {
        return <MCCLoading />;
    }
    return <></>;
}

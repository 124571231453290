import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useRoutes } from 'react-router-dom';
import '../App.css';
import Layout from '../components/Layout';
import MCCLoading from '../components/MCCLoading';
import OffLayout from '../components/OffLayout';
import SelectUnit from '../components/OffLayout/SelectUnit';
import SelectUnitCampaignManagement from '../components/OffLayout/SelectUnitCamaignManagement';
import SelectOwnerCampaignManagement from '../components/OffLayout/SelectOwnerCampaignManagement';
import SwitchLayout from '../components/OffLayout/SwitchLayout';
import Activation from '../pages/Central/Activation';
import ResetPassword from '../pages/Central/ResetPassword';
import FormFiller from '../pages/Form';
import Login from '../pages/Login';
import MessageView from '../pages/MessageView';
import FormsPreview from '../pages/Unit/Templates/Forms/Preview';
import errors from '../routing/Errors';
import unsubscribe from '../routing/Unsubscribe';
import { RootState } from '../store';
import { ViewLayoutType } from '../store/auth';
import { clientEndpoints } from '../utils/clientEndpoints';
import { adminMenus } from './adminMenus';
import { unitMenus } from './unitMenus';
import FormView from '../pages/Form/View';

export interface IRoute {
    path: string;
    element?: JSX.Element;
    children?: IRoute[];
    name?: keyof typeof clientEndpoints;
}

export function routingArr(layoutType: ViewLayoutType = ViewLayoutType.Central) {
    const login = {
        path: clientEndpoints.login,
        element: <Login />,
        children: [],
    };
    const confirmation = {
        path: clientEndpoints.central_email_confirmation,
        element: <Activation />,
        children: [],
    };
    const passwordReset = {
        path: clientEndpoints.central_email_reset_password,
        element: <ResetPassword />,
        children: [],
    };
    const messageView = {
        path: clientEndpoints.messageView,
        element: <MessageView />,
        children: [],
    };
    const loggedIn: IRoute = {
        path: clientEndpoints.home,
        element: !layoutType ? <MCCLoading /> : <Layout />,
        children: !layoutType
            ? [...adminMenus, ...unitMenus]
            : layoutType === 'unit'
            ? [...unitMenus]
            : [...adminMenus],
    };

    const formPreview: IRoute = {
        path: clientEndpoints.unit_forms_preview,
        element: <FormsPreview />,
    };

    const formFiller: IRoute = {
        path: clientEndpoints.form_filler,
        element: <FormFiller />,
    };

    const formViewer: IRoute = {
        path: clientEndpoints.form_viewer,
        element: <FormView />,
    };

    const switchLayout: IRoute = {
        path: clientEndpoints.switch_layout,
        element: (
            <OffLayout>
                <Outlet />
            </OffLayout>
        ),
        children: [
            {
                path: clientEndpoints.switch_layout,
                element: <SwitchLayout />,
            },
        ],
    };

    const selectUnit: IRoute = {
        path: clientEndpoints.select_unit,
        element: (
            <OffLayout>
                <Outlet />
            </OffLayout>
        ),
        children: [
            {
                path: clientEndpoints.select_unit,
                element: <SelectUnit />,
            },
        ],
    };

    const selectUnitCampaignManagement: IRoute = {
        path: clientEndpoints.select_unit_campaign_management,
        element: (
            <OffLayout>
                <Outlet />
            </OffLayout>
        ),
        children: [
            {
                path: clientEndpoints.select_unit_campaign_management,
                element: <SelectUnitCampaignManagement />,
            },
        ],
    };

    const selectOwnerCampaignManagement: IRoute = {
        path: clientEndpoints.select_owner_campaign_management,
        element: (
            <OffLayout>
                <Outlet />
            </OffLayout>
        ),
        children: [
            {
                path: clientEndpoints.select_owner_campaign_management,
                element: <SelectOwnerCampaignManagement />,
            },
        ],
    };

    //TODO: error routes ide vezetése importálás helyett (? ha szükséges ?)

    return [
        login,
        formFiller,
        confirmation,
        passwordReset,
        loggedIn,
        switchLayout,
        selectUnit,
        unsubscribe,
        errors,
        formPreview,
        selectUnitCampaignManagement,
        selectOwnerCampaignManagement,
        messageView,
        formViewer,
    ];
}

function Routing() {
    const { view } = useSelector(
        (state: RootState) => state.auth,
        (left, right) => JSON.stringify(left.view.layout) === JSON.stringify(right.view.layout),
    );
    const routingArray = useMemo(() => {
        return routingArr(view.layout);
    }, [view]);

    const Routing = () => useRoutes(routingArray);

    return <Routing />;
}

export default Routing;

import { GridColDef } from '@mui/x-data-grid';
import { Input } from '@silinfo/front-end-template';
import BtnCellRenderer from './BtnCellRenderer';
import KeyCellRenderer from './KeyCellRenderer';
import ValueCellRenderer from './ValueCellRenderer';

export const filterFields: Input[] = [
    {
        name: 'key',
        label: 'Kulcs',
        format: { xs: 12, md: 6, lg: 4 },
    },
].map((elem) => ({ ...elem }));

export const formFields: Input[] = [];

export const columns: GridColDef[] = [
    { headerName: 'Kulcs', field: 'key', renderCell: KeyCellRenderer },
    { headerName: 'Érték', field: 'value', renderCell: ValueCellRenderer },
    {
        headerName: 'Műveletek',
        field: 'operations',
        renderCell: BtnCellRenderer,
        align: 'center',
        sortable: false,
    },
];

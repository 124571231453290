import Switch from '@mui/material/Switch';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import emailsService, { ChangeStatusType } from '../../../services/Unit/emails';
import { RootState } from '../../../store';
import { ViewUnitCampaignManagementType } from '../../../store/auth';
import { create } from '../../../store/notification';

const SwitchCellRenderer = (params: GridRenderCellParams) => {
    const dispatch = useDispatch();
    const { view } = useSelector((state: RootState) => state.auth);

    let changeStatusType: ChangeStatusType | null = null;

    if (params.field === 'isReply') {
        changeStatusType = 'reply';
    } else if (params.field === 'isSender') {
        changeStatusType = 'sender';
    }

    return (
        <Switch
            defaultChecked={params.row[params.field]}
            onChange={(_, checked) => {
                if (changeStatusType !== null) {
                    emailsService
                        .changeStatus(params.row.id, changeStatusType)
                        .then(() =>
                            dispatch(
                                create({ type: 'success', message: `Tétel sikeresen ${checked ? '' : 'in'}aktiválva` }),
                            ),
                        )
                        .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen művelet.' })));
                } else {
                    alert('Típus nincs beállítva');
                }
            }}
            disabled={view.unitCampaignManagement === ViewUnitCampaignManagementType.Own}
        />
    );
};

export default SwitchCellRenderer;

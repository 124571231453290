import CampaignListPage from '../../../pages/Unit/Campaign/CampaignList';
import CampaignAddressList from '../../../pages/Unit/Campaign/CampaignList/CampaignAddressList';
import CampaignFormList from '../../../pages/Unit/Campaign/CampaignList/CampaignFormList';
import EmailLogShow from '../../../pages/Unit/Campaign/CampaignList/EmailLogShow';
import CampaignFormPage from '../../../pages/Unit/Campaign/CampaignList/Form';
import NewCampaignPage from '../../../pages/Unit/Campaign/NewCampaign';
import MessageTemplatesBaseForm from '../../../pages/Unit/Templates/MessageTemplates/BaseFormPage';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import Form from '../../../pages/Unit/Templates/Forms/Form';

const CampaignList = {
    path: clientEndpoints.unit_campaigns,
    children: [
        {
            path: '',
            element: <CampaignListPage />,
            name: 'unit_campaigns',
        },
        {
            path: ':id/view',
            element: <CampaignFormPage />,
            name: 'unit_campaigns_view',
        },
        {
            path: ':id/edit',
            element: <NewCampaignPage key="edit-campaign" />,
            name: 'unit_campaigns_edit',
        },
        {
            path: ':id/recipients',
            element: <CampaignAddressList />,
            name: 'unit_campaigns_recipients',
        },
        {
            path: ':id/forms-filled',
            element: <CampaignFormList />,
            name: 'unit_campaigns_recipients',
        },
        {
            path: ':id/recipients/:emailPostedId',
            element: <EmailLogShow type={'campaign_email_log'} />,
            name: 'unit_campaigns_recipients',
        },
        {
            path: ':campaignId/message-templates/:id/show',
            element: <MessageTemplatesBaseForm readonly={true} fromCampaignView={true} />,
            name: 'unit_campaigns_message_templates_show',
        },
        {
            path: ':campaignId/forms/:id/show',
            element: <Form readonly={true} fromCampaignView={true} />,
            name: 'unit_campaigns_forms_show',
        },
    ],
};

export default CampaignList;

import API from '../../api';
import { ITestEmailForm } from '../../pages/Unit/Templates/MessageTemplates/TestEmailDialog';
import downloadAPI from '../../api/downloadAPI';
import { TAttachment } from '../../pages/Unit/Templates/MessageTemplates/types';
import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import { IMessageTemplatesFormType } from '../../pages/Unit/Templates/MessageTemplates/types';

const messageTemplatesService = {
    ...crudTemplate(endpoints.unit.template.messageTemplates.main),
    get2: (id: string | number, readonly: boolean | undefined) =>
        API.get(endpoints.unit.template.messageTemplates.main + '/' + id + (readonly ? '?readonly=1' : '')),
    clone: (id: number) => API.post(endpoints.unit.template.messageTemplates.clone.replace('{id}', id.toString()), {}),
    testPdfGen: (currentValue: TAttachment) =>
        API.post(endpoints.unit.template.messageTemplates.pdf.create, currentValue),
    testPdfDownload: (data: { fileName: string; filePath: string }) =>
        downloadAPI.get(endpoints.unit.template.messageTemplates.pdf.download, { params: data }),
    getMessageFields: (filter: object) =>
        API.get(endpoints.unit.template.messageTemplates.getMessageFields, { params: filter }),
    sendTestMail: (form: ITestEmailForm) => API.post(endpoints.unit.template.messageTemplates.sendTestMail, form),
    validate: (form: IMessageTemplatesFormType) => API.post(endpoints.unit.template.messageTemplates.validate, form),
};

export default messageTemplatesService;

import { FieldGenerator, TextField } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UnitUserFormProps } from '../../Unit/Users/types';
import { UserFormProps } from './types';
import Grid from '@mui/material/Grid';
import { TextFieldProps } from '@mui/material/TextField';

const textInputs = [
    {
        label: 'Vezetéknév',
        name: 'lastName',
    },
    {
        label: 'Keresztnév',
        name: 'firstName',
    },
    {
        label: 'E-mail cím',
        name: 'email',
    },
];

export default function UserFormCore(props: UserFormProps | UnitUserFormProps) {
    const { setFieldValue, errors } = props;
    const { id } = useParams();
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [defaultEmail, setDefaultEmail] = useState<unknown>();
    const isSendActivationToNewEmail = id !== undefined && props.values.email !== defaultEmail;

    const handleBlur = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            setFieldValue(event.target.name, event.target.value);
        },
        [setFieldValue],
    );

    const TextInput = useCallback(
        (tProps: TextFieldProps) => (
            <Grid item xs={12} md={6}>
                <TextField
                    {...tProps}
                    error={!!errors['' + (tProps.name || '')]}
                    helperText={errors['' + (tProps.name || '')]}
                    onBlur={handleBlur}
                    defaultValue={props.values['' + (tProps.name || '')]}
                />
            </Grid>
        ),
        [errors, handleBlur, props.values],
    );

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
            setDefaultEmail(props.values.email);
        }
    }, [id, isFirstLoad, props.values.email]);

    return (
        <>
            {textInputs.map((input) => (
                <TextInput {...props} key={input.name} label={input.label} name={input.name} />
            ))}
            {!id &&
                FieldGenerator({
                    ...props,
                    label: 'Aktivációs e-mail küldése',
                    name: 'sendActivationEmail',
                    type: 'checkbox',
                    format: { xs: 12 },
                })}
            {isSendActivationToNewEmail &&
                FieldGenerator({
                    ...props,
                    label: 'Aktiváló e-mail küldése a felhasználó új e-mail címére',
                    name: 'sendActivationOnEmailChange',
                    type: 'checkbox',
                    format: { xs: 12 },
                })}
        </>
    );
}

import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { useEffect, useState } from 'react';
import { IStatisticsService } from '../../../../services/Unit/statistics/statisticsAggregateService';

export default function useCampaignOptions(service: IStatisticsService, ownerId?: number | null) {
    const [loading, setLoading] = useState(true);
    const [campaignOptions, setCampaignOptions] = useState<Option[]>([]);

    useEffect(() => {
        setLoading(true);
        service
            .getCampaigns(ownerId)
            .then((response) => {
                setCampaignOptions(response.data);
            })
            .finally(() => setLoading(false));
    }, [service, ownerId]);

    return { loading, campaignOptions };
}

import { clientEndpoints } from '../../utils/clientEndpoints';
import { IMainMenu, ISubMenu } from './types';
import BusinessIcon from '@mui/icons-material/Business';
import Email from '@mui/icons-material/Email';
import Message from '@mui/icons-material/Message';
import People from '@mui/icons-material/People';
import Settings from '@mui/icons-material/Settings';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Storage from '@mui/icons-material/Storage';

export const tempSuperAdminSubmenus: Record<string, ISubMenu> = {
    users: {
        to: clientEndpoints.central_users,
        icon: <People />,
        text: 'Felhasználókezelés',
        name: 'central_users',
    },
    units: {
        to: clientEndpoints.central_units,
        icon: <BusinessIcon />,
        text: 'Területi egységek',
        name: 'central_units',
    },
    systemMessages: {
        to: clientEndpoints.central_system_messages,
        icon: <Message />,
        text: 'Rendszerüzenetek',
        name: 'central_system_messages',
    },
    systemSettings: {
        to: clientEndpoints.central_system_settings,
        icon: <Settings />,
        text: 'Rendszerbeállítások',
        name: 'central_system_settings',
    },
    systemLog: {
        to: clientEndpoints.central_system_logs,
        icon: <Storage />,
        text: 'Rendszernapló',
        name: 'central_system_logs',
    },
    emailLog: {
        to: clientEndpoints.central_email_log,
        icon: <Email />,
        text: 'E-mail napló',
        name: 'central_email_log',
    },
    superAdmin: {
        to: clientEndpoints.central_super_admin,
        icon: <SettingsSuggestIcon />,
        text: 'Szuperadmin',
        name: 'central_super_admin',
    },
};

export const adminMainMenus: IMainMenu = {
    superadmin: {
        name: 'Adminisztráció',
        subMenus: Object.values(tempSuperAdminSubmenus),
    },
};

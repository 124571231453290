import DoneAllIcon from '@mui/icons-material/DoneAll';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { useEffect, useState } from 'react';
import ProgressTimelineItem, { IProgressTimelineItem } from './ProgressTimelineItem';

export enum ProgressStatus {
    Pending = 'Várakozik...',
    Running = 'Fut...',
    Success = 'Sikeres',
    Error = 'Hiba',
}

const STATUS_COLOR_MAP: Record<ProgressStatus, 'success' | 'warning' | 'error' | 'grey'> = {
    [ProgressStatus.Pending]: 'grey',
    [ProgressStatus.Running]: 'warning',
    [ProgressStatus.Success]: 'success',
    [ProgressStatus.Error]: 'error',
};

const getProgressStatus = (item: IProgressTimeline) => {
    if (item.errorMessage) {
        return ProgressStatus.Error;
    }

    if (item.progress === 0) {
        return ProgressStatus.Pending;
    }

    if (item.progress < 100) {
        return ProgressStatus.Running;
    }

    return ProgressStatus.Success;
};

export interface IProgressTimeline {
    name: string;
    icon: React.ReactNode;
    progress: number;
    errorMessage?: string | React.ReactNode;
}

export default function ProgressTimeline(props: { items: IProgressTimeline[] }) {
    const [items, setItems] = useState<IProgressTimelineItem[]>([]);

    const getEndDotColor = (): 'success' | 'grey' => {
        let isProcessEnded = true;

        items.forEach((item) => {
            if (item.progress < 100) {
                isProcessEnded = false;
            }
        });

        props.items.forEach((item) => {
            if (item.errorMessage) {
                isProcessEnded = false;
            }
        });

        return isProcessEnded ? 'success' : 'grey';
    };

    const transfer = (items: IProgressTimeline[]): IProgressTimelineItem[] => {
        const timelineItems: IProgressTimelineItem[] = [];

        items.forEach((item) => {
            timelineItems.push({
                ...item,
                ...{
                    description: item.errorMessage ? item.errorMessage : getProgressStatus(item),
                    color: STATUS_COLOR_MAP[getProgressStatus(item)] || 'grey',
                },
            });
        });

        return timelineItems;
    };

    useEffect(() => {
        setItems(transfer(props.items));
    }, [props]);

    return (
        <Timeline position="alternate">
            {items.map((item) => (
                <ProgressTimelineItem key={item.name} {...item} />
            ))}
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color={getEndDotColor()}>
                        <DoneAllIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }} />
            </TimelineItem>
        </Timeline>
    );
}

import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import axiosApi from '../api';

type FetchState<T, D> =
    | {
          loading: false;
          data: T;
          error: AxiosError<D>;
      }
    | {
          loading: true;
          data: [];
          error: AxiosError<D>;
      };

/**
 * Egyelőre ideraktam a useFetch hook-ot, mert a template-ben használt API más mint ez,
 * FIXME: próbáltam átadni az API-t paraméterként de nem működött
 */
const useFetch = <T, D = unknown>(
    url: string,
    keyOfResponse = 'data',
    dependence: unknown = false,
    apiInstance?: AxiosInstance,
): FetchState<T, D> => {
    const [state, setState] = useState<FetchState<T, D>>({
        loading: true,
        error: {
            config: {},
            isAxiosError: false,
            name: '',
            message: '',
            toJSON: () => ({}),
        },
        data: [],
    });

    const setValue = useCallback(
        (key: keyof FetchState<T, D>, value: FetchState<T, D>[keyof FetchState<T, D>]) =>
            setState((prev) => ({ ...prev, [key]: value })),
        [setState],
    );

    const api = apiInstance || axiosApi;

    useEffect(() => {
        let isSubscribed = true;
        if (url) {
            setValue('loading', true);

            api.get(url)
                .then((response: AxiosResponse) => {
                    if (isSubscribed) {
                        if (keyOfResponse !== '') {
                            setValue('data', response.data[keyOfResponse]);
                        } else {
                            const data = response.data;
                            setValue('data', data);
                        }
                    }
                })
                .catch((error: AxiosError<D>) => isSubscribed && setValue('error', error))
                .finally(() => isSubscribed && setValue('loading', false));
        } else {
            setValue('loading', false);
        }

        return () => {
            isSubscribed = false;
        };
    }, [api, url, dependence, keyOfResponse, setValue]);

    return state;
};

export default useFetch;

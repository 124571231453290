import Grid from '@mui/material/Grid';
import { SubmitButton } from '@silinfo/front-end-template';
import { useCallback, useState } from 'react';
import CampaignFormFieldDialog from './CampaignFormFieldDialog';

function CampaignFormDialogButton() {
    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    const dialogProps = {
        id: 0,
        open: open,
        handleClose: handleClose,
    };

    return (
        <>
            <Grid item>
                <SubmitButton onClick={handleOpen}>Oszlopok megjelenítése/elrejtése</SubmitButton>
                <CampaignFormFieldDialog {...dialogProps} />
            </Grid>
        </>
    );
}

export default CampaignFormDialogButton;

import { IForm } from './AppConst';

export const onChange = <T extends IForm>(changeForm: (form: T, name: string) => void, form: T) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
        changeForm(
            {
                ...form,
                [e.target.name]: e.target.value,
            },
            'form',
        );
    };
};

import { Option } from '@silinfo/front-end-template';
import { endpoints } from '../../../../../utils/endPoints';
import ApiAutocomplete from '../ApiAutocomplete';

export default function PrevCampaignInput(props: {
    isOwn: boolean;
    onChange: (value: number) => void;
    values: {
        prevCampaign: {
            label: string;
            value: number;
        } | null;
    };
}) {
    const prevCampaignUrl =
        endpoints.campaigns.campaigns.autocompletePreviousCampaigns +
        (props.isOwn ? '?isOwner=true' : '?isOwner=false');
    return (
        <ApiAutocomplete
            url={prevCampaignUrl}
            label={'Megelőző kampány'}
            name={'prevCampaign'}
            onChange={props.onChange as (val: number | number[]) => void}
            defaultValue={props.values.prevCampaign as Option<number>}
        />
    );
}

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { Accordion } from '@silinfo/front-end-template';
import { memo, useCallback } from 'react';
import AccordionHeader from './AccordionHeader';
import SelectRecipientForm from './SelectRecipientForm';
import { ListPageAccordionProps } from './types';
import { listPageFormKeys } from './utils';
import Alert from '@mui/material/Alert';

const ListPageAccordion = ({
    expanded,
    handleChange,
    setExpanded,
    selected,
    setSelected,
    sessionId,
    props,
}: ListPageAccordionProps) => {
    const hasError = useCallback(
        (array: string[]) => array.some((elem) => Object.keys(props.errors).includes(elem)),
        [props.errors],
    );

    return (
        <Grid item xs={12}>
            <Accordion
                disableGutters
                title={<AccordionHeader title="Címzettek kiválasztása" error={hasError(listPageFormKeys)} />}
                expanded={expanded === 'panel2' || hasError(listPageFormKeys)}
                onChange={handleChange('panel2')}
                sx={{ mt: 2 }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {props.errors.addresses && <Alert severity="error">{props.errors.addresses}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <SelectRecipientForm setSelected={setSelected} selected={selected} sessionId={sessionId} />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            onClick={() => {
                                setExpanded('panel3');
                            }}
                        >
                            Tovább
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Accordion>
        </Grid>
    );
};

export default memo(ListPageAccordion);

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { orange } from '@mui/material/colors';
import { CancelButton, FieldGenerator, Input, Link } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { AxiosError, AxiosResponse } from 'axios';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import { Children, useState } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '../../../../../components/Autocomplete';
import addressGroupsService, { IAddressGroup } from '../../../../../services/Unit/addressGroups';
import addressListService from '../../../../../services/Unit/addressListService';
import { RootState } from '../../../../../store';
import { clientEndpoints } from '../../../../../utils/clientEndpoints';
import FileInput from './FileInput';
import { IAddressListImportFormType } from './types';
import { ViewUnitCampaignManagementType } from '../../../../../store/auth';

const inCaseOfDuplicateAddressesOption: Option[] = [
    {
        label: 'Figyelmen kívül hagyás',
        value: 'skip',
    },
    {
        label: 'Figyelmen kívül hagyás és csoportba sorolás ismétlődő címek esetén is',
        value: 'skip_except_group',
    },
    {
        label: 'Hibalista',
        value: 'error',
    },
    {
        label: 'Meglévő adatok módosítása',
        value: 'update',
    },
];

export const formFields: Input[] = [
    {
        label: 'Ismétlődő címek esetén',
        name: 'inCaseOfDuplicateAddresses',
        type: 'select',
        options: inCaseOfDuplicateAddressesOption,
        format: { xs: 12 },
    },
];

const initialForm: IAddressListImportFormType = {
    file: undefined,
    groups: [],
    inCaseOfDuplicateAddresses: inCaseOfDuplicateAddressesOption[0].value,
};

export default function ImportForm(props: {
    ownOnly?: boolean;
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => void;
    onAfterSubmit: (response: AxiosResponse | AxiosError) => void;
    onBeforeSubmit: () => void;
}) {
    const [selectedFile, setSelectedFile] = useState<File>();
    const [loading, setLoading] = useState(false);
    const { user, view } = useSelector((state: RootState) => state.auth);

    const createRequestFormData = (form: IAddressListImportFormType) => {
        const formData = new FormData();

        formData.append('inCaseOfDuplicateAddresses', form.inCaseOfDuplicateAddresses);
        form.groups
            .map((group: Option) => group.value)
            .forEach((groupId) => {
                formData.append('groups[]', groupId);
            });
        formData.append('file', selectedFile ?? '');

        if (props.ownOnly) {
            formData.append('ownerId', (user?.id || '').toString());
        }

        return formData;
    };

    const handleSubmit = async (
        form: IAddressListImportFormType,
        { setErrors }: FormikHelpers<IAddressListImportFormType>,
    ) => {
        const data = createRequestFormData(form);

        if (!(data.get('file') instanceof File)) {
            setErrors({ file: 'Fájl csatolása kötelező' });

            return;
        }

        props.onBeforeSubmit();

        try {
            const response: AxiosResponse = await addressListService.import(data, props.onUploadProgress);

            props.onAfterSubmit(response);
        } catch (error) {
            if (error instanceof AxiosError) {
                props.onAfterSubmit(error);
            }
        }
    };

    const downloadSampleFile = () => {
        setLoading(true);
        let ownerId = '';
        if (props.ownOnly) {
            ownerId = (user?.id || '').toString();
        }
        addressListService
            .downloadSampleFile(ownerId)
            .then((resp) => {
                const blob = resp.data;
                const link = document.createElement('a');

                link.href = window.URL.createObjectURL(blob);
                link.download = 'minta.xlsx';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Formik initialValues={initialForm} onSubmit={handleSubmit}>
            {(fProps) => (
                <FormikForm>
                    <Grid container spacing={2}>
                        {Children.toArray(
                            formFields.map((field) =>
                                FieldGenerator({
                                    ...field,
                                    ...fProps,
                                }),
                            ),
                        )}
                        <Grid item xs={12}>
                            <Autocomplete
                                numberOfCharactersToSearch={0}
                                textFieldProps={{ label: 'Besorolás csoportba' }}
                                getter={(term) =>
                                    addressGroupsService
                                        .filter({
                                            name: term,
                                            ownerId:
                                                view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                                                    ? user?.id
                                                    : 'null',
                                        })
                                        .then((res) => res.data)
                                }
                                createOptions={(data: IAddressGroup[]): Option[] =>
                                    data.map((group: IAddressGroup) => {
                                        return { label: group.name, value: group.id.toString() };
                                    })
                                }
                                autocompleteProps={{
                                    onChange: (_, values) => {
                                        fProps.setFieldValue('groups', values);
                                    },
                                    multiple: true,
                                    value: fProps.values.groups,
                                    disableCloseOnSelect: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FileInput
                                label="Import fájl"
                                onDrop={(files: File[]) => setSelectedFile(files[0])}
                                error={!!(fProps.touched?.file && fProps.errors?.file)}
                                helperText={fProps.touched?.file && fProps.errors?.file}
                                accept={{
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <LoadingButton variant="contained" type="submit">
                                        Import indítása
                                    </LoadingButton>
                                </Grid>
                                <Grid item>
                                    <Link
                                        to={
                                            props.ownOnly
                                                ? clientEndpoints.unit_own_address_list
                                                : clientEndpoints.unit_address_list
                                        }
                                    >
                                        <CancelButton>Vissza</CancelButton>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item>
                                    <LoadingButton
                                        sx={{ backgroundColor: orange[500] }}
                                        variant="contained"
                                        onClick={downloadSampleFile}
                                        loading={loading}
                                    >
                                        Minta.xlsx letöltése
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormikForm>
            )}
        </Formik>
    );
}

import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Input, Link } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import ActionCellRenderer from '../../../../components/ActionCellRenderer';
import { DynamicInput } from '../../../../components/DynamicForm/types';
import { endpoints } from '../../../../utils/endPoints';
import BtnCellRenderer from './BtnCellRenderer';
import { IMessageTemplatesFormType } from './types';
import { initialValue, initialValueUploadedFile } from './utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export const filterFields: Input[] = [
    {
        name: 'name',
        label: 'Név',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'author',
        label: 'Létrehozó',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'insertDateFrom',
        label: 'Létrehozó dátum -tól',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'datetime-local',
    },
    {
        name: 'insertDateTo',
        label: 'Létrehozó dátum -ig',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'datetime-local',
    },
    {
        name: 'editor',
        label: 'Módosító',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'updateDateFrom',
        label: 'Módosítás dátum -tól',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'datetime-local',
    },
    {
        name: 'updateDateTo',
        label: 'Módosítás dátum -ig',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'datetime-local',
    },
    {
        name: 'type',
        label: 'Típus',
        type: 'multiselect',
        options: [
            { value: 'simple', label: 'Egyszerű' },
            { value: 'complex', label: 'Fejlett' },
        ],
        format: { xs: 12, md: 6, lg: 4 },
    },
];

export const dynamicFormFields = (
    id: string | number,
    formikProps: FormikProps<IMessageTemplatesFormType>,
    uploadFileMaxSize: number,
    readonly?: boolean,
): DynamicInput[] => {
    const values = formikProps.values.attachments;
    const value = values.find((a) => a.id === id || a.tmpId === id);
    const type = value?.type;
    const index = values.findIndex((a) => a.id === id || a.tmpId === id);

    return type === 'uploaded_file'
        ? [
              {
                  name: 'type',
                  label: 'Típus',
                  type: 'select',
                  options: [
                      { value: 'uploaded_file', label: 'Feltöltött fájl' },
                      { value: 'generated_file', label: 'Generált fájl' },
                  ],
                  format: { xs: 12 },
                  props: {
                      onChange: () => {
                          formikProps.setFieldValue(
                              'attachments',
                              values.map((v) =>
                                  v.id === id || v.tmpId === id ? { ...initialValue, tmpId: v.tmpId || '' } : v,
                              ),
                          );
                      },
                      disabled: readonly,
                  },
              },
              {
                  type: 'file',
                  label: 'Fájl',
                  name: 'filePath',
                  description: `(max: ${uploadFileMaxSize / 1024 / 1024}MB)`,
                  origFileName: 'attachments.' + index + '.fileName',
                  uuidFileName: 'attachments.' + index + '.filePath',
                  downloadUrl: endpoints.fileDownload + (value?.id ? `?type=attachments&id=${value.id}` : ''),
                  tempType: 'attachments',
                  format: { xs: 12 },
                  props: {
                      disabled: readonly,
                  },
              },
          ]
        : [
              {
                  name: 'type',
                  label: 'Típus',
                  type: 'select',
                  options: [
                      { value: 'uploaded_file', label: 'Feltöltött fájl' },
                      { value: 'generated_file', label: 'Generált fájl' },
                  ],
                  props: {
                      onChange: () => {
                          formikProps.setFieldValue(
                              'attachments',
                              values.map((v) =>
                                  v.id === id || v.tmpId === id
                                      ? { ...initialValueUploadedFile, tmpId: v.tmpId || '' }
                                      : v,
                              ),
                          );
                      },
                      disabled: readonly,
                  },
                  format: { xs: 12 },
              },
              {
                  name: 'header',
                  label: 'Fejléc',
                  isEditor: true,
              },
              {
                  name: 'content',
                  label: 'Tartalom',
                  isEditor: true,
              },
              {
                  name: 'footer',
                  label: 'Lábléc',
                  isEditor: true,
              },
          ];
};

export const tableColumns = (
    setRefresh: Dispatch<SetStateAction<boolean>>,
    matches: boolean,
    onlyRead: boolean,
    setSortByInsert: Dispatch<SetStateAction<boolean>>,
): GridColDef[] => {
    return [
        { headerName: 'Név', field: 'name', minWidth: 350 },
        {
            headerName: 'Típus',
            field: 'type',
            valueGetter: (params: GridValueGetterParams) =>
                params.row.type === 'Egyszerű' ? <>{params.row.type}</> : <>{params.row.type}</>, // FIXME: itt színes dobozba lenne jó rakni
            sortable: false,
        },
        { headerName: 'Létrehozó', field: 'author' },
        { headerName: 'Létrehozás dátuma', field: 'insertDate' },
        { headerName: 'Utolsó módosító', field: 'editor' },
        {
            headerName: 'Utolsó módosítás dátuma',
            field: 'updateDate',
            renderCell: (params: GridRenderCellParams) => <>{params.row.updateDate ?? params.row.insertDate}</>,
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams) =>
                onlyRead ? (
                    <Link to={clientEndpoints.unit_message_templates_show.replace(':id', params.row.id.toString())}>
                        <Tooltip title="Megtekintés">
                            <IconButton color="success" size="small">
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                    </Link>
                ) : (
                    <ActionCellRenderer>
                        <BtnCellRenderer setSortByInsert={setSortByInsert} setRefresh={setRefresh} params={params} />
                    </ActionCellRenderer>
                ),
            sortable: false,
            minWidth: matches ? 200 : 90,
        },
    ].map((col) => ({ flex: 1, minWidth: 150, ...col }));
};

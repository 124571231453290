import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { CancelButton } from '@silinfo/front-end-template';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { AuthUser } from '../../store/auth';
import { create } from '../../store/notification';
import { userHasCentralRole, userHasUnitRole, userIsSuperAdmin } from '../../utils/AccessManagementHelper';
import { downloadFile } from '../../utils/AppConst';
import { getJWTToken } from '../../utils/AuthHelper';
import { endpoints } from '../../utils/endPoints';
import MCCLoading from '../MCCLoading';

type HandbookDialogProps = {
    open: boolean;
    handleClose: () => void;
};

const useStyles = makeStyles(() => ({
    blueButton: {
        backgroundColor: 'white',
        color: '#0288d1',
    },
}));

export const handleDownloadClick = (url: string, name: string) => {
    const AUTH_TOKEN = getJWTToken();
    return axios
        .get(url, {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
            },
        })
        .then((response) => {
            downloadFile(response.data, name, 'application/pdf');
            /*  const newTab = window.open(URL.createObjectURL(response.data));
            if (newTab) newTab.document.title = name; //FIXME: ez valamiért nem jó
            return newTab; */
        });
};

function HandbookTable() {
    const classes = useStyles();
    const { user } = useSelector((state: RootState) => state.auth);
    const [adminHandbookLoading, setAdminHandbookLoading] = useState(false);
    const [unitHandbookLoading, setUnitHandbookLoading] = useState(false);
    const dispatch = useDispatch();

    const downloadAdminHandbook = useCallback(() => {
        setAdminHandbookLoading(true);
        dispatch(
            create({
                type: 'info',
                message: 'A fájl letöltése folyamatban...',
            }),
        );
        handleDownloadClick(
            `${endpoints.fileDownload}?type=handbook&subType=central`,
            'Felhasználói kézikönyv - Admin felület',
        )
            .then(() => {
                dispatch(
                    create({
                        type: 'success',
                        message: 'A fájl sikeresen letöltésre került!',
                    }),
                );
            })
            .catch(() => {
                dispatch(
                    create({
                        type: 'error',
                        message: 'A fájl letöltése sikertelen!',
                    }),
                );
            })
            .finally(() => setAdminHandbookLoading(false));
    }, [dispatch]);

    const downloadUnitHandbook = useCallback(() => {
        setUnitHandbookLoading(true);
        dispatch(
            create({
                type: 'info',
                message: 'A fájl letöltése folyamatban...',
            }),
        );
        handleDownloadClick(
            `${endpoints.fileDownload}?type=handbook&subType=unit`,
            'Felhasználói kézikönyv - Területi egység felület',
        )
            .then(() => {
                dispatch(
                    create({
                        type: 'success',
                        message: 'A fájl sikeresen letöltésre került!',
                    }),
                );
            })
            .catch(() => {
                dispatch(
                    create({
                        type: 'error',
                        message: 'A fájl letöltése sikertelen!',
                    }),
                );
            })
            .finally(() => setUnitHandbookLoading(false));
    }, [dispatch]);

    /**
     * TODO: fix this
     */
    if (user === null) return <MCCLoading />;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Dokumentum neve</TableCell>
                    <TableCell align="center">Műveletek</TableCell>
                </TableRow>
            </TableHead>
            <TableBody component="tbody">
                {(userHasCentralRole(user as AuthUser) || userIsSuperAdmin(user as AuthUser)) && (
                    <TableRow>
                        <TableCell>Felhasználói kézikönyv - Admin felület</TableCell>
                        <TableCell>
                            <Grid item xs={12} container justifyContent="center">
                                <LoadingButton
                                    loading={adminHandbookLoading}
                                    className={classes.blueButton}
                                    onClick={downloadAdminHandbook}
                                >
                                    <DownloadIcon />
                                </LoadingButton>
                            </Grid>
                        </TableCell>
                    </TableRow>
                )}
                {(userHasUnitRole(user as AuthUser) || userIsSuperAdmin(user as AuthUser)) && (
                    <TableRow>
                        <TableCell>Felhasználói kézikönyv - Területi egység felület</TableCell>
                        <TableCell>
                            <Grid item xs={12} container justifyContent="center">
                                <LoadingButton
                                    loading={unitHandbookLoading}
                                    className={classes.blueButton}
                                    onClick={downloadUnitHandbook}
                                >
                                    <DownloadIcon />
                                </LoadingButton>
                            </Grid>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
}

export default function HandbookDialog({ open, handleClose }: HandbookDialogProps) {
    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>Felhasználói kézikönyv</DialogTitle>
            <DialogContent>
                <HandbookTable />
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleClose}>Bezár</CancelButton>
            </DialogActions>
        </Dialog>
    );
}

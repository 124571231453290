import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import api from '../../api';
import { UsersUnitFormType } from '../../pages/Unit/Users/types';

const usersService = {
    ...crudTemplate<UsersUnitFormType>(endpoints.unit.administration.users.main),
    getUnitRoles: () => api.get(endpoints.unit.administration.users.getUnitRoles),
};

export default usersService;

import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { FormikProps } from 'formik';
import { IUnitsFormType } from '../../Central/Units/types';
import { IUsersForm, UnitRoles } from '../../Central/Users/types';

export type UsersUnitFormType = Omit<IUsersForm, 'roles'> & {
    roles: Record<string, string>;
};

export type UnitUserFormProps = {
    unit: IUnitsFormType;
    unitRoleOptions: UnitRoles[];
    rolesView?: boolean;
    ownCampaignValidEmailDomains: string[];
} & FormikProps<UsersUnitFormType>;

export const initialForm: UsersUnitFormType = {
    lastName: '',
    firstName: '',
    email: '',
    roles: {},
    sendActivationEmail: false,
};

export interface GroupedOption extends Option {
    group: string;
}

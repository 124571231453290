import { Input } from '@silinfo/front-end-template';
import { IMessageFieldsFormType } from './types';

export const filterFields: Input[] = [
    {
        name: 'messageId',
        label: 'Azonosító',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'columnName',
        label: 'Oszlopnév',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'description',
        label: 'Leírás',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'visible',
        label: 'Látható',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'multiselect',
        options: [
            { label: 'Igen', value: '1' },
            { label: 'Nem', value: '0' },
        ],
    },
    {
        type: 'datetime-local',
        label: 'Utolsó használat időpontja (-tól)',
        name: 'lastRelatedCampaignSubmitDateFrom',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Utolsó használat időpontja (-ig)',
        name: 'lastRelatedCampaignSubmitDateTo',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'lastRelatedCampaign',
        label: 'Utolsó kapcsolódó kampány',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'author',
        label: 'Létrehozó',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Létrehozás dátuma (-tól)',
        name: 'insertDateFrom',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Létrehozás dátuma (-ig)',
        name: 'insertDateTo',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'editor',
        label: 'Utolsó módosító',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Utolsó módosítás dátuma (-tól)',
        name: 'updateDateFrom',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Utolsó módosítás dátuma (-ig)',
        name: 'updateDateTo',
        format: { xs: 12, md: 6, lg: 4 },
    },
];

export const formFields: Input[] = [
    {
        name: 'messageId',
        label: 'Azonosító',
        format: {
            xs: 12,
        },
    },
    {
        name: 'columnName',
        label: 'Oszlopnév',
        format: {
            xs: 12,
        },
    },
    {
        name: 'description',
        label: 'Leírás',
        format: {
            xs: 12,
        },
        props: {
            multiline: true,
            rows: 4,
        },
    },
    {
        name: 'visible',
        label: 'Látható',
        type: 'checkbox',
        format: {
            xs: 12,
        },
    },
];

export const initialFormValues: IMessageFieldsFormType = {
    id: 0,
    messageId: '',
    columnName: '',
    description: '',
    visible: '1',
};

import { useState } from 'react';
import Dropzone, { DropzoneProps, DropzoneRef, FileRejection, DropEvent } from 'react-dropzone';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Upload from '@mui/icons-material/Upload';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        background: '#aaaaaa33',
        transition: '.5s all',
        height: '20vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '33',
        },
    },
    containerHover: {
        backgroundColor: theme.palette.primary.main + '33',
    },
    fileInputLabel: {
        border: '2px dashed ' + theme.palette.secondary.main,
        width: '95%',
        height: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
            padding: '18px',
            width: '90%',
            height: '85%',
        },
        transition: '.5s all',
    },
    fileInputLabelHover: {
        cursor: 'pointer',
        padding: '18px',
        width: '90%',
        height: '85%',
    },
    error: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
    },
}));

interface IFileInput {
    label: string;
    error: boolean;
    helperText?: string | false;
}

export default function FileInput(props: DropzoneProps & React.RefAttributes<DropzoneRef> & IFileInput): JSX.Element {
    const classes = useStyles();
    const [onDrag, setOnDrag] = useState(false);
    const [fileName, setFileName] = useState<string>('');

    const onDrop = (files: File[], fileRejections: FileRejection[], event: DropEvent) => {
        const fileNames: string[] = [];

        files.forEach((file) => {
            fileNames.push(file.name);
        });
        setFileName(fileNames.join(','));

        if (props.onDrop) {
            props.onDrop(files, fileRejections, event);
        }
    };

    return (
        <FormControl variant="outlined" fullWidth error={props.error}>
            <Dropzone
                {...props}
                onDrop={onDrop}
                onDragEnter={() => setOnDrag(true)}
                onDragLeave={() => setOnDrag(false)}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className={classes.container + (onDrag ? ' ' + classes.containerHover : '')}>
                        <div
                            {...getRootProps()}
                            className={
                                classes.fileInputLabel +
                                (props.error ? ' ' + classes.error : '') +
                                (onDrag ? ' ' + classes.fileInputLabelHover : '')
                            }
                        >
                            <input {...getInputProps()} />
                            <div>
                                <div style={{ textAlign: 'center' }}>
                                    <Upload />
                                </div>
                                <div>
                                    <Typography display="flex" alignItems="center" variant="h6">
                                        {props.label} feltöltése
                                    </Typography>
                                </div>
                                {fileName && (
                                    <div>
                                        <Typography align="center">({fileName})</Typography>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Dropzone>
            {props.error && <FormHelperText id="my-helper-text">{props.helperText}</FormHelperText>}
        </FormControl>
    );
}

import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableButton, TableEditButton } from '@silinfo/front-end-template';
import AddressDeleteDialogButton from './AddressDeleteDialogButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

export type GridCellRendererProps = {
    params: GridRenderCellParams;
};

const BtnCellRenderer = ({ params }: GridCellRendererProps) => {
    const { selectedRows } = useSelector((state: RootState) => state.addressList);
    return (
        <Grid container spacing={1} justifyContent="center">
            <Grid item>
                <TableEditButton tooltip={'Szerkesztés'} target={params.row.id + '/edit'} />
            </Grid>
            <Grid item>
                {selectedRows === 0 ? (
                    <AddressDeleteDialogButton
                        opener={
                            <TableButton color="error" tooltip="Törlés">
                                <DeleteIcon />
                            </TableButton>
                        }
                        objectId={parseInt(params.row.id)}
                    />
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
};

export default BtnCellRenderer;

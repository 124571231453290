import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import { UsersUnitFormType } from './types';
import { UnitRoles } from '../../Central/Users/types';
import { UnitRole } from '../../../utils/Roles';

interface RolesTitleProps {
    role: UnitRoles;
    ownCampaignValidEmailDomains: string[];
    rolesView?: boolean;
}

export default function RolesTitle({ role, rolesView, ownCampaignValidEmailDomains }: RolesTitleProps) {
    const props = useFormikContext<UsersUnitFormType>();
    const isUserCanSetRole = (user: UsersUnitFormType, role: string) =>
        role !== UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN ||
        ownCampaignValidEmailDomains.some((emailDomain) => (user.email || '').toString().endsWith(emailDomain));

    return (
        <Grid item xs={12}>
            <Typography variant="h6" style={{ marginTop: '8px' }}>
                {role.label}{' '}
                {!rolesView && (
                    <Switch
                        checked={role.value.every(
                            (roleItem) =>
                                !isUserCanSetRole(props.values, roleItem.value) ||
                                props.values.roles?.[roleItem.value] == '1',
                        )}
                        onChange={(e) =>
                            props.setFieldValue(
                                'roles',
                                role.value.reduce(
                                    (acc, roleItem) =>
                                        !isUserCanSetRole(props.values, roleItem.value)
                                            ? acc
                                            : {
                                                  ...acc,
                                                  [roleItem.value]: e.target.checked ? '1' : '0',
                                              },
                                    { ...props.values.roles },
                                ),
                            )
                        }
                    />
                )}
            </Typography>
        </Grid>
    );
}

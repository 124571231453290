import { clientEndpoints } from './clientEndpoints';
import { Header } from './Interfaces/interfaces';

const PROJECT_NAME = 'MCC Hírlevél- és űrlapkezelő rendszer';

interface Menus {
    [mainMenu: string]: {
        en: string;
        HU: string;
        subMenus: {
            [subMenu: string]: {
                url: string;
                title: string;
                formTitle?: string;
            };
        };
    };
}

export const menus: Menus = {
    address: {
        en: 'address',
        HU: 'Címek',
        subMenus: {
            addressGroups: {
                url: clientEndpoints.unit_address_groups,
                title: 'Csoportok',
                formTitle: 'Csoport',
            },
            messageFields: {
                url: clientEndpoints.unit_message_fields,
                title: 'Üzenetmezők',
                formTitle: 'Üzenetmező',
            },
            addressList: {
                url: clientEndpoints.unit_address_list,
                title: 'Címlista',
                formTitle: 'Címzett',
            },
        },
    },
    administration_unit: {
        en: 'administration',
        HU: 'Adminisztráció',
        subMenus: {
            users: {
                url: clientEndpoints.unit_users,
                title: 'Felhasználókezelés',
                formTitle: 'Felhasználó',
            },
            emails: {
                url: clientEndpoints.unit_emails,
                title: 'E-mail címek',
                formTitle: 'E-mail cím',
            },
        },
    },
    administration: {
        en: 'administration',
        HU: 'Adminisztráció',
        subMenus: {
            users: {
                url: clientEndpoints.central_users,
                title: 'Felhasználókezelés',
                formTitle: 'Felhasználó',
            },
            units: {
                url: clientEndpoints.central_units,
                title: 'Területi egységek',
                formTitle: 'Területi egység',
            },
            systemMessages: {
                url: clientEndpoints.central_system_messages,
                title: 'Rendszerüzenetek',
                formTitle: 'Rendszerüzenet',
            },
        },
    },
    statistics: {
        en: 'statistics',
        HU: 'Statisztikák',
        subMenus: {
            aggregate: {
                url: clientEndpoints.unit_statistics_aggregate,
                title: 'Összesített',
            },
            campaign: {
                url: clientEndpoints.unit_statistics_campaign,
                title: 'Kampánystatisztika',
            },
            grouped: {
                url: clientEndpoints.unit_statistics_grouped,
                title: 'Csoportosított',
            },
            other: {
                url: clientEndpoints.unit_statistics_other,
                title: 'Egyéb statisztikák',
            },
        },
    },
    template: {
        en: 'template',
        HU: 'Sablonok',
        subMenus: {
            messageTemplates: {
                url: clientEndpoints.unit_message_templates,
                title: 'Üzenetsablonok',
                formTitle: 'Üzenetsablon',
            },
        },
    },
    superadmin: {
        en: 'superadmin',
        HU: 'Szuperadmin',
        subMenus: {
            settings: {
                url: '/settings',
                title: 'Beállítások',
                formTitle: 'Beállítás',
            },
        },
    },
};

/**
 * A listaoldalak header komponensét adja vissza.
 * A paraméterek a komponenst tartalmazó mappák nevei kisbetűvel.
 * Pl. Pages/Administration/Emails => createListPageHeader('administration', 'emails')
 *
 * @param mainMenu
 * @param subMenu
 * @returns
 */
export const createListPageHeader = (mainMenu: string, subMenu: string) => {
    const header: Header = {
        breadcrumbs: {
            [menus[mainMenu].en]: menus[mainMenu].HU,
            [menus[mainMenu].subMenus[subMenu].url]: menus[mainMenu].subMenus[subMenu].title,
        },
        title: menus[mainMenu].subMenus[subMenu].title,
        project: PROJECT_NAME,
    };

    return header;
};

/**
 * Az űrlap oldalak header komponensét adja vissza.
 * A paraméterek a komponenst tartalmazó mappák nevei kisbetűvel.
 *  * Pl. Pages/Administration/EmailsForm => createListPageHeader('administration', 'emails', id)
 *
 * @param mainMenu
 * @param subMenu
 * @returns
 */
export const createFormPageHeader = (mainMenu: string, subMenu: string, id?: number | null) => {
    const title = `${menus[mainMenu].subMenus[subMenu].formTitle} ${id ? 'szerkesztése' : 'hozzáadása'}`;

    const header = {
        breadcrumbs: {
            [menus[mainMenu].en]: menus[mainMenu].HU,
            [menus[mainMenu].subMenus[subMenu].url]: menus[mainMenu].subMenus[subMenu].title,
            [id
                ? `${menus[mainMenu].subMenus[subMenu].url}/${id}/edit`
                : `${menus[mainMenu].subMenus[subMenu].url}/new`]: title,
        },
        title: title,
        project: PROJECT_NAME,
    };

    return header;
};

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@silinfo/front-end-template';
import InfoIcon from '@mui/icons-material/Info';

export default function ImportTooltip(): JSX.Element {
    return (
        <Tooltip
            title={
                <Typography variant="subtitle2">
                    <p>
                        <strong>Hibalista:</strong> Ebben az esetben azt is hibának tekintjük, ha az Excel táblázatban
                        lévő e-mail címek közül valamelyik már szerepel a rendszerben. Ha ilyen e-mail címet tartalmaz
                        az Excel, az importálás ebben az esetben meghiúsul, vagyis az Excelben lévő adatok egyáltalán
                        nem kerülnek importálásra.
                    </p>
                    <p>
                        <strong>Figyelmen kívül hagyás:</strong> Ebben az esetben ha olyan e-mail cím szerepel az Excel
                        táblázatban, amely már a rendszerben is megtalálható, akkor ezen Excel sorokat figyelmen kívül
                        hagyja az import folyamat. Vagyis a rendszerben nem kerülnek frissítésre ezen e-mail címekhez
                        tartozó mezőadatok és ezen e-mail címek nem kerülnek hozzáadásra az importáláskor kiválasztott
                        csoport(ok)hoz.
                    </p>
                    <p>
                        <strong>Figyelmen kívül hagyás és csoportba sorolás ismétlődő címek esetén is:</strong> Ebben az
                        esetben ha olyan e-mail cím szerepel az Excel táblázatban, amely már a rendszerben is
                        megtalálható, akkor ezen Excel sorokat figyelmen kívül hagyja az import folyamat. Vagyis a
                        rendszerben nem kerülnek frissítésre ezen e-mail címekhez tartozó mezőadatok, azonban ezen
                        e-mail címek is hozzáadásra kerülnek az importáláskor kiválasztott csoport(ok)hoz.
                    </p>
                    <p>
                        <strong>Meglévő adatok módosítása:</strong> Ebben az esetben ha olyan e-mail cím szerepel az
                        Excel táblázatban, amely már a rendszerben is megtalálható, akkor ezen adatok frissítésre
                        kerülnek az új adatokkal és az e-mail címek hozzáadásra kerülnek az importáláskor kiválasztott
                        csoport(ok)hoz.
                    </p>
                </Typography>
            }
        >
            <IconButton sx={{ float: 'right', p: 0, ml: 'auto' }}>
                <InfoIcon color="primary" />
            </IconButton>
        </Tooltip>
    );
}

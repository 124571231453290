import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { FieldGenerator } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { DynamicForm } from '../../../../../components/DynamicForm';
import settingsService from '../../../../../services/Unit/settingsService';
import { dynamicFormFields } from '../fields';
import { IMessageTemplatesFormType } from '../types';
import { initialValue, margins } from '../utils';
import PDFGeneration from './PDFGeneration';
import { MessageTemplatesMessageFields } from '../BaseFormPage';

interface DynamicFormPartProps {
    readonly?: boolean;
    messageFields: MessageTemplatesMessageFields[];
}

export default function DynamicFormPart({ readonly, messageFields }: DynamicFormPartProps) {
    const formikProps = useFormikContext<IMessageTemplatesFormType>();
    const { errors } = formikProps;

    const [uploadFileMaxSize, setUploadFileMaxSize] = useState(0);

    useEffect(() => {
        settingsService.getUploadFileMaxSize().then((res) => setUploadFileMaxSize(res.data.value));
    }, []);

    return (
        <Grid item xs={12}>
            <DynamicForm
                formKey="attachments"
                title="Csatolmányok"
                noItemText="Nincs hozzáadott csatolmány."
                itemText="Csatolmány"
                fields={(id) => dynamicFormFields(id, formikProps, uploadFileMaxSize, readonly)}
                initialValue={initialValue}
                readonly={readonly}
            >
                {(formProps) => {
                    const currentValue = formikProps.values.attachments.find(
                        (a) => a.id === formProps.id || a.tmpId === formProps.id,
                    );

                    if (!currentValue?.type) return null;

                    if (currentValue.type === 'generated_file') {
                        return (
                            <Grid item xs={12} container spacing={2} key={formProps.id}>
                                <Grid item xs={12} container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" marginTop="8px">
                                            Margó
                                        </Typography>
                                    </Grid>
                                    {margins.map((margin) =>
                                        FieldGenerator({
                                            ...formikProps,
                                            name: 'attachments.' + formProps.i + '.margin.' + margin.value,
                                            label: margin.label,
                                            format: { xs: 12, sm: 6, md: 3 },
                                            fieldType: 'base',
                                            props: {
                                                value: currentValue.margin?.[margin.value],
                                                InputProps: {
                                                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                                },
                                                inputProps: {
                                                    type: 'number',
                                                    min: 0,
                                                    step: 0.1,
                                                },
                                                disabled: readonly,
                                                error: !!errors[formProps.i + '.margin.' + margin.value],
                                                helperText: errors[formProps.i + '.margin.' + margin.value],
                                            },
                                        }),
                                    )}
                                </Grid>
                                {FieldGenerator({
                                    name: 'attachments.' + formProps.i + '.fileNameGen',
                                    label: 'Generált fájl neve',
                                    format: { xs: 12 },
                                    props: {
                                        value: currentValue.fileNameGen,
                                        InputProps: {
                                            endAdornment: <InputAdornment position="end">.pdf</InputAdornment>,
                                        },
                                        error: !!errors[formProps.i + '.fileNameGen'],
                                        helperText: errors[formProps.i + '.fileNameGen'],
                                        disabled: readonly,
                                    },
                                    fieldType: 'base',
                                    ...formikProps,
                                })}
                                {readonly ? null : (
                                    <PDFGeneration
                                        messageTemplates={messageFields}
                                        currentValue={currentValue}
                                        i={formProps.i}
                                    />
                                )}
                            </Grid>
                        );
                    }

                    return null;
                }}
            </DynamicForm>
        </Grid>
    );
}

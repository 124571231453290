import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FormikListPage, TableButton } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import Header, { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ActionCellRenderer from '../../../../components/ActionCellRenderer';
import MCCLoading from '../../../../components/MCCLoading';
import campaignsService from '../../../../services/campaigns/campaigns';
import campaignsEmailPostedService from '../../../../services/campaigns/campaignsEmailPosted';
import { PROJECT_NAME } from '../../../../utils/AppConst';
import { InfoAlert } from '../../../../utils/Components';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { ICampaign, initialCampaign } from './types';

function CampaignAddressList() {
    const [campaign, setCampaign] = useState<ICampaign>(initialCampaign);
    const { id } = useParams() as { id: string };
    const breadcrumbs = {
        addresses: 'Kampányok',
        [clientEndpoints.unit_campaigns]: 'Kampánylista',
        recipients_name: 'Címzettek',
    };

    const headerOnlyGroup: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Címzettek',
        breadcrumbs: {},
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [groups, setGroups] = useState<Option[]>([]);

    useEffect(() => {
        setLoading(true);

        axios
            .all([campaignsEmailPostedService.groupsWithEmailPosted(id), campaignsService.get(+id)])
            .then((res) => {
                setGroups(res[0].data);
                setCampaign(res[1].data);
            })
            .finally(() => setLoading(false));
    }, [id]);

    const BtnCellRenderer = (params: GridRenderCellParams) => {
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item>
                        <TableButton
                            tooltip={'E-mail megtekintése'}
                            onClick={() =>
                                navigate(
                                    clientEndpoints.unit_campaigns_recipients_email_log
                                        .replace(':id', id?.toString())
                                        .replace(':emailPostedId', params.row.id.toString()),
                                )
                            }
                        >
                            <EmailIcon />
                        </TableButton>
                    </Grid>
                </Grid>
            </>
        );
    };

    if (loading) {
        return <MCCLoading />;
    }
    return (
        <Grid container spacing={2}>
            <Header {...{ ...headerOnlyGroup, breadcrumbs }} />
            <InfoAlert backText={'Vissza a kampánylistához'} backLink={clientEndpoints.unit_campaigns}>
                <Box>
                    Jelenleg a(z) <strong>{campaign.name}</strong> kampány címzettjeinek listáját látod.
                    {campaign.submitDate ? ' Kiküldés ideje: ' + new Date(campaign.submitDate).toLocaleString() : ''}
                </Box>
            </InfoAlert>
            <Grid item xs={12}>
                <FormikListPage
                    autoload
                    header={headerOnlyGroup}
                    tableProps={{
                        initialState: { pinnedColumns: { right: ['operations'] } },
                    }}
                    columns={[
                        { headerName: 'E-mail', field: 'email', maxWidth: 300 },
                        { headerName: 'Csoportok', field: 'groups', sortable: false },
                        {
                            headerName: 'Műveletek',
                            field: 'operations',
                            renderCell: (params) => (
                                <ActionCellRenderer>
                                    <BtnCellRenderer {...params} />
                                </ActionCellRenderer>
                            ),
                            sortable: false,
                            maxWidth: 200,
                        },
                    ]}
                    service={{ filter: (form) => campaignsEmailPostedService.filterEmailPosted(+id, form) }}
                    defaultSort={{ email: 'asc' }}
                    filter={{
                        fields: [
                            {
                                name: 'email',
                                label: 'E-mail cím',
                                format: { xs: 12, md: 4, lg: 4 },
                            },
                            {
                                name: 'groups',
                                label: 'Csoportok',
                                type: 'multiselect',
                                options: groups,
                                format: { xs: 12, md: 4, lg: 4 },
                            },
                        ],
                    }}
                    responseKey="data"
                />
            </Grid>
        </Grid>
    );
}

export default CampaignAddressList;

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FormikListPage } from '@silinfo/front-end-template';
import { useMemo, useState } from 'react';
import { createListPageHeader } from '../../../utils/Headers';
import { filterFields, tableColumns } from './fields';
import systemMessagesService from '../../../services/Central/systemMessages';

export default function SystemMessages() {
    const [refresh, setRefresh] = useState(false);
    const header = createListPageHeader('administration', 'systemMessages');
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
    const columns = useMemo(() => tableColumns(setRefresh, matches), [matches]);

    return (
        <FormikListPage
            service={systemMessagesService}
            header={header}
            autoload={true}
            filter={{
                fields: filterFields,
                initialValues: {
                    flag: ['0'],
                },
            }}
            refresh={refresh}
            responseKey="data"
            columns={columns}
            tableProps={{
                initialState: { pinnedColumns: { right: ['operations'] } },
            }}
        />
    );
}

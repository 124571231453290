import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Page } from '@silinfo/front-end-template';
import { AxiosError, AxiosResponse } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MCCLoading from '../../../../components/MCCLoading';
import campaignsService from '../../../../services/campaigns/campaigns';
import { IViolations } from '../../../../services/ResponseInterfaces';
import { store } from '../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';
import { create } from '../../../../store/notification';
import { transformApiErrors } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import AlertDialog, { AlertDialogProps } from './AlertDialog';
import Form from './Form';
import { NewCampaignFormType } from './types';
import { getAlertDialogMessage, header, initialForm } from './utils';
import { checkPageAccessByEntityOwner } from '../../../../utils/AccessManagementHelper';

export default function NewCampaign() {
    const isOwn = store.getState().auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;

    const { id } = useParams();
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [dialogState, setDialogState] = useState<AlertDialogProps>({
        openState: [
            false,
            (value: boolean) => setDialogState((prev) => ({ ...prev, openState: [value, prev.openState[1]] })),
        ],
        text: '',
        handleNo: () => navigate(clientEndpoints.unit_campaigns),
        handleSubmit: console.log,
    });
    const [isIntentionalLeave, setIsIntentionalLeave] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<NewCampaignFormType>({ ...initialForm, isOwn: isOwn });
    const navigate = useNavigate();
    const [isError, setIsError] = useState<boolean>(false);

    const initialDialogState: AlertDialogProps = {
        openState: [
            false,
            (value: boolean) => setDialogState((prev) => ({ ...prev, openState: [value, prev.openState[1]] })),
        ],
        text: '',
        handleSubmit: console.log,
    };
    const checkEntityAccess = useCallback((res: AxiosResponse) => checkPageAccessByEntityOwner(res, isOwn), [isOwn]);

    const submitAfterConfirmation = (
        form: Omit<NewCampaignFormType, 'withForm'>,
        formikHelpers: FormikHelpers<NewCampaignFormType>,
    ) => {
        setFormLoading(true);
        setIsIntentionalLeave(true);
        campaignsService
            .save(form)
            .then(() =>
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres mentés!',
                        redirect: clientEndpoints.unit_campaigns,
                    }),
                ),
            )
            .catch((error: AxiosError<{ violations: IViolations[] }>) => {
                setIsIntentionalLeave(false);
                if (error.response?.status === 422) {
                    formikHelpers.setErrors(transformApiErrors<NewCampaignFormType>(error.response?.data.violations));
                }
                dispatch(
                    create({
                        type: 'error',
                        message: 'Hiba a kampány mentése során.',
                    }),
                );
            })
            .finally(() => {
                setFormLoading(false);
            });
        setDialogState(initialDialogState);
    };

    const handleSubmit = (form: NewCampaignFormType, formikHelpers: FormikHelpers<NewCampaignFormType>) => {
        const { withForm, ...cleanForm } = form;
        setDialogState({
            openState: [true, dialogState.openState[1]],
            text: getAlertDialogMessage(form.submitType, withForm),
            handleSubmit: () =>
                submitAfterConfirmation(
                    {
                        ...cleanForm,
                    },
                    formikHelpers,
                ),
        });
    };

    useEffect(() => {
        if (id) {
            setLoading(true);
            campaignsService
                .saveAutomatic({ id: +id, openToEdit: true })
                .then(checkEntityAccess)
                .then((res) =>
                    setInitialValues((prev) => ({
                        ...prev,
                        ...res.data,
                        replyEmailId: res.data.replyEmail?.value ?? initialForm.replyEmailId,
                        senderEmailId: res.data.senderEmail?.value ?? initialForm.senderEmailId,
                        messageTemplateId: res.data.messageTemplate?.id ?? initialForm.messageTemplateId,
                        prevCampaignId: res.data.prevCampaign?.value ?? initialForm.prevCampaignId,
                    })),
                )
                .catch((error: AxiosError<{ violations: IViolations[] }>) => {
                    setIsError(true);
                    setIsIntentionalLeave(true);
                    if (error.response?.status === 422 && error.response?.data?.violations) {
                        setDialogState((prev) => ({
                            ...prev,
                            openState: [true, prev.openState[1]],
                            text: error.response ? error.response.data.violations[0].message : '',
                            handleSubmit: () => navigate(clientEndpoints.unit_campaigns_view.replace(':id', id)),
                        }));
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [id, navigate, isOwn, checkEntityAccess]);

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <Page header={header(!!id)}>
            <Grid item xs={12}>
                {isError ? (
                    <Typography variant="h6" color="GrayText">
                        <i>Hiba a kampány betöltése során.</i>
                    </Typography>
                ) : (
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        {() => (
                            <Form
                                formLoading={formLoading}
                                isEdit={!!id}
                                setFormLoading={setFormLoading}
                                isIntentionalLeave={isIntentionalLeave}
                                setIsIntentionalLeave={setIsIntentionalLeave}
                            />
                        )}
                    </Formik>
                )}
            </Grid>
            <AlertDialog {...dialogState} />
        </Page>
    );
}

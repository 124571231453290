import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { IForm } from '../../utils/AppConst';
import { AxiosResponse } from 'axios';

export interface ISystemMessagesService {
    filter: (form: IForm) => Promise<AxiosResponse>;
    get: (id: string | number) => Promise<AxiosResponse>;
    update: (id: string | number, form: IForm) => Promise<AxiosResponse>;
    getMessageFields: () => Promise<AxiosResponse>;
}

const systemMessagesService: ISystemMessagesService = {
    filter: (form) => API.get(endpoints.central.systemMessages.main, { params: form }),
    get: (id) => API.get(`${endpoints.central.systemMessages.main}/${id}`),
    update: (id, form) => API.put(`${endpoints.central.systemMessages.main}/${id}`, form),
    getMessageFields: () => API.get(endpoints.central.systemMessages.getMessageFields),
};

export default systemMessagesService;

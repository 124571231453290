import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Input } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { YES_NO_OPTIONS } from '../../../../utils/AppConst';
import SeenCellRenderer from './SeenCellRenderer';
import WrongEmailCellRenderer from './WrongEmailCellRenderer';

export const filterFields = (campaignOptions: Option[]): Input[] => [
    {
        name: 'campaign',
        label: 'Kampány',
        format: { xs: 12, md: 6, lg: 3 },
        type: 'multiselect',
        options: campaignOptions,
    },
    {
        name: 'mailingAddress',
        label: 'Címzett',
        format: { xs: 12, md: 6, lg: 3 },
    },
    {
        name: 'seen',
        label: 'Megnyitotta',
        format: { xs: 12, md: 6, lg: 3 },
        type: 'multiselect',
        options: YES_NO_OPTIONS,
    },
    {
        name: 'wrongEmail',
        label: 'Hibás e-mail cím',
        format: { xs: 12, md: 6, lg: 3 },
        type: 'multiselect',
        options: YES_NO_OPTIONS,
    },
    {
        label: 'Kampány indítás ideje (-tól)',
        name: 'campaignSubmitDateFrom',
        type: 'date',
        format: { xs: 12, md: 6, lg: 3 },
    },
    {
        label: 'Kampány indítás ideje (-ig)',
        name: 'campaignSubmitDateUntil',
        type: 'date',
        format: { xs: 12, md: 6, lg: 3 },
    },
    {
        label: 'Link kattintások (-tól)',
        name: 'allLinkClickTimesMin',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Link kattintások (-ig)',
        name: 'allLinkClickTimesMax',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Leiratkozás link kattintások (-tól)',
        name: 'openLinkUnsubscribeTimesMin',
        type: 'text',
        format: { xs: 12, md: 6, lg: 6 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Leiratkozás link kattintások (-ig)',
        name: 'openLinkUnsubscribeTimesMax',
        type: 'text',
        format: { xs: 12, md: 6, lg: 6 },
        props: {
            type: 'number',
        },
    },
    {
        label: '"Ha az üzenet nem megfelelően jelenik meg" kattintások (-tól)',
        name: 'openLinkNotCorrectViewTimesMin',
        type: 'text',
        format: { xs: 12, md: 12, lg: 6 },
        props: {
            type: 'number',
        },
    },
    {
        label: '"Ha az üzenet nem megfelelően jelenik meg" kattintások (-ig)',
        name: 'openLinkNotCorrectViewTimesMax',
        type: 'text',
        format: { xs: 12, md: 12, lg: 6 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Űrlapot megnyitotta (-tól)',
        name: 'openTimesMin',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Űrlapot megnyitotta (-ig)',
        name: 'openTimesMax',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Űrlapot kitöltötte (-tól)',
        name: 'fillTimesMin',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Űrlapot kitöltötte (-ig)',
        name: 'fillTimesMax',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
];

export const tableColumns: GridColDef[] = [
    { headerName: 'Kampány', field: 'campaignName', flex: 1, minWidth: 250 },
    { headerName: 'Kampányindítás ideje', field: 'campaignSubmitDate', flex: 1, minWidth: 160 },
    { headerName: 'Címzett', field: 'mailingAddress', flex: 1 },
    {
        headerName: 'Megnyitotta',
        field: 'seen',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => <SeenCellRenderer seen={params.value} />,
    },
    {
        headerName: 'Link kattintások',
        field: 'allLinkClickTimes',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => params.value ?? 0,
    },
    { headerName: 'Leiratkozás link kattintások', field: 'openLinkUnsubscribeTimes', flex: 1 },
    {
        headerName: `'Ha az üzenet nem megfelelően jelenik meg' kattintások`,
        field: 'openLinkNotCorrectViewTimes',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => params.value ?? 0,
    },
    {
        headerName: 'Űrlapot megnyitotta',
        field: 'openTimes',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => params.value ?? 0,
    },
    {
        headerName: 'Űrlapot kitöltötte',
        field: 'fillTimes',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => params.value ?? 0,
    },
    {
        headerName: 'Hibás e-mail cím',
        field: 'wrongEmail',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => <WrongEmailCellRenderer params={params} />,
    },
];

import { FormikListPage } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { useEffect, useState } from 'react';
import MCCLoading from '../../../../components/MCCLoading';
import campaignsService from '../../../../services/campaigns/campaigns';
import { fetchCampaigns, PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { filterFields, tableColumns } from './fields';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { GridCellParams } from '@mui/x-data-grid';

export default function CampaignList() {
    const [loading, setLoading] = useState<boolean>(true);
    const [precedingCampaigns, setPrecedingCampaigns] = useState<Option<string>[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const matches = useMediaQuery('(min-width:900px)', { noSsr: true });
    const { view, user, selectedOwner } = useSelector((state: RootState) => state.auth);
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Kampánylista',
        breadcrumbs: {
            'campaign': 'Kampányok',
            [clientEndpoints.unit_campaigns]: 'Kampánylista',
        },
    };

    useEffect(() => {
        fetchCampaigns(
            campaignsService.findSent,
            {
                ownerId: view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ? user?.id : 'null',
            },
            setLoading,
            setPrecedingCampaigns,
        );
    }, [user?.id, view.unitCampaignManagement]);

    if (loading) return <MCCLoading />;

    return (
        <FormikListPage
            autoload
            header={header}
            columns={tableColumns(matches, setRefresh)}
            service={{
                filter: (search) =>
                    campaignsService.filter(
                        view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                            ? { ...search, ...{ ownerId: user?.id } }
                            : view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner
                            ? { ...search, ...{ ownerId: selectedOwner?.value } }
                            : { ...search, ...{ ownerId: 'null' } },
                    ),
            }}
            defaultSort={{ sentAt: 'desc' }}
            filter={{ fields: filterFields(precedingCampaigns) }}
            responseKey="data"
            refresh={refresh}
            tableProps={{
                getCellClassName: (params: GridCellParams<number>) => (params.field !== 'name' ? 'ellipsis' : ''),
                getEstimatedRowHeight: () => 100,
                getRowHeight: () => 'auto',
                sx: {
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: 1,
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '15px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                        py: '22px',
                    },
                },
                initialState: {
                    pinnedColumns: { right: ['operations'] },
                    sorting: {
                        sortModel: [
                            {
                                field: 'sentAt',
                                sort: 'desc',
                            },
                        ],
                    },
                },
            }}
        />
    );
}

import Chip from '@mui/material/Chip';
import { GridRenderCellParams } from '@mui/x-data-grid';

interface WrongEmailCellRendererProps {
    params: GridRenderCellParams;
}

export default function WrongEmailCellRenderer({ params }: WrongEmailCellRendererProps) {
    if (!params.value) {
        return <Chip color="success" label="Nem" />;
    }

    return <Chip color="error" label="Igen" />;
}

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Input } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction } from 'react';
import ActionCellRenderer from '../../../components/ActionCellRenderer';
import BtnCellRenderer from './BtnCellRenderer';

export const filterFields: Input[] = [
    {
        name: 'name',
        label: 'Név',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'author',
        label: 'Létrehozó',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'insertDateFrom',
        label: 'Létrehozó dátum -tól',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'datetime-local',
    },
    {
        name: 'insertDateTo',
        label: 'Létrehozó dátum -ig',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'datetime-local',
    },
    {
        name: 'editor',
        label: 'Módosító',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'updateDateFrom',
        label: 'Módosítás dátum -tól',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'datetime-local',
    },
    {
        name: 'updateDateTo',
        label: 'Módosítás dátum -ig',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'datetime-local',
    },
];

export const tableColumns = (setRefresh: Dispatch<SetStateAction<boolean>>, matches: boolean): GridColDef[] =>
    [
        { headerName: 'Név', field: 'name' },
        { headerName: 'Létrehozó', field: 'author' },
        { headerName: 'Létrehozás dátuma', field: 'insertDate' },
        { headerName: 'Utolsó módosító', field: 'editor' },
        { headerName: 'Utolsó módosítás dátuma', field: 'updateDate' },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams) => (
                <ActionCellRenderer>
                    <BtnCellRenderer params={params} />
                </ActionCellRenderer>
            ),
            sortable: false,
            minWidth: matches ? 200 : 90,
        },
    ].map((col) => ({ flex: 1, minWidth: 150, ...col }));

import { clientEndpoints } from '../../utils/clientEndpoints';
import EmailLogShow from '../../pages/Unit/Campaign/CampaignList/EmailLogShow';
import EmailLogPage from '../../pages/Unit/Administration/EmailLog';
const EmailLog = {
    path: clientEndpoints.central_email_log,
    children: [
        {
            path: '',
            element: <EmailLogPage type={'central'} />,
            name: 'central_email_log',
        },
        {
            path: ':emailPostedId',
            element: <EmailLogShow type={'central_email_log'} />,
            name: 'central_email_log_show',
        },
    ],
};

export default EmailLog;

import { useCallback, useState } from 'react';

export interface OpenState {
    isOpen: boolean;
    close: () => void;
    open: () => void;
    toggleOpen: () => void;
}

export default function useOpen(defaultOpen = false): OpenState {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const toggleOpen = useCallback(() => setIsOpen((prev) => !prev), [setIsOpen]);
    const close = useCallback(() => setIsOpen(false), [setIsOpen]);
    const open = useCallback(() => setIsOpen(true), [setIsOpen]);
    return { isOpen, close, open, toggleOpen };
}

import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import { FormikErrors, FormikProps } from 'formik';
import { Option } from '../../../../components/Form/Form';
import { IForm } from '../../../../utils/AppConst';
import MessageFieldsDialogButton from './MessageFieldsDialogButton';
import { IMessageTemplatesFormType } from './types';

export const margins: Option<'left' | 'right' | 'top' | 'bottom'>[] = [
    {
        label: 'Bal',
        value: 'left',
    },
    {
        label: 'Jobb',
        value: 'right',
    },
    {
        label: 'Felső',
        value: 'top',
    },
    {
        label: 'Alsó',
        value: 'bottom',
    },
];

export const initialValue: IForm = {
    type: 'generated_file',
    header: '',
    footer: '',
    content: '',
    margin: {
        top: 2.5,
        right: 2.5,
        bottom: 2.5,
        left: 2.5,
    },
    fields: {},
    fileNameGen: '',
};

export const initialValueUploadedFile: IForm = {
    type: 'uploaded_file',
    filePath: '',
    fileName: '',
};

export const useStyles = makeStyles(() => ({
    richTextEditor: {
        '& .ck-editor__main > .ck-editor__editable': {
            height: '30vh',
        },
        marginTop: '8px',
    },
}));

export const messageFieldProps = (
    name: string,
    props: FormikProps<IMessageTemplatesFormType>,
    globalErrors?: FormikErrors<IMessageTemplatesFormType>,
    readonly?: boolean,
) => ({
    props: {
        InputProps: readonly
            ? {}
            : {
                  endAdornment: (
                      <InputAdornment position="end">
                          <MessageFieldsDialogButton fieldName={name} {...props} />
                      </InputAdornment>
                  ),
              },
        error: !!props?.errors[name] || !!globalErrors?.[name],
        helperText: props?.errors[name] || globalErrors?.[name],
        disabled: readonly,
    },
});

export const transformForm = (form: IMessageTemplatesFormType): IMessageTemplatesFormType => {
    const transformedAttachments = form.attachments.map((attachment) =>
        attachment.type === 'uploaded_file'
            ? attachment
            : { ...attachment, fileNameGen: attachment.fileNameGen + '.pdf' },
    );

    return { ...form, attachments: transformedAttachments };
};

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Dispatch, SetStateAction } from 'react';
import colors from '../../../../styles/colors';
import { IAddressesField } from './types';

function handleCheckboxClick(
    e: React.ChangeEvent<HTMLInputElement>,
    form: IAddressesField[],
    setForm: Dispatch<SetStateAction<IAddressesField[]>>,
) {
    e.preventDefault();
    const { name, checked } = e.target;
    const index = form.findIndex((item) => item.name === name);
    const newForm = [...form];
    newForm[index] = { ...newForm[index], visible: checked };
    setForm(newForm);
}

export interface RowProps {
    field: IAddressesField;
    form: IAddressesField[];
    setForm: Dispatch<SetStateAction<IAddressesField[]>>;
}

const useStyles = makeStyles(() => ({
    root: {
        borderBottom: `1px solid ${colors.grayHover}`,
    },
}));

export default function Row({ field, form, setForm }: RowProps): JSX.Element {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            item
            xs={12}
            key={field.name}
            alignItems="center"
            justifyContent="center"
            className={classes.root}
        >
            <Grid item xs={9}>
                <Typography>{field.label}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Checkbox
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxClick(e, form, setForm)}
                    name={field.name}
                    checked={field.visible}
                    disabled={field.default}
                />
            </Grid>
        </Grid>
    );
}

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FormikProps } from 'formik';
import { useCallback, useState } from 'react';
import { theme } from '../../../../utils/theme';
import MessageFieldsDialog from './MessageFieldsDialog';

import { IMessageTemplatesFormType } from './types';

export interface IMessageFieldsDialogButtonProps extends FormikProps<IMessageTemplatesFormType> {
    fieldName: string;
}

export interface DialogProps {
    id: number;
    open: boolean;
    handleClose: () => void;
}

export default function MessageFieldsDialogButton(props: IMessageFieldsDialogButtonProps) {
    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    const dialogProps: DialogProps = {
        id: 0,
        open,
        handleClose,
    };

    return (
        <Grid item sx={{ borderLeft: '1px solid ' + theme.palette.secondary.main }}>
            <Button onClick={handleOpen} color="secondary">
                Üzenetmezők
            </Button>
            <MessageFieldsDialog {...dialogProps} {...props} />
        </Grid>
    );
}

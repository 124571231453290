import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { Accordion } from '@silinfo/front-end-template';
import GrapesJS from 'grapesjs';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    showCommonMessageFieldsCondition,
    showOwnMessageFieldsCondition,
} from '../../pages/Unit/Templates/MessageTemplates/BaseForm/MessageFieldsDialog';
import { MessageTemplatesMessageFields } from '../../pages/Unit/Templates/MessageTemplates/BaseFormPage';
import useFilterFlaggedMessageFields from '../../pages/Unit/Templates/MessageTemplates/useFilterFlaggedMessageFields';
import { RootState } from '../../store';
import { onlyCommonCampaignCondition } from '../../utils/AccessManagementHelper';
import MCCLoading from '../MCCLoading';

type ComponentToEdit = GrapesJS.Component | GrapesJS.RichTextEditor | null;

interface MessageFieldsDialogProps {
    componentState: [ComponentToEdit, React.Dispatch<React.SetStateAction<ComponentToEdit>>];
}

const isComponent = (component: ComponentToEdit): component is GrapesJS.Component =>
    (component as GrapesJS.Component)?.attributes?.type === 'text';

const MessageFieldTable = ({
    messageFields,
    component,
}: {
    component: ComponentToEdit;
    messageFields: MessageFieldTableMessageFieldType[];
}) => {
    const handleInsert = useCallback(
        (messageKey: string) => () => {
            if (isComponent(component)) {
                component.getEl().innerHTML += messageKey;
            } else if (component) {
                // rossz a típuzosása a grapesjsnek, ezért a trükközés
                (component as unknown as { insertHTML: (val: string) => void }).insertHTML(messageKey);
            }
        },
        [component],
    );

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Kulcs</TableCell>
                        <TableCell align="center">Leírás</TableCell>
                        <TableCell align="center">Kiválasztás</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {messageFields.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align="left">{row.key}</TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">
                                <Tooltip title="Beszúrás">
                                    <IconButton size="small" onClick={handleInsert(row.key)}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export type MessageFieldTableMessageFieldType = {
    id: number;
    name: string;
    key: string;
    description: string;
};

const transformMessageFields = (
    messageFields: MessageTemplatesMessageFields[],
): MessageFieldTableMessageFieldType[] => {
    return messageFields.map((item) => ({
        id: item.id,
        name: item.columnName,
        key: item.messageId,
        description: item.description,
    }));
};

export default function MessageFieldsDialog({ componentState }: MessageFieldsDialogProps) {
    const [component, setComponent] = componentState;
    const { ownMessageFields, commonMessageFields } = useSelector((state: RootState) => state.messageTemplates);
    const { user } = useSelector((state: RootState) => state.auth);
    const flaggedMessageFields = useFilterFlaggedMessageFields();

    const handleClose = useCallback(() => {
        setComponent(null);
    }, [setComponent]);

    if (!user) {
        return <MCCLoading />;
    }

    return (
        <Dialog open={!!component} fullWidth onClose={handleClose}>
            <DialogTitle>Üzenetmező beszúrása</DialogTitle>
            <DialogContent dividers style={{ paddingTop: '16px' }}>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12} style={{ marginTop: '8px' }}>
                        <Accordion title={`Rendszerszintű üzenetmezők`} defaultExpanded={false}>
                            <MessageFieldTable
                                component={component}
                                messageFields={transformMessageFields(flaggedMessageFields)}
                            />
                        </Accordion>
                    </Grid>
                    {showCommonMessageFieldsCondition(user) && (
                        <Grid item xs={12} style={{ marginTop: '8px' }}>
                            <Accordion title={`Közös üzenetmezők`} defaultExpanded={onlyCommonCampaignCondition()}>
                                <MessageFieldTable
                                    component={component}
                                    messageFields={transformMessageFields(commonMessageFields)}
                                />
                            </Accordion>
                        </Grid>
                    )}
                    {showOwnMessageFieldsCondition(user) && (
                        <Grid item xs={12}>
                            <Accordion title={`Saját üzenetmezők`} defaultExpanded={true}>
                                <MessageFieldTable
                                    component={component}
                                    messageFields={transformMessageFields(ownMessageFields)}
                                />
                            </Accordion>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import { GridRenderCellParams } from '@mui/x-data-grid';
import { Input } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import StatusCellRenderer from './StatusCellRenderer';

import ContentCellRenderer from './ContentCellRenderer';

const statusOptions = [
    { value: 'success', label: 'Kiküldve' },
    { value: 'generated', label: 'Kiküldésre vár' },
    { value: 'sending', label: 'Kiküldés alatt' },
    { value: 'error', label: 'Hibás' },
    { value: 'init', label: 'Inicializálva' },
];

export const filterFields = (unitOptions: Option[], isCentral = false): Input[] => [
    ...(isCentral
        ? [
              {
                  name: 'unit',
                  label: 'Területi egység',
                  format: { xs: 12, md: 6, lg: 4 },
                  type: 'multiselect' as const,
                  options: unitOptions,
              },
          ]
        : []),

    {
        name: 'mailingAddress',
        label: 'Címzett e-mail',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'subject',
        label: 'Tárgy',
        format: { xs: 12, md: 6, lg: 4 },
    },
    ...(isCentral
        ? []
        : [
              {
                  name: 'content',
                  label: 'Tartalom',
                  format: { xs: 12, md: 6, lg: 4 },
              },
          ]),
    {
        type: 'datetime-local',
        label: 'Kiküldés (-től)',
        name: 'sendDateFrom',
        format: { xs: 12, md: 6, lg: 3 },
    },
    {
        type: 'datetime-local',
        label: 'Kiküldés (-ig)',
        name: 'sendDateTo',
        format: { xs: 12, md: 6, lg: 3 },
    },
    {
        type: 'datetime-local',
        label: 'Generálás (-tól)',
        name: 'generationDateFrom',
        format: { xs: 12, md: 6, lg: 3 },
    },
    {
        type: 'datetime-local',
        label: 'Generálás (-ig)',
        name: 'generationDateTo',
        format: { xs: 12, md: 6, lg: 3 },
    },
    {
        name: 'messageTemplate',
        label: 'Üzenetsablon',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'campaign',
        label: 'Kampány',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'status',
        label: 'Státusz',
        type: 'multiselect',
        format: { xs: 12, md: 6, lg: 4 },
        options: statusOptions,
    },
];

export const formFields: Input[] = [];

export const columns = (isCentral: boolean) =>
    [
        ...(isCentral ? [{ headerName: 'Területi egység', field: 'unit' }] : []),
        { headerName: 'Címzett e-mail', field: 'mailingAddress', minWidth: 250 },
        { headerName: 'Üzenetsablon', field: 'messageTemplate' },
        { headerName: 'Tárgy', field: 'subject' },
        { headerName: 'Generálás', field: 'generationDate' },
        { headerName: 'Kiküldés', field: 'sendDate' },
        { headerName: 'Kampány', field: 'campaign' },
        {
            headerName: 'Tartalom',
            field: 'content',
            sortable: false,
            renderCell: (params: GridRenderCellParams) => <ContentCellRenderer params={params} isCentral={isCentral} />,
        },
        {
            headerName: 'Státusz',
            field: 'status',
            renderCell: (params: GridRenderCellParams) => <StatusCellRenderer params={params} isCentral={isCentral} />,
            sortable: false,
        },
    ].map((elem) => ({ flex: 1, minWidth: 150, ...elem }));

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { GridRowClassNameParams } from '@mui/x-data-grid';
import { FormikListPage } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MCCLoading from '../../../../components/MCCLoading';
import statisticsGroupedService from '../../../../services/Unit/statistics/statisticsGroupedService';
import { create } from '../../../../store/notification';
import colors from '../../../../styles/colors';
import { IForm, IInfo, paginatorInfoBuild, PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import useCampaignOptions from '../hooks/useCampaignOptions';
import { filterFields, tableFields } from './fields';
import StatisticsGroupedChart from './StatisticsGroupedChart';
import { arraysForChart, initialInfo, StatisticsGroupedTable } from './types';
import { RootState } from '../../../../store';
import statisticsAggregateService from '../../../../services/Unit/statistics/statisticsAggregateService';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';
import { getOwnerIdStatistics } from '../Aggregate';

const useStyles = makeStyles(() => ({
    title: {
        fontSize: 'larger',
        borderBottom: '1px solid black',
        color: 'black',
    },
    bold: {
        fontWeight: 'bolder',
    },
    totalRow: {
        background: colors.primaryYellow,
        color: colors.black,
        fontWeight: 'bold',
    },
}));

export default function StatisticsGrouped() {
    const { user, view, selectedOwner } = useSelector((state: RootState) => state.auth);
    const { loading: campaingOptionsLoading, campaignOptions } = useCampaignOptions(
        statisticsAggregateService,
        getOwnerIdStatistics(view, user, selectedOwner),
    );
    const [chartOpen, setChartOpen] = useState<boolean>(false);
    const [chartData, setChartData] = useState<string[][]>([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [tableData, setTableData] = useState<StatisticsGroupedTable[]>([]);
    const [hideTable, setHideTable] = useState<boolean>(true);
    const [info, setInfo] = useState(initialInfo);
    const dispatch = useDispatch();
    const classes = useStyles();

    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Csoportosított',
        breadcrumbs: {
            statistics: 'Statisztikák',
            [clientEndpoints.unit_statistics_campaign]: 'Csoportosított',
        },
    };

    const onSubmit = (form: IForm) => {
        setHideTable(false);
        setTableLoading(true);

        setInfo((prevState) => ({
            ...prevState,
            filter: form,
        }));

        const filter =
            view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ||
            view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner
                ? {
                      ...form,
                      ...paginatorInfoBuild(info),
                      ...{ ownerId: getOwnerIdStatistics(view, user, selectedOwner) },
                  }
                : { ...form, ...paginatorInfoBuild(info) };

        return statisticsGroupedService
            .filter(filter)
            .then((response: AxiosResponse) => {
                setTableData(response.data.data);
                setInfo((prev) => ({
                    ...prev,
                    metadata: response.data.metadata,
                }));
                handleChart(response.data.data);
            })
            .finally(() => setTableLoading(false));
    };

    const setValueByKey = (key: string, value: string) => {
        setInfo((prev) => ({ ...prev, [key]: value }));
    };

    const fetchData = useCallback(
        (form: IInfo<IForm>) => {
            setTableLoading(true);
            statisticsGroupedService
                .filter(
                    view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                        ? {
                              ...form.filter,
                              ...paginatorInfoBuild(form),
                              ...{ ownerId: getOwnerIdStatistics(view, user, selectedOwner) },
                          }
                        : { ...form.filter, ...paginatorInfoBuild(form) },
                )
                .then((response: AxiosResponse) => {
                    setTableData(response.data.data);

                    setInfo((prev) => {
                        const firstPageCondition = response.data.metadata.page === 1 && prev.page !== 1;

                        return {
                            ...prev,
                            page: firstPageCondition ? 1 : response.data.metadata.page,
                            metadata: {
                                ...response.data.metadata,
                                page: firstPageCondition ? 1 : response.data.metadata.page,
                            },
                        };
                    });
                    handleChart(response.data.data);
                })
                .catch(() => dispatch(create({ type: 'error', message: 'Hiba a betöltés során!' })))
                .finally(() => {
                    setChartOpen(true);
                    setTableLoading(false);
                });
        },
        [dispatch, view, selectedOwner, user],
    );

    const handleChart = (data: StatisticsGroupedTable[]) => {
        const arraysForChartTmp = _.cloneDeep(arraysForChart);

        data.forEach((element) => {
            if (+element.id !== 0) {
                for (const property in arraysForChartTmp) {
                    arraysForChartTmp[`${property}`].push(element[property]);
                }
            }
        });

        const chartDataTmp: string[][] = [];
        for (const [, value] of Object.entries(arraysForChartTmp)) {
            chartDataTmp.push(value);
        }
        setChartData(chartDataTmp);
        setChartOpen(true);
    };

    if (campaingOptionsLoading) return <MCCLoading />;

    return (
        <Grid container spacing={1} direction={'row'}>
            <FormikListPage
                header={header}
                filter={{
                    onSubmit,
                    fields: filterFields(campaignOptions),
                }}
                table={{
                    columnDefs: tableFields,
                    rowData: tableData,
                    loading: tableLoading,
                    info: info,
                    setInfo: setValueByKey,
                    server: true,
                    searchfunc: fetchData,
                    initialState: {
                        pagination: {
                            pageSize: 10,
                        },
                    },
                    rowsPerPageOptions: [10, 20, 50, 100],
                    getRowClassName: (params: GridRowClassNameParams) => (params.id === 0 ? classes.totalRow : ''),
                }}
                hideTable={hideTable}
            />
            {chartOpen && (
                <StatisticsGroupedChart
                    loading={tableLoading}
                    chartData={chartData}
                    tableDataLength={tableData.length - 1} // -1 because of total row
                />
            )}
        </Grid>
    );
}

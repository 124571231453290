import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { CampaignSubmitType } from '../../../../services/campaigns/campaigns';
import { IForm, PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { Info, NewCampaignFormType } from './types';

export const AUTOMATIC_SAVE_TIME = 60;

const instantMessage = 'Figyelem, a kiküldés nem vonható vissza! Biztosan ki szeretnéd küldeni a kampányt?';
const scheduledHasFormMessage =
    'Figyelem, a kampány az üzenetsablon és az űrlap jelenlegi változatával kerül kiküldésre! A kiküldés visszavonása (a kampány törlése), illetve a kampány módosítása csak a kiküldés időpontjáig lehetséges. Biztosan szeretnéd véglegesíteni és beállítani ütemezett kiküldésre a kampányt?';
const scheduledNoFormMessage =
    'Figyelem, a kampány az üzenetsablon jelenlegi változatával kerül kiküldésre! A kiküldés visszavonása (a kampány törlése), illetve a kampány módosítása csak a kiküldés időpontjáig lehetséges. Biztosan szeretnéd véglegesíteni és beállítani ütemezett kiküldésre a kampányt?';
export const leavingPageMessage =
    'A kampány piszkozatként mentésre kerül, amely a Kampánylista menüpontból megnyitható további szerkesztésre. Biztosan szeretnéd elhagyni az oldalt?';

export const getAlertDialogMessage = (type: string, hasForm: boolean) => {
    if (type === CampaignSubmitType.Scheduled) {
        return hasForm ? scheduledHasFormMessage : scheduledNoFormMessage;
    }

    return instantMessage;
};

export const paginatorInfoBuild = (info: Info) => {
    const ret: Record<string, unknown> = {
        itemsPerPage: info.perpage,
        page: info.page,
    };

    if (info.sort && Object.keys(info.sort).length) {
        const orderKey = 'order[' + Object.keys(info.sort)[0].toString() + ']';
        const orderDescription = Object.values(info.sort)[0];
        ret[orderKey] = orderDescription;
    }

    return ret;
};

export const formKey = 'filter';

export const initInfo: Info = {
    perpage: 25,
    page: 1,
    count: 0,
    sort: { email: 'asc' },
    metadata: {
        'allCount': 0,
        'filteredCount': 0,
        'lastPage': 1,
        'page': 1,
        'perpage': 25,
        'rowCount': 0,
    },
    filter: {},
};

export const transferFilterToFilterData = (filter: IForm) => {
    const notAllowedKeys = ['order', 'itemsPerPage', 'page'];

    return Object.fromEntries(
        Object.entries(filter).filter(([key]) => !notAllowedKeys.includes(key) && !key.includes('order[')),
    );
};

export const transferFilterToOrder = (filter: IForm) =>
    Object.fromEntries(
        Object.entries(filter)
            .filter(([key]) => key.includes('order['))
            .map(([key, val]) => {
                return [key.slice(0, -1).slice(6), val];
            }),
    );

export const defaultSort = { email: 'asc' };

export const header = (isEdit: boolean): HeaderProps => ({
    project: PROJECT_NAME,
    title: isEdit ? 'Kampány szerkesztése' : 'Új kampány',
    breadcrumbs: {
        [clientEndpoints.unit_campaigns]: 'Kampányok',
        campaign: isEdit ? 'Kampány szerkesztése' : 'Új kampány',
    },
});
export const listPageFormKeys = ['addresses'];
export const sendDataFormKeys = ['submitType', 'submitDate', 'senderEmailId', 'senderSignId', 'replyEmailId'];
export const basicDataFormKeys = [
    'campaignId',
    'name',
    'messageTemplateId',
    'formsId',
    'formValidity',
    'tokenCountSpend',
];

export const initialForm: NewCampaignFormType = {
    prevCampaign: null,
    prevCampaignId: 0,
    name: '',
    messageTemplate: null,
    messageTemplateId: 0,
    formsId: 0,
    formValidity: '',
    tokenCountSpend: 0,
    submitType: CampaignSubmitType.Instant,
    replyEmailId: 0,
    senderEmailId: 0,
    submitDate: '',
    senderSignId: 0,
    filter: {},
    withForm: false,
    id: 0,
    session: '',
    isOwn: false,
    form: null,
};

export const preventEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
        e.preventDefault();
    }
};

export const isOwnParams = (isOwn: boolean) => ({
    showAddressType: isOwn ? ['onlyOwn'] : ['onlyCommon'],
    isOwn: isOwn ? 'true' : 'false',
});

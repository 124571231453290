import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';

const superAdminService = {
    ...crudTemplate(endpoints.central.superAdmin.main),
};

export type SuperAdminServiceType = typeof superAdminService;

export default superAdminService;

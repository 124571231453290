import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ArticleIcon from '@mui/icons-material/Article';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import Email from '@mui/icons-material/Email';
import Equalizer from '@mui/icons-material/Equalizer';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import GroupsIcon from '@mui/icons-material/Groups';
import InsertChart from '@mui/icons-material/InsertChart';
import ListAlt from '@mui/icons-material/ListAlt';
import People from '@mui/icons-material/People';
import PieChart from '@mui/icons-material/PieChart';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Settings from '@mui/icons-material/Settings';
import SsidChart from '@mui/icons-material/SsidChart';
import { IAuthState, ViewUnitCampaignManagementType } from '../../store/auth';
import { clientEndpoints } from '../../utils/clientEndpoints';
import { IMainMenu, ISubMenu } from './types';
import { CommonChip, OwnChip } from './Chips';

export const addressSubmenus = (auth: IAuthState): Record<string, ISubMenu> => ({
    addressList: {
        to: clientEndpoints.unit_address_list,
        icon: <ContactMailIcon />,
        text: 'Címlista',
        name: 'unit_address_list',
        right: auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && <CommonChip />,
    },
    addressListOwn: {
        to: clientEndpoints.unit_own_address_list,
        icon: <ContactMailIcon />,
        text: 'Címlista',
        name: 'unit_own_address_list',
        right: <OwnChip />,
    },
    addressGroups: {
        to: clientEndpoints.unit_address_groups,
        icon: <GroupsIcon />,
        text: 'Csoportok',
        name: 'unit_address_groups',
        right: auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && <CommonChip />,
    },
    addressGroupsOwn: {
        to: clientEndpoints.unit_own_address_groups,
        icon: <GroupsIcon />,
        text: 'Csoportok',
        name: 'unit_own_address_groups',
        right: <OwnChip />,
    },
    messageFields: {
        to: clientEndpoints.unit_message_fields,
        icon: <FormatListBulleted />,
        text: 'Üzenetmezők',
        name: 'unit_message_fields',
        right: auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && <CommonChip />,
    },
    messageFieldsOwn: {
        to: clientEndpoints.unit_own_message_fields,
        icon: <FormatListBulleted />,
        text: 'Üzenetmezők',
        name: 'unit_own_message_fields',
        right: <OwnChip />,
    },
});

export const campaignSubmenus = (auth: IAuthState): Record<string, ISubMenu> => ({
    newCampaign: {
        to: clientEndpoints.unit_new_campaign,
        icon: <PlaylistAddIcon />,
        text: 'Új kampány',
        name: 'unit_new_campaign',
    },
    campaignList: {
        to: clientEndpoints.unit_campaigns,
        icon: <ListAlt />,
        text: 'Kampánylista',
        name: 'unit_campaigns',
        right: (auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ||
            auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner) && <OwnChip />,
    },
});

export const templateSubmenus = (auth: IAuthState): Record<string, ISubMenu> => ({
    messageTemplates: {
        to: clientEndpoints.unit_message_templates,
        icon: <DynamicFeedIcon />,
        text: 'Üzenetsablonok',
        name: 'unit_message_templates',
        right: (auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ||
            auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner) && <CommonChip />,
    },
    messageTemplatesOwn: {
        to: clientEndpoints.unit_own_message_templates,
        icon: <DynamicFeedIcon />,
        text: 'Üzenetsablonok',
        name: 'unit_own_message_templates',
        right: <OwnChip />,
    },
    forms: {
        to: clientEndpoints.unit_forms,
        icon: <ArticleIcon />,
        text: 'Űrlapok',
        name: 'unit_forms',
        right: (auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ||
            auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner) && <CommonChip />,
    },
    formsOwn: {
        to: clientEndpoints.unit_own_forms,
        icon: <ArticleIcon />,
        text: 'Űrlapok',
        name: 'unit_own_forms',
        right: <OwnChip />,
    },
});

export const administrationSubmenus = (auth: IAuthState): Record<string, ISubMenu> =>
    Object.fromEntries(
        Object.entries({
            settings: {
                to: clientEndpoints.unit_settings,
                icon: <Settings />,
                text: 'Beállítások',
                name: 'unit_settings',
            },
            users: {
                to: clientEndpoints.unit_users,
                icon: <People />,
                text: 'Felhasználókezelés',
                name: 'unit_users',
            },
            emails: {
                to: clientEndpoints.unit_emails,
                icon: <AlternateEmailIcon />,
                text: 'E-mail címek',
                name: 'unit_emails',
            },
            emailsOwn: {
                to: clientEndpoints.unit_own_emails,
                icon: <AlternateEmailIcon />,
                text: 'E-mail címek',
                name: 'unit_own_emails',
                right: <OwnChip />,
            },
            emailLog: {
                to: clientEndpoints.unit_email_log,
                icon: <Email />,
                text: 'E-mail napló',
                name: 'unit_email_log',
            },
        }).filter(
            ([key, _]) =>
                auth.view.unitCampaignManagement !== ViewUnitCampaignManagementType.Own || key === 'emailsOwn',
        ),
    ) as Record<string, ISubMenu>;

export const statisticsSubmenus = (auth: IAuthState): Record<string, ISubMenu> => ({
    aggregate: {
        to: clientEndpoints.unit_statistics_aggregate,
        icon: <InsertChart />,
        text: 'Összesített',
        name: 'unit_statistics_aggregate',
        right: (auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ||
            auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner) && <OwnChip />,
    },
    campaign: {
        to: clientEndpoints.unit_statistics_campaign,
        icon: <PieChart />,
        text: 'Kampány',
        name: 'unit_statistics_campaign',
        right: (auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ||
            auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner) && <OwnChip />,
    },
    grouped: {
        to: clientEndpoints.unit_statistics_grouped,
        icon: <Equalizer />,
        text: 'Csoportosított',
        name: 'unit_statistics_grouped',
        right: (auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ||
            auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner) && <OwnChip />,
    },
    other: {
        to: clientEndpoints.unit_statistics_other,
        icon: <SsidChart />,
        text: 'Egyéb',
        name: 'unit_statistics_other',
        right: (auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ||
            auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner) && <OwnChip />,
    },
});

export const unitMainMenus = (auth: IAuthState): IMainMenu => ({
    address: {
        name: 'Címek',
        subMenus: Object.values(addressSubmenus(auth)),
    },
    campaign: {
        name: 'Kampányok',
        subMenus: Object.values(campaignSubmenus(auth)),
    },
    template: {
        name: 'Sablonok',
        subMenus: Object.values(templateSubmenus(auth)),
    },
    statistics: {
        name: 'Statisztikák',
        subMenus: Object.values(statisticsSubmenus(auth)),
    },
    administration: {
        name: 'Adminisztráció',
        subMenus: Object.values(administrationSubmenus(auth)),
    },
});

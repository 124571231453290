import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import api from '../../api';
import axios from 'axios';

export interface IUnitsForm {
    id?: string | number;
    name: string;
    serial: number;
}

function incrementSerial(id: number) {
    return api
        .post(endpoints.central.units.incrementSerial.replace('{id}', id.toString()), {})
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function decrementSerial(id: number) {
    return api
        .post(endpoints.central.units.decrementSerial.replace('{id}', id.toString()), {})
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

const checkStatus = () => axios.get(endpoints.status);

const unitsService = {
    ...crudTemplate<IUnitsForm>(endpoints.central.units.main),
    incrementSerial,
    decrementSerial,
    checkStatus,
};

export default unitsService;

import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Loading } from '@silinfo/front-end-template';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import messageFieldsService from '../../../services/address/messageFields';
import { create } from '../../../store/notification';

export interface IConfirmationDialog {
    message: string;
    params?: GridRenderCellParams;
    refresh: () => void;
}

export interface ConfirmationDialogProps {
    state: IConfirmationDialog;
    setState: (key: string, value: unknown) => void;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
    const dispatch = useDispatch();
    const [isVisibleDialogLoading, setVisibleDialogLoading] = React.useState(false);
    const { state, setState } = props;
    const { message, params, refresh } = state;

    return (
        <Dialog open={!!params} onClose={() => setState('params', undefined)} fullWidth>
            <>
                <DialogTitle>Megerősítés</DialogTitle>
                <>
                    <DialogContent style={{ paddingTop: '24px' }}>
                        {isVisibleDialogLoading ? <Loading noHeight /> : message}
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            loading={isVisibleDialogLoading}
                            variant="outlined"
                            onClick={() => setState('params', undefined)}
                        >
                            Nem
                        </LoadingButton>
                        <LoadingButton
                            loading={isVisibleDialogLoading}
                            onClick={() => {
                                if (!params) return;
                                setVisibleDialogLoading(true);
                                messageFieldsService
                                    .changeVisible(+params.id, {
                                        visible: params.row.visible ? '0' : '1',
                                    })
                                    .then(() => {
                                        setState('params', undefined);
                                        refresh();
                                        dispatch(create({ type: 'success', message: 'Sikerült.' }));
                                    })
                                    .catch(() =>
                                        dispatch(
                                            create({
                                                type: 'error',
                                                message: 'Hiba.',
                                            }),
                                        ),
                                    )
                                    .finally(() => {
                                        setVisibleDialogLoading(false);
                                    });
                            }}
                            variant="contained"
                        >
                            Igen
                        </LoadingButton>
                    </DialogActions>
                </>
            </>
        </Dialog>
    );
}

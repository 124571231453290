import Grid from '@mui/material/Grid';
import { Dispatch, SetStateAction } from 'react';
import { IAddressesField } from './types';
import Row from './Row';

export interface ContentProps {
    form: IAddressesField[];
    setForm: Dispatch<SetStateAction<IAddressesField[]>>;
}

export default function Content({ form, setForm }: ContentProps) {
    return (
        <Grid item xs={12}>
            {form.map((field) => (
                <Row key={field.name} field={field} form={form} setForm={setForm} />
            ))}
        </Grid>
    );
}

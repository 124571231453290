import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Accordion, Loading } from '@silinfo/front-end-template';
import Chart from 'react-google-charts';
import { chartOptions } from './types';

export interface StatisticsGroupedChartProps {
    loading: boolean;
    chartData: string[][];
    tableDataLength: number;
}

const AlertMessage = () => (
    <Accordion title="Grafikon">
        <Grid item xs={12}>
            <Alert severity="warning">
                <Typography>Grafikon legfeljebb 20 megjelenő sor esetén készül.</Typography>
            </Alert>
        </Grid>
    </Accordion>
);

const ChartComponent = ({ chartData, loading }: Omit<StatisticsGroupedChartProps, 'tableDataLength'>) => (
    <Accordion title="Grafikon">
        {loading ? (
            <Loading noHeight />
        ) : (
            <Chart chartType="BarChart" width="100%" height="600px" data={chartData} options={chartOptions} />
        )}
    </Accordion>
);

const StatisticsGroupedChart = ({ loading, chartData, tableDataLength }: StatisticsGroupedChartProps) => {
    if (tableDataLength > 20) return <AlertMessage />;
    return <ChartComponent chartData={chartData} loading={loading} />;
};

export default StatisticsGroupedChart;

import LoginOutlined from '@mui/icons-material/LoginOutlined';
import { LoadingButton as Button } from '@mui/lab';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Input } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import OffLayout from '../../../components/OffLayout';
import emailActivationService from '../../../services/Central/emailActivationService';
import { IForm, UserTokenType } from '../../../utils/AppConst';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import MCCLoading from '../../../components/MCCLoading';
import BackToLoginComponent from '../Activation/BackToLoginComponent';
import BackToLoginButton from '../Activation/BackToLoginButton';
import { isLogin } from '../../../utils/AuthHelper';

export interface PasswordForm extends IForm {
    token: string;
    password: string;
    passwordConfirmation: string;
}

const fields: Input[] = [
    {
        type: 'text',
        name: 'password',
        label: 'Jelszó',
        props: {
            type: 'password',
            required: true,
        },
        format: {
            xs: 12,
        },
    },
    {
        type: 'text',
        name: 'passwordConfirmation',
        label: 'Jelszó ismét',
        props: {
            type: 'password',
            required: true,
        },
        format: {
            xs: 12,
        },
    },
];

function Routing() {
    const [loading, setLoading] = useState<boolean>(false);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const { token } = useParams() as { token: string }; //mindig van token, különben nem ez a komponens renderelődn
    const navigate = useNavigate();
    const initialValues: PasswordForm = { token, password: '', passwordConfirmation: '' };
    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
    useEffect(() => {
        setLoading(true);
        emailActivationService
            .isTokenValid({ token: token, type: UserTokenType.ForgottenPassword })
            .then(() => setIsTokenValid(true))
            .catch(() => setIsTokenValid(false))
            .finally(() => setLoading(false));
    }, [token]);

    function handleSubmit(form: PasswordForm) {
        setFormLoading(true);

        return emailActivationService.resetPassword(form).finally(() => setFormLoading(false));
    }

    if (loading) {
        return <MCCLoading />;
    }

    if (!isTokenValid) {
        return (
            <BackToLoginComponent
                message={' Az aktivációs kulcs nem létezik, már felhasználásra került vagy lejárt.'}
            />
        );
    }

    if (!isLogin()) {
        return (
            <Form
                fields={fields}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                successMessage="Sikeres jelszó módosítás!"
                errorMessage="Hiba az jelszó módosítás során."
                hideButtons
                back={clientEndpoints.login}
            >
                {() => (
                    <>
                        <Grid item xs={12}>
                            <LoadingButton
                                loading={formLoading}
                                disabled={formLoading}
                                fullWidth
                                type="submit"
                                size="large"
                                variant="contained"
                            >
                                Mentés
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12}>
                            <BackToLoginButton />
                        </Grid>
                    </>
                )}
            </Form>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography textAlign="center" variant="h5">
                    Bejelentkezve nem hajtható végre új jelszó beállítása!
                </Typography>
            </Grid>
            <Grid item xs={12} justifyContent="center" textAlign="center">
                <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    size="large"
                    sx={{
                        padding: '8px',
                        height: 56,
                        width: 300,
                    }}
                    endIcon={<LoginOutlined />}
                    onClick={() => {
                        navigate(clientEndpoints.home);
                    }}
                    loading={loading}
                >
                    Vissza a főoldalra
                </Button>
            </Grid>
        </Grid>
    );
}

export default function ResetPassword() {
    return (
        <OffLayout>
            <Routing />
        </OffLayout>
    );
}

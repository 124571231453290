import UsersPage from '../../pages/Central/Users';
import UsersForm from '../../pages/Central/Users/FormPage';
import { clientEndpoints } from '../../utils/clientEndpoints';

const Users = {
    path: clientEndpoints.central_users,
    children: [
        {
            path: '',
            element: <UsersPage />,
            name: 'central_users',
        },
        {
            path: 'new',
            element: <UsersForm />,
            name: 'central_users_create',
        },
        {
            path: 'edit/:id',
            element: <UsersForm />,
            name: 'central_users_edit',
        },
    ],
};

export default Users;

import MessageTemplatesPage from '../../../pages/Unit/Templates/MessageTemplates';
import MessageTemplatesBaseForm from '../../../pages/Unit/Templates/MessageTemplates/BaseFormPage';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const OwnMessageTemplatesPage = () => <MessageTemplatesPage ownOnly={true} />;
const OwnMessageTemplatesBaseForm = () => <MessageTemplatesBaseForm ownOnly={true} />;
const OwnMessageTemplatesComplexForm = () => <MessageTemplatesBaseForm ownOnly complex />;

const OwnMessageTemplates = {
    path: clientEndpoints.unit_own_message_templates,
    children: [
        {
            path: '',
            element: <OwnMessageTemplatesPage />,
            name: 'unit_own_message_templates',
        },
        {
            path: 'new',
            element: <OwnMessageTemplatesBaseForm />,
            name: 'unit_own_message_templates_create',
        },
        {
            path: 'new-complex',
            element: <OwnMessageTemplatesComplexForm />,
            name: 'unit_own_message_templates_create_complex',
        },
        {
            path: ':id/edit',
            element: <OwnMessageTemplatesBaseForm />,
            name: 'unit_own_message_templates_edit',
        },
    ],
};

export default OwnMessageTemplates;

import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { PasswordForm } from '../../pages/Central/Activation';
import { UserTokenType } from '../../utils/AppConst';

const emailActivationService = {
    activate: (form: PasswordForm) => API.post(endpoints.central.emailActivation.activate, form),
    isTokenValid: (tokenForm: { token: string; type: UserTokenType }) =>
        API.post(endpoints.central.emailActivation.isTokenValid, tokenForm),
    resend: (userId: number | string) =>
        API.get(endpoints.central.emailActivation.resend?.replace('{userId}', userId?.toString())),
    send: (userId: number) => API.get(endpoints.central.emailActivation.send.replace('{id}', userId.toString())),
    resetPassword: (form: PasswordForm) => API.post(endpoints.central.emailActivation.resetPassword, form),
};

export type EmailActivationServiceType = typeof emailActivationService;

export default emailActivationService;

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { CKEditor, FieldGenerator } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { messageFieldProps } from '../../Unit/Templates/MessageTemplates/utils';
import { IMessageTemplatesFormType } from './../../Unit/Templates/MessageTemplates/types';

const useStyles = makeStyles(() => ({
    richTextEditor: {
        '& .ck-editor__main > .ck-editor__editable': {
            height: '30vh',
        },
        marginTop: '8px',
    },
}));

export default function BaseForm(props: FormikProps<IMessageTemplatesFormType>) {
    const classes = useStyles();

    return (
        <Grid container item xs={12}>
            {FieldGenerator({
                ...props,
                label: 'Név',
                name: 'name',
                type: 'text',
                format: {
                    xs: 12,
                },
            })}
            <Grid container spacing={1} direction="row" alignItems="center" justifyContent="center" item xs={12}>
                {FieldGenerator({
                    ...props,
                    label: 'Címzett neve',
                    name: 'recipientName',
                    type: 'text',
                    format: {
                        xs: 12,
                    },
                    ...messageFieldProps('recipientName', props),
                })}
            </Grid>
            <Grid container spacing={1} direction="row" alignItems="center" justifyContent="center" item xs={12}>
                {FieldGenerator({
                    ...props,
                    label: 'Tárgy',
                    name: 'subject',
                    type: 'text',
                    format: {
                        xs: 12,
                    },
                    ...messageFieldProps('subject', props),
                })}
            </Grid>
            <Grid
                className={classes.richTextEditor}
                style={props?.errors['content'] ? { border: '0.5px solid red', borderStyle: 'solid' } : {}}
                item
                xs={12}
            >
                <CKEditor
                    name={`content`}
                    format={{ xs: 12 }}
                    style={{ maxHeight: '10vh' }}
                    data={props.values?.content || ''}
                    onChange={(_: unknown, editor: { getData: () => string }) =>
                        props.setFieldValue(`content`, editor.getData())
                    }
                />
            </Grid>
        </Grid>
    );
}

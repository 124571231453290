const traitInputAttr = { placeholder: 'Szöveg helye' };

export const blockTranslations = {
    category: 'Alap',
    labelColumn1: '1 oszlop',
    labelColumn2: '2 oszlop',
    labelColumn3: '3 oszlop',
    labelColumn37: '2 oszlop 3/7',
    labelText: 'Szöveg',
    labelImage: 'Kép',
    labelVideo: 'Videó',
    labelMap: 'Térkép',
};

export const nlTranslations = {
    cmtTglImagesLabel: 'Képek váltása',
    cmdBtnMoveLabel: 'Mozgatás',
    cmdBtnUndoLabel: 'Visszavonás',
    cmdBtnRedoLabel: 'Újra',
    cmdBtnDesktopLabel: 'Asztali',
    cmdBtnTabletLabel: 'Tablet',
    cmdBtnMobileLabel: 'Mobil',
    modalTitleImport: 'Sablon importálása',
    modalTitleExport: 'Sablon exportálása',
    modalBtnImport: 'Importálás',
    sect100BlkLabel: '1 szekció',
    sect50BlkLabel: '1/2 szekció',
    sect30BlkLabel: '1/3 szekció',
    sect37BlkLabel: '3/7 szekció',
    buttonBlkLabel: 'Gomb',
    dividerBlkLabel: 'Elválasztó',
    textBlkLabel: 'Szöveg',
    textSectionBlkLabel: 'Szöveg szekció',
    imageBlkLabel: 'Kép',
    quoteBlkLabel: 'Idézet',
    linkBlkLabel: 'Link',
    linkBlockBlkLabel: 'Link blokk',
    gridItemsBlkLabel: 'Rácselemek',
    listItemsBlkLabel: 'Listaelemek',
    assetsModalTitle: 'Kép kiválasztása',
    styleManagerSectors: [
        {
            name: 'Méret',
            open: false,
            buildProps: ['width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
            properties: [
                { name: 'Szélesség', property: 'width' },
                { name: 'Magasság', property: 'height' },
                { name: 'Max. szélesség', property: 'max-width' },
                { name: 'Min. magasság', property: 'min-height' },
                {
                    name: 'Margó',
                    property: 'margin',
                    properties: [
                        { name: 'Felső', property: 'margin-top' },
                        { name: 'Bal', property: 'margin-left' },
                        { name: 'Jobb', property: 'margin-right' },
                        { name: 'Alsó', property: 'margin-bottom' },
                    ],
                },
                {
                    name: 'Kitöltés',
                    property: 'padding',
                    properties: [
                        { name: 'Felső', property: 'padding-top' },
                        { name: 'Jobb', property: 'padding-right' },
                        { name: 'Alsó', property: 'padding-bottom' },
                        { name: 'Bal', property: 'padding-left' },
                    ],
                },
            ],
        },
        {
            name: 'Tipográfia',
            open: false,
            buildProps: [
                'font-family',
                'font-size',
                'font-weight',
                'letter-spacing',
                'color',
                'line-height',
                'text-align',
                'text-decoration',
                'font-style',
                'vertical-align',
                'text-shadow',
            ],
            properties: [
                { name: 'Betűtípus', property: 'font-family' },
                { name: 'Betűméret', property: 'font-size' },
                { name: 'Betű vastagsága', property: 'font-weight' },
                { name: 'Betűköz', property: 'letter-spacing' },
                { name: 'Betűszín', property: 'color' },
                { name: 'Sorköz', property: 'line-height' },
                {
                    property: 'text-align',
                    name: 'Szövegigazítás',
                    defaults: 'left',
                    list: [
                        { value: 'left', name: 'Balra', className: 'fa fa-align-left' },
                        { value: 'center', name: 'Középre', className: 'fa fa-align-center' },
                        { value: 'right', name: 'Jobbra', className: 'fa fa-align-right' },
                        { value: 'justify', name: 'Sorkizárt', className: 'fa fa-align-justify' },
                    ],
                },
                {
                    property: 'text-decoration',
                    name: 'Szövegdekoráció',
                    type: 'radio',
                    defaults: 'none',
                    list: [
                        { value: 'none', name: 'Nincs', className: 'fa fa-times' },
                        { value: 'underline', name: 'Aláhúzás', className: 'fa fa-underline' },
                        { value: 'line-through', name: 'Áthúzás', className: 'fa fa-strikethrough' },
                    ],
                },
                {
                    property: 'font-style',
                    name: 'Betűstílus',
                    type: 'radio',
                    defaults: 'normal',
                    list: [
                        { value: 'normal', name: 'Normál', className: 'fa fa-font' },
                        { value: 'italic', name: 'Dőlt', className: 'fa fa-italic' },
                    ],
                },
                {
                    property: 'vertical-align',
                    name: 'Függőleges igazítás',
                    type: 'select',
                    defaults: 'baseline',
                    list: [
                        { value: 'baseline', name: 'Alapszint' },
                        { value: 'top', name: 'Felülre' },
                        { value: 'middle', name: 'Középre' },
                        { value: 'bottom', name: 'Alulra' },
                    ],
                },
                {
                    property: 'text-shadow',
                    name: 'Szövegárnyék',
                    properties: [
                        { name: 'X pozíció', property: 'text-shadow-h' },
                        { name: 'Y pozíció', property: 'text-shadow-v' },
                        { name: 'Homály', property: 'text-shadow-blur' },
                        { name: 'Szín', property: 'text-shadow-color' },
                    ],
                },
            ],
        },
        {
            name: 'Díszítések',
            open: false,
            buildProps: ['background-color', 'border-collapse', 'border-radius', 'border', 'background'],
            properties: [
                {
                    property: 'background-color',
                    name: 'Háttér',
                },
                {
                    name: 'Keret sugara',
                    property: 'border-radius',
                    properties: [
                        { name: 'Felső', property: 'border-top-left-radius' },
                        { name: 'Jobb', property: 'border-top-right-radius' },
                        { name: 'Alsó', property: 'border-bottom-left-radius' },
                        { name: 'Bal', property: 'border-bottom-right-radius' },
                    ],
                },
                {
                    property: 'border-collapse',
                    name: 'Keretösszevonás',
                    type: 'radio',
                    defaults: 'separate',
                    list: [
                        { value: 'separate', name: 'Nem' },
                        { value: 'collapse', name: 'Igen' },
                    ],
                },
                /*
        { // Too much low support
          property: 'box-shadow',
          properties: [
            { name: 'X position', property: 'box-shadow-h'},
            { name: 'Y position', property: 'box-shadow-v'},
            { name: 'Blur', property: 'box-shadow-blur'},
            { name: 'Spread', property: 'box-shadow-spread'},
            { name: 'Color', property: 'box-shadow-color'},
            { name: 'Shadow type', property: 'box-shadow-type'}
          ],
        },*/ {
                    property: 'border',
                    name: 'Keret',
                    properties: [
                        { name: 'Szélesség', property: 'border-width', defaults: '0' },
                        { name: 'Stílus', property: 'border-style' },
                        { name: 'Szín', property: 'border-color', defaults: '#000' },
                    ],
                },
                {
                    name: 'Háttér',
                    property: 'background',
                    properties: [
                        { name: 'Kép', property: 'background-image' },
                        {
                            name: 'Ismétlés',
                            property: 'background-repeat',
                            list: [
                                { value: 'repeat', name: 'Ismétlődő' },
                                { value: 'repeat-x', name: 'X tengelyen ismétlődő' },
                                { value: 'repeat-y', name: 'Y tengelyen ismétlődő' },
                                { value: 'no-repeat', name: 'Nem ismétlődő' },
                            ],
                        },

                        { name: 'Pozíció', property: 'background-position' },
                        { name: 'Csatolmány', property: 'background-attachment' },
                        { name: 'Méret', property: 'background-size' },
                    ],
                },
            ],
        },
    ],
};

export const DOMTranslations = {
    'Crop': 'Kivágás',
    'Delete-all': 'Összes törlése',
    'Bold': 'Félkövér',
    'Apply': 'Alkalmaz',
    'Arrow': 'Nyíl',
    'Arrow-2': 'Nyíl 2',
    'Arrow-3': 'Nyíl 3',
    'Blend': 'Keverés',
    'Blur': 'Elmosás',
    'Brightness': 'Fényerő',
    'Bubble': 'Gömb',
    'Cancel': 'Mégse',
    'Center': 'Középre',
    'Circle': 'Kör',
    'Color': 'Szín',
    'Color Filter': 'Színszűrő',
    'Custom': 'Egyéni',
    'Custom icon': 'Egyéni ikon',
    'Delete': 'Törlés',
    'Distance': 'Távolság',
    'Download': 'Letöltés',
    'Draw': 'Rajzolás',
    'Emboss': 'Élesítés',
    'Fill': 'Kitöltés',
    'Filter': 'Szűrő',
    'Flip': 'Tükrözés',
    'Flip X': 'Tükrözés vízszintesen',
    'Flip Y': 'Tükrözés függőlegesen',
    'Free': 'Szabad',
    'Grayscale': 'Szürkeárnyalatos',
    'Heart': 'Szív',
    'Icon': 'Ikon',
    'Invert': 'Invertálás',
    'Italic': 'Dőlt',
    'Left': 'Balra',
    'Load': 'Betöltés',
    'Load Mask Image': 'Maszk kép betöltése',
    'Location': 'Hely',
    'Mask': 'Maszk',
    'Multiply': 'Szorzás',
    'Noise': 'Zaj',
    'Pixelate': 'Pixelizálás',
    'Polygon': 'Poligon',
    'Range': 'Tartomány',
    'Rectangle': 'Téglalap',
    'Redo': 'Újra',
    'Remove White': 'Fehér eltávolítása',
    'Reset': 'Visszaállítás',
    'Right': 'Jobbra',
    'Rotate': 'Forgatás',
    'Sepia': 'Szépia',
    'Sepia2': 'Szépia 2',
    'Shape': 'Forma',
    'Sharpen': 'Élesítés',
    'Square': 'Négyzet',
    'Star-1': 'Csillag 1',
    'Star-2': 'Csillag 2',
    'Straight': 'Egyenes',
    'Stroke': 'Áthúzás',
    'Text': 'Szöveg',
    'Text size': 'Szövegméret',
    'Threshold': 'Küszöb',
    'Tint': 'Színezés',
    'Triangle': 'Háromszög',
    'Underline': 'Aláhúzás',
    'Undo': 'Visszavonás',
    'Value': 'Érték',
};

export default {
    assetManager: {
        addButton: 'Kép hozzáadása',
        inputPlh: 'http://eleres/a/kephez/kep.jpg',
        modalTitle: 'Válassz képet',
        uploadTitle: 'Dobd ide a fájlokat, vagy kattints a feltöltéshez',
    },
    // Here just as a reference, GrapesJS core doesn't contain any block,
    // so this should be omitted from other local files
    blockManager: {
        labels: {
            // 'block-id': 'Block Label',
        },
        categories: {
            // 'category-id': 'Category Label',
        },
    },
    domComponents: {
        names: {
            '': 'Doboz',
            wrapper: 'Törzs',
            text: 'Szöveg',
            comment: 'Megjegyzés',
            image: 'Kép',
            video: 'Videó',
            label: 'Címke',
            link: 'Link',
            map: 'Térkép',
            tfoot: 'Táblázat lábléc',
            tbody: 'Táblázat törzs',
            thead: 'Táblázat fejléc',
            table: 'Táblázat',
            row: 'Táblázat sor',
            cell: 'Táblázat cella',
        },
    },
    deviceManager: {
        device: 'Eszköz',
        devices: {
            desktop: 'Asztali',
            tablet: 'Tablet',
            mobileLandscape: 'Mobil fekvő',
            mobilePortrait: 'Mobil álló',
        },
    },
    panels: {
        buttons: {
            titles: {
                preview: 'Előnézet',
                fullscreen: 'Teljes képernyő',
                'sw-visibility': 'Komponensek láthatósága',
                'export-template': 'Kód',
                'open-sm': 'Stílusok',
                'open-tm': 'Beállítások',
                'open-layers': 'Rétegek',
                'open-blocks': 'Blokkok',
            },
        },
    },
    selectorManager: {
        label: 'Osztályok',
        selected: 'Kiválasztott',
        emptyState: '- Állapot -',
        states: {
            hover: 'Felett',
            active: 'Aktív',
            'nth-of-type(2n)': 'Páros/Páratlan',
        },
    },
    styleManager: {
        // 'Select an element before using Style Manager'
        empty: 'Válassz egy elemet a stílusok beállításához',
        layer: 'Réteg',
        fileButton: 'Képek',
        sectors: {
            general: 'Általános',
            layout: 'Elrendezés',
            typography: 'Betűtípus',
            decorations: 'Dekoráció',
            extra: 'Extra',
            flex: 'Flex',
            dimension: 'Méret',
        },
        // Default names for sub properties in Composite and Stack types.
        // Other labels are generated directly from their property names (eg. 'font-size' will be 'Font size').
        properties: {
            'text-shadow-h': 'X',
            'text-shadow-v': 'Y',
            'text-shadow-blur': 'Homály',
            'text-shadow-color': 'Szín',
            'box-shadow-h': 'X',
            'box-shadow-v': 'Y',
            'box-shadow-blur': 'Homály',
            'box-shadow-spread': 'Szórás',
            'box-shadow-color': 'Szín',
            'box-shadow-type': 'Típus',
            'margin-top-sub': 'Felső',
            'margin-right-sub': 'Jobb',
            'margin-bottom-sub': 'Alsó',
            'margin-left-sub': 'Bal',
            'padding-top-sub': 'Felső',
            'padding-right-sub': 'Jobb',
            'padding-bottom-sub': 'Alsó',
            'padding-left-sub': 'Bal',
            'border-width-sub': 'Szélesség',
            'border-style-sub': 'Stílus',
            'border-color-sub': 'Szín',
            'border-top-left-radius-sub': 'Bal felső',
            'border-top-right-radius-sub': 'Jobb felső',
            'border-bottom-right-radius-sub': 'Jobb alsó',
            'border-bottom-left-radius-sub': 'Bal alsó',
            'transform-rotate-x': 'Forgatás X tengely körül',
            'transform-rotate-y': 'Forgatás Y tengely körül',
            'transform-rotate-z': 'Forgatás Z tengely körül',
            'transform-scale-x': 'Méretezés X tengely alapján',
            'transform-scale-y': 'Méretezés Y tengely alapján',
            'transform-scale-z': 'Méretezés Z tengely alapján',
            'transition-property-sub': 'Tulajdonság',
            'transition-duration-sub': 'Időtartam',
            'transition-timing-function-sub': 'Időzítés',
            'background-image-sub': 'Kép',
            'background-repeat-sub': 'Ismétlés',
            'background-position-sub': 'Pozíció',
            'background-attachment-sub': 'Csatolmány',
            'background-size-sub': 'Méret',
        },
        // Translate options in style properties
        // options: {
        //   float: { // Id of the property
        //     ...
        //     left: 'Left', // {option id}: {Option label}
        //   }
        // }
    },
    traitManager: {
        empty: 'Válassz egy elemet a tulajdonságok beállításához',
        label: 'Komponens tulajdonságai',
        traits: {
            // The core library generates the name by their `name` property
            labels: {
                id: 'Azonosító',
                alt: 'Alternatív szöveg',
                title: 'Cím',
                href: 'Hivatkozás',
            },
            // In a simple trait, like text input, these are used on input attributes
            attributes: {
                id: traitInputAttr,
                alt: traitInputAttr,
                title: traitInputAttr,
                href: { placeholder: 'pl. https://google.com' },
            },
            // In a trait like select, these are used to translate option names
            options: {
                target: {
                    false: 'Ez az oldal',
                    _blank: 'Új ablak',
                },
            },
        },
    },
    storageManager: {
        recover: 'Vissza akarod állítani a legutóbbi munkádat?',
    },
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultFields, defaultTableFields } from '../../pages/Unit/Addresses/AddressList/fields';
import { IAddressesField } from '../../pages/Unit/Addresses/AddressList/types';

const initialState = {
    /**
     * We need to take out the 'operations' field:
     */
    fields: defaultFields().filter((field) => field.name !== 'operations'),
    tableFields: defaultTableFields.filter((field) => field.field !== 'operations'),
    fieldsAdded: false,
};

const campaignViewSlice = createSlice({
    name: 'campaignView',
    initialState,
    reducers: {
        addFields(state, action: PayloadAction<IAddressesField[]>) {
            if (state.fieldsAdded) return;

            state.fields = [...action.payload, ...state.fields];

            /**
             * SET TABLE FIELDS:
             */

            const tableFieldsCopy = state.tableFields.map((field) => ({ ...field }));

            let fieldIndexTable = 1;
            action.payload.forEach((field) => {
                if (!field.default) {
                    const index = tableFieldsCopy.findIndex((tableField) => tableField.field === field.name);
                    if (index === -1 && field.visible) {
                        tableFieldsCopy.splice(fieldIndexTable, 0, {
                            ...field,
                            headerName: field.label,
                            field: field.name,
                            minWidth: 100,
                        });
                    }

                    fieldIndexTable++;

                    if (index !== -1 && !field.visible) {
                        tableFieldsCopy.splice(index, 1);
                    }
                }
            });

            /**
             * Here we need to take out the 'operations' field
             */
            const operationsIndex = tableFieldsCopy.findIndex((field) => field.field === 'operations');

            if (operationsIndex !== -1) {
                tableFieldsCopy.splice(operationsIndex, 1);
            }

            state.tableFields = tableFieldsCopy.map((field) => ({ ...field, flex: 1 }));

            /**
             * We switch the variable
             */
            state.fieldsAdded = true;
        },
        setFields(state, action: PayloadAction<IAddressesField[]>) {
            state.fields = action.payload;
        },
        setTableFields(state, action: PayloadAction<IAddressesField[]>) {
            const tableFieldsCopy = state.tableFields.map((field) => ({ ...field }));

            let fieldIndex = 1;

            action.payload.forEach((field) => {
                if (!field.default) {
                    const index = tableFieldsCopy.findIndex((tableField) => tableField.field === field.name);
                    if (index === -1 && field.visible) {
                        tableFieldsCopy.splice(fieldIndex, 0, {
                            ...field,
                            headerName: field.label,
                            field: field.name,
                            minWidth: 100,
                        });
                    }

                    fieldIndex++;

                    if (index !== -1 && !field.visible) {
                        tableFieldsCopy.splice(index, 1);
                    }
                }
            });

            /**
             * Here we need to take out the 'operations' field
             */
            const operationsIndex = tableFieldsCopy.findIndex((field) => field.field === 'operations');

            if (operationsIndex !== -1) {
                tableFieldsCopy.splice(operationsIndex, 1);
            }

            state.tableFields = tableFieldsCopy.map((field) => ({ ...field, flex: 1 }));
        },
        resetFields(state) {
            state.fields = initialState.fields;
            state.tableFields = initialState.tableFields;
            state.fieldsAdded = false;
        },
    },
});

export const { addFields, setFields, setTableFields, resetFields } = campaignViewSlice.actions;
export default campaignViewSlice.reducer;

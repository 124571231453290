import FormsPage from '../../../pages/Unit/Templates/Forms';
import Form from '../../../pages/Unit/Templates/Forms/Form';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const Forms = {
    path: clientEndpoints.unit_forms,
    children: [
        {
            path: '',
            element: <FormsPage />,
            name: 'unit_forms',
        },
        {
            path: 'new',
            element: <Form />,
            name: 'unit_forms_new',
        },
        {
            path: ':id/edit',
            element: <Form />,
            name: 'unit_forms_edit',
        },
        {
            path: ':id/show',
            element: <Form readonly={true} />,
            name: 'unit_forms_show',
        },
    ],
};

export default Forms;

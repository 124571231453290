import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import api from '../../api';

const systemLogService = {
    ...crudTemplate(endpoints.central.systemLogs.main),
    units: () => api.get(endpoints.central.systemLogs.units),
    events: () => api.get(endpoints.central.systemLogs.events),
    types: () => api.get(endpoints.central.systemLogs.types),
};

export type SystemLogServiceType = typeof systemLogService;

export default systemLogService;

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { makeStyles } from '@mui/styles';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableButton } from '@silinfo/front-end-template';
import { useCallback, useState } from 'react';
import UserRolesDialog from './UserRolesDialog';

type UserRolesButtonProps = {
    params: GridRenderCellParams;
};

export const useStyles = makeStyles(() => ({
    rolesButton: {
        backgroundColor: 'white',
        color: '#0288d1',
    },
}));

export default function UserRolesButton({ params }: UserRolesButtonProps) {
    const id = params.row.id.toString();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    const dialogProps = {
        id: id,
        open: open,
        handleClose: handleClose,
    };

    return (
        <>
            <TableButton onClick={handleOpen} tooltip={`Jogosultságok`} color={'info'} className={classes.rolesButton}>
                <ManageAccountsIcon />
            </TableButton>
            <UserRolesDialog {...dialogProps} />
        </>
    );
}

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    footerHeader: {
        '& .ck-editor__main > .ck-editor__editable': {
            height: '10vh',
        },
        marginTop: '8px',
    },
    content: {
        '& .ck-editor__main > .ck-editor__editable': {
            height: '30vh',
        },
        marginTop: '8px',
    },
    errorTextEditor: {
        border: '1px solid red',
    },
}));

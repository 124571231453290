import MessageFieldsPage from '../../../pages/Address/MessageFields';
import MessageFieldsForm from '../../../pages/Address/MessageFields/Form';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const MessageFields = {
    path: clientEndpoints.unit_message_fields,
    children: [
        {
            path: '',
            element: <MessageFieldsPage />,
            name: 'unit_message_fields',
        },
        {
            path: 'new',
            element: <MessageFieldsForm />,
            name: 'unit_message_fields_create',
        },
        {
            path: ':id/edit',
            element: <MessageFieldsForm />,
            name: 'unit_message_fields_edit',
        },
    ],
};

export default MessageFields;

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { AxiosResponse } from 'axios';
import { IProgressTimeline } from './ProgressTimeline';
import { ErrorResponse, ErrorResult, ImportStatuses } from './types';
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles((theme: Theme) => ({
    tableCellLink: {
        color: theme.palette.text.primary,
        textTransform: 'none',
        textDecoration: 'underline',
        minWidth: 'fit-content',
        fontSize: '16px',
        paddingLeft: 0,
        paddingRight: 0,
        verticalAlign: 'inherit',
    },
}));

function deepCloneObject<T>(obj: T): T {
    // Handle primitives and null
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
        return new Date(obj.getTime()) as unknown as T;
    }

    // Handle Array
    if (Array.isArray(obj)) {
        return obj.map((item) => deepCloneObject(item)) as unknown as T;
    }

    // Handle Object
    const clonedObject = {} as T;
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            clonedObject[key] = deepCloneObject(obj[key]);
        }
    }
    return clonedObject;
}

const getUpdateImportStatuses = (
    importStatuses: IProgressTimeline[],
    response: AxiosResponse,
    importId: number,
    hasError: (
        response: AxiosResponse,
        importId: number,
    ) => { hasErrorMessage: boolean; errorMessage: React.ReactNode; statusKey: number },
) => {
    const updatedImportStatuses = deepCloneObject(importStatuses);
    const { hasErrorMessage, errorMessage, statusKey } = hasError(response, importId);
    const { data } = response;

    updatedImportStatuses[0].progress = data.status !== 'init' ? 100 : 0;

    //init
    if (updatedImportStatuses[0].progress === 100) {
        updatedImportStatuses[1].progress = 100;
    }

    const denominator = data.allCount === 0 ? 1 : data.allCount;

    //validation
    updatedImportStatuses[2].progress = Math.round((data.validationProcess / denominator) * 100);

    //save mysql
    updatedImportStatuses[3].progress = Math.round((data.saveProcess1 / denominator) * 100);

    //save mongodb
    updatedImportStatuses[4].progress = Math.round((data.saveProcess2 / denominator) * 100);

    if (hasErrorMessage) {
        updatedImportStatuses[statusKey].errorMessage = errorMessage;
    }

    return updatedImportStatuses;
};

const ERROR_MESSAGES = [
    'Hiba az inicializálás során',
    'Hiba a validáció során',
    'Hiba a validáció során, a hibalista megtekintéséhez kattints ide',
    'Hiba a MySQL mentés során',
    'Hiba a MongoDB mentés során',
];

const getStatusKey = (updatedImportStatuses: ImportStatuses): number => {
    let statusKey = 0;
    for (let i = 4; i > 0; i--) {
        if (updatedImportStatuses[i].progress > 1 || (i === 1 && updatedImportStatuses[i].progress === 100)) {
            statusKey = i;
            break;
        }
    }

    return statusKey;
};

const getErrorMessage = (
    importStatuses: ImportStatuses,
    response: ErrorResponse,
): { errMsg: string; statusKey: number } | undefined => {
    if (response.status === 'error_unknown') {
        const updatedImportStatuses = { ...importStatuses };
        let errMsg = '';
        let statusKey = getStatusKey(updatedImportStatuses);

        errMsg = ERROR_MESSAGES[statusKey];

        if (response.errorMessage) {
            errMsg = (errMsg ? errMsg + ': ' : '') + response.errorMessage;
        }

        if (response.allCount && !statusKey) {
            statusKey = 2;
        }

        return { errMsg, statusKey };
    } else if (response.status === 'error_validation') {
        return { errMsg: '', statusKey: 2 };
    }
};

const hasError = (
    response: AxiosResponse<ErrorResponse>,
    importId: number,
    importStatuses: ImportStatuses,
): ErrorResult => {
    const { errMsg: errorMessage, statusKey = 0 } = getErrorMessage(importStatuses, response.data) ?? {};
    const hasErrorMessage = errorMessage !== undefined;

    return {
        hasErrorMessage: hasErrorMessage ?? false,
        errorMessage: hasErrorMessage ? errorMessage || <ErrorMessage importId={importId} /> : '',
        statusKey,
    };
};

function isImportFinished(importStatuses: IProgressTimeline[]): boolean {
    let isProcessEnded = true;
    let hasErrorMessage = false;

    importStatuses.forEach((item) => {
        if (item.progress < 100) {
            isProcessEnded = false;
        }
    });

    importStatuses.forEach((item) => {
        if (item.errorMessage) {
            hasErrorMessage = true;
        }
    });

    return isProcessEnded || hasErrorMessage;
}

export {
    ERROR_MESSAGES,
    getErrorMessage,
    getStatusKey,
    getUpdateImportStatuses,
    hasError,
    useStyles,
    isImportFinished,
};

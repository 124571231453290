import settingsService from '../../../services/Central/settingsService';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import SystemSettingsForm from './Form';

export default function FormPage() {
    return (
        <SystemSettingsForm
            title="Rendszerbeállítások"
            clientUrl={clientEndpoints.central_system_settings}
            clientEditUrl={clientEndpoints.central_system_settings_edit}
            service={settingsService}
        />
    );
}

import AddressGroupsPage from '../../../pages/Address/AddressGroups';
import AddressGroupsForm from '../../../pages/Address/AddressGroups/Form';
import IndexOnlyGroup from '../../../pages/Unit/Addresses/AddressList/indexOnlyGroup';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const OwnAddressGroupsPage = () => <AddressGroupsPage ownOnly={true} />;
const OwnAddressGroupsForm = () => <AddressGroupsForm ownOnly={true} />;

const OwnAddressGroups = {
    path: clientEndpoints.unit_own_address_groups,
    children: [
        {
            path: '',
            element: <OwnAddressGroupsPage />,
            name: 'unit_own_address_groups',
        },
        {
            path: 'new',
            element: <OwnAddressGroupsForm />,
            name: 'unit_own_address_groups_create',
        },
        {
            path: ':id/edit',
            element: <OwnAddressGroupsForm />,
            name: 'unit_own_address_groups_edit',
        },
        {
            path: ':groupId/address',
            element: <IndexOnlyGroup ownOnly={true} />,
            name: 'unit_own_address_groups_only_group',
        },
    ],
};

export default OwnAddressGroups;

import { Action, ThunkAction } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { BrowserHistory, createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import addressList from './Unit/addressList';
import campaignFormList from './Unit/campaignFormList';
import campaignView from './Unit/campaignView';
import messageTemplates from './Unit/messageTemplates';
import auth from './auth';
import accessManagement from './accessManagement';
import loading from './loading';
import notification from './notification';

export const history = createBrowserHistory();
const loggerMiddleware = createLogger();

const rootReducer = (history: BrowserHistory) =>
    combineReducers({
        campaignFormList,
        campaignView,
        addressList,
        messageTemplates,
        router: connectRouter(history),
        notification,
        loading,
        auth,
        accessManagement,
    });

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer(history),
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware, loggerMiddleware)),
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

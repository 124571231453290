import OffLayout from '../../components/OffLayout';
import { Outlet, Navigate } from 'react-router-dom';
import Error from '../../pages/Error';

const errors = {
    path: '/',
    element: (
        <OffLayout>
            <Outlet />
        </OffLayout>
    ),
    children: [
        {
            path: '403',
            element: <Error statusCode={403} />,
        },
        {
            path: '404',
            element: <Error statusCode={404} />,
        },
        {
            path: '500',
            element: <Error statusCode={500} />,
        },
        {
            path: '502',
            element: <Error statusCode={502} />,
        },
        {
            path: '503',
            element: <Error statusCode={503} />,
        },
        {
            path: '*',
            element: <Navigate to="/404" />,
        },
    ],
};

export default errors;

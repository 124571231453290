import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { FormikContextType, useFormikContext } from 'formik';
import { useState } from 'react';
import { IMessageTemplatesFormType } from './types';

export default function OpenTestSendButton(props: {
    onClick: (form: FormikContextType<IMessageTemplatesFormType>) => Promise<void>;
}) {
    const [loading, setLoading] = useState(false);
    const formikProps = useFormikContext<IMessageTemplatesFormType>();

    const handleClick = async () => {
        setLoading(true);
        try {
            await props.onClick(formikProps);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid item>
            <LoadingButton loading={loading} color="warning" variant="contained" onClick={handleClick}>
                Teszt kiküldés
            </LoadingButton>
        </Grid>
    );
}

import AccordionActions from '@mui/material/AccordionActions';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { Accordion } from '@silinfo/front-end-template';
import Chart from 'react-google-charts';
import SplitButton, { IExportContext } from '../../../../components/Form/SplitButton';
import colors from '../../../../styles/colors';

const useStyles = makeStyles(() => ({
    exportButton: {
        backgroundColor: colors.export,
        '&:hover': {
            background: colors.exportHover,
            color: colors.white,
        },
    },
    newButton: {
        backgroundColor: colors.new,
        '&:hover': {
            background: colors.newHover,
            color: colors.white,
        },
    },
    totalRow: {
        background: colors.primaryYellow,
        color: colors.black,
        fontWeight: 'bold',
    },
}));

const Actions = (props: { exportContext: IExportContext }) => (
    <AccordionActions>
        <Grid item xs={8} md={10} container spacing={2} justifyContent="flex-end">
            <Grid item>
                <SplitButton placement="top" exportContext={props.exportContext} />
            </Grid>
        </Grid>
    </AccordionActions>
);

export interface CampaignStatisticsTableProps extends DataGridProps {
    title: string;
    chartData: unknown;
    exportContext: IExportContext;
}
const isChartDataEmpty = (chartData: unknown): boolean => {
    let isEmpty = true;

    if (!Array.isArray(chartData) || chartData.length <= 1) {
        return isEmpty;
    }

    for (let i = 1; i < chartData.length; i++) {
        if (chartData[i][1] > 0) {
            isEmpty = false;
        }
    }

    return isEmpty;
};

export default function CampaignStatisticsTable(props: CampaignStatisticsTableProps) {
    const title = props.title;
    const chartData = props.chartData;
    const classes = useStyles();

    const chartOptions = {
        sliceVisibilityThreshold: 0,
        chartArea: { top: 15, bottom: 15, height: 0 },
    };

    /**
     * TODO: hover-t levenni az összesen sorról
     */

    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Accordion title={title}>
                    <>
                        <Actions exportContext={props.exportContext} />
                        <DataGrid
                            disableColumnMenu={true}
                            hideFooterSelectedRowCount
                            autoHeight
                            getCellClassName={() => 'ellipsis'}
                            hideFooter={true}
                            getRowClassName={(params) => (params.id === 0 ? classes.totalRow : '')}
                            {...props}
                        />
                        {chartData && !isChartDataEmpty(chartData) && (
                            <Chart
                                style={{ paddingTop: '10px' }}
                                chartType="PieChart"
                                data={chartData}
                                options={chartOptions}
                                width={'100%'}
                            />
                        )}
                    </>
                </Accordion>
            </Grid>
        </Grid>
    );
}

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { SubmitButton } from '@silinfo/front-end-template';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import colors from '../../../../styles/colors';
import { ICampaignFormField } from './types';
import Content from '../../Addresses/AddressList/Content';
import { resetFields, setTableFields } from '../../../../store/Unit/campaignFormList';

export interface AddressFieldDialogProps {
    open: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles(() => ({
    defaultButton: {
        backgroundColor: colors.error,
        '&:hover': {
            backgroundColor: colors.errorHover,
        },
    },
}));

function CampaignFormFieldDialog(props: AddressFieldDialogProps) {
    const { open, handleClose } = props;
    const { fields } = useSelector((state: RootState) => state.campaignFormList);
    const [form, setForm] = useState<ICampaignFormField[]>(fields);
    const dispatch = useDispatch();
    const classes = useStyles();

    /**
     * MENTÉS:
     * Elmenti a változtatásokat a redux state-ben és megjeleníti az extra táblázat oszlopokat és szűrő mezőket, bezárja a dialógust
     */
    function handleSubmit() {
        dispatch(setTableFields(form));
        handleClose();
    }

    /**
     * BEZÁR: elveti a változtatásokat és bezárja a dialógust
     */
    function handleCancel() {
        setForm(fields);
        handleClose();
    }

    /**
     *  ALAPÉRTELMEZETT: visszaállítja a kezdőállapotot (táblázat + szűrők)
     */
    const handleDefault = useCallback(() => {
        setForm(fields.map((item) => ({ ...item, visible: item.default })));
    }, [fields]);

    useEffect(() => {
        return () => {
            dispatch(resetFields());
        };
    }, [dispatch]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid item container>
                <Grid item xs={12}>
                    <DialogTitle>Oszlopok megjelenítése/elrejtése</DialogTitle>
                </Grid>
                <Grid item container xs={12}>
                    <DialogContent>
                        <Content form={form} setForm={setForm} />
                    </DialogContent>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        <SubmitButton onClick={handleSubmit}>Mentés</SubmitButton>
                        <Button variant="outlined" onClick={handleCancel}>
                            Bezár
                        </Button>
                        <SubmitButton className={classes.defaultButton} onClick={handleDefault}>
                            Alapértelmezett
                        </SubmitButton>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default CampaignFormFieldDialog;

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useCallback, useEffect, useState } from 'react';
import statisticsOtherService from '../../../../services/Unit/statistics/statisticsOtherService';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    dataStat: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
}));

export default function DataStatus(): JSX.Element {
    const classes = useStyles();
    const [lastUpdate, setLastUpdate] = useState<string | null>(null);
    const [isRunning, setIsRunning] = useState<boolean>(true);
    const [isFirst, setIsFirst] = useState(true);
    const [fetchTimeout, setFetchTimeout] = useState<string | number | NodeJS.Timeout | undefined>(undefined);
    const fetchInfo = useCallback(() => {
        statisticsOtherService.sendgridGetLast.info().then((res) => {
            setLastUpdate(res.data.lastUpdate);
            setIsRunning(res.data.run);

            if (res.data.run) {
                setFetchTimeout(
                    setTimeout(() => {
                        fetchInfo();
                    }, 5 * 1000),
                );
            }
        });
    }, [setFetchTimeout]);
    const handleUpdateClick = () => {
        setIsRunning(true);

        statisticsOtherService.sendgridGetLast.update().then(() => {
            fetchInfo();
        });
    };

    useEffect(() => {
        if (isFirst) {
            fetchInfo();
            setIsFirst(false);
        }

        return () => {
            clearTimeout(fetchTimeout);
        };
    }, [fetchInfo, isFirst, fetchTimeout]);

    return (
        <Grid container spacing={2} my={2}>
            <Grid item xs={12}>
                <Paper className={classes.dataStat}>
                    <Grid container p={1}>
                        <Grid item xs={12} textAlign="right">
                            {!isRunning && lastUpdate && (
                                <Typography display={'inline-flex'}>
                                    A háttéradatok ekkor kerültek frissítésre utoljára: {lastUpdate}
                                </Typography>
                            )}
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick={() => handleUpdateClick()}
                                loading={isRunning}
                                sx={{ marginLeft: '1rem' }}
                            >
                                Frissítés most
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

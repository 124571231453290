import { DynamicInput } from '../../../../components/DynamicForm/types';

export const dynamicFields: DynamicInput[] = [
    {
        name: 'name',
        label: 'Elnevezés',
        format: {
            xs: 12,
        },
    },
    {
        name: 'content',
        label: 'Tartalom',
        isEditor: true,
    },
];

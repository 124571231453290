import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CancelButton, FieldGenerator, TextField } from '@silinfo/front-end-template';
import { AxiosError } from 'axios';
import { Form as FormikForm, Formik, FormikErrors, FormikHelpers } from 'formik';
import { Children, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IViolations } from '../../../../services/ResponseInterfaces';
import messageTemplatesService from '../../../../services/Unit/messageTemplates';
import { create } from '../../../../store/notification';
import { transformApiErrors } from '../../../../utils/AppConst';
import { MessageTemplatesMessageFields } from './BaseFormPage';
import { IMessageTemplatesFormType, initialMessageTemplatesForm } from './types';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { transformForm } from './utils';
import { RootState } from '../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';

const useStyles = makeStyles((theme: Theme) => ({
    errorMessage: {
        fontSize: '.8rem',
        color: theme.palette.error.main,
    },
}));

export interface ITestEmailForm extends IMessageTemplatesFormType {
    mailTo: string;
    fields: Record<string, string>;
}

const initialForm = (messageTemplateFields: MessageTemplatesMessageFields[]): ITestEmailForm => {
    const initialForm: ITestEmailForm = {
        mailTo: '',
        fields: {},
        ...initialMessageTemplatesForm,
    };

    messageTemplateFields.map((field) => {
        initialForm.fields[field.id] = '';
    });

    return initialForm;
};

export interface ITestEmailDialog {
    open: boolean;
    onClose: () => void;
    messageTemplatesFields: MessageTemplatesMessageFields[];
    messageTemplatesForm: IMessageTemplatesFormType;
    setGlobalErrors: (errors: FormikErrors<Record<string, unknown>>) => void;
}

export type ViolationError = AxiosError<{ violations: IViolations[] }>;

export default function TestEmailDialog(props: ITestEmailDialog): JSX.Element {
    const { user, view } = useSelector((state: RootState) => state.auth);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const handleSubmit = (form: ITestEmailForm, { setErrors }: FormikHelpers<ITestEmailForm>) => {
        setLoading(true);

        messageTemplatesService
            .sendTestMail({
                ...form,
                ...transformForm(props.messageTemplatesForm),
                ...{
                    ownerId: view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ? user?.id : null,
                },
            })
            .then(() => {
                props.onClose();
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres kiküldés!',
                    }),
                );
            })
            .catch((error) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                    props.setGlobalErrors(transformApiErrors(error.response?.data.violations));
                } else {
                    setErrors({});
                }

                dispatch(
                    create({
                        type: 'error',
                        message: error.response?.data.error ? error.response?.data.error : 'Hiba a kiküldés során.',
                    }),
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog open={props.open} fullWidth maxWidth="lg">
            <DialogTitle>Teszt e-mail</DialogTitle>
            <DialogContent style={{ paddingTop: '24px' }}>
                <Formik
                    initialValues={initialForm(props.messageTemplatesFields)}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {(fProps) => (
                        <FormikForm noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" sx={{ width: '100%', mt: 1 }}>
                                        <Typography variant="h6" gutterBottom>
                                            Teszt email kiküldése erre a címre
                                        </Typography>
                                        {FieldGenerator({
                                            ...fProps,
                                            label: 'Teszt e-mail kiküldése erre a címre',
                                            name: 'mailTo',
                                            type: 'text',
                                            format: {
                                                xs: 12,
                                            },
                                        })}
                                        <Typography component="p">
                                            Pontosvesszővel elválasztva több e-mail cím is megadható. Például:
                                        </Typography>
                                        <Typography component="p" noWrap={true}>
                                            gipsz.jakab@example.com‌;teszt.elek@example.com‌;kovacs.bela@example.com
                                        </Typography>
                                    </FormControl>
                                    {props.messageTemplatesFields.length > 0 && (
                                        <FormControl component="fieldset" sx={{ width: '100%', mt: 3 }}>
                                            <Typography variant="h6" gutterBottom>
                                                Sablonban szereplő mezők értékének megadása
                                            </Typography>
                                            {typeof fProps.errors?.fields === 'string' && (
                                                <Typography component="p" className={classes.errorMessage}>
                                                    {fProps.errors?.fields}
                                                </Typography>
                                            )}
                                            {Children.toArray(
                                                props.messageTemplatesFields.map((field) => (
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            defaultValue={fProps.values.fields[field.id]}
                                                            label={field.description}
                                                            name={`fields.${field.id}`}
                                                            error={!!fProps.errors[`fields.${field.id}`]}
                                                            helperText={fProps.errors[`fields.${field.id}`]}
                                                            placeholder={field.columnName}
                                                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                                                fProps.setFieldValue(
                                                                    `fields.${field.id}`,
                                                                    e.target.value,
                                                                );
                                                            }}
                                                        />
                                                    </Grid>
                                                )),
                                            )}
                                        </FormControl>
                                    )}
                                </Grid>
                                <Grid item container spacing={2} sx={{ mt: 1 }}>
                                    <Grid item>
                                        <LoadingButton loading={loading} variant="contained" type="submit">
                                            Küldés
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item>
                                        <CancelButton onClick={props.onClose}>Mégsem</CancelButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormikForm>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

import SystemLog from '../../pages/Central/SystemLog';
import EmailLogShow from '../../pages/Central/SystemLog/Show';
import { clientEndpoints } from '../../utils/clientEndpoints';
const SystemLogs = {
    path: clientEndpoints.central_system_logs,
    children: [
        {
            path: '',
            element: <SystemLog />,
            name: 'central_system_logs',
        },
        {
            path: ':id',
            element: <EmailLogShow />,
            name: 'central_system_logs_show',
        },
    ],
};

export default SystemLogs;

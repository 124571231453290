import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Loading } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import emailLogService from '../../../../services/Unit/emailLogService';

export interface ErrorStatuDialogProps {
    id: number;
    open: boolean;
    isCentral: boolean;
    handleClose: () => void;
}

export default function ErrorStatusDialog(props: ErrorStatuDialogProps) {
    const { id, open, handleClose, isCentral } = props;

    const [loading, setLoading] = useState(false);
    const [loggerText, setLoggerText] = useState('');

    useEffect(() => {
        if (open) {
            setLoading(true);
            emailLogService
                .loggerText(id, isCentral)
                .then((response: AxiosResponse) => setLoggerText(response.data.loggerText))
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [open, id, isCentral]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
            <Grid item container>
                <Grid item xs={12}>
                    <DialogTitle>Hibaüzenet</DialogTitle>
                </Grid>
                <Grid item container xs={12}>
                    <DialogContent>
                        {loading ? <Loading noHeight /> : <pre>{JSON.stringify(loggerText, null, 4)}</pre>}
                    </DialogContent>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>
                            Bezár
                        </Button>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
}

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { FormikListPage } from '@silinfo/front-end-template';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getOtherOwnerCondition, ViewUnitCampaignManagementType } from '../../../../store/auth';
import { useListPageProps } from './utils';

export default function Forms(props: { ownOnly?: boolean }) {
    const { ownOnly } = props;
    const { view, selectedOwner } = useSelector((state: RootState) => state.auth);
    const otherOwnerCondition = getOtherOwnerCondition();
    const listPageProps = useListPageProps(ownOnly);

    return (
        <Grid container spacing={2}>
            {view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && !ownOnly && !otherOwnerCondition && (
                <Grid item xs={12}>
                    <Alert severity="info">
                        Mivel saját kampány módban használod a rendszert, a közös űrlapokat csak olvasási módban tudod
                        megtekinteni.
                    </Alert>
                </Grid>
            )}
            {otherOwnerCondition && selectedOwner && (
                <Grid item xs={12}>
                    <Alert severity="info">
                        {`Mivel ${selectedOwner.label} saját kampányadatait látod, ezért az űrlapokat csak olvasási módban tudod megtekinteni.`}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <FormikListPage {...listPageProps} />
            </Grid>
        </Grid>
    );
}

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import addressListService from '../../../../../services/Unit/addressListService';
import ImportValidationErrorDialogContent, {
    IImportValidationErrorDialogContentErrorMessage as IErrorMessage,
} from './ImportValidationErrorDialogContent';
import { Loading } from '@silinfo/front-end-template';

export default function ImportValidationErrorDialog(props: { id: number }): JSX.Element {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dialogContentItems, setDialogContentItems] = useState<IErrorMessage[]>([]);
    const translate = (response: Record<number, string>): IErrorMessage[] => {
        const errorMessages: IErrorMessage[] = [];

        Object.entries(response).forEach(([key, value]) => {
            errorMessages.push({
                row: +key,
                message: value,
            });
        });

        return errorMessages;
    };

    useEffect(() => {
        if (props.id !== 0) {
            setIsDialogOpen(true);
            setIsLoading(true);
            addressListService
                .getImportErrorList(props.id)
                .then((res) => {
                    setDialogContentItems(translate(res.data));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [props.id]);

    return (
        <Dialog open={isDialogOpen}>
            <DialogTitle>Validáció hibalista</DialogTitle>
            <DialogContent style={{ paddingTop: '24px' }}>
                {isLoading && <Loading noHeight />}
                {!isLoading && <ImportValidationErrorDialogContent errors={dialogContentItems} />}
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        setIsDialogOpen(false);
                    }}
                >
                    Bezárás
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

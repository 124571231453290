import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableEditButton } from '@silinfo/front-end-template';
import { clientEndpoints } from '../../../../utils/clientEndpoints';

const BtnCellRenderer = (params: GridRenderCellParams) => {
    return (
        <TableEditButton
            tooltip={'Szerkesztés'}
            target={clientEndpoints.unit_settings_edit.replace(':id', params.row.id.toString())}
        />
    );
};

export default BtnCellRenderer;

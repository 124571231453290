import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { AxiosResponse } from 'axios';
import { FormikErrors } from 'formik';
import { IViolations } from '../services/ResponseInterfaces';
import { ICrudTemplate } from '../services/templates';
import { store } from '../store';
import { create } from '../store/notification';
import { Language } from '../store/sharedInterfaces';
import { clientEndpoints } from './clientEndpoints';

export const PROJECT_NAME = 'MCC Hírlevél- és űrlapkezelő rendszer';

export const LAYOUT_TYPE_COOKIE = 'mccnl_layout_type';
export const LAYOUT_NAME_COOKIE = 'mccnl_layout_name';
export const SELECTED_UNIT_COOKIE = 'mccnl_selected_unit';
export const SELECTED_UNIT_CAMPAIGN_MANAGEMENT = 'mccnl_selected_unit_campaign_management';
export const SELECTED_OWNER_COOKIE = 'mccnl_selected_owner';

export const findLabelByValue = (value: Option | string, options: Option[]) => {
    if (typeof value === 'string' || typeof value === 'number')
        return options.find((opt) => opt.value === value)?.label || value || '';
    return value?.label || '';
};

export interface IInfo<T> extends IForm {
    filter: IForm;
    sort: {
        [key in keyof T]?: 'asc' | 'desc';
    };
    page: number;
    perpage: number;
    metadata: Record<string, string | number>;
}

export const fixDateRangeFields = <T>(form: Record<string, T>) => {
    const fixedForm: Record<string, T> = {};
    Object.entries(form).forEach(([key, value]) => {
        const newKey = key.replace(/(.*)From/i, '$1[after]').replace(/(.*)Until/i, '$1[before]');
        fixedForm[newKey] = value;
    });
    return fixedForm;
};

export const fixNumberRangeFields = <T>(form: Record<string, T>) => {
    const fixedForm: Record<string, T> = {};
    Object.entries(form).forEach(([key, value]) => {
        const newKey = key.replace(/(.*)Min/i, '$1[gte]').replace(/(.*)Max/i, '$1[lte]');
        fixedForm[newKey] = value;
    });
    return fixedForm;
};

export const fixRangeFields = <T>(form: Record<string, T>) => {
    let fixedForm: Record<string, T> = fixDateRangeFields(form);
    fixedForm = fixNumberRangeFields(fixedForm);
    return fixedForm;
};

export function deleteUnnecessaryFields(form: Record<string, unknown>, fields: string[]) {
    fields.forEach((field) => {
        if (field in form) {
            if (form[field] !== '') {
                delete form[field];
            }
        }
    });
    return form;
}

export type RecursiveRecord<K extends string | number | symbol, V> = {
    [key in K]: RecursiveRecord<K, V> | V;
};

export type IForm = RecursiveRecord<string, unknown>;

export function deleteFieldIfEmpty(form: IInfo<IForm>, fields: string[]) {
    fields.forEach((field) => {
        if (field in form.filter) {
            if (form.filter[field] === '') {
                delete form.filter[field];
            }
        }
    });
    return form;
}

export function onlySpaces(str: string) {
    return str.trim().match(/^-$/);
}

export function transformApiErrors<T extends Record<string, unknown>>(violations: Array<IViolations>): FormikErrors<T> {
    const errors: Record<string, unknown> = {};
    for (const violation of violations) {
        /*   if (violation['propertyPath'].includes('.')) {
            errors = { ...errors, ...deepen({ [violation.propertyPath]: violation.message }) };
        } else {*/
        errors[violation['propertyPath']] = violation['message'];
    }
    /*  }*/
    return errors as FormikErrors<T>;
}

export const paginatorInfoBuild = <T>(info: IInfo<T>) => {
    const ret: IForm = {
        itemsPerPage: info.perpage,
        page: info.page,
    };

    if (info.sort && Object.keys(info.sort).length) {
        const orderKey = 'order[' + Object.keys(info.sort)[0].toString() + ']';
        const orderDescription = Object.values(info.sort)[0];
        ret[orderKey as keyof typeof ret] = orderDescription;
    }

    return ret;
};

export const languages: Language[] = ['hu', 'en'];

/**
 *  Listaoldal (szűrő + táblázat) szűréskor és első töltéskor használt fetch függvény
 *
 * @param filterFunction - ez a service.filter
 *
 * Ezek pedig a komponensben használt state manager függvények és változók:
 * @param setLoading
 * @param setData
 * @param info
 * @param setInfo
 *
 * a generikusok:
 * F = a szűrő interface
 * D = a táblázat interface
 */
export function fetchData<T extends IForm, D>(
    form: IForm,
    service: ICrudTemplate<T>,
    setLoading: (value: boolean) => void,
    setData: (data: D[]) => void,
    info: IInfo<D>,
    setInfo: React.Dispatch<React.SetStateAction<IInfo<D>>>,
) {
    setLoading(true);

    service
        .filter({ ...form, ...paginatorInfoBuild(info) })
        .then((d: AxiosResponse) => {
            const totalItems = d.data['hydra:totalItems'];
            setData(d.data['hydra:member']);
            setInfo((prev: IInfo<D>) => ({
                ...prev,
                metadata: {
                    allCount: totalItems,
                    filteredCount: totalItems,
                    lastPage: totalItems ? Math.ceil(totalItems / info.perpage) : 1,
                    page: info.page,
                    perpage: info.perpage,
                },
            }));
        })
        .catch(() =>
            store.dispatch(
                create({
                    type: 'error',
                    message: 'Hiba a betöltés során!',
                }),
            ),
        )
        .finally(() => {
            setLoading(false);
        });
}

export function fetchDataNormal<T extends IForm, D>(
    form: IForm,
    service: ICrudTemplate<T>,
    setLoading: (value: boolean) => void,
    setData: (data: D[]) => void,
    info: IInfo<D>,
    setInfo: React.Dispatch<React.SetStateAction<IInfo<D>>>,
) {
    if (setLoading) {
        setLoading(true);
    }

    service
        .filter({ ...form, ...paginatorInfoBuild(info) })
        .then((d: AxiosResponse) => {
            setData(d.data?.data);
            setInfo((prev: IInfo<D>) => ({
                ...prev,
                metadata: d.data?.metadata,
            }));
        })
        .catch(() =>
            store.dispatch(
                create({
                    type: 'error',
                    message: 'Hiba a betöltés során!',
                }),
            ),
        )
        .finally(() => {
            if (setLoading) {
                setLoading(false);
            }
        });
}

/**
 * Listaoldalakon a betöltéskor vagy szűréskor érkező válasz kezelése (paginált táblához)
 *
 * @param response - az érkezett válasz
 *
 *  Ezek pedig a komponensben használt state manager függvények és változók:
 * @param setData
 * @param info
 * @param setInfo
 *
 * a generikusok:
 * F = a szűrő interface
 * D = a táblázat interface
 */
export const handleResponse = <F, D>(
    response: AxiosResponse,
    setData: (data: D[]) => void,
    info: IInfo<F>,
    setInfo: React.Dispatch<React.SetStateAction<IInfo<F>>>,
) => {
    const totalItems = response.data['hydra:totalItems'];
    setData(response.data['hydra:member']);
    setInfo((prev) => ({
        ...prev,
        metadata: {
            allCount: totalItems,
            filteredCount: totalItems,
            lastPage: totalItems ? Math.ceil(totalItems / info.perpage) : 1,
            page: info.page,
            perpage: info.perpage,
            rowCount: totalItems,
        },
    }));
};

export const handleResponseNormal = <F, D>(
    response: AxiosResponse,
    setData: (data: D[]) => void,
    info: IInfo<F>,
    setInfo: React.Dispatch<React.SetStateAction<IInfo<F>>>,
) => {
    setData(response.data?.data);
    setInfo((prev) => ({
        ...prev,
        metadata: response.data?.metadata,
    }));
};

/**
 * Ez a függvény hozza backendről a kampányokat, és a átalakítja a select menübe
 *
 * @param fetchFunction - itt adjuk meg az endpoint-ot hívó függvényt
 * @param setLoading - töltő funkció
 * @param setCampaigns - kampányokat beállítja
 *
 * <D> -> maga a kampányok opciók tömbje
 */
export const fetchCampaigns = <D>(
    fetchFunction: (query: { ownerId?: number | string }) => Promise<AxiosResponse>,
    query: { ownerId?: number | string },
    setLoading: (value: boolean) => void,
    setCampaigns: (data: D[]) => void,
) => {
    setLoading(true);
    fetchFunction(query)
        .then((response: AxiosResponse) => {
            setCampaigns(response.data);
        })
        .finally(() => setLoading(false));
};

/**
 * @param setInfo
 * @param key
 * @param value
 */
export const setValueByKey = (
    setInfo: React.Dispatch<React.SetStateAction<IInfo<IForm>>>,
    key: string,
    value: unknown,
) => {
    setInfo((prev) => ({ ...prev, [key]: value }));
};

export const initialInfo = <T>(defaultSort?: {
    [K in keyof T]: 'asc' | 'desc';
}): IInfo<T> => ({
    filter: {},
    sort: defaultSort || {},
    page: 1,
    perpage: 25,
    metadata: {},
});

export const YES_NO_OPTIONS: Option[] = [
    { value: '1', label: 'Igen' },
    { value: '0', label: 'Nem' },
];

//TODO: ezt kivinni template-be:
export type ChecboxOptionType = '0' | '1';

export const makeOptionsFromHydra = <T extends { id: string | number; name: string }>(arr: {
    'hydra:member': T[];
}): Option[] =>
    arr['hydra:member'].map((elem) => ({
        value: '' + elem.id,
        label: elem.name,
    }));

export function downloadFile(data: BlobPart, filename: string, mime: string) {
    const blob = new Blob([data], { type: mime || 'application/octet-stream' });
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    const filenameTest = mime === 'text/csv' ? filename + '.csv' : filename;

    tempLink.setAttribute('download', filenameTest);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}

export const NO_REDIRECT_URL_KEYS: (keyof typeof clientEndpoints)[] = [
    'central_email_confirmation',
    'central_email_reset_password',
];

export enum UserTokenType {
    Activation = 1,
    ForgottenPassword = 2,
}

import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { makeStyles, useTheme } from '@mui/styles';
import BackgroundLogo from '../../images/MCClogo_negacolor.svg';
import Avatar from '@mui/material/Avatar';
import Logo from '../../images/image.svg';
import { Theme } from '@mui/material/styles';
import Notification from '../Notification';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${BackgroundLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.secondary.main,
        backgroundSize: '80%',
        backgroundPosition: 'center',
        boxShadow: '20px',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(2),
    },
}));

export default function OffLayout({ children }: { children: JSX.Element[] | JSX.Element }) {
    const classes = useStyles();
    const theme = useTheme() as Theme;

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                style={{ backgroundColor: theme.palette.secondary.main }}
                className={classes.image}
                component={Paper}
                elevation={20}
                square
            />
            <Grid item xs={12} sm={8} md={5}>
                <div className={classes.paper}>
                    <Avatar
                        className={classes.avatar}
                        src={Logo}
                        variant="square"
                        sx={{ height: '100%', width: '45%' }}
                    />
                    {children}
                </div>
            </Grid>
            <Notification />
        </Grid>
    );
}

import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Input } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import moment from 'moment';
import BtnCellRenderer from './BtnCellRenderer';
import ActionCellRenderer from '../../../components/ActionCellRenderer/index';
import { Dispatch, SetStateAction } from 'react';
import { GroupedOption } from '../../Unit/Users/types';
import { RoleOptions, UnitRoles } from './types';
import { AuthUser, ViewLayoutType } from '../../../store/auth';

export const title = (id: string | undefined) => {
    if (id) {
        return 'Felhasználó szerkesztése';
    } else {
        return 'Felhasználó hozzáadása';
    }
};

export const filterFields = (roleOptions: GroupedOption[], units?: Option[]): Input[] =>
    [
        { label: 'Vezetéknév', name: 'lastName', format: { xs: 12, md: 4, lg: 3 } },
        { label: 'Keresztnév', name: 'firstName', format: { xs: 12, md: 4, lg: 3 } },
        { label: 'E-mail cím', name: 'email', format: { xs: 12, md: 4, lg: 3 } },
        {
            label: 'Jogosultság',
            name: 'roles',
            type: 'multiselect',
            format: { xs: 12, md: 4, lg: 3 },
            options: roleOptions,
            props: { groupBy: (option: GroupedOption) => option.group },
        },
        ...(units
            ? [
                  {
                      label: 'Területi egység',
                      name: 'units',
                      type: 'multiselect',
                      format: { xs: 12, md: 4, lg: 3 },
                      options: units,
                  },
              ]
            : []),
        {
            type: 'datetime-local',
            label: 'Utolsó belépés (-tól)',
            name: 'lastLoginFrom',
            format: { xs: 12, md: 4, lg: 3 },
        },
        {
            type: 'datetime-local',
            label: 'Utolsó belépés (-ig)',
            name: 'lastLoginTo',
            format: { xs: 12, md: 4, lg: 3 },
        },
        {
            label: 'Létrehozó',
            name: 'author',
            format: { xs: 12, md: 4, lg: 3 },
        },
        {
            label: 'Módosító',
            name: 'editor',
            format: { xs: 12, md: 4, lg: 3 },
        },
        {
            label: 'Létrehozás ideje (-tól)',
            name: 'insertDateFrom',
            type: 'datetime-local',
            format: { xs: 12, md: 4, lg: 3 },
        },
        {
            label: 'Létrehozás ideje (-ig)',
            name: 'insertDateTo',
            type: 'datetime-local',
            format: { xs: 12, md: 4, lg: 3 },
        },
        {
            label: 'Módosítás ideje (-tól)',
            name: 'updateDateFrom',
            type: 'datetime-local',
            format: { xs: 12, md: 4, lg: 3 },
        },
        {
            label: 'Módosítás ideje (-ig)',
            name: 'updateDateTo',
            type: 'datetime-local',
            format: { xs: 12, md: 4, lg: 3 },
        },
    ] as Input[];

export const formFields = (roleOptions: Option[]): Input[] => [
    { label: 'Vezetéknév', name: 'lastName', format: { xs: 12, md: 6 } },
    { label: 'Keresztnév', name: 'firstName', format: { xs: 12, md: 6 } },
    { label: 'E-mail cím', name: 'email', format: { xs: 12, md: 6 } },
    {
        label: 'Jogosultság',
        name: 'roles',
        type: 'multiselect',
        options: roleOptions,
        format: { xs: 12, md: 6 },
    },
    {
        label: 'Aktivációs e-mail küldése',
        name: 'sendActivationEmail',
        type: 'checkbox',
        format: { xs: 12, md: 6 },
    },
];

export const tableFields = (
    matches: boolean,
    setRefresh: Dispatch<SetStateAction<boolean>>,
    type: ViewLayoutType = ViewLayoutType.Central,
) =>
    [
        { headerName: 'Vezetéknév', field: 'lastName', minWidth: 150 },
        { headerName: 'Keresztnév', field: 'firstName', minWidth: 150 },

        { headerName: 'E-mail cím', field: 'email', minWidth: 200 },
        {
            headerName: 'Utolsó belépés',
            field: 'lastLogin',
            minWidth: 160,
            flex: 0.5,
            align: 'center',
            valueGetter: (params: GridValueGetterParams) =>
                params.row?.lastLogin ? moment(params.row?.lastLogin).format('YYYY.MM.DD. HH:mm:ss') : '-',
        },
        {
            headerName: 'Létrehozó',
            field: 'author',
            minWidth: 160,
        },
        {
            headerName: 'Létrehozás ideje',
            field: 'insertDate',
            minWidth: 160,
        },
        {
            headerName: 'Módosító',
            field: 'editor',
            minWidth: 160,
        },
        {
            headerName: 'Módosítás ideje',
            field: 'updateDate',
            minWidth: 160,
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams) => (
                <ActionCellRenderer>
                    <BtnCellRenderer type={type} params={params} setRefresh={setRefresh} />
                </ActionCellRenderer>
            ),
            sortable: false,
            minWidth: matches ? 200 : 90,
        },
    ].map((e) => ({ headerAlign: 'center', flex: 1, ...e })) as GridColDef[];

/**
 * Átalakítja a server-ről kapott jogosultságokat a selecthez szükséges formátumba
 *
 * @param roleGroups
 * @returns
 */
export const transformUnitRoleOptions = (roleGroups: UnitRoles[]) => {
    const options: GroupedOption[] = [];

    roleGroups.forEach((roleGroup) => {
        roleGroup.value.forEach((role) => {
            options.push({ label: role.label, value: role.value, group: roleGroup.label });
        });
    });

    return options;
};

/**
 * Átalakítja a server-ről kapott területi egységhez jogosultságokat a selecthez szükséges formátumba
 *
 * @param layoutTypes
 * @returns
 */
export const transformRoleOptions = (layoutTypes: RoleOptions, user: AuthUser): GroupedOption[] => {
    const options: GroupedOption[] = [];

    layoutTypes.central.forEach((role) => {
        options.push({ label: role.label, value: role.value, group: 'Admin' });
    });

    if (user.roles?.central?.includes('ROLE_SUPER_ADMIN')) {
        const unitOptions = transformUnitRoleOptions(layoutTypes.unit);

        options.push(...unitOptions);
    }

    return options;
};

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { RootState } from '../../store';
import { setUser, setViewUnitCampaignManagement, ViewUnitCampaignManagementType } from '../../store/auth';
import { othersOwnCampaignViewCondition, userHasRole } from '../../utils/AccessManagementHelper';
import { clientEndpoints } from '../../utils/clientEndpoints';
import { endpoints } from '../../utils/endPoints';
import { UnitRole } from '../../utils/Roles';
import MCCLoading from '../MCCLoading';

export default function SelectUnitCampaignManagement(): JSX.Element {
    const [loading, setLoading] = useState<boolean>(true);
    const { user } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function handleCampaignManagementSelect(type: ViewUnitCampaignManagementType) {
        dispatch(setViewUnitCampaignManagement(type));
        navigate(clientEndpoints.home);
    }

    useEffect(() => {
        if (user?.selectedUnit === null) {
            api.get(endpoints.me)
                .then((res) => {
                    dispatch(setUser(res.data));
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [user, dispatch]);

    if (!user || loading) return <MCCLoading />;

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography textAlign="center" variant="h4">
                    Kérjük, válassz opciót a területi egységhez kapcsolódóan!
                </Typography>
            </Grid>
            {userHasRole(user, undefined, user?.selectedUnit, [
                UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN,
                UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS,
                UnitRole.ROLE_OTHER_OTHER_USERS_VIEW_OWN_CAMPAIGN_DATA,
            ]) && (
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        onClick={() => handleCampaignManagementSelect(ViewUnitCampaignManagementType.Common)}
                        sx={{
                            padding: '8px',
                            height: 56,
                        }}
                    >
                        Közös kampányadatok kezelése
                    </Button>
                </Grid>
            )}
            {userHasRole(user, UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN, user?.selectedUnit) && (
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        onClick={() => handleCampaignManagementSelect(ViewUnitCampaignManagementType.Own)}
                        sx={{
                            padding: '8px',
                            height: 56,
                        }}
                    >
                        Saját kampányadatok kezelése
                    </Button>
                </Grid>
            )}
            {othersOwnCampaignViewCondition(user) && (
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        onClick={() => navigate(clientEndpoints.select_owner_campaign_management)}
                        sx={{
                            padding: '8px',
                            height: 56,
                        }}
                    >
                        Más felhasználó saját kampányadatainak megtekintése
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => navigate(clientEndpoints.select_unit)}
                    sx={{
                        padding: '8px',
                        height: 56,
                    }}
                >
                    Vissza
                </Button>
            </Grid>
        </Grid>
    );
}

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export interface AlertDialogProps {
    openState: [boolean, (value: boolean) => void];
    handleSubmit: () => void;
    text: string;
    handleNo?: () => void;
}

export default function AlertDialog({ openState, handleSubmit, text, handleNo }: AlertDialogProps) {
    const [open, setOpen] = openState;

    const handleClose = () => {
        handleNo?.();
        setOpen(false);
    };

    const handleYes = () => {
        handleSubmit();
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Megerősítés</DialogTitle>
            <DialogContent style={{ paddingTop: '20px' }}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    Nem
                </Button>
                <Button onClick={handleYes} variant="contained">
                    Igen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

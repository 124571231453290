import Grid from '@mui/material/Grid';
import { Accordion, FieldGenerator } from '@silinfo/front-end-template';
import CustomDisabledCheckbox from './CustomDisabledCheckbox';
import { UserFormProps } from './types';

export default function CentralRoles(props: UserFormProps) {
    const centralRoles = props.roleOptions.central;

    return (
        <Grid item xs={12}>
            {centralRoles.length > 0 ? (
                <Accordion title="Globál" defaultExpanded>
                    <Grid container item xs={12} spacing={2}>
                        {centralRoles.map((role) => {
                            return props.rolesView ? (
                                <Grid item xs={12} key={`${role.value}-1`}>
                                    <CustomDisabledCheckbox role={role} {...props} />
                                </Grid>
                            ) : (
                                <Grid item xs={12} key={`${role.value}-2`}>
                                    {FieldGenerator({
                                        ...props,
                                        label: role.label,
                                        name: `roles.central.${role.value}`,
                                        type: 'checkbox',
                                        format: { xs: 12, md: 6 },
                                        props: {
                                            checked: props.values.roles.central?.[role.value] == '1',
                                            disabled: props.rolesView,
                                        },
                                    })}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Accordion>
            ) : (
                'Loading...'
            )}
        </Grid>
    );
}

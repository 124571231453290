import { Accordion, Page } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Form from '../../../../components/Form/Form';
import MCCLoading from '../../../../components/MCCLoading';
import addressListService from '../../../../services/Unit/addressListService';
import { RootState } from '../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';
import { PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { createFormFields, transformData } from './fields';
import { IAddressesField } from './types';
import { checkPageAccess, checkPageAccessByEntityOwner } from '../../../../utils/AccessManagementHelper';

export interface IAddressListForm extends Record<string, string> {
    email: string;
}

const initialForm: IAddressListForm = {
    email: '',
};

function AddressListForm(props: { ownOnly?: boolean }) {
    const { ownOnly } = props;
    const { id } = useParams();
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [form, setForm] = useState<IAddressListForm>(initialForm);
    const [addressGroups, setAddressGroups] = useState<Option[]>([]);
    const [messageFields, setMessageFields] = useState<IAddressesField[]>([]);
    const { user, view } = useSelector((state: RootState) => state.auth);
    const back = ownOnly ? clientEndpoints.unit_own_address_list : clientEndpoints.unit_address_list;
    const title = (id: string | undefined) => {
        if (id) {
            return 'Címzett szerkesztése';
        } else {
            return 'Címzett hozzáadása';
        }
    };
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Címlista',
        breadcrumbs: {
            addresses: 'Címek',
            [back]: 'Címlista',
            form: title(id),
        },
    };
    const checkEntityAccess = useCallback(
        (res: AxiosResponse) => checkPageAccessByEntityOwner(res, ownOnly || false),
        [ownOnly],
    );

    checkPageAccess(ownOnly || false, false);

    useEffect(() => {
        const ownerFilter = {
            ownerId: view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && ownOnly ? user?.id : 'null',
        };
        const requests = id
            ? [
                  addressListService
                      .get(id)
                      .then(checkEntityAccess)
                      .then((res: AxiosResponse) => setForm(res.data)),
                  addressListService
                      .getMessageFields(ownerFilter)
                      .then((res: AxiosResponse) => setMessageFields(transformData(res.data))),
                  addressListService
                      .getAddressGroups(ownerFilter)
                      .then((res: AxiosResponse) => setAddressGroups(res.data)),
              ]
            : [
                  addressListService
                      .getMessageFields(ownerFilter)
                      .then((res: AxiosResponse) => setMessageFields(transformData(res.data))),
                  addressListService
                      .getAddressGroups(ownerFilter)
                      .then((res: AxiosResponse) => setAddressGroups(res.data)),
              ];
        axios.all(requests).finally(() => setPageLoading(false));
    }, [id, ownOnly, user?.id, view.unitCampaignManagement, checkEntityAccess]);

    const handleSubmit = (form: IAddressListForm) =>
        id
            ? addressListService.update(id, {
                  ...form,
                  ...{
                      ownerId:
                          ownOnly && view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                              ? user?.id
                              : null,
                  },
              })
            : addressListService.create({
                  ...form,
                  ...{
                      ownerId:
                          ownOnly && view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                              ? user?.id
                              : null,
                  },
              });

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={title(id)} defaultExpanded disableClosing>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={form}
                    fields={createFormFields(messageFields, addressGroups)}
                    back={back}
                />
            </Accordion>
        </Page>
    );
}

export default AddressListForm;

import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Button, { ButtonProps } from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormikListPage, Link } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import messageTemplatesService from '../../../../services/Unit/messageTemplates';
import { RootState } from '../../../../store';
import { getOtherOwnerCondition, ViewUnitCampaignManagementType } from '../../../../store/auth';
import { PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { getOwnerId } from '../../../Address/MessageFields';
import { filterFields, tableColumns } from './fields';

interface AddButonData {
    to: string;
    variant: ButtonProps['variant'];
    text: string;
}

const simpleData: AddButonData = {
    to: 'new',
    variant: 'outlined',
    text: 'Egyszerű',
};

const complexData: AddButonData = {
    to: 'new-complex',
    variant: 'contained',
    text: 'Fejlett',
};

const AddButton = ({ simple }: { simple: boolean }) => {
    const data = simple ? simpleData : complexData;

    return (
        <Grid item>
            <Link to={data.to}>
                <Button startIcon={<AddIcon />} variant={data.variant} color="success">
                    {data.text} sablon hozzáadása
                </Button>
            </Link>
        </Grid>
    );
};

const Buttons = () => (
    <Grid item xs={12} justifyContent="flex-end" container spacing={2}>
        <AddButton simple />
        <AddButton simple={false} />
    </Grid>
);

export default function MessageTemplates(props: { ownOnly?: boolean }) {
    const { ownOnly } = props;
    const [refresh, setRefresh] = useState(false);
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Üzenetsablonok',
        breadcrumbs: {
            addresses: 'Sablonok',
            [clientEndpoints[`unit${ownOnly ? '_own' : ''}_message_templates`]]: 'Üzenetsablonok',
        },
    };
    const theme = useTheme();
    const { view, user, selectedOwner } = useSelector((state: RootState) => state.auth);
    const matches = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
    const [sortByInsert, setSortByInsert] = useState(false);
    const otherOwnerCondition = getOtherOwnerCondition();
    const canAdd =
        ([undefined, ViewUnitCampaignManagementType.Common].includes(view.unitCampaignManagement) || ownOnly) &&
        !otherOwnerCondition;
    const columns = useMemo(() => tableColumns(setRefresh, matches, !canAdd, setSortByInsert), [matches, canAdd]);
    const [info, setInfo] = useState<Record<string, unknown>>({});

    return (
        <Grid container spacing={2}>
            {view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && !ownOnly && !otherOwnerCondition && (
                <Grid item xs={12}>
                    <Alert severity="info">
                        Mivel saját kampány módban használod a rendszert, a közös üzenetsablonokat csak olvasási módban
                        tudod megtekinteni.
                    </Alert>
                </Grid>
            )}
            {otherOwnerCondition && selectedOwner && (
                <Grid item xs={12}>
                    <Alert severity="info">
                        {`Mivel ${selectedOwner.label} saját kampányadatait látod, ezért az üzenetsablonokat csak olvasási módban tudod megtekinteni.`}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <FormikListPage
                    service={{
                        filter: async (search: Record<string, unknown>) => {
                            const noChange = JSON.stringify(search) === JSON.stringify(info);
                            if (sortByInsert) {
                                Object.keys(search).forEach((key) => {
                                    if (key.match(/order\[.*\]/)) {
                                        delete search[key];
                                    }
                                });
                            }
                            const response = await messageTemplatesService.filter({
                                ...search,
                                ownerId: getOwnerId(view, ownOnly || false, user, selectedOwner),
                                ...(sortByInsert
                                    ? { 'order[insertDate]': 'desc', ...(noChange ? { page: 1 } : {}) }
                                    : {}),
                            });

                            if (info.page == search.page && !noChange) {
                                setSortByInsert(false);
                            }
                            setInfo(search);
                            return response;
                        },
                    }}
                    header={header}
                    autoload={true}
                    filter={{
                        fields: filterFields,
                        initialValues: {
                            flag: ['0'],
                        },
                    }}
                    refresh={refresh}
                    responseKey="data"
                    columns={columns}
                    tableProps={{
                        initialState: {
                            pinnedColumns: { right: ['operations'] },
                            sorting: {
                                sortModel: [
                                    {
                                        field: 'insertDate',
                                        sort: 'desc',
                                    },
                                ],
                            },
                        },
                    }}
                    defaultSort={{ insertDate: 'desc' }}
                    customButtons={canAdd ? <Buttons /> : <></>}
                />
            </Grid>
        </Grid>
    );
}

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { Field } from 'formik';
import { useCheckboxStyles } from '../../Central/Users/CustomDisabledCheckbox';
import { UnitUserFormProps } from './types';

export type CustomDisabledCheckboxProps = {
    role: Option<string, string>;
} & UnitUserFormProps;

export default function CustomDisabledCheckboxUnit({ role, ...props }: CustomDisabledCheckboxProps) {
    const classes = useCheckboxStyles();

    return (
        <Field as="div" name={`roles.${role.value}`}>
            {
                <Grid item container xs={12} direction="row" alignItems={'center'}>
                    <Checkbox
                        className={classes.checkbox}
                        inputProps={{
                            name: `roles.${role.value}`,
                        }}
                        checked={props.values.roles?.[role.value] == '1'}
                        disabled={props.rolesView}
                    />
                    <Typography>{role.label}</Typography>
                </Grid>
            }
        </Field>
    );
}

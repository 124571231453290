import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Loading } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../api';
import unitService from '../../services/unitService';
import { RootState } from '../../store';
import {
    setLayoutName,
    setUser,
    setViewUnit,
    setViewUnitCampaignManagement,
    ViewLayoutType,
    ViewUnitCampaignManagementType,
} from '../../store/auth';
import { create } from '../../store/notification';
import { getUserLayouts, userHasRole } from '../../utils/AccessManagementHelper';
import { clientEndpoints } from '../../utils/clientEndpoints';
import { endpoints } from '../../utils/endPoints';
import { CentralRole, UnitRole } from '../../utils/Roles';
import useFetch from '../../utils/useFetch';

//TODO: bal oldali logot OffLayout-ból fixálni, mert így le kell tekerni hozzá
export default function SelectUnit(): JSX.Element {
    //TODO: fix this, hogy küldje az auth header-t
    const { data, loading } = useFetch<Option<number>[]>(
        `${endpoints.central.unit.list}?order[serial]=asc`,
        'data',
        false,
        API,
    );
    const [selectLoading, setSelectLoading] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.auth);

    if (!user || loading || selectLoading > 0) {
        return <Loading noHeight />;
    }

    const userLayouts = getUserLayouts(user);
    const backButtonCondition =
        userLayouts.length > 1 || (userLayouts.length === 1 && userLayouts[0] !== ViewLayoutType.Unit);

    function handleSelectUnit(unit: Option<number>) {
        setSelectLoading((prev) => prev + 1);

        unitService
            .selectUnit({ unit: unit.value })
            .then(() => {
                setSelectLoading((prev) => prev + 1);
                API.get(endpoints.me)
                    .then((res: AxiosResponse) => {
                        dispatch(setUser(res.data));
                    })
                    .then(() => {
                        setSelectLoading((prev) => prev - 1);
                        dispatch(setViewUnit(unit.value));
                        dispatch(setLayoutName(unit.label));

                        const centralRoles = user?.roles?.central;
                        const unitRoles = user?.roles?.unit?.[unit.value];

                        if (
                            !centralRoles?.includes(CentralRole.ROLE_SUPER_ADMIN) &&
                            ((unitRoles?.length === 1 && unitRoles?.[0] === UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN) ||
                                (unitRoles?.length === 2 &&
                                    unitRoles?.includes(UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN) &&
                                    unitRoles?.includes(UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS)))
                        ) {
                            dispatch(setViewUnitCampaignManagement(ViewUnitCampaignManagementType.Own));
                            navigate(clientEndpoints.home);
                        } else if (
                            centralRoles?.includes(CentralRole.ROLE_SUPER_ADMIN) ||
                            unitRoles?.includes(UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN) ||
                            userHasRole(user, UnitRole.ROLE_OTHER_OTHER_USERS_VIEW_OWN_CAMPAIGN_DATA, unit.value)
                        ) {
                            navigate(clientEndpoints.select_unit_campaign_management);
                        } else {
                            navigate(clientEndpoints.home);
                        }
                    });
            })
            .catch((error: AxiosError<{ message: string; detail: string }, string>) => {
                dispatch(
                    create({
                        type: 'error',
                        message: error.response?.data?.detail || 'Hiba a területi egység kiválasztása során!',
                    }),
                );
            })
            .finally(() => {
                setSelectLoading((prev) => prev - 1);
            });
    }

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography textAlign="center" variant="h4">
                    Kérjük, válassz területi egységet
                </Typography>
            </Grid>
            {data.length > 0 ? (
                data.map((unit: Option<number>) => (
                    <Grid item xs={12} key={unit.value}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            fullWidth
                            onClick={() => handleSelectUnit(unit)}
                            sx={{
                                padding: '8px',
                                height: 56,
                            }}
                        >
                            {unit.label}
                        </Button>
                    </Grid>
                ))
            ) : (
                <Grid item xs={12}>
                    <Typography textAlign="center">Nincs választható területi egység az adatbázisban</Typography>
                </Grid>
            )}
            {backButtonCondition && (
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={() => navigate(clientEndpoints.switch_layout)}
                        sx={{
                            padding: '8px',
                            height: 56,
                        }}
                    >
                        Vissza
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}

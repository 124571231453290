import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import Tooltip from '@mui/material/Tooltip';

export default function ViewButtonRenderer(params: GridRenderCellParams) {
    return (
        <Tooltip title="Űrlap megtekintése">
            <IconButton
                onClick={() => {
                    window.open(clientEndpoints.form_viewer.replace(':id', '' + params.id), '_blank');
                }}
                color="primary"
            >
                <Visibility />
            </IconButton>
        </Tooltip>
    );
}

import MessageTemplatesPage from '../../../pages/Unit/Templates/MessageTemplates';
import MessageTemplatesBaseForm from '../../../pages/Unit/Templates/MessageTemplates/BaseFormPage';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const MessageTemplates = {
    path: clientEndpoints.unit_message_templates,
    children: [
        {
            path: '',
            element: <MessageTemplatesPage />,
            name: 'unit_message_templates',
        },
        {
            path: 'new',
            element: <MessageTemplatesBaseForm />,
            name: 'unit_message_templates_new',
        },
        {
            path: 'new-complex',
            element: <MessageTemplatesBaseForm complex />,
            name: 'unit_message_templates_new_complex',
        },
        {
            path: ':id/edit',
            element: <MessageTemplatesBaseForm />,
            name: 'unit_message_templates_edit',
        },
        {
            path: ':id/show',
            element: <MessageTemplatesBaseForm readonly={true} />,
            name: 'unit_message_templates_show',
        },
    ],
};

export default MessageTemplates;

import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import api from '../../api';

const emailLogService = {
    ...crudTemplate(endpoints.unit.administration.emailLog.main),
    campaigns: () => api.get(endpoints.unit.administration.emailLog.campaigns),
    loggerText: (id: number, isCentral: boolean) =>
        api.get(
            isCentral
                ? endpoints.central.emailLogs.loggerText.replace('{id}', id.toString())
                : endpoints.unit.administration.emailLog.loggerText.replace('{id}', id.toString()),
        ),
};

export default emailLogService;

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { GridSelectionModel } from '@mui/x-data-grid';
import { Accordion, IViolations } from '@silinfo/front-end-template';
import axios, { AxiosError } from 'axios';
import { Form as FormikForm, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactRouterPrompt from 'react-router-prompt';
import MCCLoading from '../../../../components/MCCLoading';
import campaignsService from '../../../../services/campaigns/campaigns';
import { RootState, store } from '../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';
import AccordionHeader from './AccordionHeader';
import AlertDialog from './AlertDialog';
import AutomaticSave from './AutomaticSave';
import BasicDataForm from './BasicDataForm';
import ListPageAccordion from './ListPageAccordion';
import SendDataForm from './SendDataForm';
import { NewCampaignFormType } from './types';
import { basicDataFormKeys, leavingPageMessage, preventEnter, sendDataFormKeys } from './utils';
import ExpiredSessionDialog from './ExpiredSessionDialog';

const Form = ({
    formLoading,
    setIsIntentionalLeave,
    isEdit,
    setFormLoading,
    isIntentionalLeave,
}: {
    formLoading: boolean;
    setFormLoading: (val: boolean) => void;
    isEdit: boolean;
    setIsIntentionalLeave: (val: boolean) => void;
    isIntentionalLeave: boolean;
}) => {
    const isOwn = store.getState().auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;
    const { user, view } = useSelector((state: RootState) => state.auth);
    const ownerId = view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ? user?.id : null;
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState<string | false>('panel1');
    const fProps = useFormikContext<NewCampaignFormType>();
    const { setFieldValue, values } = fProps;
    const [automaticSaveLoading, setAutomaticSaveLoading] = useState(false);
    const [selected, setSelected] = useState<GridSelectionModel>([]);
    const sessionId = values.session;
    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {
        const requests = [];
        if (!isEdit) {
            requests.push(
                campaignsService.saveAutomatic({ isOwn: isOwn }).then((res) => {
                    setFieldValue('id', res.data.id);
                    setFieldValue('session', res.data.session);
                }),
            );
        }

        setLoading(true);
        axios.all(requests).finally(() => setLoading(false));
    }, [isEdit, setFieldValue, isOwn]);
    const handleChange = useCallback(
        (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        },
        [],
    );

    const handleAutomaticSaveClick = useCallback(
        (unlocked?: boolean, automatic?: boolean, manual?: boolean) => {
            setAutomaticSaveLoading(true);
            return campaignsService
                .saveAutomatic({
                    ...fProps.values,
                    unlock: unlocked ? 1 : 0,
                    automatic: !!automatic,
                    manual: !!manual,
                    isOwn,
                    ownerId,
                })
                .then((res) => {
                    setFieldValue('session', res.data.session);
                })
                .catch((error: AxiosError<{ violations: IViolations[] }>) => {
                    if (error.response?.status === 422) {
                        setIsIntentionalLeave(true);
                        setIsExpired(true);
                    }
                })
                .finally(() => setAutomaticSaveLoading(false));
        },
        [fProps.values, isOwn, ownerId, setIsIntentionalLeave, setFieldValue],
    );
    const hasError = useCallback(
        (array: string[]) => array.some((elem) => Object.keys(fProps.errors).includes(elem)),
        [fProps.errors],
    );

    const handlePageLeave = (onConfirm: () => void) => {
        handleAutomaticSaveClick(true);
        onConfirm();
    };

    if (loading || !sessionId) return <MCCLoading />;
    return (
        <FormikForm onKeyDown={preventEnter}>
            <ReactRouterPrompt when={!isIntentionalLeave}>
                {({ isActive, onConfirm, onCancel }) =>
                    isActive ? (
                        <AlertDialog
                            openState={[isActive, onCancel]}
                            handleSubmit={() => handlePageLeave(onConfirm as () => void)}
                            text={leavingPageMessage}
                        />
                    ) : null
                }
            </ReactRouterPrompt>
            {isExpired ? <ExpiredSessionDialog /> : null}
            <Grid container spacing={2}>
                <AutomaticSave
                    handleAutomaticSaveClick={handleAutomaticSaveClick}
                    automaticSaveLoading={automaticSaveLoading}
                    formLoading={formLoading}
                />
                <Grid item xs={12}>
                    <Accordion
                        disableGutters
                        defaultExpanded
                        title={<AccordionHeader title="Alapadatok megadása" error={hasError(basicDataFormKeys)} />}
                        expanded={expanded === 'panel1' || hasError(basicDataFormKeys)}
                        onChange={handleChange('panel1')}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <BasicDataForm {...fProps} />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    onClick={() => {
                                        setExpanded('panel2');
                                    }}
                                >
                                    Tovább
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Accordion>
                </Grid>
                <ListPageAccordion
                    {...{ props: fProps, expanded, handleChange, setExpanded, selected, setSelected, sessionId }}
                />
                <Grid item xs={12}>
                    <Accordion
                        disableGutters
                        title={
                            <AccordionHeader
                                title="Küldéshez kapcsolódó adatok megadása"
                                error={hasError(sendDataFormKeys)}
                            />
                        }
                        expanded={expanded === 'panel3' || hasError(sendDataFormKeys)}
                        onChange={handleChange('panel3')}
                        sx={{ mt: 2 }}
                    >
                        <SendDataForm
                            {...fProps}
                            setIsIntentionalLeave={setIsIntentionalLeave}
                            loading={automaticSaveLoading || formLoading}
                            handleAutomaticSaveClick={handleAutomaticSaveClick}
                            setFormLoading={setFormLoading}
                        />
                    </Accordion>
                </Grid>
            </Grid>
        </FormikForm>
    );
};

export default Form;

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableButton } from '@silinfo/front-end-template';
import * as React from 'react';
import { IConfirmationDialog } from './ConfirmationDialog';

export function TableVisibleBtn(
    params: GridRenderCellParams & {
        setState: React.Dispatch<React.SetStateAction<IConfirmationDialog>>;
        refresh: () => void;
    },
) {
    const { setState, refresh } = params;

    return (
        <Grid item>
            {params.row.visible ? (
                <TableButton
                    color="warning"
                    onClick={() => {
                        setState({
                            message: 'Valóban rejtetté akarod állítani az üzenetmezőt?',
                            params,
                            refresh,
                        });
                    }}
                >
                    <VisibilityOffIcon />
                </TableButton>
            ) : (
                <TableButton
                    color="warning"
                    onClick={() => {
                        setState({
                            message: 'Valóban láthatóvá akarod állítani az üzenetmezőt?',
                            params,
                            refresh,
                        });
                    }}
                >
                    <VisibilityIcon />
                </TableButton>
            )}
        </Grid>
    );
}

import { AxiosResponse } from 'axios';
import API from '../api';
import downloadAPI from '../api/downloadAPI';
import { fixDateRangeFields, IForm } from '../utils/AppConst';

export interface ICrudTemplate<T> {
    filter: (form: IForm) => Promise<AxiosResponse>;
    get: (id: string | number) => Promise<AxiosResponse>;
    create: (form: T) => Promise<AxiosResponse>;
    update: (id: string | number, form: T) => Promise<AxiosResponse>;
    delete: (id: string | number) => Promise<AxiosResponse>;
    replace: (id: string | number, form: T) => Promise<AxiosResponse>;
}

export const crudTemplate = <T>(url: string): ICrudTemplate<T> => ({
    filter: (form) => API.get(url, { params: form }),
    get: (id) => API.get(url + '/' + id),
    create: (form) => API.post(url, form),
    update: (id, form) => API.post(url + '/' + id, form), //TODO: ez eddig PATCH volt, most post
    replace: (id, form) => API.put(url + '/' + id, form),
    delete: (id) => API.delete(url + '/' + id),
});

export interface IExportTemplate {
    initiateExport: (form: IForm, filter: IForm) => Promise<AxiosResponse>;
    checkExport: (exportId: string | number) => Promise<AxiosResponse>;
    downloadExport: (exportId: string | number) => Promise<AxiosResponse>;
}

export const exportTemplate = (url: string): IExportTemplate => ({
    initiateExport: (form, filter) =>
        API.post(`${url}/initiate-export`, { ...form }, { params: fixDateRangeFields(filter) }),
    checkExport: (exportId) => API.get(`${url}/check-export/${exportId.toString()}`),
    downloadExport: (exportId) => downloadAPI.get(`${url}/download-export/${exportId.toString()}`),
});

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Accordion, Input, Loading, MultiselectField, TextField } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useFormikContext } from 'formik';
import { Children, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import addressListService from '../../../../services/Unit/addressListService';
import { RootState } from '../../../../store';
import { AuthUser, ViewUnitCampaignManagementType } from '../../../../store/auth';
import { userHasUnitRoleByIdAndRole, userIsSuperAdmin } from '../../../../utils/AccessManagementHelper';
import { IForm, findLabelByValue } from '../../../../utils/AppConst';
import ApiAutocomplete from './ApiAutocomplete';
import OptionRenderer from './BasicDataForm/OptionRenderer';
import { NewCampaignFormType } from './types';
import { formKey, initInfo, paginatorInfoBuild } from './utils';
import { getOwnerId } from '../../../Address/MessageFields';
import { setAddressGroupOptions } from '../../../../store/Unit/addressList';

const NormalFieldGenerator = ({
    field,
    handleChange,
}: {
    field: Input;
    handleChange: (name: string, value: unknown) => void;
}) =>
    field.type === 'multiselect' ? (
        <MultiselectField
            options={field.options}
            label={field.label}
            onChange={(_, values) =>
                handleChange(
                    field.name,
                    values.map((v) => v?.value || v),
                )
            }
            filterSelectedOptions
            isOptionEqualToValue={(option, value) =>
                option.value === value.value || option.value === value || option === value
            }
            getOptionLabel={(option) => findLabelByValue(option, field.options)}
        />
    ) : (
        <TextField label={field.label} name={field.name} onBlur={(e) => handleChange(field.name, e.target.value)} />
    );

export default function AddressFilter(props: {
    service: {
        filter: (form: IForm) => Promise<AxiosResponse>;
    };
    setValueByKey: (key: string, value: unknown) => void;
}) {
    const { view, user, selectedOwner } = useSelector((state: RootState) => state.auth);
    const { filterFields } = useSelector((state: RootState) => state.addressList);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const isOwn = view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;
    const { service, setValueByKey } = props;
    const formikProps = useFormikContext<NewCampaignFormType>();
    const { setFieldValue } = formikProps;
    const [filterForm, setFilterForm] = useState<Record<string, unknown>>({
        ...(formikProps.values[formKey] || {}),
        showAddressType: [isOwn ? 'onlyOwn' : 'onlyCommon'],
        isOwn: isOwn ? 'true' : 'false',
    });
    const addressGroupFilter = useMemo(
        () => ({
            ownerId: getOwnerId(view, isOwn || false, user, selectedOwner),
        }),
        [view, isOwn, user, selectedOwner],
    );
    const handleSubmit = useCallback(
        (form: Record<string, unknown>) => {
            setFieldValue(formKey, form);
            setValueByKey(formKey, form);
            service.filter({ ...form, ...paginatorInfoBuild(initInfo) });
        },
        [setFieldValue, service, setValueByKey],
    );

    const handleChange = useCallback((name: string, value: unknown) => {
        setFilterForm((prev) => ({ ...prev, [name]: value }));
    }, []);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter') {
                handleSubmit(filterForm);
            }
        },
        [filterForm, handleSubmit],
    );

    const showAddressType = [
        { value: 'onlyOwn', label: 'Csak saját címlistában szereplők' },
        { value: 'onlyCommon', label: 'Csak közös címlistában szereplők' },
        { value: 'ownAndCommon', label: 'Saját és közös címlistában is szereplők' },
    ];

    useEffect(() => {
        addressListService
            .getAddressGroups(addressGroupFilter)
            .then((res: AxiosResponse) => dispatch(setAddressGroupOptions(res.data)))
            .finally(() => setLoading(false));
    }, [dispatch, addressGroupFilter]);

    if (loading) return <Loading noHeight />;

    return (
        <Accordion title="Szűrő">
            <Grid item container spacing={2} onKeyDown={handleKeyDown}>
                <Grid item container xs={12} spacing={1}>
                    {isOwn &&
                        (userHasUnitRoleByIdAndRole(
                            user?.selectedUnit || -1,
                            'ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS',
                            user,
                        ) ||
                            userIsSuperAdmin(user as AuthUser)) && (
                            <Grid item xs={12} md={6} lg={3}>
                                <MultiselectField
                                    options={showAddressType}
                                    label={'Megjelenített címzettek'}
                                    value={(filterForm['showAddressType'] as string[]) || []}
                                    onChange={(_, values) => {
                                        handleChange(
                                            'showAddressType',
                                            values.map((v) => v?.value || v),
                                        );
                                        setFieldValue(
                                            'filter.showAddressType',
                                            values.map((v) => v?.value || v),
                                        );
                                    }}
                                    filterSelectedOptions
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value || option.value === value || option === value
                                    }
                                    getOptionLabel={(option) => findLabelByValue(option, showAddressType)}
                                />
                            </Grid>
                        )}
                    {Children.toArray(
                        filterFields.map((field) => {
                            return (
                                <Grid item {...field.format}>
                                    {field.type === 'backendSelect' ? (
                                        <ApiAutocomplete
                                            label={field.label}
                                            name={field.name}
                                            onChange={(value) => handleChange(field.name, value)}
                                            url={
                                                field.url +
                                                '?' +
                                                (
                                                    (filterForm.showAddressType as string[]) || [
                                                        isOwn ? 'onlyOwn' : 'onlyCommon',
                                                    ]
                                                )
                                                    .map((query) => 'showAddressType[]=' + query)
                                                    .join('&') +
                                                '&isOwn=' +
                                                (isOwn ? 'true' : 'false')
                                            }
                                            renderOption={
                                                (filterForm.showAddressType as string[]).includes('ownAndCommon')
                                                    ? OptionRenderer
                                                    : undefined
                                            }
                                            multiple
                                        />
                                    ) : (
                                        <NormalFieldGenerator field={field} handleChange={handleChange} />
                                    )}
                                </Grid>
                            );
                        }),
                    )}
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color={'info'}
                            onClick={() => {
                                handleSubmit(filterForm);
                            }}
                            fullWidth
                        >
                            Szűrés
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color={'info'}
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                                setFilterForm({
                                    showAddressType: [isOwn ? 'onlyOwn' : 'onlyCommon'],
                                    isOwn: isOwn ? 'true' : 'false',
                                });
                                handleSubmit({
                                    showAddressType: [isOwn ? 'onlyOwn' : 'onlyCommon'],
                                    isOwn: isOwn ? 'true' : 'false',
                                });
                            }}
                        >
                            Alapértelmezett
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Accordion>
    );
}

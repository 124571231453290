import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useRef, useState } from 'react';
import usersService, { PasswordReminderForm } from '../../services/Central/userService';
import { transformApiErrors } from '../../utils/AppConst';
import { HttpResponseStatusCode } from '../../utils/HttpResponseStatusCode';

export default function ForgottenPasswordDialog(props: { open: boolean; onClose: () => void }): JSX.Element {
    const { open, onClose } = props;
    const formRef = useRef<FormikProps<PasswordReminderForm>>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessSuccessful, setIsProcessSuccessful] = useState<boolean>(false);
    const sendPasswordReminder = (form: PasswordReminderForm, { setErrors }: FormikHelpers<PasswordReminderForm>) => {
        setIsLoading(true);
        usersService
            .sendPasswordReminder(form)
            .then(() => setIsProcessSuccessful(true))
            .catch((error) => {
                if (error?.response?.status === HttpResponseStatusCode.UNPROCESSABLE_ENTITY) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                }
            })
            .finally(() => setIsLoading(false));
    };
    const onDialogClose = () => {
        setIsProcessSuccessful(false);
        setIsLoading(false);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Elfelejtett jelszó</DialogTitle>
            <DialogContent style={{ paddingTop: '24px' }}>
                {!isProcessSuccessful && (
                    <Formik initialValues={{ email: '' }} onSubmit={sendPasswordReminder} innerRef={formRef}>
                        {({ errors, touched }) => (
                            <Form style={{ width: '100%' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Kérjük, add meg az e-mail címedet!</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field as="div" field="email">
                                            <TextField
                                                required
                                                name="email"
                                                label="E-mail cím"
                                                margin="normal"
                                                error={!!(errors.email && touched.email)}
                                                helperText={touched.email && errors.email}
                                                fullWidth
                                                autoFocus
                                            />
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                )}
                {isProcessSuccessful && (
                    <Typography variant="h6">
                        Sikeres mentés! Amennyiben helyes e-mail címet adtál meg, kérjük, ellenőrizd a postafiókodat,
                        ahová további információkat küldtünk!
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                {!isProcessSuccessful && (
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            if (formRef.current) {
                                formRef.current.handleSubmit();
                            }
                        }}
                        loading={isLoading}
                    >
                        Mehet
                    </LoadingButton>
                )}
                <LoadingButton color="primary" variant="outlined" onClick={() => onDialogClose()}>
                    Vissza
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

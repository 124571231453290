import MainPage from './MainPage';
import Addresses from './Unit/Addresses/AddressList';
import AddressGroups from './Unit/Addresses/AddressGroups';
import OwnAddressGroups from './Unit/Addresses/OwnAddressGroups';
import CampaignList from './Unit/Campaigns/CampaignList';
import NewCampaign from './Unit/Campaigns/NewCampaign';
import MessageFields from './Unit/Addresses/MessageFields';
import MessageTemplates from './Unit/Template/MessageTemplates';
import EmailLog from './Unit/Administration/Emaillog';
import Emails from './Unit/Administration/Emails';
import Settings from './Unit/Administration/Settings';
import StatisticsAggregate from './Unit/Statistics/StatisticsAggregate';
import StatisticsCampaign from './Unit/Statistics/StatisticsCampaign';
import StatisticsGrouped from './Unit/Statistics/StatisticsGrouped';
import StatisticsOther from './Unit/Statistics/StatisticsOther';
import UnitUsers from '../routing/Unit/Administration/Users';
import Forms from './Unit/Template/Forms';
import OwnMessageFields from './Unit/Addresses/OwnMessageFields';
import OwnAddressList from './Unit/Addresses/OwnAddressList';
import OwnForms from './Unit/Template/OwnForms';
import OwnMessageTemplates from './Unit/Template/OwnMessageTemplates';
import OwnEmails from './Unit/Administration/OwnEmails';
import ExportList from './Unit/ExportList';

// Területi egység menüpontok:
export const unitMenus = [
    {
        path: '',
        element: <MainPage type="unit" />,
    },
    Addresses,
    OwnAddressList,
    AddressGroups,
    OwnAddressGroups,
    CampaignList,
    NewCampaign,
    MessageFields,
    OwnMessageFields,
    MessageTemplates,
    OwnMessageTemplates,
    EmailLog,
    Emails,
    Settings,
    StatisticsAggregate,
    StatisticsCampaign,
    StatisticsGrouped,
    StatisticsOther,
    UnitUsers,
    Forms,
    OwnForms,
    OwnEmails,
    ExportList,
];

import Grid from '@mui/material/Grid';
import { Filter, MultiselectField, Page } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../../../components/MCCLoading';
import statisticsAggregateService from '../../../../services/Unit/statistics/statisticsAggregateService';
import statisticsCampaignService from '../../../../services/Unit/statistics/statisticsCampaignService';
import { RootState } from '../../../../store';
import { create } from '../../../../store/notification';
import { findLabelByValue, PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { getOwnerIdStatistics } from '../Aggregate';
import useCampaignOptions from '../hooks/useCampaignOptions';
import CampaignStatisticsTable from './CampaignStatisticsTable';
import { emailsOpenedTable, emailsSentTable, formsFilledTable } from './fields';
import {
    CAMPAIGN_STATISTICS_TYPES,
    EmailsOpenedType,
    EmailsSentType,
    FormsFilledType,
    initialStatisticalCampaignFilterState,
    StatisticsCampaignFilter,
    statisticsTypes,
} from './types';

export default function StatisticsCampaign() {
    const { view, user, selectedOwner } = useSelector((state: RootState) => state.auth);
    const { loading: campaignOptionsLoading, campaignOptions } = useCampaignOptions(
        statisticsAggregateService,
        getOwnerIdStatistics(view, user, selectedOwner),
    );
    const [filter, setFilter] = useState<StatisticsCampaignFilter>(initialStatisticalCampaignFilterState);
    const [pageLoading, setPageLoading] = useState(false);
    const dispatch = useDispatch();
    const [emailsOpenedTableOpen, setEmailsOpenedTableOpen] = useState<boolean>(false);
    const [emailsSentTableOpen, setEmailsSentTableOpen] = useState<boolean>(false);
    const [formsFilledTableOpen, setFormsFilledTableOpen] = useState<boolean>(false);
    const [emailsOpenedData, setEmailsOpenedData] = useState<EmailsOpenedType[]>([]);
    const [emailsSentData, setEmailsSentData] = useState<EmailsSentType[]>([]);
    const [formsFilledData, setFormsFilledData] = useState<FormsFilledType[]>([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const { campaignId } = useParams();

    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Kampánystatisztika',
        breadcrumbs: {
            statistics: 'Statisztikák',
            [clientEndpoints.unit_statistics_campaign]: 'Kampánystatisztika',
        },
    };

    const submitFunction = useCallback((filter: StatisticsCampaignFilter) => {
        setPageLoading(true);
        axios
            .all([
                statisticsCampaignService.emailsOpened(filter)?.then((response: AxiosResponse) => {
                    setEmailsOpenedData(response.data.data);
                    setEmailsOpenedTableOpen(true);
                }),
                statisticsCampaignService.emailsSent(filter)?.then((response: AxiosResponse) => {
                    setEmailsSentData(response.data.data);
                    setEmailsSentTableOpen(true);
                }),
                statisticsCampaignService.formsFilled(filter)?.then((response: AxiosResponse) => {
                    setFormsFilledData(response.data.data);
                    setFormsFilledTableOpen(true);
                }),
            ])
            .finally(() => setPageLoading(false));
    }, []);

    const handleSubmit = () => {
        if (filter.campaigns.length === 0) {
            dispatch(create({ type: 'error', message: 'Válassz ki legalább egy kampányt!' }));
            return;
        }
        submitFunction(filter);
    };

    const resetForm = () => {
        setFilter(initialStatisticalCampaignFilterState);
        setEmailsOpenedTableOpen(false);
        setEmailsSentTableOpen(false);
        setFormsFilledTableOpen(false);
    };

    const filterParams = {
        form: filter,
        setForm: () => {
            resetForm();
        },
        onSubmit: handleSubmit,
    };

    const handleMultiChange = (fieldName: string) => {
        //name prop is not used in any way in multiselect
        //TODO: This is not too generic. Should rebuild MultiselectField to handle name prop and expose onChange properly
        return (_: unknown, v: { value: string }[]) => {
            setFilter((prevState) => ({
                ...prevState,
                [fieldName]: v?.map((obj) => obj.value || obj),
            }));
        };
    };

    /**
     * Ez a kód felelős, ha a kampánylistáról szeretnénk egy adott kampány statiszitkáira navigálni.
     */
    useEffect(() => {
        if (campaignId && firstLoad) {
            const filterTmp: StatisticsCampaignFilter = {
                campaigns: [Number(campaignId)],
                statisticsType: CAMPAIGN_STATISTICS_TYPES,
            };
            setFilter(filterTmp);

            submitFunction(filterTmp);
            setFirstLoad(false);
        }
    }, [campaignId, firstLoad, submitFunction]);

    /**
     * TODO: lehetne olyan, hogy nem az egész oldal tölt, csak a táblázatok és mondjuk a szűrés gomb
     */
    if (campaignOptionsLoading || pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Filter {...filterParams}>
                <Grid item container spacing={2} xs={12} wrap={'wrap'} style={{ marginTop: 3 }}>
                    <Grid item xs={12} md={6}>
                        <MultiselectField
                            onChange={handleMultiChange('campaigns')}
                            label="Kampány"
                            value={filter['campaigns'] || []}
                            options={campaignOptions}
                            getOptionLabel={(option) => option.label || findLabelByValue(option, campaignOptions)}
                            isOptionEqualToValue={(option, value) =>
                                option.value === value.value || value === option.value
                            }
                            filterSelectedOptions
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultiselectField
                            onChange={handleMultiChange('statisticsType')}
                            label="Statisztika"
                            value={filter['statisticsType'] || []}
                            options={statisticsTypes}
                            getOptionLabel={(option) => option.label || findLabelByValue(option, statisticsTypes)}
                            isOptionEqualToValue={(option, value) =>
                                option.value === value.value || value === option.value
                            }
                            filterSelectedOptions
                        />
                    </Grid>
                </Grid>
            </Filter>
            {emailsOpenedTableOpen && <CampaignStatisticsTable {...emailsOpenedTable(emailsOpenedData, filter)} />}
            {emailsSentTableOpen && <CampaignStatisticsTable {...emailsSentTable(emailsSentData, filter)} />}
            {formsFilledTableOpen && <CampaignStatisticsTable {...formsFilledTable(formsFilledData, filter)} />}
        </Page>
    );
}

import API from '../../api/';
import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';

function incrementSerial(id: number) {
    return API.post(endpoints.address.messageFields.incrementSerial.replace('{id}', id.toString()), {})
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function decrementSerial(id: number) {
    return API.post(endpoints.address.messageFields.decrementSerial.replace('{id}', id.toString()), {})
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function changeVisible(id: number, form: { visible: string }) {
    return API.post(endpoints.address.messageFields.changeVisible.replace('{id}', id.toString()), form)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

const messageFieldsService = {
    ...crudTemplate(endpoints.address.messageFields.main),
    decrementSerial,
    incrementSerial,
    changeVisible,
};

export default messageFieldsService;

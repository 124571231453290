import Grid from '@mui/material/Grid';
import { Tooltip } from '@silinfo/front-end-template';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { changeMultipleTaskDialog, setMultipleTaskDialogType } from '../../../../store/Unit/addressList';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import { Dispatch, SetStateAction, memo } from 'react';

function AddressListToolbar(props: { selected: (number | string)[]; setOpen: Dispatch<SetStateAction<boolean>> }) {
    const { selected, setOpen } = props;
    const dispatch = useDispatch();

    return (
        <Grid item container xs={12} justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>{selected.length} cím kiválasztva</Grid>
            <Grid item>
                <Tooltip title="Csoportos törlés">
                    <IconButton
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        <DeleteIcon color="error" />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Hozzáadás csoporthoz">
                    <IconButton
                        onClick={() => {
                            dispatch(changeMultipleTaskDialog());
                            dispatch(setMultipleTaskDialogType('ADD_GROUP'));
                        }}
                    >
                        <GroupAddIcon color="success" />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Törlés csoportból">
                    <IconButton
                        onClick={() => {
                            dispatch(changeMultipleTaskDialog());
                            dispatch(setMultipleTaskDialogType('DELETE_GROUP'));
                        }}
                    >
                        <GroupRemoveIcon color="error" />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
}

export default memo(AddressListToolbar);

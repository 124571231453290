import Grid from '@mui/material/Grid';
import { Accordion, CancelButton, Loading, Page } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MCCLoading from '../../../components/MCCLoading';
import systemLogService from '../../../services/Central/systemLogService';
import { PROJECT_NAME } from '../../../utils/AppConst';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import { ISystemLog, RouteParams } from './types';
import { initialEmailLog } from './utils';
import SystemLogTable from './Table';

export default function Show() {
    const [loading, setLoading] = useState<boolean>(false);
    const [systemLog, setSystemLog] = useState<ISystemLog>(initialEmailLog);
    const { id } = useParams<RouteParams>() as { id: string };
    const navigate = useNavigate();

    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Rendszernapló',
        breadcrumbs: {
            admin: 'Adminisztráció',
            [clientEndpoints.central_system_logs]: 'Rendszernapló',
            central_system_logs_view: 'Rendszernapló megtekintése',
        },
    };

    useEffect(() => {
        setLoading(true);

        systemLogService
            .get(+id)
            .then((res) => setSystemLog(res.data))
            .finally(() => setLoading(false));
    }, [id]);

    if (loading) {
        return <MCCLoading />;
    }

    const NoLoading = !systemLog ? 'Nem található adat.' : <SystemLogTable id={id} systemLog={systemLog} />;

    return (
        <Page header={header}>
            <Accordion title="Rendszernapló megjelenítés">
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ overflow: 'auto' }}>
                        {loading ? <Loading noHeight /> : NoLoading}
                    </Grid>
                    <Grid item xs={12}>
                        <CancelButton
                            onClick={() => {
                                navigate(clientEndpoints.central_system_logs);
                            }}
                        >
                            Vissza
                        </CancelButton>
                    </Grid>
                </Grid>
            </Accordion>
        </Page>
    );
}

import Chip from '@mui/material/Chip';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    importProgressStatus: {
        backgroundColor: blue[500],
        color: '#fff',
    },
}));

export default function ImportStatusCellRenderer(props: GridRenderCellParams<string>): JSX.Element {
    const classes = useStyles();
    const getStatus = (): {
        color: 'error' | 'default' | 'success' | 'secondary' | undefined;
        label: string;
        className: string | undefined;
    } => {
        switch (props.value) {
            case 'error_unknown':
            case 'error_validation':
                return {
                    color: 'error',
                    label: 'sikertelen',
                    className: undefined,
                };

            case 'init':
                return {
                    color: 'default',
                    label: 'inicializálás',
                    className: undefined,
                };

            case 'success':
                return {
                    color: 'success',
                    label: 'sikeres',
                    className: undefined,
                };

            case 'processing':
                return {
                    color: undefined,
                    label: 'fut',
                    className: classes.importProgressStatus,
                };

            default:
                return {
                    color: 'default',
                    label: 'inicializálás',
                    className: undefined,
                };
        }
    };

    return <Chip className={getStatus()['className']} label={getStatus()['label']} color={getStatus()['color']} />;
}

import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import OffLayout from '../../components/OffLayout';
import LoginForm from './LoginForm';

export default function Login() {
    const [error, setError] = useState<string>('');

    return (
        <OffLayout>
            <LoginForm setError={setError} />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={!!error}
                autoHideDuration={2000}
                onClose={() => setError('')}
            >
                <Alert severity="error">{error}</Alert>
            </Snackbar>
        </OffLayout>
    );
}

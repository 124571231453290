import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { GridColumns } from '@mui/x-data-grid';
import { Link } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import formsService from '../../../../services/Unit/forms';
import { RootState } from '../../../../store';
import { getOtherOwnerCondition, ViewUnitCampaignManagementType } from '../../../../store/auth';
import { PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { getOwnerId } from '../../../Address/MessageFields';
import ActionCellRenderer from './ActionCellRenderer';
import { fields } from './fields';
import { IFormsForm } from './types';

const header = {
    title: 'Űrlapok',
    project: 'MCC',
    breadcrumbs: {
        templates: 'Sablonok',
        forms: 'Űrlapok',
    },
};

const filter = {
    fields,
};

const editorNames: Omit<IFormsForm, 'name' | 'formBuilderData'> = {
    notificationSuccess: 'Sikeres kitöltés esetén megjelenő szöveg',
    notificationDone: 'Már kitöltött kérdőív esetén megjelenő szöveg',
    notificationOutOfDate: 'Érvényesség lejárta után megjelenő szöveg',
};

const columns = (
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>,
    onlyRead: boolean,
    setSortByInsert: React.Dispatch<React.SetStateAction<boolean>>,
): GridColumns => [
    {
        field: 'name',
        headerName: 'Név',
        flex: 2,
        minWidth: 150,
    },
    {
        headerName: 'Létrehozás dátuma',
        field: 'insertDate',
        flex: 1,
        minWidth: 150,
    },
    {
        headerName: 'Létrehozó',
        field: 'author',
        flex: 1,
        minWidth: 150,
    },
    {
        headerName: 'Utolsó módosítás',
        field: 'updateDate',
        flex: 1,
        minWidth: 150,
    },
    {
        headerName: 'Módosító',
        field: 'editor',
        flex: 1,
        minWidth: 150,
    },
    {
        field: 'operations',
        headerName: 'Műveletek',
        flex: 1,
        minWidth: 200,
        renderCell: (params) =>
            onlyRead ? (
                <Link to={clientEndpoints.unit_forms_show.replace(':id', params.row.id.toString())}>
                    <Tooltip title="Megtekintés">
                        <IconButton color="success" size="small">
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </Link>
            ) : (
                <ActionCellRenderer {...params} setRefresh={setRefresh} setSortByInsert={setSortByInsert} />
            ),
    },
];

const back = (ownOnly?: boolean): string => {
    return ownOnly || getOtherOwnerCondition() ? clientEndpoints.unit_own_forms : clientEndpoints.unit_forms;
};

const headerById = (
    title: string,
    ownOnly?: boolean,
    readonly?: boolean,
    fromCampaignView?: boolean,
    campaignId?: string,
) => {
    if (fromCampaignView) {
        const backLink = clientEndpoints.unit_campaigns_view.replace(':id', campaignId || '');

        return {
            project: PROJECT_NAME,
            title: 'Űrlap ' + (!readonly ? 'szerkesztése' : 'megtekintése'),
            breadcrumbs: {
                'campaign': 'Kampányok',
                [clientEndpoints.unit_campaigns]: 'Kampánylista',
                [backLink]: 'Kampány megtekintése',
                form: title,
            },
        };
    }

    return {
        title: 'Űrlap ' + (!readonly ? 'szerkesztése' : 'megtekintése'),
        project: PROJECT_NAME,
        breadcrumbs: {
            templates: 'Sablonok',
            [back(ownOnly)]: 'Űrlapok',
            form: title,
        },
    };
};

const initialValue: IFormsForm = {
    name: '',
    notificationSuccess: '',
    notificationOutOfDate: '',
    notificationDone: '',
    formBuilderData: {
        pages: {},
    },
};

export const useListPageProps = (ownOnly?: boolean) => {
    const [refresh, setRefresh] = useState(false);
    const [sortByInsert, setSortByInsert] = useState(false);
    const [isFirstPage, setIsFirstPage] = useState(false);
    const [info, setInfo] = useState<Record<string, unknown>>({});
    const { view, user, selectedOwner } = useSelector((state: RootState) => state.auth);
    const otherOwnerCondition = getOtherOwnerCondition();
    const isOperationsVisible =
        ([undefined, ViewUnitCampaignManagementType.Common].includes(view.unitCampaignManagement) || !!ownOnly) &&
        !otherOwnerCondition;

    return {
        autoload: true as const,
        header,
        columns: columns(setRefresh, !isOperationsVisible, setSortByInsert),
        service: {
            filter: async (search: Record<string, unknown>): Promise<AxiosResponse> => {
                const noChange = JSON.stringify(search) === JSON.stringify(info);
                // if key matches /order\[.*\]/, then delete
                setIsFirstPage(sortByInsert && noChange);
                if (sortByInsert) {
                    Object.keys(search).forEach((key) => {
                        if (key.match(/order\[.*\]/)) {
                            delete search[key];
                        }
                    });
                }
                const response = await formsService.filter({
                    ...search,
                    ownerId: getOwnerId(view, ownOnly || false, user, selectedOwner),
                    ...(sortByInsert ? { 'order[insertDate]': 'desc', ...(noChange ? { page: 1 } : {}) } : {}),
                });

                if (info.page == search.page && !noChange) {
                    setSortByInsert(false);
                }
                setInfo(search);
                return response;
            },
        },
        responseKey: 'data',
        filter,
        newLabel:
            ([undefined, ViewUnitCampaignManagementType.Common].includes(view.unitCampaignManagement) || ownOnly) &&
            !otherOwnerCondition
                ? 'Új űrlap létrehozása'
                : undefined,
        refresh,
        defaultSort: { updateDate: 'desc' as const },
        tableProps: isFirstPage
            ? {
                  sortModel: [{ field: 'insertDate', sort: 'desc' as const }],
                  page: 0,
              }
            : {},
    };
};

export default {
    header,
    filter,
    columns,
    editorNames,
    back,
    headerById,
    initialValue,
};

import { FormPreview } from '@silinfo/form-builder-2';
import { useFetch } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../../../components/MCCLoading';
import Logo from '../../../../images/MCClogo_negacolor.svg';
import { endpoints } from '../../../../utils/endPoints';
import { IFormsForm } from './types';

const asyncAlert = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve('');
        }, 2000);
    });
};

export default function FormsPreview() {
    const { id } = useParams();
    const { data, loading } = useFetch<IFormsForm>(endpoints.unit.template.forms.main + '/' + id, '');
    const [form, setForm] = useState({});
    return loading ? (
        <MCCLoading />
    ) : (
        <FormPreview
            onSubmit={asyncAlert}
            formToSet={form}
            setForm={setForm}
            form={data.formBuilderData}
            image={Logo}
            {...data}
        />
    );
}

import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../../api/';
import { IForm } from '../../utils/AppConst';
import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';

const findAll = () => {
    return API.get(endpoints.campaigns.campaigns.all)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
};

const findSent = (query: { ownerId?: number | string }) => {
    return API.get(endpoints.campaigns.campaigns.sent, {
        params: query,
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
};

const columns = (campaignId: number) => {
    return API.get(endpoints.campaigns.campaigns.columns?.replace('{campaignId}', campaignId?.toString()))
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
};
export interface IPreviousCampaign {
    id: number;
    name: string;
}

export interface ICampaignTemplate {
    id: number;
    name: string;
    withForm: boolean;
    withSign: boolean;
}

export interface ICampaignForm {
    id: number;
    name: string;
}

export enum CampaignSubmitType {
    Instant = 'instant',
    Scheduled = 'scheduled',
}

export enum CampaignStatus {
    Scheduled = 'scheduled',
    ScheduledInstant = 'scheduled_instant',
    Sent = 'sent',
    Draft = 'draft',
    Sending = 'sending',
}

export type ICampaignSender = Option<number>;

export type ICampaignSenderSign = {
    id: number;
    name: string;
    content: string;
};

export type ICampaignReplier = Option<number>;

export type previousCampaignFormType = {
    sessionId: string;
};
export type previousCampaignType = {
    formTemplate: ICampaignForm;
    formTemplateValidity: string;
    replyId: number;
    senderId: number;
    tokenCountSpend: number;
};
export type CampaignFormAddressListSaveType = {
    session: string;
    address: number;
    checked: boolean;
    filterData?: { [k: string]: unknown };
};

export type CampaignFormAddressListSaveManyType = {
    session: string;
    page?: number | string;
    perpage?: number | string;
    checked: boolean;
    filterData?: { [k: string]: unknown };
    order?: { [k: string]: unknown };
    isOwn?: boolean;
    showAddressType?: string[];
};
export type NewCampaignType = {
    prevCampaignId: Option<number> | number;
    name: string;
    messageTemplateId: (Option<number> & { withForm: boolean }) | number;
    forms?: Option<number> & { own: boolean };
    formsId: number | (Option<number> & { own: boolean });
    formValidity: string;
    tokenCountSpend: number;
    submitType: CampaignSubmitType;
    submitDate: string;
    senderEmailId: number;
    senderEmail?: Option<number>;
    senderSignId: number;
    replyEmailId: number;
    replyEmail?: Option<number>;
    isOwn: boolean;
};
export type NewCampaignMessageFieldsType = {
    id: number;
    name: string;
    columnName: string;
};
export type CampaignTestDataType = {
    targetEmails: string;
    messageFieldValues: Record<string, string>;
};
export type CampaignTestType = NewCampaignType & CampaignTestDataType;

const campaignsService = {
    ...crudTemplate(endpoints.campaigns.campaigns.main),
    findAll,
    findSent,
    getPreviousCampaigns: (isOwn?: boolean): Promise<AxiosResponse<IPreviousCampaign[]>> =>
        API.get(endpoints.campaigns.campaigns.previousCampaigns + (isOwn ? '?isOwn=true' : '')),
    getTemplates: (): Promise<AxiosResponse<ICampaignTemplate[]>> => API.get(endpoints.campaigns.campaigns.templates),
    getForms: (): Promise<AxiosResponse<ICampaignForm[]>> => API.get(endpoints.campaigns.campaigns.forms),
    senders: (): Promise<AxiosResponse<ICampaignSender[]>> => API.get(endpoints.campaigns.campaigns.senders),
    repliers: (): Promise<AxiosResponse<ICampaignReplier[]>> => API.get(endpoints.campaigns.campaigns.repliers),
    signs: (id: number): Promise<AxiosResponse<ICampaignSenderSign[]>> =>
        API.get(endpoints.campaigns.campaigns.sender.signs.replace('{id}', id.toString())),
    previousCampaign: (id: number, form: previousCampaignFormType): Promise<AxiosResponse> =>
        API.get(endpoints.campaigns.campaigns.previousCampaign.replace('{id}', id.toString()), { params: form }),
    columns,
    saveFormAddressList: (form: CampaignFormAddressListSaveType) =>
        API.post(endpoints.campaigns.form.addressList.save, form),
    saveManyFormAddressList: (form: CampaignFormAddressListSaveManyType, config?: AxiosRequestConfig) =>
        API.post(endpoints.campaigns.form.addressList.saveMany, form, config),
    saveAutomatic: (params?: unknown) => API.post(endpoints.campaigns.campaigns.saveAutomatic, params || {}),
    save: (params?: unknown) => API.post(endpoints.campaigns.campaigns.save, params || {}),
    validate: (form: NewCampaignType, isOwn?: boolean): Promise<AxiosResponse<NewCampaignMessageFieldsType[]>> =>
        API.post(endpoints.campaigns.campaigns.validate + (isOwn ? '?isOwn=true' : '?isOwn=false'), form),
    sendTest: (form: CampaignTestType) => API.post(endpoints.campaigns.campaigns.sendTest, form),
    addressList: (form: IForm, id: number) =>
        API.get(endpoints.campaigns.campaigns.addressList.replace('{campaignId}', id.toString()), { params: form }),
};

export default campaignsService;

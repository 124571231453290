import { IForm } from '@silinfo/form-builder-2';
import instance from '../../api';
import { endpoints } from '../../utils/endPoints';

const handleSubmit = (unitId: number, token: string) => (data: Record<string, unknown>) =>
    instance.post(endpoints.form.main(unitId, token), data);

const dummyData: IForm = {
    'pages': {
        '0': {
            'ID': 'silinfo_page_1669293363381',
            'index': 0,
            'panels': {
                '0': {
                    'ID': 'silinfo_panel_1669298958388',
                    'index': 0,
                    'title': 'Panel 1',
                    'elements': {
                        '0': {
                            'ID': 'silinfo_1669298956087',
                            'index': 0,
                            'type': 'select',
                            'question': '',
                            'tooltip': '',
                            'help': '',
                            'formAnswer': {
                                'ID': 'silinfo_form_element_answer_1669298967059',
                                'elements': {
                                    '0': {
                                        'ID': 'silinfo_form_element_answer_option_1669298962686',
                                        'value': '1',
                                        'jumpPoint': '1',
                                        'checkboxOther': false,
                                    },
                                },
                                'elementOptions': {
                                    'multiselect': false,
                                    'radio': false,
                                },
                            },
                            'validations': {
                                '0': {
                                    'ID': 'silinfo_validation_text_1669306215439',
                                    'type': 'required',
                                    'errMessage': 'hiba',
                                },
                            },
                        },
                        '1': {
                            'ID': 'silinfo_1669298956087_cp',
                            'index': 1,
                            'type': 'multiselect',
                            'question': '',
                            'tooltip': '',
                            'help': '',
                            'formAnswer': {
                                'ID': 'silinfo_form_element_answer_1669298986488',
                                'elements': {
                                    '0': {
                                        'ID': 'silinfo_form_element_answer_option_1669298987988',
                                        'value': '1',
                                        'jumpPoint': '1',
                                        'checkboxOther': true,
                                    },
                                },
                                'elementOptions': {
                                    'multiselect': true,
                                    'radio': false,
                                },
                            },
                            'validations': {},
                        },
                        '2': {
                            'ID': 'silinfo_1669298956087_cp_cp',
                            'index': 2,
                            'type': 'selectradio',
                            'question': '',
                            'tooltip': '',
                            'help': '',
                            'formAnswer': {
                                'ID': 'silinfo_form_element_answer_1669298994446',
                                'elements': {
                                    '0': {
                                        'ID': 'silinfo_form_element_answer_option_1669298994859',
                                        'value': '1',
                                        'jumpPoint': '1',
                                        'checkboxOther': true,
                                    },
                                },
                                'elementOptions': {
                                    'multiselect': true,
                                    'radio': true,
                                },
                            },
                            'validations': {},
                        },
                        '3': {
                            'ID': 'silinfo_1669298956087_cp_cp_cp',
                            'index': 3,
                            'type': 'table',
                            'question': '',
                            'tooltip': '',
                            'help': '',
                            'formAnswer': {
                                'ID': 'silinfo_form_element_answer_1669299010551',
                                'cols': [
                                    {
                                        'ID': 'silinfo_table_answer_col_1669299014693',
                                        'value': 'oszlop',
                                        'index': 0,
                                    },
                                ],
                                'rows': [
                                    {
                                        'ID': 'silinfo_table_answer_row_1669299008891',
                                        'value': 'sor',
                                        'index': 0,
                                    },
                                ],
                            },
                            'validations': {},
                        },
                        '4': {
                            'ID': 'silinfo_1669298956087_cp_cp_cp_cp',
                            'index': 4,
                            'type': 'scale',
                            'question': '',
                            'tooltip': '',
                            'help': '',
                            'formAnswer': {
                                'ID': 'silinfo_form_element_answer_1669299026144',
                                'fromScale': 1,
                                'toScale': 5,
                                'fromVal': 'eleje',
                                'toVal': 'vége',
                            },
                            'validations': {},
                        },
                    },
                },
            },
            'nextPage': '',
        },
        '1': {
            'ID': 'silinfo_page_1669298982799',
            'index': 1,
            'panels': {
                1: {
                    'ID': 'silinfo_panel_1534926152619',
                    'index': 0,
                    'title': 'Felvételi eredmény',
                    'elements': [
                        {
                            'ID': 'silinfo_1534926266428',
                            'index': 0,
                            'type': 'text',
                            'question': 'Melyik egyetemre/főiskolára vettek fel?',
                            'tooltip': '',
                            'help': '',
                            'formAnswer': {
                                'ID': 'silinfo_form_element_answer_1536323427793',
                            },
                            'validations': [
                                {
                                    'ID': 'silinfo_validation_text_1536323425587',
                                    'type': 'required',
                                    'errMessage': 'Kötelező',
                                },
                            ],
                        },
                    ],
                },
            },
            'nextPage': '',
        },
    },
};

export { dummyData, handleSubmit };

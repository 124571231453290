import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { AUTOMATIC_SAVE_TIME } from '../utils';

interface AutomaticSaveProps {
    handleAutomaticSaveClick: (unlocked?: boolean, automatic?: boolean, manual?: boolean) => Promise<unknown>;
    automaticSaveLoading: boolean;
    formLoading: boolean;
}

export default function AutomaticSave({
    handleAutomaticSaveClick,
    automaticSaveLoading,
    formLoading,
}: AutomaticSaveProps) {
    const [counter, setCounter] = useState(AUTOMATIC_SAVE_TIME);

    useEffect(() => {
        if (counter === 0) {
            handleAutomaticSaveClick(false, true).then(() => setCounter(AUTOMATIC_SAVE_TIME));
        }
    }, [counter, handleAutomaticSaveClick]);
    useEffect(() => {
        const downCounter = setInterval(() => {
            if (!formLoading) {
                setCounter((prev) => (prev > 0 ? prev - 1 : AUTOMATIC_SAVE_TIME));
            }
        }, 1000);

        return () => clearInterval(downCounter);
    }, [formLoading]);

    return (
        <>
            <ThemeProvider theme={createTheme()}>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={automaticSaveLoading}>
                    <Alert severity="info">Automatikus mentés...</Alert>
                </Snackbar>
            </ThemeProvider>
            <Grid item container xs={12} justifyContent="flex-end" spacing={2} display="flex" alignItems="center">
                <Grid item>
                    <Typography color="GrayText" fontSize="14px">
                        {automaticSaveLoading
                            ? 'Automatikus mentés...'
                            : `Automatikus mentés ${counter} másodperc múlva`}
                    </Typography>
                </Grid>
                <Grid item>
                    <LoadingButton
                        loading={automaticSaveLoading}
                        variant="contained"
                        onClick={() =>
                            handleAutomaticSaveClick(false, false, true).then(() => setCounter(AUTOMATIC_SAVE_TIME))
                        }
                    >
                        Mentés most
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

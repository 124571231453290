import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, SubmitButton } from '@silinfo/front-end-template';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { endpoints } from '../../utils/endPoints';
import { history, store } from '../../store';
import MCCLoading from '../../components/MCCLoading';
import { create } from '../../store/notification';

export default function Unsubscribe() {
    const { unitId, token } = useParams();
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [text, setText] = useState<string>('');
    const [status, setStatus] = useState<string>('');

    useEffect(() => {
        setPageLoading(true);
        axios
            .get(
                endpoints.unsubscribe
                    .replace(':unitId', (unitId ?? 0).toString())
                    .replace(':token', (token ?? '').toString()),
            )
            .then((response) => {
                setText(response.data.messages);
                setStatus(response.data.status);
            })
            .finally(() => setPageLoading(false));
    }, [unitId, token]);

    if (pageLoading) {
        return <MCCLoading />;
    }

    const unsubscribeHandler = () => {
        setPageLoading(true);
        axios
            .post(
                endpoints.unsubscribe
                    .replace(':unitId', (unitId ?? 0).toString())
                    .replace(':token', (token ?? '').toString()),
                {},
            )
            .then(() => {
                history.push('/');
                store.dispatch(create({ message: 'Sikeres leiratkozás', type: 'success' }));
            })
            .finally(() => setPageLoading(false));
    };

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography align="center" variant="h1">
                    Leiratkozás
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center" variant="h4">
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </Typography>
            </Grid>

            <>
                <Grid item xs={12}>
                    <Link to="/">
                        <SubmitButton fullWidth size="large">
                            Vissza a főoldalra
                        </SubmitButton>
                    </Link>
                </Grid>
                {status === 'ok' && (
                    <Grid item xs={12}>
                        <SubmitButton onClick={unsubscribeHandler} fullWidth size="large">
                            Leiratkozás
                        </SubmitButton>
                    </Grid>
                )}
            </>
        </Grid>
    );
}

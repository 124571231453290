import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILoadingState {
    count: number;
    urls: string[];
}

const initialState: ILoadingState = { count: 0, urls: [] };

const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        increment(state, action: PayloadAction<string>) {
            state.count++;
            state.urls.push(action.payload);
        },
        decrement(state, action: PayloadAction<string>) {
            state.count--;
            state.urls = state.urls.filter((url) => url !== action.payload);
        },
    },
});

export const { increment, decrement } = loadingSlice.actions;
export default loadingSlice.reducer;

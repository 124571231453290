import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AccessTreeType = {
    central: Record<string, string[]>;
    unit: Record<string, string[]>;
};

interface IAccessManagementState {
    accessTree: AccessTreeType;
    accessTreeLoading: boolean;
}

const accessManagementSlice = createSlice({
    name: 'accessManagement',
    initialState: { accessTree: { central: {}, unit: {} }, accessTreeLoading: true } as IAccessManagementState,
    reducers: {
        setAccessTree(state, action: PayloadAction<AccessTreeType>) {
            state.accessTree = action.payload;
            state.accessTreeLoading = false;
        },
        setAccessTreeLoading(state, action: PayloadAction<boolean>) {
            state.accessTreeLoading = action.payload;
        },
    },
});

export const { setAccessTree, setAccessTreeLoading } = accessManagementSlice.actions;
export default accessManagementSlice.reducer;

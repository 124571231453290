import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridColDef } from '@mui/x-data-grid';
import { defaultFields, transformToTableFields } from '../../pages/Unit/Campaign/CampaignList/fields';
import { ICampaignFormField } from '../../pages/Unit/Campaign/CampaignList/types';

export interface ICampaignFormListState {
    fields: GridColDef[];
    refresh: boolean;
}

export interface ICampaignFormColumns {
    name: string;
    label: string;
}

const campaignFormListSlice = createSlice({
    name: 'campaignFormList',
    initialState: {
        fields: defaultFields,
        tableFields: transformToTableFields(defaultFields),
        refresh: false,
    },
    reducers: {
        addFields(state, action: PayloadAction<ICampaignFormColumns[]>) {
            const copy = action.payload.map((item) => {
                return {
                    name: item.name,
                    label: item.label,
                    visible: false,
                    default: false,
                };
            });
            state.fields = defaultFields.concat(copy);
        },
        setTableFields(state, action: PayloadAction<ICampaignFormField[]>) {
            state.tableFields = transformToTableFields(action.payload);
        },
        resetFields(state) {
            state.fields = defaultFields;
            state.tableFields = transformToTableFields(defaultFields);
        },
        setRefresh(state) {
            state.refresh = !state.refresh;
        },
    },
});

export const { addFields, setTableFields, resetFields, setRefresh } = campaignFormListSlice.actions;
export default campaignFormListSlice.reducer;

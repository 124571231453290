import ContentCopy from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import ActionCellRendererWrapper from '../../../../components/ActionCellRenderer';
import ActionCellContext from '../../../../components/ActionCellRenderer/Context';
import formsService from '../../../../services/Unit/forms';
import { create } from '../../../../store/notification';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { theme } from '../../../../utils/theme';

const ActionButton = ({
    id,
    setRefresh,
    action,
    Icon,
}: {
    id: number | string;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    action: (id: string | number) => Promise<unknown>;
    Icon: JSX.Element;
}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const setAnchorEl = useContext(ActionCellContext);

    const handleClick = async () => {
        try {
            setLoading(true);
            await action(id);
            setAnchorEl(null);
            setLoading(false);
            setRefresh((prev) => !prev);
            dispatch(create({ type: 'success', message: 'Elem duplikálása sikerült.' }));
        } catch (e) {
            dispatch(create({ type: 'error', message: 'Hiba lépett fel az elem duplikálása közben.' }));
        }
    };

    return (
        <Grid item>
            {loading ? (
                <CircularProgress />
            ) : (
                <Tooltip title="Duplikálás">
                    <IconButton
                        onClick={handleClick}
                        color="primary"
                        size="small"
                        style={{ border: '1px solid ' + theme.palette.primary.main }}
                    >
                        {Icon}
                    </IconButton>
                </Tooltip>
            )}
        </Grid>
    );
};

const DeleteButton = ({
    params,
    setRefresh,
}: {
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    params: GridRenderCellParams;
}) => {
    const setAnchorEl = useContext(ActionCellContext);
    const [loading, setLoading] = useState(false);

    return (
        <Grid item>
            {loading ? (
                <CircularProgress />
            ) : (
                <TableDeleteButton
                    deleteAction={() => {
                        setLoading(true);
                        formsService
                            .delete(params.id)
                            .then(() => {
                                setRefresh((prev) => !prev);
                                setAnchorEl(null);
                            })
                            .finally(() => setLoading(false));
                    }}
                    confirmationMessage={`Biztosan törölni szeretnéd a '${params.row.name}' nevű űrlapot?`}
                />
            )}
        </Grid>
    );
};

export const ViewFormButton = ({ id }: { id: number | string }) => {
    const handleClick = () => {
        window.open(clientEndpoints.unit_forms_preview.replace(':id', '' + id), '_blank');
    };
    return (
        <Grid item>
            <Tooltip title="Űrlap előnézete">
                <IconButton
                    color="success"
                    style={{ border: '1px solid ' + theme.palette.success.main }}
                    size="small"
                    onClick={handleClick}
                >
                    <VisibilityIcon />
                </IconButton>
            </Tooltip>
        </Grid>
    );
};

export default function ActionCellRenderer(
    params: GridRenderCellParams & {
        setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
        setSortByInsert: React.Dispatch<React.SetStateAction<boolean>>;
    },
) {
    const { setRefresh, setSortByInsert } = params;

    const duplicateAction = async (id: string | number) => {
        await formsService.duplicate(id);
        setSortByInsert(true);
    };

    return (
        <ActionCellRendererWrapper>
            <Grid container spacing={1}>
                <Grid item>
                    <TableEditButton target={`${params.id}/edit`} tooltip="Szerkesztés" />
                </Grid>
                <ViewFormButton id={params.id} />
                <ActionButton id={params.id} setRefresh={setRefresh} action={duplicateAction} Icon={<ContentCopy />} />
                {params.row.isDeletable && <DeleteButton params={params} setRefresh={setRefresh} />}
            </Grid>
        </ActionCellRendererWrapper>
    );
}

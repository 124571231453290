import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { FieldGenerator, CancelButton } from '@silinfo/front-end-template';
import { Form as FormikForm, Formik, FormikErrors, FormikHelpers } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Children } from 'react';
import { useDispatch } from 'react-redux';
import { create } from '../../../../store/notification';
import { AxiosError } from 'axios';
import { IViolations } from '../../../../services/ResponseInterfaces';
import { transformApiErrors } from '../../../../utils/AppConst';
import campaignsService, {
    CampaignTestDataType,
    NewCampaignMessageFieldsType,
    NewCampaignType,
} from '../../../../services/campaigns/campaigns';

const initialForm: CampaignTestDataType = {
    targetEmails: '',
    messageFieldValues: {},
};

export interface ITestCampaignDialog {
    open: boolean;
    onClose: () => void;
    messageTemplates: NewCampaignMessageFieldsType[];
    newCampaign: NewCampaignType;
}

export type ViolationError = AxiosError<{ violations: IViolations[] }>;

export default function TestCampaignDialog(props: ITestCampaignDialog): JSX.Element {
    const dispatch = useDispatch();
    const handleSubmit = (form: CampaignTestDataType, { setErrors }: FormikHelpers<CampaignTestDataType>) => {
        campaignsService
            .sendTest({ ...props.newCampaign, ...form })
            .then(() => {
                props.onClose();
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres kiküldés!',
                    }),
                );
            })
            .catch((error: AxiosError<{ violations: IViolations[] }>) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                }
                dispatch(
                    create({
                        type: 'error',
                        message: 'Hiba az új kampány teszt kiküldése során.',
                    }),
                );
            });
    };

    return (
        <Dialog open={props.open} fullWidth maxWidth="lg">
            <DialogTitle>Teszt e-mail</DialogTitle>
            <DialogContent style={{ paddingTop: '24px' }}>
                <Formik initialValues={initialForm} onSubmit={handleSubmit}>
                    {(fProps) => (
                        <FormikForm noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" sx={{ width: '100%', mt: 1 }}>
                                        <Typography variant="h6" gutterBottom>
                                            Teszt email kiküldése erre a címre
                                        </Typography>
                                        {FieldGenerator({
                                            ...fProps,
                                            label: 'Teszt e-mail kiküldése erre a címre',
                                            name: 'targetEmails',
                                            type: 'text',
                                            format: {
                                                xs: 12,
                                            },
                                        })}
                                        <Typography component="p">
                                            Pontosvesszővel elválasztva több e-mail cím is megadható. Például:
                                        </Typography>
                                        <Typography component="p" noWrap={true}>
                                            gipsz.jakab@example.com‌;teszt.elek@example.com‌;kovacs.bela@example.com
                                        </Typography>
                                    </FormControl>
                                    <FormControl component="fieldset" sx={{ width: '100%', mt: 3 }}>
                                        <Typography variant="h6" gutterBottom>
                                            Sablonban szereplő mezők értékének megadása
                                        </Typography>
                                        {Children.toArray(
                                            props.messageTemplates.map((field) =>
                                                FieldGenerator({
                                                    ...fProps,
                                                    label: field.name,
                                                    name: `messageFieldValues.${field.id}`,
                                                    type: 'text',
                                                    format: {
                                                        xs: 12,
                                                    },
                                                    props: {
                                                        error: !!fProps.errors[
                                                            `messageFieldValues.${field.id}` as keyof FormikErrors<CampaignTestDataType>
                                                        ],
                                                        helperText:
                                                            fProps.errors[
                                                                `messageFieldValues.${field.id}` as keyof FormikErrors<CampaignTestDataType>
                                                            ],
                                                        placeholder: field.columnName,
                                                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                            fProps.setFieldValue(
                                                                `messageFieldValues.${field.id}`,
                                                                e.target.value,
                                                            );
                                                        },
                                                        value: fProps.values.messageFieldValues[field.id],
                                                    },
                                                }),
                                            ),
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item container spacing={2} sx={{ mt: 1 }}>
                                    <Grid item>
                                        <LoadingButton variant="contained" type="submit">
                                            Küldés
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item>
                                        <CancelButton onClick={props.onClose}>Mégsem</CancelButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormikForm>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

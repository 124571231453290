import ErrorIcon from '@mui/icons-material/Error';

const AccordionHeader = ({ title, error }: { title: string; error: boolean }) => (
    <span style={{ display: 'flex', gap: '4px' }}>
        {title}
        {error ? <ErrorIcon color="error" /> : null}
    </span>
);

export default AccordionHeader;

import {
    emailsOpenedExportService,
    emailsSentExportService,
    formsFilledExportService,
} from '../../../../services/Unit/statistics/statisticsCampaignService';
import { CampaignStatisticsTableProps } from './CampaignStatisticsTable';
import { EmailsOpenedType, EmailsSentType, FormsFilledType, StatisticsCampaignFilter } from './types';

export const emailsOpenedTable = (
    emailsOpenedData: EmailsOpenedType[],
    filter: StatisticsCampaignFilter,
): CampaignStatisticsTableProps => ({
    columns: [
        { headerName: 'Kampány', field: 'campaignName', flex: 1 },
        { headerName: 'Megnyitottak', field: 'opened', flex: 1 },
        { headerName: 'Nem megnyitottak', field: 'notOpened', flex: 1 },
    ],
    rows: emailsOpenedData,
    title: 'Megnyitott e-mailek',
    exportContext: {
        initiateExport: emailsOpenedExportService.initiateExport,
        checkExport: emailsOpenedExportService.checkExport,
        downloadExport: emailsOpenedExportService.downloadExport,
        filter: filter,
    },
    chartData: [
        ['Megnyitott e-mailek', 'Megnyitott e-mailek'],
        ['Megnyitottak', emailsOpenedData[0]?.opened],
        ['Nem megnyitottak', emailsOpenedData[0]?.notOpened],
    ],
});

export const emailsSentTable = (
    emailsSentData: EmailsSentType[],
    filter: StatisticsCampaignFilter,
): CampaignStatisticsTableProps => ({
    columns: [
        { headerName: 'Kampány', field: 'campaignName', flex: 1 },
        { headerName: 'Kézbesítve', field: 'delivered', flex: 1 },
        { headerName: 'Nincs kézbesítve', field: 'notDelivered', flex: 1 },
        { headerName: 'Folyamatban', field: 'processing', flex: 1 },
        { headerName: 'Nincs információ', field: 'noInformation', flex: 1 },
    ],
    rows: emailsSentData,
    title: 'Kézbesített e-mailek',
    exportContext: {
        initiateExport: emailsSentExportService.initiateExport,
        checkExport: emailsSentExportService.checkExport,
        downloadExport: emailsSentExportService.downloadExport,
        filter: filter,
    },
    chartData: [
        ['Kézbesített e-mailek', 'Kézbesített e-mailek'],
        ['Kézbesítve', emailsSentData[0]?.delivered],
        ['Nincs kézbesítve', emailsSentData[0]?.notDelivered],
        ['Folyamatban', emailsSentData[0]?.processing],
        ['Nincs információ', emailsSentData[0]?.noInformation],
    ],
});

export const formsFilledTable = (
    formsFilledData: FormsFilledType[],
    filter: StatisticsCampaignFilter,
): CampaignStatisticsTableProps => ({
    columns: [
        { headerName: 'Kampány', field: 'campaignName', flex: 1 },
        { headerName: 'Kitöltötte', field: 'filled', flex: 1 },
        { headerName: 'Nem töltötte ki', field: 'notFilled', flex: 1 },
    ],
    rows: formsFilledData,
    title: 'Űrlap kitöltések',
    exportContext: {
        initiateExport: formsFilledExportService.initiateExport,
        checkExport: formsFilledExportService.checkExport,
        downloadExport: formsFilledExportService.downloadExport,
        filter: filter,
    },
    chartData: [
        ['Űrlap kitöltések', 'Űrlap kitöltések'],
        ['Kitöltötte', formsFilledData[0]?.filled],
        ['Nem töltötte ki', formsFilledData[0]?.notFilled],
    ],
});

import ExportListPage from '../../pages/Unit/ExportList';
import { clientEndpoints } from '../../utils/clientEndpoints';

const ExportList = {
    path: clientEndpoints.export_list,
    children: [
        {
            path: '',
            element: <ExportListPage />,
            name: 'export_list',
        },
    ],
};

export default ExportList;

import settingsService from '../../../../services/Unit/settingsService';
import { FormikListPage } from '@silinfo/front-end-template';
import { columns, filterFields } from './fields';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';

export default function SystemSettings() {
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Beállítások',
        breadcrumbs: {
            admin: 'Adminisztráció',
            [clientEndpoints.unit_settings]: 'Beállítások',
        },
    };

    const filter = {
        fields: filterFields,
    };

    return (
        <FormikListPage
            autoload
            header={header}
            service={settingsService}
            columns={columns}
            defaultSort={{ key: 'asc' }}
            filter={filter}
            responseKey="data"
        />
    );
}

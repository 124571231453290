import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { clientEndpoints } from '../../../../utils/clientEndpoints';

export default function ExpiredSessionDialog() {
    const navigate = useNavigate();
    const handleClose = () => {
        navigate(clientEndpoints.unit_campaigns);
    };

    return (
        <Dialog open onClose={handleClose}>
            <DialogTitle>Lejárt munkamenet</DialogTitle>
            <DialogContent style={{ paddingTop: '16px' }}>
                <DialogContentText>Lejárt a munkameneted, átirányítunk a kampánylistára.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleClose}>
                            OK
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

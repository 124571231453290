import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import unitsService from '../../../services/Central/units';
import { create } from '../../../store/notification';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import * as React from 'react';
import { TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { IConfirmationDialog } from './ConfirmationDialog';
import ActionCellRenderer from '../../../components/ActionCellRenderer';

const useStyles = makeStyles(() => ({
    button: {
        cursor: 'pointer',
    },
}));

const SerialCellRenderer = (params: GridRenderCellParams<string> & { refresh: () => void }) => {
    const { refresh } = params;
    const classes = useStyles();
    const dispatch = useDispatch();
    return (
        <>
            {params.value}
            {params?.value != '1' && (
                <ArrowDropUp
                    onClick={() => {
                        unitsService
                            .decrementSerial(+params.id)
                            .then(() => dispatch(create({ type: 'success', message: 'Elem mozgatása sikerült.' })))
                            .catch(() =>
                                dispatch(
                                    create({ type: 'error', message: 'Hiba lépett fel az elem mozgatása közben.' }),
                                ),
                            )
                            .finally(refresh);
                    }}
                    className={classes.button}
                />
            )}
            {params?.value != '0' && (
                <ArrowDropDown
                    onClick={() => {
                        unitsService
                            .incrementSerial(+params.id)
                            .then(() => dispatch(create({ type: 'success', message: 'Elem mozgatása sikerült.' })))
                            .catch(() =>
                                dispatch(
                                    create({
                                        type: 'error',
                                        message: 'Hiba lépett fel az elem mozgatása közben.',
                                    }),
                                ),
                            )
                            .finally(refresh);
                    }}
                    className={classes.button}
                />
            )}
        </>
    );
};

const BtnCellRenderer = (
    params: GridRenderCellParams & {
        setState: React.Dispatch<React.SetStateAction<IConfirmationDialog>>;
        refresh: () => void;
    },
) => {
    const dispatch = useDispatch();

    return (
        <Grid container spacing={1}>
            <Grid item>
                <TableEditButton target={'edit/' + params.id} />
            </Grid>
            <Grid item>
                <TableDeleteButton
                    deleteAction={() => {
                        unitsService
                            .delete(params.id)
                            .then(() => {
                                dispatch(
                                    create({
                                        type: 'success',
                                        message: 'Az területi egység sikeresen törlésre került!',
                                    }),
                                );
                                params.refresh();
                            })
                            .catch((e) => {
                                let msg = 'Az területi egység törlése sikertelen volt. Hibaüzenet: ';
                                if (e.response?.status === 422) {
                                    msg += e.response?.data?.violations[0]?.message;
                                } else {
                                    msg += e.message;
                                }
                                dispatch(create({ type: 'error', message: msg }));
                            });
                    }}
                    confirmationElement={params.row.name}
                />
            </Grid>
        </Grid>
    );
};

export const tableColumns = (
    setState: React.Dispatch<React.SetStateAction<IConfirmationDialog>>,
    refresh: () => void,
    matches: boolean,
): GridColDef[] =>
    [
        {
            headerName: '#',
            field: 'serial',
            renderCell: (params: GridRenderCellParams) => <SerialCellRenderer refresh={refresh} {...params} />,
            minWidth: 70,
        },
        {
            headerName: 'Megnevezés',
            field: 'name',
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams) => (
                <ActionCellRenderer>
                    <BtnCellRenderer setState={setState} refresh={refresh} {...params} />
                </ActionCellRenderer>
            ),
            sortable: false,
            minWidth: matches ? 150 : 90,
        },
    ].map((col) => ({ flex: 1, minWidth: 150, ...col }));

import UploadFileIcon from '@mui/icons-material/UploadFile';
import LoopIcon from '@mui/icons-material/Loop';
import StorageIcon from '@mui/icons-material/Storage';
import SearchIcon from '@mui/icons-material/Search';
import { IProgressTimeline } from './ProgressTimeline';

export const importStatuses: IProgressTimeline[] = [
    {
        name: 'Fájlfeltöltés',
        icon: <UploadFileIcon />,
        progress: 0,
    },
    {
        name: 'Inicializálás',
        icon: <LoopIcon />,
        progress: 0,
    },
    {
        name: 'Validáció',
        icon: <SearchIcon />,
        progress: 0,
    },
    {
        name: 'MySQL mentés',
        icon: <StorageIcon />,
        progress: 0,
    },
    {
        name: 'MongoDB mentés',
        icon: <StorageIcon />,
        progress: 0,
    },
];

import Chip from '@mui/material/Chip';
import { GridRenderCellParams } from '@mui/x-data-grid';
import ErrorStatusDialogButton from './ErrorStatusDialogButton';

type StatusCellRendererProps = {
    params: GridRenderCellParams;
    isCentral: boolean;
};

export default function StatusCellRenderer(props: StatusCellRendererProps) {
    const { params, isCentral } = props;

    switch (params.value) {
        case 1:
        case 'Kiküldve':
        case 'success':
            return <Chip label="Kiküldve" color="success" />;
        case 0:
        case 'Kiküldésre vár':
        case 'generated':
            return <Chip label="Kiküldésre vár" color="warning" />;
        case 3:
        case 'Kiküldés alatt':
        case 'sending':
            return <Chip label="Kiküldés alatt" color="info" />;
        case 'init':
            return <Chip label="Inicializálva" color="info" />;
        case -1:
        case 'Hibás':
        case 'error':
            return <ErrorStatusDialogButton id={params.row.id} isCentral={isCentral} />;
        default:
            return <Chip label="Ismeretlen státusz" color="warning" />;
    }
}

import FormsPage from '../../../pages/Unit/Templates/Forms';
import Form from '../../../pages/Unit/Templates/Forms/Form';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const OwnFormsPage = () => <FormsPage ownOnly={true} />;
const OwnForm = () => <Form ownOnly={true} />;

const OwnForms = {
    path: clientEndpoints.unit_own_forms,
    children: [
        {
            path: '',
            element: <OwnFormsPage />,
            name: 'unit_own_forms',
        },
        {
            path: 'new',
            element: <OwnForm />,
            name: 'unit_own_forms_new',
        },
        {
            path: ':id/edit',
            element: <OwnForm />,
            name: 'unit_own_forms_edit',
        },
    ],
};

export default OwnForms;

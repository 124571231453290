import Grid from '@mui/material/Grid';
import { TableButton } from '@silinfo/front-end-template';
import GroupsIcon from '@mui/icons-material/Groups';
import { useNavigate } from 'react-router-dom';
import { clientEndpoints } from '../../../utils/clientEndpoints';

export function TableGroupAddressesButton(props: { isOwn?: boolean; groupId: number | string }) {
    const { isOwn, groupId } = props;
    const navigate = useNavigate();

    return (
        <Grid item>
            <TableButton
                color="warning"
                onClick={() => {
                    navigate(
                        clientEndpoints[
                            isOwn ? 'unit_own_address_groups_only_group' : 'unit_address_groups_only_group'
                        ].replace(':groupId', groupId.toString()),
                    );
                }}
                tooltip={'Csoport tagjai'}
            >
                <GroupsIcon />
            </TableButton>
        </Grid>
    );
}

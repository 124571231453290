import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { Theme } from '@mui/system';
import { Layout as BasicLayout, Option } from '@silinfo/front-end-template';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { default as API, default as api } from '../../api';
import Logo from '../../images/mcc_logo.svg';
import { RootState } from '../../store';
import { setAccessTree, setAccessTreeLoading } from '../../store/accessManagement';
import {
    AppViewType,
    AuthUser,
    ViewLayoutType,
    ViewUnitCampaignManagementType,
    clearViewLayout,
    setUser,
    setUserLoading,
} from '../../store/auth';
import { checkAccess, curriedCheckAccess, getMenus, getUserLayouts } from '../../utils/AccessManagementHelper';
import { PROJECT_NAME, SELECTED_UNIT_COOKIE } from '../../utils/AppConst';
import { logout } from '../../utils/AuthHelper';
import { clientEndpoints } from '../../utils/clientEndpoints';
import { endpoints } from '../../utils/endPoints';
import MCCLoading from '../MCCLoading';
import Notification from '../Notification';
import AccessChecker from './AccessChecker';
import ChangePasswordDialog from './ChangePasswordDialog';
import HandbookDialog from './HandbookDialog';
import { IMainMenu } from './types';
import ExportChecker from './ExportChecker';
import { ExportStatusProvider } from '../../providers/ExportStatusProvider';

const getHeaderText = (view: AppViewType, layoutName?: string, selectedOwner?: Option<number, string>): string => {
    let headerText = PROJECT_NAME;

    if (layoutName) {
        headerText += ` - ${layoutName}`;
    }

    if (view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner && selectedOwner) {
        headerText += ` - ${selectedOwner.label}`;
    }

    return headerText;
};

export default function Layout() {
    const th = useTheme<Theme>();
    const matches = useMediaQuery(th.breakpoints.up('md'), { noSsr: true });
    const { user, view, layoutName, selectedOwner } = useSelector((state: RootState) => state.auth);
    const { accessTree } = useSelector((state: RootState) => state.accessManagement);
    const navigate = useNavigate();
    const [sidebarMenu, setSideBarMenu] = useState<IMainMenu>({});
    const extraHeaderAccountMenuItems: object[] = [];
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [handbookDialogOpen, setHandbookDialogOpen] = useState<boolean>(false);
    const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState<boolean>(false);
    const hasAccessToExportList = curriedCheckAccess('export_list');
    const openHandbookDialog = useCallback(() => setHandbookDialogOpen(true), []);
    const closeHandbookDialog = useCallback(() => setHandbookDialogOpen(false), []);
    const [refresh, setRefresh] = useState<number>(Date.now());

    useEffect(() => {
        if (accessTree && user && view.layout) {
            const menu = getMenus(accessTree, user, view.layout);
            setSideBarMenu(menu);
        }
    }, [accessTree, user, view]);

    /**
     * HOTFIX: mert a tesztrendszer összeakad a TAR teszrendszerrel
     */
    if (view.layout !== ViewLayoutType.Central && view.layout !== ViewLayoutType.Unit) {
        dispatch(clearViewLayout());
    }

    useEffect(() => {
        setLoading(true);
        axios
            .all([
                api.get(endpoints.me).then((res: AxiosResponse) => {
                    dispatch(setUser(res.data));
                    setLoading(false);
                }),
                api.get(endpoints.accessTree).then((res: AxiosResponse) => {
                    dispatch(setAccessTree(res.data));
                }),
            ])
            .catch(() => {
                setLoading(false);
                dispatch(setUserLoading(false));
                dispatch(setAccessTreeLoading(false));
            })
            .finally(() => setLoading(false));

        setInterval(() => {
            const selectedUnit = localStorage.getItem(SELECTED_UNIT_COOKIE);
            if (selectedUnit && +selectedUnit !== view.unit) {
                window.location.reload();
            }
        }, 5000);

        return () => {
            // Get a reference to the last interval + 1
            const interval_id = window.setInterval(function () {
                return;
            }, Number.MAX_SAFE_INTEGER);

            // Clear any timeout/interval up to that id
            for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
            }
        };
    }, [dispatch, view.unit]);

    if ((user && getUserLayouts(user).length > 1) || Object.keys(user?.roles?.unit || {}).length > 0) {
        const hasAccessToExportList = checkAccess('export_list', accessTree, user as AuthUser, view.layout);
        extraHeaderAccountMenuItems.push({
            name: 'Nézetváltás',
            props: {
                onClick: () => {
                    navigate(clientEndpoints.switch_layout);
                },
            },
        });

        if (hasAccessToExportList && view.layout === ViewLayoutType.Unit) {
            extraHeaderAccountMenuItems.push({
                name: 'Exportlista',
                props: {
                    onClick: () => {
                        navigate(clientEndpoints.export_list);
                        setRefresh(Date.now());
                    },
                },
            });
        }
    }

    const headerAccountMenu = [
        {
            name: 'Felhasználói kézikönyv',
            props: {
                onClick: openHandbookDialog,
            },
        },
        ...extraHeaderAccountMenuItems,
        {
            name: 'Jelszóváltoztatás',
            props: {
                onClick: () => setIsChangePasswordDialogOpen(true),
            },
        },
        {
            name: 'Kijelentkezés',
            props: {
                onClick: () => {
                    API.post(endpoints.logout, {}).finally(() => {
                        logout();
                        navigate(clientEndpoints.login);
                    });
                },
            },
        },
    ];

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <ExportStatusProvider>
            {hasAccessToExportList && <ExportChecker />}
            <BasicLayout
                key={refresh}
                logo={Logo}
                roles={sidebarMenu}
                headerAccountMenu={headerAccountMenu}
                headerAccountUsername={matches ? `${user?.lastName} ${user?.firstName}` : ' '}
                drawerType={matches ? 'permanent' : 'temporary'}
                defaultExpanded={matches}
                headerText={getHeaderText(view, layoutName, selectedOwner)}
            >
                <Outlet />
                <Notification />
                <HandbookDialog open={handbookDialogOpen} handleClose={closeHandbookDialog} />
                <ChangePasswordDialog
                    open={isChangePasswordDialogOpen}
                    onClose={() => setIsChangePasswordDialogOpen(false)}
                    onSuccess={() => setIsChangePasswordDialogOpen(false)}
                    onError={() => setIsChangePasswordDialogOpen(false)}
                />
            </BasicLayout>
            <AccessChecker />
        </ExportStatusProvider>
    );
}

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { FormikListPage, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionCellRenderer from '../../../components/ActionCellRenderer';
import MCCLoading from '../../../components/MCCLoading';
import addressGroupsService from '../../../services/Unit/addressGroups';
import { create } from '../../../store/notification';
import { filterFields } from './fields';
import { TableGroupAddressesButton } from './TableGroupAddressesButton';
import { useTheme } from '@mui/material/styles';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import { RootState } from '../../../store';
import { getOtherOwnerCondition, ViewUnitCampaignManagementType } from '../../../store/auth';
import Alert from '@mui/material/Alert';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { PROJECT_NAME } from '../../../utils/AppConst';
import { getOwnerId } from '../MessageFields';

const BtnCellRenderer = (
    params: GridRenderCellParams<string> & {
        ownOnly?: boolean;
        setLoading: (value: boolean) => void;
        otherOwnerCondition: boolean;
    },
): JSX.Element => {
    const { setLoading, ownOnly, otherOwnerCondition } = params;
    const dispatch = useDispatch();
    const { view } = useSelector((state: RootState) => state.auth);

    return (
        <Grid container spacing={1}>
            {([undefined, ViewUnitCampaignManagementType.Common].includes(view.unitCampaignManagement) || ownOnly) &&
                !otherOwnerCondition && (
                    <Grid item>
                        <TableEditButton
                            target={(!ownOnly
                                ? clientEndpoints.unit_address_groups_edit
                                : clientEndpoints.unit_own_address_groups_edit
                            ).replace(':id', params.id.toString())}
                        />
                    </Grid>
                )}
            {([undefined, ViewUnitCampaignManagementType.Common].includes(view.unitCampaignManagement) || ownOnly) &&
                !otherOwnerCondition && (
                    <Grid item>
                        <TableDeleteButton
                            deleteAction={() => {
                                setLoading(true); //TODO: ide sztem tableloading kellene
                                addressGroupsService
                                    .delete(params.id)
                                    .then(() => {
                                        dispatch(create({ type: 'success', message: 'Sikeres törlés' }));
                                    })
                                    .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen törlés' })))
                                    .finally(() => setLoading(false));
                            }}
                            confirmationMessage={'Biztosan törlöd a következő elemet:'}
                            confirmationElement={params.row.name}
                        />
                    </Grid>
                )}
            <TableGroupAddressesButton isOwn={ownOnly} groupId={params.id} />
        </Grid>
    );
};

export default function AddressGroups(props: { ownOnly?: boolean }) {
    const { ownOnly } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Csoportok',
        breadcrumbs: {
            addresses: 'Címek',
            [ownOnly ? clientEndpoints.unit_own_address_groups : clientEndpoints.unit_address_groups]: 'Csoportok',
        },
    };
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
    const { view, user, selectedOwner } = useSelector((state: RootState) => state.auth);
    const filter = {
        fields: filterFields,
    };
    const otherOwnerCondition = getOtherOwnerCondition();

    const columns: GridColDef[] = [
        { headerName: 'Név', field: 'name' },
        { headerName: 'Leírás', field: 'description' },
        { headerName: 'Leiratkozás tiltása eddig az időpontig', field: 'unsubscribeUNow' },
        { headerName: 'Létrehozó', field: 'author' },
        { headerName: 'Létrehozás', field: 'insertDate' },
        { headerName: 'Utolsó módosító', field: 'editor' },
        { headerName: 'Utolsó módosítás', field: 'updateDate' },
        {
            headerName: 'Műveletek',
            field: 'operations',
            sortable: false,
            renderCell: (params) => (
                <ActionCellRenderer>
                    <BtnCellRenderer
                        {...params}
                        ownOnly={ownOnly}
                        setLoading={setLoading}
                        otherOwnerCondition={otherOwnerCondition}
                    />
                </ActionCellRenderer>
            ),
            minWidth: matches ? 200 : 90,
        },
    ];

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <Grid container spacing={2}>
            {view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && !ownOnly && !otherOwnerCondition && (
                <Grid item xs={12}>
                    <Alert severity="info">
                        Mivel saját kampány módban használod a rendszert, a közös csoportokat csak olvasási módban tudod
                        megtekinteni.
                    </Alert>
                </Grid>
            )}
            {otherOwnerCondition && selectedOwner && (
                <Grid item xs={12}>
                    <Alert severity="info">
                        {`Mivel ${selectedOwner.label} saját kampányadatait látod, ezért a csoportokat csak olvasási módban tudod megtekinteni.`}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <FormikListPage
                    refresh={view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && ownOnly}
                    autoload
                    header={header}
                    service={{
                        filter: (search) =>
                            addressGroupsService.filter({
                                ...search,
                                ...{ ownerId: getOwnerId(view, ownOnly || false, user, selectedOwner) },
                            }),
                    }}
                    columns={columns.map((column) => ({
                        flex: 1,
                        minWidth: column.field === 'name' ? 300 : 150,
                        ...column,
                    }))}
                    defaultSort={{ name: 'asc' }}
                    newLabel={
                        ([undefined, ViewUnitCampaignManagementType.Common].includes(view.unitCampaignManagement) ||
                            ownOnly) &&
                        !otherOwnerCondition
                            ? 'Csoport hozzáadása'
                            : undefined
                    }
                    filter={filter}
                    responseKey="data"
                    tableProps={{
                        initialState: {
                            pinnedColumns: { right: ['operations'] },
                            sorting: {
                                sortModel: [
                                    {
                                        field: 'name',
                                        sort: 'asc',
                                    },
                                ],
                            },
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
}

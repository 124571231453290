import Grid from '@mui/material/Grid';
import { FormikListPage, Option } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { useState } from 'react';
import SplitButton from '../../../../components/Form/SplitButton';
import MCCLoading from '../../../../components/MCCLoading';
import statisticsAggregateService from '../../../../services/Unit/statistics/statisticsAggregateService';
import { IForm, initialInfo, PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import useCampaignOptions from '../hooks/useCampaignOptions';
import DataStatus from './DataStatus';
import { filterFields, tableColumns } from './fields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { AppViewType, AuthUser, ViewUnitCampaignManagementType } from '../../../../store/auth';

export function getOwnerIdStatistics(
    view: AppViewType,
    user?: AuthUser,
    selectedOwner?: Option<number, string>,
): number | null {
    if (view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && user && user.id) {
        return user.id;
    } else if (view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner && selectedOwner) {
        return selectedOwner?.value;
    }

    return null;
}

export default function StatisticsAggregate() {
    const { user, view, selectedOwner } = useSelector((state: RootState) => state.auth);
    const { loading, campaignOptions } = useCampaignOptions(
        statisticsAggregateService,
        getOwnerIdStatistics(view, user, selectedOwner),
    );
    const [info, setInfo] = useState<IForm>(initialInfo());

    const service = {
        filter: (form: IForm) => {
            setInfo(form);
            return statisticsAggregateService.filter(
                view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ||
                    view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner
                    ? { ...form, ...{ ownerId: getOwnerIdStatistics(view, user, selectedOwner) } }
                    : { ...form },
            );
        },
    };

    const exportContext = {
        initiateExport: statisticsAggregateService.initiateExport,
        checkExport: statisticsAggregateService.checkExport,
        downloadExport: statisticsAggregateService.downloadExport,
        filter: info,
    };

    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Összesített',
        breadcrumbs: {
            statistics: 'Statisztikák',
            [clientEndpoints.unit_statistics_aggregate]: 'Összesített',
        },
    };

    if (loading) return <MCCLoading />;

    return (
        <>
            <DataStatus />
            <FormikListPage
                autoload
                header={header}
                filter={{
                    fields: filterFields(campaignOptions),
                }}
                service={service}
                columns={tableColumns}
                responseKey="data"
                customButtons={
                    <Grid item>
                        <SplitButton placement="top" exportContext={exportContext} />
                    </Grid>
                }
            />
        </>
    );
}

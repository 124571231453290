import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserHistory } from 'history';
import { Suspense, useLayoutEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';
import './App.css';
import MCCLoading from './components/MCCLoading';
import Versions from './components/Versions';
import Routing from './routing';
import { history } from './store';
import { theme } from './utils/theme';

const Provider = HelmetProvider as unknown as React.FC;

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Suspense fallback={<MCCLoading />}>
                    <Provider>
                        <CustomRouter history={history}>{<Routing />}</CustomRouter>
                    </Provider>
                    <Versions />
                </Suspense>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

/**
 * https://stackoverflow.com/questions/69871987/react-router-v6-navigate-outside-of-components
 */
interface CustomRouterProps {
    history: BrowserHistory;
    children: React.ReactNode;
}
const CustomRouter = (props: CustomRouterProps) => {
    const { history } = props;
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};

export default App;

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import colors from '../../../styles/colors';
import { UnitRole } from '../../../utils/Roles';
import UserFormCore from '../../Central/Users/UserFormCore';
import { UnitUserFormProps } from './types';
import UnitRolesAccordion from './UnitRolesAccordion';

export default function Form(props: UnitUserFormProps) {
    const { values, setFieldValue, ownCampaignValidEmailDomains } = props;

    useEffect(() => {
        if (
            !ownCampaignValidEmailDomains.some((emailDomain) => (values.email || '').toString().endsWith(emailDomain))
        ) {
            Object.keys(values.roles).forEach((role) => {
                if (role === UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN && values.roles[role] !== '0') {
                    setFieldValue(`roles.${role}`, '0');
                }
            });
        }
    }, [values.email, values.roles, setFieldValue, ownCampaignValidEmailDomains]);

    return (
        <Grid container item xs={12} spacing={2}>
            <UserFormCore {...props} />
            <Grid item xs={12}>
                {props.errors.roles ? (
                    <>
                        <Typography variant="h6" style={{ marginTop: '8px' }}>
                            Jogosultságok:
                        </Typography>
                        <Typography style={{ marginTop: '8px', color: colors.error }}>{props.errors.roles}</Typography>
                    </>
                ) : (
                    <Typography variant="h6" style={{ marginTop: '8px' }}>
                        Jogosultságok:
                    </Typography>
                )}
            </Grid>
            <UnitRolesAccordion {...props} />
        </Grid>
    );
}

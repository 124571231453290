import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Accordion, CancelButton } from '@silinfo/front-end-template';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import {
    AuthUser,
    getUnitCampaignManagementSelectedType,
    ViewUnitCampaignManagementType,
} from '../../../../../store/auth';
import {
    onlyCommonCampaignCondition,
    onlyOwnCampaignCondition,
    userHasCommonCampaignRoleInUnit,
    userIsSuperAdmin,
} from '../../../../../utils/AccessManagementHelper';
import MessageFieldsDialogTable from '../MessageFieldsDialogTable';
import useFilterFlaggedMessageFields from '../useFilterFlaggedMessageFields';
import { IMessageFieldEditor } from './types';

interface MessageDialogProps {
    open: boolean;
    handleClose: () => void;
    editor: IMessageFieldEditor | null;
}

export const showCommonMessageFieldsCondition = (user: AuthUser) =>
    onlyCommonCampaignCondition() || userHasCommonCampaignRoleInUnit(user) || userIsSuperAdmin(user);

export const showOwnMessageFieldsCondition = (user: AuthUser) =>
    (onlyOwnCampaignCondition(user) || userHasCommonCampaignRoleInUnit(user) || userIsSuperAdmin(user)) &&
    getUnitCampaignManagementSelectedType() === ViewUnitCampaignManagementType.Own;

export default function MessageFieldsDialog(props: MessageDialogProps) {
    const { open, handleClose, editor } = props;
    const { commonMessageFields, ownMessageFields } = useSelector((state: RootState) => state.messageTemplates);
    const { user } = useSelector((state: RootState) => state.auth);
    const flaggedMessageFields = useFilterFlaggedMessageFields();

    const handleClick = useCallback(
        (messageId: string) => {
            if (editor) {
                editor.execute('insertText', {
                    text: messageId,
                });
            }
        },
        [editor],
    );
    if (!user) return null;

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Üzenetmező beszúrása</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12} style={{ marginTop: '8px' }}>
                        <Accordion title={`Rendszerszintű üzenetmezők`} defaultExpanded={false}>
                            <MessageFieldsDialogTable
                                handleClick={handleClick}
                                handleClose={handleClose}
                                messageFields={flaggedMessageFields}
                            />
                        </Accordion>
                    </Grid>
                    {showCommonMessageFieldsCondition(user) && (
                        <Grid item xs={12} style={{ marginTop: '8px' }}>
                            <Accordion title={`Közös üzenetmezők`} defaultExpanded={onlyCommonCampaignCondition()}>
                                <MessageFieldsDialogTable
                                    handleClick={handleClick}
                                    handleClose={handleClose}
                                    messageFields={commonMessageFields}
                                />
                            </Accordion>
                        </Grid>
                    )}
                    {showOwnMessageFieldsCondition(user) && (
                        <Grid item xs={12}>
                            <Accordion title={`Saját üzenetmezők`} defaultExpanded={true}>
                                <MessageFieldsDialogTable
                                    handleClick={handleClick}
                                    handleClose={handleClose}
                                    messageFields={ownMessageFields}
                                />
                            </Accordion>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container xs={12} spacing={1} justifyContent={'flex-end'}>
                    <Grid item>
                        <CancelButton type="button" onClick={handleClose}>
                            Bezár
                        </CancelButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

// Admin menüpontok:
import MainPage from './MainPage';
import CentralUsers from './Central/Users';
import SuperAdmin from './Central/SuperAdmin';
import SystemMessages from './Central/SystemMessages';
import Units from './Central/Units';
import CentralEmailLog from './Central/CentralEmailLog';
import SystemLogs from './Central/SystemLogs';
import SystemSettings from './Central/SystemSettings';

export const adminMenus = [
    {
        path: '',
        element: <MainPage type="admin" />,
    },

    CentralUsers,
    SuperAdmin,
    SystemMessages,
    Units,
    CentralEmailLog,
    SystemLogs,
    SystemSettings,
];

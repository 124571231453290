import { VersionModal } from '@silinfo/front-end-template';
import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import packageJson from '../../../package.json';
import { endpoints } from '../../utils/endPoints';

const initVersions = [
    {
        name: 'Frontend',
        version: packageJson.version,
        versionDate: process.env.REACT_APP_CODE_VERSION_DATE,
    },
    {
        name: 'Backend',
        version: 'Betöltés...',
        versionDate: '',
    },
];

export default function Versions() {
    const [versions, setVersions] = useState<{ name: string; version: string; versionDate?: string }[]>(initVersions);

    const setBackendVersion = (value: string, date?: string) =>
        setVersions((prev) =>
            prev.map((version) =>
                version.name === 'Backend' ? { ...version, version: value, versionDate: date } : version,
            ),
        );

    const handleOpen = () => {
        axios
            .get(endpoints.backendVersion)
            .then((response: AxiosResponse<{ version: string; versionDate?: string }>) =>
                setBackendVersion(response.data.version, response.data.versionDate),
            )
            .catch(() => setBackendVersion('-'));
    };

    return (
        <VersionModal
            versions={(versions ?? []).map((v) => {
                return {
                    'name': v.name,
                    'version': v.version + (v.versionDate ? ' (' + v.versionDate + ')' : ''),
                };
            })}
            handleOpen={handleOpen}
        />
    );
}

import instance from '../../api';
import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import { IForm } from '../../utils/AppConst';

const formsService = {
    ...crudTemplate(endpoints.unit.template.forms.main),
    duplicate: (id: string | number) => instance.post(endpoints.unit.template.forms.duplicate(id), {}),
    cache: (form: IForm) => instance.post(endpoints.unit.template.forms.cache, form),
    get2: (id: string | number, readonly: boolean | undefined) =>
        instance.get(endpoints.unit.template.forms.main + '/' + id + (readonly ? '?readonly=1' : '')),
};

export default formsService;

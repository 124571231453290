import DoneAllIcon from '@mui/icons-material/DoneAll';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { GridSelectionModel } from '@mui/x-data-grid';
import { Dispatch, SetStateAction, useState } from 'react';
import campaignsService from '../../../../services/campaigns/campaigns';
import AddressFieldDialogButton from './../../Addresses/AddressList/AddressFieldDialogButton';
import { transferFilterToFilterData, transferFilterToOrder } from './utils';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { IForm } from '../../../../utils/AppConst';

const Header = ({
    checkedCount,
    setCheckedCount,
    setSelected,
    addresses,
    isChecked,
    filterForm,
    sessionId,
    isOwn,
    showAddressType,
    setIsChecked,
}: {
    checkedCount: number;
    setCheckedCount: Dispatch<SetStateAction<number>>;
    setSelected: Dispatch<SetStateAction<GridSelectionModel>>;
    addresses: { id: number }[];
    isChecked: boolean;
    setIsChecked: Dispatch<SetStateAction<boolean>>;
    filterForm: IForm;
    sessionId: string;
    isOwn: boolean;
    showAddressType: string[];
}) => {
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Grid item container xs={12} spacing={2} style={{ padding: '4px' }}>
            <Grid item xs={6} display="inline-flex">
                <Tooltip title="Összes szűrt elem kijelölése">
                    <Checkbox
                        color="primary"
                        icon={loading ? <CircularProgress size="24px" /> : <DoneAllIcon />}
                        checkedIcon={loading ? <CircularProgress size="24px" /> : <DoneAllIcon />}
                        checked={isChecked}
                        onChange={(_, checked) => {
                            setLoading(true);
                            campaignsService
                                .saveManyFormAddressList({
                                    session: sessionId,
                                    page: 'null',
                                    checked,
                                    filterData: transferFilterToFilterData(filterForm),
                                    order: transferFilterToOrder(filterForm),
                                    isOwn: isOwn,
                                    showAddressType: showAddressType || (isOwn ? ['onlyOwn'] : ['onlyCommon']),
                                })
                                .then((res) => {
                                    setSelected(checked ? addresses.map((address) => address.id) : []);
                                    setCheckedCount(res.data.checked);
                                    setIsChecked(!!res.data.allChecked);
                                })
                                .finally(() => setLoading(false));
                        }}
                    />
                </Tooltip>
                <Typography sx={{ py: 1, pl: 1 }}>{checkedCount} kijelölt címzett</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
                <AddressFieldDialogButton />
            </Grid>
        </Grid>
    );
};

export default Header;

import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import api from '../../api';
import { AxiosResponse } from 'axios';

export type ChangeStatusType = 'reply' | 'sender';

export interface IEmailSign {
    id?: number;
    name: string;
    content: string;
}

export interface IEmailsFormType {
    address: string;
    isSender: boolean;
    isReply: boolean;
    name: string;
    // attached_users: number[];
    // signs?: {
    //     name: string;
    // }[];
    signs: IEmailSign[];
}

export type EmailView = {
    content: string;
};

const emailsService = {
    ...crudTemplate(endpoints.unit.administration.emails.main),
    changeStatus: (id: string, type: ChangeStatusType) =>
        api.post(endpoints.unit.administration.emails.changeStatus.replace('{id}', id).replace('{type}', type), {}),
    userSearch: (term: string) => api.get(endpoints.unit.administration.emails.userSearch(term)),
    view: (unitId: number, token: string): Promise<AxiosResponse<EmailView>> =>
        api.get(endpoints.messageView.replace('{token}', token).replace('{unitId}', unitId.toString())),
};

export default emailsService;

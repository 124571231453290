import { Input } from '@silinfo/front-end-template';

export const filterFields: Input[] = [
    {
        name: 'name',
        label: 'Név',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'description',
        label: 'Leírás',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Leiratkozás tiltása eddig az időpontig (-tól)',
        name: 'unsubscribeUntilNowFrom',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Leiratkozás tiltása eddig az időpontig  (-ig)',
        name: 'unsubscribeUntilNowTo',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'author',
        label: 'Létrehozó',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Létrehozás dátuma (-tól)',
        name: 'insertDateFrom',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Létrehozás dátuma (-ig)',
        name: 'insertDateTo',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'editor',
        label: 'Utolsó módosító',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Utolsó módosítás dátuma (-tól)',
        name: 'updateDateFrom',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Utolsó módosítás dátuma (-ig)',
        name: 'updateDateTo',
        format: { xs: 12, md: 6, lg: 4 },
    },
];

export const formFields: Input[] = [
    {
        name: 'name',
        label: 'Név',
        format: {
            xs: 12,
        },
    },
    {
        name: 'description',
        label: 'Leírás',
        format: {
            xs: 12,
        },
        props: {
            multiline: true,
            rows: 4,
        },
    },
    {
        name: 'prohibitionOfUnsubscription',
        label: 'Leiratkozás tiltása a csoportban lévő címzetteknél',
        format: {
            xs: 'auto',
        },
        type: 'checkbox',
    },
    {
        name: 'unsubscribeUntilNow',
        label: 'Leiratkozás tiltása eddig az időpontig',
        format: {
            xs: 12,
        },
        type: 'datetime-local',
    },
];

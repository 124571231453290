import { CancelButton, SubmitButton } from '@silinfo/front-end-template';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

interface ButtonsProps {
    buttonLoading: boolean;
    cancel: string;
}

export default function Buttons(props: ButtonsProps) {
    const navigate = useNavigate();
    const { buttonLoading } = props;

    return (
        <Grid item container xs={12} spacing={1}>
            <Grid item xs={6} md={2}>
                <SubmitButton type="submit" fullWidth loading={buttonLoading}>
                    Mentés
                </SubmitButton>
            </Grid>
            <Grid item xs={6} md={2}>
                <CancelButton type="button" fullWidth onClick={() => navigate(props.cancel)}>
                    Mégsem
                </CancelButton>
            </Grid>
        </Grid>
    );
}

import { Accordion, Page } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import addressListService from '../../../../../services/Unit/addressListService';
import { PROJECT_NAME } from '../../../../../utils/AppConst';
import { clientEndpoints } from '../../../../../utils/clientEndpoints';
import ImportForm from './Form';
import ImportList from './ImportList';
import ImportTooltip from './ImportTooltip';
import { IProgressTimeline } from './ProgressTimeline';
import ProgressView from './ProgressView';
import { importStatuses as importInitStatuses } from './importStatuses';
import { getUpdateImportStatuses, hasError, isImportFinished } from './utils';
import { checkPageAccess } from '../../../../../utils/AccessManagementHelper';

export default function Import(props: { ownOnly?: boolean }) {
    const { ownOnly } = props;
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isImportRunning, setIsImportRunning] = useState<boolean>(false);
    const [importStatuses, setImportStatus] = useState<IProgressTimeline[]>(importInitStatuses);
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Import',
        breadcrumbs: {
            addresses: 'Címek',
            [clientEndpoints[`unit${ownOnly ? '_own' : ''}_address_list`]]: 'Címlista',
            [clientEndpoints[`unit${ownOnly ? '_own' : ''}_address_list_import`]]: 'Import',
        },
    };

    const reset = useCallback(() => {
        setIsSubmitted(false);
        setIsImportRunning(false);
        setImportStatus(importInitStatuses);
    }, []);

    useEffect(() => {
        return reset;
    }, [reset]);

    const onUploadProgress = (progressEvent: { loaded: number; total: number }): void => {
        setImportStatus((prev) => [
            { ...prev[0], progress: Math.round((progressEvent.loaded / progressEvent.total) * 100) },
            ...prev.slice(1),
        ]);
    };

    const onAfterSubmit = (response: AxiosResponse | AxiosError): void => {
        if (response instanceof AxiosError) {
            if (response.response?.status === 422) {
                setImportStatus((prev) => [{ ...prev[0], errorMessage: 'Hiba a feltöltés során' }, ...prev.slice(1)]);
            }
        } else {
            fetchStatus(response.data.id);
        }
    };

    const onBeforeSubmit = (): void => {
        setIsSubmitted(true);
        setIsImportRunning(true);
    };

    const fetchStatus = async (importId: number) => {
        const response: AxiosResponse = await addressListService.getImportStatus(importId);

        updateImportStatuses(response, importId);

        if (
            isImportFinished(importStatuses) ||
            response.data.status.includes('error') ||
            response.data.status === 'success'
        ) {
            setIsImportRunning(false);

            return;
        }

        setTimeout(() => {
            fetchStatus(importId);
        }, 1000);
    };

    const updateImportStatuses = (response: AxiosResponse, importId: number) => {
        const updatedImportStatuses = getUpdateImportStatuses(importStatuses, response, importId, (res, importId) =>
            hasError(res, importId, importStatuses),
        );
        setImportStatus(updatedImportStatuses);
    };

    checkPageAccess(ownOnly || false, false);

    return (
        <Page header={header}>
            <Accordion
                title={
                    <>
                        {header.title}
                        <ImportTooltip />
                    </>
                }
                defaultExpanded
                disableClosing
            >
                {isSubmitted ? (
                    <ProgressView
                        importStatuses={importStatuses}
                        ownOnly={ownOnly}
                        isImportFinished={() => isImportFinished(importStatuses)}
                        reset={reset}
                    />
                ) : (
                    <ImportForm
                        ownOnly={ownOnly}
                        onBeforeSubmit={onBeforeSubmit}
                        onUploadProgress={onUploadProgress}
                        onAfterSubmit={onAfterSubmit}
                    />
                )}
            </Accordion>
            {!isImportRunning && <ImportList />}
        </Page>
    );
}

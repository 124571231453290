import TimelineItem from '@mui/lab/TimelineItem';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import Typography from '@mui/material/Typography';

export type IProgressTimelineItem = {
    name: string;
    description: string | React.ReactNode;
    icon: React.ReactNode;
    color: 'success' | 'info' | 'warning' | 'error' | 'inherit' | 'primary' | 'secondary' | 'grey' | undefined;
    progress: number;
};

export default function ProgressTimelineItem(props: IProgressTimelineItem) {
    return (
        <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                {props.progress}%
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color={props.color}>{props.icon}</TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {props.name}
                </Typography>
                <Typography color="text.secondary">{props.description}</Typography>
            </TimelineContent>
        </TimelineItem>
    );
}

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import ProgressTimeline, { IProgressTimeline } from './ProgressTimeline';
import { CancelButton, Link } from '@silinfo/front-end-template';
import { clientEndpoints } from '../../../../../utils/clientEndpoints';

export default function ProgressView(props: {
    isImportFinished: () => boolean;
    importStatuses: IProgressTimeline[];
    ownOnly?: boolean;
    reset: () => void;
}) {
    const { importStatuses, ownOnly, isImportFinished, reset } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ProgressTimeline items={importStatuses} />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {isImportFinished() && (
                        <Grid item>
                            <LoadingButton variant="contained" onClick={reset}>
                                Új import készítése
                            </LoadingButton>
                        </Grid>
                    )}
                    <Grid item>
                        <Link to={ownOnly ? clientEndpoints.unit_own_address_list : clientEndpoints.unit_address_list}>
                            <CancelButton>Vissza</CancelButton>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

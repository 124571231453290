import ContentCopy from '@mui/icons-material/ContentCopy';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableButton } from '@silinfo/front-end-template';
import { MessageTemplatesMessageFields } from './BaseFormPage';
import { IMessageTemplatesFormType } from './types';
import Typography from '@mui/material/Typography';

export type MessageFieldsDialogTableProps = {
    messageFields: MessageTemplatesMessageFields[];
    handleClose: () => void;
} & (
    | {
          setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void;
          fieldName: string;
          values: IMessageTemplatesFormType;
          handleClick?: never;
      }
    | {
          handleClick: (messageId: string) => void;
          setFieldValue?: never;
          fieldName?: never;
          values?: never;
      }
);

export default function MessageFieldsDialogTable({
    messageFields,
    setFieldValue,
    fieldName,
    values,
    handleClose,
    handleClick,
}: MessageFieldsDialogTableProps) {
    if (messageFields.length === 0)
        return (
            <Typography>
                <i>Nincs megjeleníthető üzenetmező.</i>
            </Typography>
        );

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Kulcs</TableCell>
                    <TableCell>Leírás</TableCell>
                    <TableCell align="center">Kiválasztás</TableCell>
                </TableRow>
            </TableHead>
            <TableBody component="tbody">
                {messageFields.map((d) => {
                    return (
                        <TableRow key={d.messageId}>
                            <TableCell>{d.messageId}</TableCell>
                            <TableCell>{d.description}</TableCell>
                            <TableCell>
                                <Grid item xs={12} container justifyContent="center">
                                    <TableButton
                                        onClick={() => {
                                            handleClick && handleClick(d.messageId);
                                            if (!handleClick) {
                                                setFieldValue(
                                                    fieldName,
                                                    values[fieldName as keyof IMessageTemplatesFormType] + d.messageId,
                                                );
                                            }
                                            handleClose();
                                        }}
                                    >
                                        <ContentCopy />
                                    </TableButton>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

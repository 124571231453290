import Info from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { Accordion, Checkbox, FieldGenerator, Option } from '@silinfo/front-end-template';
import { UnitRole } from '../../../utils/Roles';
import CustomDisabledCheckbox from './CustomDisabledCheckbox';
import RolesTitle from './RolesTitle';
import { UserFormProps } from './types';

export interface CheckboxProps {
    unit: Option<number, string>;
    roleItem: Option<string, string>;
    props: UserFormProps;
    disabled?: boolean;
}

function shouldHideRole(
    roleItem: Option<string, string>,
    props: UserFormProps,
    unit: Option<number, string>,
    ownCampaignValidEmailDomains: string[],
): boolean {
    return (
        ((roleItem.value === UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN ||
            roleItem.value === UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS) &&
            !ownCampaignValidEmailDomains.some((emailDomain) => props.values.email.endsWith(emailDomain))) ||
        (roleItem.value === UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS &&
            props.values.roles?.[unit.value]?.[UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN] != '1')
    );
}

function DisabledCheckbox({ unit, roleItem, props }: CheckboxProps) {
    return (
        <Grid container item xs={12} key={`${unit.label}-${roleItem.label}--outer`}>
            <CustomDisabledCheckbox unit={unit} role={roleItem} {...props} />
        </Grid>
    );
}

export function SpecialTooltipLabel({ label }: { label: string }) {
    return (
        <>
            {label}
            <Tooltip
                style={{ marginLeft: '8px' }}
                title={'Címlista, csoportok, üzenetmezők, üzenetsablonok, űrlapsablonok'}
            >
                <Info />
            </Tooltip>
        </>
    );
}

function NormalCheckbox({ unit, roleItem, props, disabled }: CheckboxProps) {
    const isOtherAllowUseCommonCampaigns = roleItem.value === UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS;

    return (
        <Grid container item xs={12} key={`${unit.label}-${roleItem.label}--outer`}>
            {FieldGenerator({
                ...props,
                label: roleItem.label,
                checkboxLabel: isOtherAllowUseCommonCampaigns ? (
                    <SpecialTooltipLabel label={roleItem.label} />
                ) : (
                    roleItem.label
                ),
                name: `roles.${unit.value}.${roleItem.value}`,
                type: 'checkbox',
                format: { xs: 12 },
                props: {
                    checked: props.values.roles?.[unit.value]?.[roleItem.value] == '1',
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        props.setFieldValue(`roles.${unit.value}.${roleItem.value}`, e.target.checked ? '1' : '0');
                    },
                    disabled: disabled,
                },
            })}
        </Grid>
    );
}

export default function UnitRoles(props: UserFormProps) {
    const { units, roleOptions, rolesView, ownCampaignValidEmailDomains } = props;
    const unitRoles = roleOptions.unit;
    const filteredUnits = rolesView ? units.filter((unit) => !!props.values.roles[unit.value]) : units;

    return (
        <>
            {filteredUnits.map((unit) => {
                return (
                    <Grid item xs={12} key={unit.value}>
                        <Accordion title={unit.label} defaultExpanded={unit.label === 'archive'}>
                            <Grid container item xs={12} spacing={2} direction="row">
                                {unitRoles.map((role) => {
                                    return (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={rolesView ? 12 : 6}
                                            alignSelf="flex-start"
                                            key={`${unit.label}-${role.label}--outer`}
                                        >
                                            <RolesTitle
                                                unit={unit}
                                                role={role}
                                                rolesView={rolesView}
                                                ownCampaignValidEmailDomains={ownCampaignValidEmailDomains}
                                            />
                                            {role.value.map((roleItem) => {
                                                if (
                                                    shouldHideRole(
                                                        roleItem,
                                                        props,
                                                        unit,
                                                        ownCampaignValidEmailDomains,
                                                    ) &&
                                                    !rolesView
                                                ) {
                                                    if (props.values.roles?.[unit.value]?.[roleItem.value] == '1') {
                                                        props.setFieldValue(
                                                            `roles.${unit.value}.${roleItem.value}`,
                                                            '0',
                                                        );
                                                    }
                                                    return (
                                                        <Checkbox
                                                            key={`${unit.label}-${role.label}-${roleItem.label}-checkbox`}
                                                            checkbox={{
                                                                disabled: true,
                                                            }}
                                                            label={
                                                                roleItem.value ===
                                                                UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS ? (
                                                                    <SpecialTooltipLabel label={roleItem.label} />
                                                                ) : (
                                                                    roleItem.label
                                                                )
                                                            }
                                                        />
                                                    );
                                                }

                                                return rolesView ? (
                                                    <DisabledCheckbox
                                                        key={`${unit.label}-${role.label}-${roleItem.label}-checkbox`}
                                                        unit={unit}
                                                        roleItem={roleItem}
                                                        props={props}
                                                    />
                                                ) : (
                                                    <NormalCheckbox
                                                        key={`${unit.label}-${role.label}-${roleItem.label}-checkbox`}
                                                        unit={unit}
                                                        roleItem={roleItem}
                                                        props={props}
                                                    />
                                                );
                                            })}
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Accordion>
                    </Grid>
                );
            })}
        </>
    );
}

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import ActionCellContext from './Context';

const ActionCellRenderer = ({ children }: { children: React.ReactNode }) => {
    const matches = useMediaQuery('(min-width:900px)', { noSsr: true });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    return (
        <Grid item container xs={12} justifyContent="center">
            {matches ? (
                children
            ) : (
                <>
                    <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                        <ActionCellContext.Provider value={setAnchorEl}>
                            <div style={{ padding: '8px' }}>{children}</div>
                        </ActionCellContext.Provider>
                    </Menu>
                </>
            )}
        </Grid>
    );
};

export default ActionCellRenderer;

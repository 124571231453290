import { useFetch } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import instance from '../../api';
import { useExportStatus } from '../../providers/ExportStatusProvider';
import { create } from '../../store/notification';
import { endpoints } from '../../utils/endPoints';

interface ExportStatus {
    id: number;
    processedRows: number;
    rowCount: number;
    type: string;
}

const handleDownload = async (fileName: string, type: string, id: string | number) => {
    try {
        const response = await instance.get(
            endpoints.unit.exportList.download.replace('{type}', type).replace('{id}', '' + id),
            { responseType: 'blob' },
        );

        const contentType = response.headers['content-type'];

        const blob = new Blob([response.data], { type: contentType });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};

function useExportCheck() {
    const dispatch = useDispatch();
    const { setExportStatus } = useExportStatus();

    const handleExportCheck = useCallback(
        (type: string, id: string | number) => {
            setExportStatus((prev) => [...prev, { id: +id, processedRows: 0, rowCount: 0 }]);
            let ready = false;
            let isError = false;
            const checker = () =>
                setTimeout(() => {
                    instance
                        .get(endpoints.unit.exportList.check.replace('{type}', type).replace('{id}', '' + id))
                        .then((d: AxiosResponse) => {
                            const status = d.data.status;
                            ready = status === 'finished' || status === 'unknown_error';
                            isError = status !== 'finished';
                            setExportStatus((prev) => {
                                const index = prev.findIndex((e) => e.id === id);
                                if (index !== -1) {
                                    prev[index].processedRows = d.data.info.processedRows;
                                    prev[index].rowCount = d.data.info.rowCount;
                                }
                                return [...prev];
                            });
                            if (ready) {
                                setExportStatus((prev) => prev.filter((e) => e.id !== id));
                                instance
                                    .get(
                                        endpoints.unit.exportList.download
                                            .replace('{type}', type)
                                            .replace('{id}', '' + id),
                                    )
                                    .then((response: AxiosResponse) => {
                                        if (isError) {
                                            throw new Error();
                                        }
                                        const fileName =
                                            response.headers['content-disposition']
                                                ?.split(';')[1]
                                                ?.split('=')[1]
                                                ?.split('.')[0] || 'export';
                                        handleDownload(fileName, type, id);

                                        dispatch(
                                            create({
                                                type: 'success',
                                                message: `A fájl sikeresen előállítva!`,
                                            }),
                                        );
                                    });
                            } else {
                                checker();
                            }
                        })
                        .catch(() => {
                            dispatch(
                                create({
                                    type: 'error',
                                    message: `Hiba történt a fájl előállítása során`,
                                }),
                            );
                        });
                }, 2000);

            checker();
        },
        [dispatch, setExportStatus],
    );

    return handleExportCheck;
}

export default function ExportChecker() {
    const { data, loading } = useFetch<ExportStatus[]>(endpoints.unit.exportList.inProgress);
    const handleExportCheck = useExportCheck();

    useEffect(() => {
        if (!loading && data) {
            data.forEach((d) => {
                handleExportCheck(d.type, d.id);
            });
        }
    }, [data, loading, handleExportCheck]);

    return null;
}

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { GridAlignment, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import ActionCellRenderer from '../../../components/ActionCellRenderer';
import UserNameCellRenderer from '../../../components/UserNameCellRenderer';
import messageFieldsService from '../../../services/address/messageFields';
import { create } from '../../../store/notification';
import { VisibleCellRenderer } from './CellRenderers';
import { IConfirmationDialog } from './ConfirmationDialog';
import { TableVisibleBtn } from './TableVisibleBtn';

const useStyles = makeStyles(() => ({
    button: {
        cursor: 'pointer',
    },
}));

const SerialCellRenderer = (params: GridRenderCellParams<string> & { refresh: () => void; isNotOwnOnly: boolean }) => {
    const { refresh, isNotOwnOnly } = params;
    const classes = useStyles();
    const dispatch = useDispatch();
    return (
        <>
            {params.value}
            {isNotOwnOnly && params?.value != '1' && (
                <ArrowDropUp
                    onClick={() => {
                        messageFieldsService
                            .decrementSerial(+params.id)
                            .then(() => dispatch(create({ type: 'success', message: 'Elem mozgatása sikerült.' })))
                            .catch(() =>
                                dispatch(
                                    create({ type: 'error', message: 'Hiba lépett fel az elem mozgatása közben.' }),
                                ),
                            )
                            .finally(refresh);
                    }}
                    className={classes.button}
                />
            )}
            {
                //params.value !== data.length && ( //FIXME: ez volt itt
                isNotOwnOnly && params?.value != '0' && parseInt(params?.value ?? '1') < params.api.getRowsCount() && (
                    <ArrowDropDown
                        onClick={() => {
                            messageFieldsService
                                .incrementSerial(+params.id)
                                .then(() => dispatch(create({ type: 'success', message: 'Elem mozgatása sikerült.' })))
                                .catch(() =>
                                    dispatch(
                                        create({
                                            type: 'error',
                                            message: 'Hiba lépett fel az elem mozgatása közben.',
                                        }),
                                    ),
                                )
                                .finally(refresh);
                        }}
                        className={classes.button}
                    />
                )
            }
        </>
    );
};

const DeleteButton = (
    params: GridRenderCellParams & {
        setState: React.Dispatch<React.SetStateAction<IConfirmationDialog>>;
        refresh: () => void;
    },
) => {
    const dispatch = useDispatch();
    return (
        <TableDeleteButton
            deleteAction={() => {
                messageFieldsService
                    .delete(params.id)
                    .then(() => {
                        dispatch(
                            create({
                                type: 'success',
                                message: 'Az üzenetmező sikeresen törlésre került!',
                            }),
                        );
                        params.refresh();
                    })
                    .catch((e) => {
                        let msg = 'Az üzenetmező törlése sikertelen volt. Hibaüzenet: ';
                        if (e.response?.status === 422) {
                            msg += e.response?.data?.violations[0]?.message;
                        } else {
                            msg += e.message;
                        }
                        dispatch(create({ type: 'error', message: msg }));
                    });
            }}
            confirmationElement={params.row.name}
            confirmationMessage={
                'Figyelem, az üzenetmezőhöz tartozó címzettadatok is véglegesen törlésre kerülnek.\n' +
                'Biztosan törölni szeretnéd az üzenetmezőt?'
            }
            disabled={false}
            tooltip={
                !params.row.canDelete
                    ? 'Az üzenetmezőhöz van várakozó kampánykiküldés, így nem törölhető'
                    : params.row.flag === null
                    ? 'Törlés'
                    : 'Az üzenetmező nem törölhető, mert a rendszer alapját képező mező'
            }
        />
    );
};

const BtnCellRenderer = (
    params: GridRenderCellParams & {
        setState: React.Dispatch<React.SetStateAction<IConfirmationDialog>>;
        refresh: () => void;
    },
) => {
    return (
        <Grid container spacing={1} justifyContent="flex-start">
            <Grid item>
                <TableEditButton size="small" target={params.id + '/edit'} />
            </Grid>

            <>
                {params.row.canDelete && params.row.flag === null && (
                    <Grid item>
                        <DeleteButton {...params} />
                    </Grid>
                )}

                {params.row.flag != null ? <></> : <TableVisibleBtn {...params} />}
            </>
        </Grid>
    );
};

export const tableColumns = (
    setState: React.Dispatch<React.SetStateAction<IConfirmationDialog>>,
    refresh: () => void,
    isNotOwnOnly: boolean,
    matches?: boolean,
): GridColDef[] =>
    [
        {
            headerName: '#',
            field: 'serial',
            renderCell: (params: GridRenderCellParams) => (
                <SerialCellRenderer isNotOwnOnly={isNotOwnOnly} refresh={refresh} {...params} />
            ),
            minWidth: 70,
        },
        { headerName: 'Azonosító', field: 'messageId', minWidth: 200 },
        { headerName: 'Oszlopnév', field: 'columnName', minWidth: 200 },
        { headerName: 'Leírás', field: 'description' },
        {
            headerName: 'Látható',
            field: 'visible',
            renderCell: VisibleCellRenderer,
            align: 'center' as GridAlignment,
            sortable: false,
            minWidth: 75,
        },
        {
            headerName: 'Utolsó kapcsolódó kampány',
            field: 'lastRelatedCampaign',
            minWidth: 120,
        },
        {
            headerName: 'Utolsó használat időpontja',
            field: 'lastRelatedCampaignSubmitDate',
            minWidth: 120,
            valueGetter: (params: GridRenderCellParams) =>
                params.value ? new Date(params.value).toLocaleString() : '-',
        },
        {
            headerName: 'Létrehozó',
            field: 'author',
            minWidth: 300,
            renderCell: (params: GridRenderCellParams) => (
                <UserNameCellRenderer value={params.value} check={params.row.authorDeleted} />
            ),
        },
        {
            headerName: 'Létrehozás dátuma',
            field: 'insertDate',
            valueGetter: (params: GridRenderCellParams) =>
                params.value ? new Date(params.value).toLocaleString() : '-',
            minWidth: 200,
        },
        {
            headerName: 'Utolsó módosító',
            field: 'editor',
            minWidth: 300,
            renderCell: (params: GridRenderCellParams) => (
                <UserNameCellRenderer value={params.value} check={params.row.editorDeleted} />
            ),
        },
        {
            headerName: 'Utolsó módosítás',
            field: 'updateDate',
            valueGetter: (params: GridRenderCellParams) =>
                params.value ? new Date(params.value).toLocaleString() : '-',
            minWidth: 200,
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams) => (
                <ActionCellRenderer>
                    <BtnCellRenderer setState={setState} refresh={refresh} {...params} />
                </ActionCellRenderer>
            ),
            sortable: false,
            minWidth: matches ? 150 : 90,
        },
    ]
        .filter((col) => col.field !== 'operations' || isNotOwnOnly)
        .map((col) => ({ flex: 1, minWidth: 150, ...col }));

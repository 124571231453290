import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ISystemLog } from './types';
import Typography from '@mui/material/Typography';

interface SystemLogTableProps {
    id: string;
    systemLog: ISystemLog;
}

const translations: Record<string, string> = {
    true: 'Igaz',
    false: 'Hamis',
};

const valueGetter = (item: unknown): React.ReactNode =>
    item === null ? <i>üres</i> : translations['' + item] || '' + item;

export default function SystemLogTable({ id, systemLog }: SystemLogTableProps) {
    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={3}>
                            <Typography variant="h6">
                                <strong>Alapadatok</strong>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <strong>Kulcs</strong>
                        </TableCell>
                        <TableCell colSpan={2}>
                            <strong>Érték</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Rendszernapló ID</TableCell>
                        <TableCell>{id}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Esemény</TableCell>
                        <TableCell colSpan={3}>{systemLog.actionTrans}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Típus</TableCell>
                        <TableCell colSpan={2}>{systemLog.typeTrans}</TableCell>
                    </TableRow>
                    <TableRow style={{ borderBottom: '5px solid #ddd' }}>
                        <TableCell>Felhasználó</TableCell>
                        <TableCell colSpan={2}>{systemLog.actor}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={5}>
                            <Typography variant="h6">
                                <strong>Változások</strong>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="20%">
                            <strong>Kulcs</strong>
                        </TableCell>
                        <TableCell width="40%">
                            <strong>Régi érték</strong>
                        </TableCell>
                        <TableCell width="40%">
                            <strong>Új érték</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {systemLog.info.map((item) => (
                        <TableRow key={item.field}>
                            <TableCell width="20%">{item.field}</TableCell>
                            <TableCell width="40%">{valueGetter(item.old)}</TableCell>
                            <TableCell width="40%">{valueGetter(item.new)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

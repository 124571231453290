import { Alert } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FieldGenerator, TextField } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { AxiosError } from 'axios';
import { FormikProps, useFormikContext } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IViolations } from '../../../../services/ResponseInterfaces';
import campaignsService, {
    CampaignSubmitType,
    ICampaignSenderSign,
    NewCampaignMessageFieldsType,
} from '../../../../services/campaigns/campaigns';
import { RootState } from '../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';
import { create } from '../../../../store/notification';
import { transformApiErrors } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { endpoints } from '../../../../utils/endPoints';
import ApiAutocomplete from './ApiAutocomplete';
import SendDataFormFooter from './SendDataFormFooter';
import TestCampaignDialog from './TestCampaignDialog';
import { NewCampaignFormType } from './types';
import { initialForm } from './utils';
import instance from '../../../../api';

const campaignSubmitTypes: Option[] = [
    {
        label: 'Azonnal',
        value: CampaignSubmitType.Instant,
    },
    {
        label: 'Ütemezetten',
        value: CampaignSubmitType.Scheduled,
    },
];

type SendDataFormProps = FormikProps<NewCampaignFormType> & {
    loading: boolean;
    setIsIntentionalLeave: (value: boolean) => void;
    handleAutomaticSaveClick: (unlocked?: boolean, automatic?: boolean, manual?: boolean) => Promise<unknown>;
    setFormLoading: (value: boolean) => void;
};

const ReplyField = () => {
    const { view, user } = useSelector((state: RootState) => state.auth);
    const isOwn = view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;
    const { setFieldValue, ...props } = useFormikContext<NewCampaignFormType>();
    return (
        <Grid item xs={12}>
            <ApiAutocomplete
                url={endpoints.campaigns.campaigns.replierOptions + `?ownerId=${isOwn ? user?.id : 'null'}`}
                label={'Válasz e-mail cím'}
                name={'replyEmailId'}
                disabled={isOwn}
                onAutoCompleteChange={(_, { value }) => {
                    setFieldValue('replyEmailId', value);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={!!props.errors.replyEmailId}
                        helperText={props.errors.replyEmailId}
                        label="Válasz e-mail cím"
                    />
                )}
                isFirstMount={!isOwn}
                defaultValue={isOwn ? { label: user?.email || '', value: 0 } : props.values.replyEmail}
            />
        </Grid>
    );
};

export default function SendDataForm(props: SendDataFormProps) {
    const dispatch = useDispatch();
    const { view, user } = useSelector((state: RootState) => state.auth);
    const isOwn = view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;
    const {
        values,
        setFieldValue,
        handleAutomaticSaveClick,
        loading,
        setErrors,
        setIsIntentionalLeave,
        setFormLoading,
    } = props;
    const [withSign, setWithSign] = useState<boolean>(!!values.senderSignId);
    const [signs, setSigns] = useState<ICampaignSenderSign[]>([]);
    const [signOptions, setSignOptions] = useState<Option<number>[]>([]);
    const [isSignOptionsLoading, setIsSignOptionsLoading] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [usedMessageTemplates, setUsedMessageTemplates] = useState<NewCampaignMessageFieldsType[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (values.submitType !== CampaignSubmitType.Scheduled) {
            setFieldValue('submitDate', initialForm.submitDate);
        }
    }, [values.submitType, setFieldValue]);
    useEffect(() => {
        let wSign = false;
        if (values.withSign !== undefined) {
            setWithSign(values.withSign);
            wSign = values.withSign;
        } else if (values.messageTemplate?.withSign !== undefined) {
            setWithSign(values.messageTemplate?.withSign);
            wSign = values.messageTemplate?.withSign;
        } else {
            setWithSign(false);
        }
        if (wSign && values.senderEmailId) {
            setIsSignOptionsLoading(true);
            campaignsService
                .signs(values.senderEmailId)
                .then((res) => {
                    setSigns(res.data);
                    setSignOptions(res.data.map((sign) => ({ label: sign.name, value: sign.id })));

                    if (values.senderSignId === null || values.senderSignId === 0) {
                        setFieldValue('senderSignId', initialForm.senderSignId);
                    }
                })
                .finally(() => setIsSignOptionsLoading(false));
        }
    }, [
        values.messageTemplate,
        values.messageTemplateId,
        values.senderEmailId,
        values.senderSignId,
        setFieldValue,
        values.withSign,
    ]);

    const handleTestSend = (form: NewCampaignFormType) => {
        setFormLoading(true);
        campaignsService
            .validate(_.omit(form, ['withForm', 'filter']), isOwn)
            .then((res) => {
                setUsedMessageTemplates(res.data);
                setIsDialogOpen(true);
            })
            .catch((error: AxiosError<{ violations: IViolations[] }>) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors<NewCampaignFormType>(error.response?.data.violations));
                }
                dispatch(
                    create({
                        type: 'error',
                        message: 'Hiba az új kampány teszt kiküldése során.',
                    }),
                );
            })
            .finally(() => {
                setFormLoading(false);
            });
    };

    useEffect(() => {
        if (isOwn) {
            instance
                .get(endpoints.campaigns.campaigns.senderOptions + `?ownerId=${isOwn ? user?.id : 'null'}`)
                .then((res) => {
                    if (res.data[0]) {
                        setFieldValue('senderEmailId', res.data[0].value);
                    }
                });
        }
    }, [isOwn, setFieldValue, user?.id]);

    const handleDraftSave = () => {
        setIsIntentionalLeave(true);
        handleAutomaticSaveClick(true)
            .then(() => navigate(clientEndpoints.unit_campaigns))
            .catch(() => setIsIntentionalLeave(false));
    };

    return (
        <>
            <Grid container spacing={2}>
                {FieldGenerator({
                    name: 'submitType',
                    label: 'Küldés',
                    type: 'select',
                    options: campaignSubmitTypes,
                    format: { xs: 12 },
                    ...props,
                })}
                {props.values.submitType === CampaignSubmitType.Scheduled &&
                    FieldGenerator({
                        name: 'submitDate',
                        label: 'Ütemezett kiküldés ideje',
                        format: { xs: 12 },
                        type: 'datetime-local',
                        props: {
                            error: !!props.errors.submitDate,
                            helperText: props.errors.submitDate,
                        },
                        ...props,
                    })}
                <Grid item xs={12}>
                    <ApiAutocomplete
                        url={endpoints.campaigns.campaigns.senderOptions + `?ownerId=${isOwn ? user?.id : 'null'}`}
                        label={'Küldő e-mail cím'}
                        name={'senderEmailId'}
                        onAutoCompleteChange={(_, value) => {
                            setFieldValue('senderEmailId', value.value);
                            setFieldValue('senderSignId', initialForm.senderSignId);
                        }}
                        disabled={isOwn}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!!props.errors.senderEmailId}
                                helperText={props.errors.senderEmailId}
                                label="Küldő e-mail cím"
                            />
                        )}
                        error={!!props.errors.senderEmailId}
                        helperText={props.errors.senderEmailId}
                        isFirstMount={!isOwn}
                        defaultValue={isOwn ? { label: user?.email || '', value: 0 } : props.values.senderEmail}
                    />
                </Grid>
                {withSign &&
                    ![0, null].includes(values.senderEmailId) &&
                    !isSignOptionsLoading &&
                    signOptions.length < 1 && (
                        <Grid item xs={12}>
                            <Alert severity="info">
                                Figyelem! Nem tartozik aláírás a kiválasztott e-mail címhez, de a kiválasztott
                                üzenetsablon tartalmaz %ALAIRAS% mezőt. Kiküldés során ez a mező kitörlésre kerül.
                            </Alert>
                        </Grid>
                    )}
                {withSign &&
                    values.senderEmailId != 0 &&
                    signOptions.length > 0 &&
                    FieldGenerator({
                        name: 'senderSignId',
                        label: 'Aláírás',
                        type: 'select',
                        options: signOptions,
                        format: { xs: 12 },
                        ...props,
                        fieldType: 'base',
                    })}
                {props.values.senderSignId !== 0 && withSign && (
                    <Grid item xs={12}>
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: signs.find((sign) => sign.id === props.values.senderSignId)?.content || '',
                            }}
                        ></Typography>
                    </Grid>
                )}
                <ReplyField />
                <SendDataFormFooter
                    {...props}
                    loading={loading}
                    onTestSendClick={() => handleTestSend(props.values)}
                    onDraftSaveClick={() => handleDraftSave()}
                />
            </Grid>
            <TestCampaignDialog
                messageTemplates={usedMessageTemplates}
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                newCampaign={_.omit(props.values, ['withForm', 'filter'])}
            />
        </>
    );
}

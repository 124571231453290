import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { GridCellRendererProps } from '../../Unit/Addresses/AddressList/BtnCellRenderer';

const useStyles = makeStyles(() => ({
    truncate: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'black',
    },
    info: {
        margin: '8px',
    },
}));

const NameCellRenderer = ({ params }: GridCellRendererProps) => {
    const classes = useStyles();

    return (
        <>
            {params.row.name}
            <Tooltip className={classes.info} title={params.row.description || 'Nincs megadva leírás'}>
                <div className={classes.truncate}>
                    <Info />
                </div>
            </Tooltip>
        </>
    );
};

export default NameCellRenderer;

import { clientEndpoints } from '../../../utils/clientEndpoints';
import StatisticsOtherPage from '../../../pages/Unit/Statistics/Other';

const StatisticsOther = {
    path: clientEndpoints.unit_statistics_other,
    children: [
        {
            path: '',
            element: <StatisticsOtherPage />,
            name: 'unit_statistics_other',
        },
    ],
};

export default StatisticsOther;

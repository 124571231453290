import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Field, Form, Formik } from 'formik';
import { createRef, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import api from '../../api';
import { history } from '../../store';
import { setAccessTree } from '../../store/accessManagement';
import { setUser } from '../../store/auth';
import { setJWTToken } from '../../utils/AuthHelper';
import { endpoints } from '../../utils/endPoints';
import ForgottenPasswordDialog from './ForgottenPasswordDialog';

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Hibás e-mail formátum').required('Kötelező megadni'),
    password: Yup.string().required('Kötelező megadni'),
});

const LoginForm = ({ setError }: { setError: (value: string) => void }) => {
    const [loading, setLoading] = useState(false);
    const [isForgottenPasswordDialogOpen, setIsForgottenPasswordDialogOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const ref = createRef<ReCAPTCHA>();

    useEffect(() => {
        setLoading(true);
        api.get(endpoints.me)
            .then((res) => {
                dispatch(setUser(res.data));
                history.push('/');
            })
            .finally(() => setLoading(false));
    }, [dispatch]);

    return (
        <>
            <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={async (form) => {
                    const token = await ref.current?.executeAsync();
                    setLoading(true);
                    api.post(endpoints.login, { ...form, recaptchaValue: token })
                        .then((response: AxiosResponse) => {
                            setJWTToken(response.data.token || '');
                            axios
                                .all([
                                    api.get(endpoints.me).then((res) => {
                                        dispatch(setUser(res.data));
                                    }),
                                    api.get(endpoints.accessTree).then((res: AxiosResponse) => {
                                        dispatch(setAccessTree(res.data));
                                    }),
                                ])
                                .finally(() => {
                                    setLoading(false);
                                    history.push('/');
                                });
                        })
                        .catch((error: AxiosError<{ code: number; message: string }>) =>
                            setError(
                                error.response?.status === 401
                                    ? error.response?.data?.message ?? 'Hibás bejelentkezési adatok.'
                                    : 'Hiba történt.',
                            ),
                        )
                        .finally(() => setLoading(false));
                }}
                validationSchema={LoginSchema}
            >
                {({ errors, touched, values }) => (
                    <Form style={{ width: '100%' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field as="div" field="email">
                                    <TextField
                                        required
                                        name="email"
                                        label="E-mail cím"
                                        margin="normal"
                                        error={!!(errors.email && touched.email)}
                                        helperText={touched.email && errors.email}
                                        fullWidth
                                        autoFocus
                                    />
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Field as="div" field="password">
                                    <TextField
                                        required
                                        name="password"
                                        type="password"
                                        label="Jelszó"
                                        margin="normal"
                                        error={!!(errors.password && touched.password)}
                                        helperText={touched.password && errors.password}
                                        fullWidth
                                    />
                                </Field>
                            </Grid>
                            <Grid item xs={12} textAlign="right">
                                <Button
                                    sx={{ textTransform: 'none' }}
                                    onClick={() => setIsForgottenPasswordDialogOpen(true)}
                                >
                                    Elfelejtett jelszó
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    disabled={!!(errors.email || errors.password) || !(values.email && values.password)}
                                    sx={{
                                        padding: '8px',
                                        height: 56,
                                    }}
                                    loading={loading}
                                >
                                    Bejelentkezés
                                </LoadingButton>
                            </Grid>
                        </Grid>
                        <ReCAPTCHA
                            size="invisible"
                            ref={ref}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                        />
                    </Form>
                )}
            </Formik>
            <ForgottenPasswordDialog
                open={isForgottenPasswordDialogOpen}
                onClose={() => setIsForgottenPasswordDialogOpen(false)}
            />
        </>
    );
};

export default LoginForm;

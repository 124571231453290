import { IForm } from '../../utils/AppConst';
import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import api from '../../api';
import { AxiosResponse } from 'axios';

export interface Form extends IForm {
    value: string;
    key: string;
    keyTranslated: string;
    helperText: string;
}

type UploadFileMaxSizeType = {
    value: number;
};
export type OwnCampaignValidEmailDomainsType = {
    value: string[];
};

const settingsService = {
    ...crudTemplate(endpoints.unit.administration.settings.main),
    getUploadFileMaxSize: (): Promise<AxiosResponse<UploadFileMaxSizeType>> =>
        api.get(endpoints.unit.administration.settings.getUploadFileMaxSize),
    getOwnCampaignValidEmailDomains: (): Promise<AxiosResponse<OwnCampaignValidEmailDomainsType>> =>
        api.get(endpoints.unit.administration.settings.ownCampaignValidEmailDomains),
    default: (id: string | number) =>
        api.post(endpoints.unit.administration.settings.default.replace('{id}', id.toString()), {}),
};

export default settingsService;

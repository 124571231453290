import Grid from '@mui/material/Grid';
import { FormBuilder } from '@silinfo/form-builder-2';
import { EditorProps } from '@silinfo/form-builder-2/lib/esm/components/FormBuilder/types';
import { Accordion, CKEditor, FieldGenerator, Page } from '@silinfo/front-end-template';
import { AxiosError } from 'axios';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormikForm from '../../../../components/Form/Form';
import MCCLoading from '../../../../components/MCCLoading';
import { IViolations } from '../../../../services/ResponseInterfaces';
import formsService from '../../../../services/Unit/forms';
import { RootState } from '../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';
import { create } from '../../../../store/notification';
import { transformApiErrors } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { ViewFormButton } from './ActionCellRenderer';
import { fields } from './fields';
import PreviewButton from './PreviewButton';
import utils from './utils';
import { useNavigate } from 'react-router-dom';
import { checkPageAccess } from '../../../../utils/AccessManagementHelper';

const EditorComponent = (props: EditorProps) => (
    <Grid item xs={12}>
        <CKEditor {...props} />
    </Grid>
);

const OptimizedFormBuilder = memo(FormBuilder);

export default function Form(props: { ownOnly?: boolean; readonly?: boolean; fromCampaignView?: boolean }) {
    const { ownOnly, readonly, fromCampaignView } = props;
    const dispatch = useDispatch();
    const { editorNames, headerById, back, initialValue } = utils;
    const { id, campaignId } = useParams();
    const title = 'Űrlap ' + (id ? (!readonly ? 'szerkesztése' : 'megtekintése') : 'létrehozása');
    const header = headerById(title, ownOnly, readonly, fromCampaignView, campaignId);
    const [formBuilder, setFormBuilder] = useState(initialValue.formBuilderData);
    const [initialValues, setInitialValues] = useState(initialValue);
    const [loading, setLoading] = useState(false);
    const { user, view } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    /*
    const checkEntityAccess = useCallback(
        (res: AxiosResponse) => checkPageAccessByEntityOwner(res, ownOnly || false),
        [ownOnly],
    );
*/
    checkPageAccess(ownOnly || false, readonly || false);

    useEffect(() => {
        if (id) {
            setLoading(true);
            formsService
                .get2(id, readonly)
                .then((data) => {
                    setInitialValues(data.data);
                    setFormBuilder(data.data.formBuilderData);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [id, navigate, readonly]);

    const FormPreviewButton = (
        <PreviewButton
            onClick={(formikProps) => {
                formsService
                    .cache({
                        ...formikProps.values,
                        formBuilderData: formBuilder,
                        ...{
                            ownerId:
                                ownOnly && view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                                    ? user?.id
                                    : null,
                        },
                    })
                    .then((res) => {
                        window.open(
                            clientEndpoints.unit_forms_preview.replace(':id', res.data.key),
                            '_blank',
                            'noopener noreferrer',
                        );
                    })
                    .catch((error: AxiosError<{ violations: IViolations[] }>) => {
                        if (error.response?.status === 422) {
                            formikProps.setErrors(transformApiErrors(error.response?.data.violations));
                        }
                        dispatch(
                            create({
                                type: 'error',
                                message: 'Hiba az előnézet megjelenítése során.',
                            }),
                        );
                    });
            }}
        />
    );

    if (loading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={title} disableClosing>
                <>
                    <FormikForm
                        fields={[]}
                        onSubmit={(form) =>
                            id
                                ? formsService.replace(id, {
                                      ...form,
                                      formBuilderData: formBuilder,
                                      ...{
                                          ownerId:
                                              ownOnly &&
                                              view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                                                  ? user?.id
                                                  : null,
                                      },
                                  })
                                : formsService.create({
                                      ...form,
                                      formBuilderData: formBuilder,
                                      ...{
                                          ownerId:
                                              ownOnly &&
                                              view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                                                  ? user?.id
                                                  : null,
                                      },
                                  })
                        }
                        initialValues={initialValues}
                        back={
                            fromCampaignView
                                ? clientEndpoints.unit_campaigns_view.replace(':id', campaignId || '')
                                : back(ownOnly)
                        }
                        readonly={!!readonly}
                        footerButtons={readonly ? <></> : FormPreviewButton}
                    >
                        {(formProps) => (
                            <>
                                {FieldGenerator({
                                    ...fields[0],
                                    props: {
                                        error: !!formProps.errors.name,
                                        helperText: formProps.errors.name,
                                        disabled: readonly,
                                    },
                                    ...formProps,
                                })}
                                {Object.entries(editorNames).map(([value, label]) => (
                                    <Grid item xs={12} key={value}>
                                        <Accordion title={'' + label}>
                                            {readonly ? (
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: '' + formProps.values[value] }}
                                                />
                                            ) : (
                                                <CKEditor
                                                    name={value}
                                                    format={{ xs: 12 }}
                                                    data={formProps.values[value] || ''}
                                                    onChange={(_: unknown, editor: { getData: () => string }) =>
                                                        formProps.setFieldValue(value, editor.getData())
                                                    }
                                                />
                                            )}
                                        </Accordion>
                                    </Grid>
                                ))}
                                {readonly ? (
                                    <ViewFormButton id={'' + id} />
                                ) : (
                                    <OptimizedFormBuilder
                                        form={formBuilder}
                                        setForm={setFormBuilder}
                                        editor={EditorComponent}
                                    />
                                )}
                            </>
                        )}
                    </FormikForm>
                </>
            </Accordion>
        </Page>
    );
}

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FormikContextType, useFormikContext } from 'formik';
import { IForm } from '../../../../utils/AppConst';

export default function PreviewButton(props: { onClick: (form: FormikContextType<IForm>) => void }) {
    const formikProps = useFormikContext<IForm>();

    return (
        <Grid item>
            <Button color="warning" variant="contained" onClick={() => props.onClick(formikProps)}>
                Előnézet
            </Button>
        </Grid>
    );
}

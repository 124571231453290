import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import { FormikProps } from 'formik';
import { NewCampaignFormType } from './types';
import { CampaignSubmitType } from '../../../../services/campaigns/campaigns';

export default function SendDataFormFooter(
    props: FormikProps<NewCampaignFormType> & {
        loading: boolean;
        onTestSendClick: () => void;
        onDraftSaveClick: () => void;
    },
) {
    const { loading, onTestSendClick, onDraftSaveClick, values } = props;

    return (
        <Grid item xs={12} container spacing={2}>
            <Grid item>
                <LoadingButton loading={loading} variant="contained" color="warning" onClick={() => onTestSendClick()}>
                    Teszt kiküldés
                </LoadingButton>
            </Grid>
            <Grid item>
                <LoadingButton loading={loading} variant="contained" type="submit" color="success">
                    {values.submitType === CampaignSubmitType.Scheduled
                        ? 'Kampány véglegesítése és beállítás ütemezett kiküldésre'
                        : 'Kampány véglegesítése és azonnali kiküldése'}
                </LoadingButton>
            </Grid>
            <Grid item>
                <LoadingButton variant="outlined" loading={loading} onClick={() => onDraftSaveClick()}>
                    Mentés piszkozatként
                </LoadingButton>
            </Grid>
        </Grid>
    );
}

import { FormikListPage } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import axios from 'axios';
import { useEffect, useState } from 'react';
import MCCLoading from '../../../../components/MCCLoading';
import emailLogService from '../../../../services/Unit/emailLogService';
import { PROJECT_NAME } from '../../../../utils/AppConst';
import { columns, filterFields } from './fields';
import centralEmailLogService from '../../../../services/Central/emailLogService';

interface IEmailLogPageProps {
    type: 'unit' | 'central';
}

export default function EmailLog(props: IEmailLogPageProps) {
    const [units, setUnits] = useState<Option[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'E-mail napló',
        breadcrumbs: {
            admin: 'Adminisztráció',
            'clientEndpoints.unit_email_log': 'E-mail napló',
        },
    };

    useEffect(() => {
        axios
            .all([
                ...(props.type === 'central'
                    ? [centralEmailLogService.units().then((unitRes) => setUnits(unitRes.data))]
                    : []),
            ])
            .finally(() => setLoading(false));
    }, [props.type]);

    if (loading) return <MCCLoading />;

    return (
        <FormikListPage
            autoload
            header={header}
            service={props.type == 'central' ? centralEmailLogService : emailLogService}
            columns={columns(props.type === 'central')}
            defaultSort={{ generationDate: 'desc' }}
            filter={{ fields: filterFields(units, props.type === 'central') }}
            responseKey="data"
        />
    );
}

import AddressListPage from '../../../pages/Unit/Addresses/AddressList';
import AddressListForm from '../../../pages/Unit/Addresses/AddressList/Form';
import AddressListImport from '../../../pages/Unit/Addresses/AddressList/Import';
import IndexOnlyGroup from '../../../pages/Unit/Addresses/AddressList/indexOnlyGroup';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const AddressList = {
    path: clientEndpoints.unit_address_list,
    children: [
        {
            path: '',
            element: <AddressListPage />,
            name: 'unit_address_list',
        },
        {
            path: 'new',
            element: <AddressListForm />,
            name: 'unit_address_list_create',
        },
        {
            path: ':id/edit',
            element: <AddressListForm />,
            name: 'unit_address_list_edit',
        },
        {
            path: ':groupId/address',
            element: <IndexOnlyGroup />,
            name: 'unit_address_list_only_group',
        },
        {
            path: 'import',
            element: <AddressListImport />,
            name: 'unit_address_list_import',
        },
    ],
};

export default AddressList;

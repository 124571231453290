import Grid from '@mui/material/Grid';
import { Accordion, FieldGenerator } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { DynamicFormTitle } from '.';
import EditorField from './EditorField';
import { AccordionFormProps, DynamicFormType } from './types';

export default function AccordionForm<T extends DynamicFormType>(
    props: AccordionFormProps & { itemText: string; children?: (childrenProps: AccordionFormProps) => React.ReactNode },
) {
    const { id, i, fields, formKey, value, itemText, children, readonly } = props;
    const formikProps = useFormikContext<T>();
    const values = formikProps.values[formKey];

    return (
        <Accordion
            title={
                <DynamicFormTitle readonly={readonly} id={'' + id} formKey={formKey} title={`${i + 1}. ` + itemText} />
            }
        >
            <Grid container item xs={12}>
                {fields.map((field) => {
                    return field.isEditor ? (
                        <EditorField
                            readonly={readonly}
                            i={i}
                            key={field.name}
                            id={id}
                            formKey={formKey}
                            value={value}
                            field={field}
                        />
                    ) : (
                        FieldGenerator({
                            ...field,
                            fieldType: 'base',
                            name: formKey + `.${i}.${field.name}`,
                            props: {
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    formikProps.setFieldValue(
                                        formKey,
                                        values.map((v) =>
                                            v.id === id || v.tmpId === id ? { ...v, [field.name]: e.target.value } : v,
                                        ),
                                    );
                                },
                                value: value[field.name],
                                error: formikProps.errors?.[`${i}.${field.name}`],
                                ...field.props,
                            },
                            ...formikProps,
                        })
                    );
                })}
                {children?.(props)}
            </Grid>
        </Accordion>
    );
}

import { endpoints } from '../../../utils/endPoints';
import API from '../../../api';
import { IStatisticsService } from './statisticsAggregateService';
import { AxiosResponse } from 'axios';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { CampaignStatisticsType, StatisticsCampaignFilter } from '../../../pages/Unit/Statistics/Campaign/types';
import { fixDateRangeFields } from '../../../utils/AppConst';
import { exportTemplate, IExportTemplate } from '../../templates';

export interface IStatisticsAggregateService extends IStatisticsService {
    emailsOpened: (filter: StatisticsCampaignFilter) => Promise<AxiosResponse<Option[]>> | null;
    emailsSent: (filter: StatisticsCampaignFilter) => Promise<AxiosResponse<Option[]>> | null;
    formsFilled: (filter: StatisticsCampaignFilter) => Promise<AxiosResponse<Option[]>> | null;
}

const emailsOpened = (filter: StatisticsCampaignFilter): Promise<AxiosResponse<Option[]>> | null => {
    if (!filter.statisticsType.includes(CampaignStatisticsType.EmailsOpened)) {
        return null;
    }

    return API.get(endpoints.unit.statistics.campaign.emailsOpened, {
        params: fixDateRangeFields(filter),
    });
};

const emailsSent = (filter: StatisticsCampaignFilter): Promise<AxiosResponse<Option[]>> | null => {
    if (!filter.statisticsType.includes(CampaignStatisticsType.EmailsSent)) {
        return null;
    }

    return API.get(endpoints.unit.statistics.campaign.emailsSent, {
        params: fixDateRangeFields(filter),
    });
};

const formsFilled = (filter: StatisticsCampaignFilter): Promise<AxiosResponse<Option[]>> | null => {
    if (!filter.statisticsType.includes(CampaignStatisticsType.FormsFilled)) {
        return null;
    }

    return API.get(endpoints.unit.statistics.campaign.formsFilled, {
        params: fixDateRangeFields(filter),
    });
};

const statisticsCampaignService: IStatisticsAggregateService = {
    getCampaigns: () => API.get(endpoints.unit.statistics.campaign.getCampaigns),
    emailsOpened,
    emailsSent,
    formsFilled,
};

export default statisticsCampaignService;

export const emailsOpenedExportService: IExportTemplate = {
    ...exportTemplate(endpoints.unit.statistics.campaign.emailsOpened),
};

export const emailsSentExportService: IExportTemplate = {
    ...exportTemplate(endpoints.unit.statistics.campaign.emailsSent),
};

export const formsFilledExportService: IExportTemplate = {
    ...exportTemplate(endpoints.unit.statistics.campaign.formsFilled),
};

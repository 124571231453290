import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Accordion, CKEditor } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { AccordionFormProps, DynamicFormType } from './types';
import { useStyles } from './utils';

export default function EditorField<T extends DynamicFormType>({
    id,
    formKey,
    value,
    field,
    i,
    readonly,
}: Omit<AccordionFormProps, 'fields'> & { field: { name: string; label: string } }) {
    const classes = useStyles();
    const formikProps = useFormikContext<T>();
    const values = formikProps.values[formKey];
    const { name } = field;
    const error = formikProps.errors?.[`${i}.${field.name}`];

    return (
        <Grid className={classes[name === 'content' ? 'content' : 'footerHeader']} item xs={12}>
            {readonly ? null : (
                <Grid item xs={12}>
                    <Typography variant="h6">{field.label}</Typography>
                </Grid>
            )}
            <Grid item xs={12} className={error ? classes.errorTextEditor : ''}>
                {readonly ? (
                    <Accordion title={field.label}>
                        <div dangerouslySetInnerHTML={{ __html: '' + value[name] }} />
                    </Accordion>
                ) : (
                    <CKEditor
                        format={{ xs: 12 }}
                        data={value[name]}
                        onChange={(_: unknown, editor: { getData: () => string }) =>
                            formikProps.setFieldValue(
                                formKey,
                                values.map((v) =>
                                    v.id === id || v.tmpId === id ? { ...v, [name]: editor.getData() } : v,
                                ),
                            )
                        }
                    />
                )}
            </Grid>
            <Typography variant="caption" color="error">
                {error}
            </Typography>
        </Grid>
    );
}

import Grid from '@mui/material/Grid';
import { Link, SubmitButton } from '@silinfo/front-end-template';
import { getJWTToken, logout } from '../../utils/AuthHelper';

export const ActionButtons = () => {
    return getJWTToken() ? (
        <>
            <Grid item xs={12}>
                <Link to="/">
                    <SubmitButton fullWidth size="large">
                        Vissza a főoldalra
                    </SubmitButton>
                </Link>
            </Grid>
            <Grid item xs={12}>
                <Link to="/login">
                    <SubmitButton onClick={logout} fullWidth size="large">
                        Kijelentkezés
                    </SubmitButton>
                </Link>
            </Grid>
        </>
    ) : (
        <Grid item xs={12}>
            <Link to="/login">
                <SubmitButton fullWidth size="large">
                    Bejelentkezés
                </SubmitButton>
            </Link>
        </Grid>
    );
};

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { LoadingButton } from '@mui/lab';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import { makeStyles } from '@mui/styles';
import { AxiosError, AxiosResponse } from 'axios';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { create, remove } from '../../store/notification';
import colors from '../../styles/colors';
import { downloadFile, IForm } from '../../utils/AppConst';
import InputIcon from '@mui/icons-material/Input';
import { useExportStatus } from '../../providers/ExportStatusProvider';

const useStyles = makeStyles(() => ({
    exportButton: {
        transition: 'none',
        backgroundColor: colors.export,
        '&:hover': {
            background: colors.exportHover,
            color: colors.white,
        },
    },
    textMenuItem: {
        backgroundColor: colors.white,
        color: colors.black,
        width: '136.27px',
        '&:hover': {
            background: '#dbdbdb',
            color: colors.black,
        },
        '&.Mui-selected': {
            background: colors.lightPrimary,
        },
        '&.Mui-selected:hover': {
            background: colors.lightPrimary,
            color: colors.black,
        },
    },
    paperClass: {
        background: '#dbdbdb',
    },
    arrowButton: {
        padding: '0px',
    },
}));

const splitButtonOptions = ['CSV fájlba', 'Excel fájlba'];

export type IExportContext = {
    initiateExport: (form: IForm, filter: IForm) => Promise<AxiosResponse>;
    checkExport: (id: number, i?: number) => Promise<AxiosResponse>;
    downloadExport: (id: number, i?: number) => Promise<AxiosResponse>;
    filter: IForm;
    campaignId?: number;
};

export type IExportContextWithCampaignId = {
    initiateExport: (campaignId: number, form: IForm, filter: IForm) => Promise<AxiosResponse>;
    checkExport: (campaignId: number, id: number, i?: number) => Promise<AxiosResponse>;
    downloadExport: (campaignId: number, id: number, i?: number) => Promise<AxiosResponse>;
    filter: IForm;
    campaignId: number;
};

export type ExportContextType = IExportContext | IExportContextWithCampaignId;
export type ExportFormatType = 'csv' | 'xlsx';

function isExportContextWithCampaignId(value: ExportContextType): value is IExportContextWithCampaignId {
    return typeof value.campaignId === 'number';
}

const initiateExport = (exportContext: ExportContextType, form: { format: ExportFormatType }, filter: IForm) => {
    if (isExportContextWithCampaignId(exportContext)) {
        return exportContext.initiateExport(exportContext.campaignId, form, filter);
    } else {
        return exportContext.initiateExport(form, filter);
    }
};

const checkExport = (exportContext: ExportContextType, id: number, i?: number) => {
    if (isExportContextWithCampaignId(exportContext)) {
        return exportContext.checkExport(exportContext.campaignId, id, i);
    } else {
        return exportContext.checkExport(id, i);
    }
};

const downloadExport = (exportContext: ExportContextType, id: number, i?: number) => {
    if (isExportContextWithCampaignId(exportContext)) {
        return exportContext.downloadExport(exportContext.campaignId, id, i);
    } else {
        return exportContext.downloadExport(id, i);
    }
};

function SplitButton(
    props: Omit<PopperProps, 'open'> & {
        exportContext: ExportContextType;
    },
) {
    const { exportContext } = props;
    const anchorRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { setExportStatus } = useExportStatus();

    const handleMenuItemClick = (_: unknown, index: number) => {
        if (typeof exportContext === typeof undefined || !exportContext.initiateExport) {
            console.error('Nincs megadva az initiateExport', exportContext);
            setOpen(false);
            return;
        }

        const format: ExportFormatType = index === 0 ? 'csv' : 'xlsx';
        const toSendForm = {
            format: format,
        };

        dispatch(
            create({
                type: 'info',
                message: 'A fájl előállítása folyamatban...',
            }),
        );
        const searchForm = exportContext.filter;
        setLoading(true);
        initiateExport(exportContext, toSendForm, searchForm)
            .then((data: AxiosResponse) => {
                const id = data.data.id;
                setExportStatus((prev) => [...prev, { id, processedRows: 0, rowCount: 0 }]);
                let fileUnit = data?.data?.fileUnit || 0;
                let ready = false;
                let isError = false;

                const checker = () =>
                    setTimeout(() => {
                        checkExport(exportContext, id)
                            .then((d: AxiosResponse) => {
                                const status = d.data.status;
                                fileUnit = d.data.fileUnit;
                                ready = status === 'finished' || status === 'unknown_error';
                                isError = status !== 'finished';
                                setExportStatus((prev) => {
                                    const index = prev.findIndex((e) => e.id === id);
                                    if (index !== -1) {
                                        prev[index].processedRows = d.data.info.processedRows;
                                        prev[index].rowCount = d.data.info.rowCount;
                                    }
                                    return [...prev];
                                });
                            })
                            .catch(() => {
                                setLoading(false);
                            });
                        if (ready) {
                            setExportStatus((prev) => prev.filter((e) => e.id !== id));
                            setLoading(false);
                            if (!isError) {
                                if (fileUnit > 0) {
                                    for (let i = 1; i <= fileUnit; i++) {
                                        downloadExport(exportContext, id, i)
                                            .then((response: AxiosResponse) => {
                                                const fileName =
                                                    response.headers['content-disposition']
                                                        ?.split(';')[1]
                                                        ?.split('=')[1]
                                                        ?.split('.')[0] || `export_${i}`;
                                                downloadFile(response.data, fileName, response.data.type);
                                            })
                                            .catch(() => {
                                                dispatch(remove());
                                                dispatch(
                                                    create({
                                                        type: 'error',
                                                        message: `Hiba történt a fájl előállítása során (export_${i})`,
                                                    }),
                                                );
                                            });
                                    }
                                } else {
                                    downloadExport(exportContext, id, 0)
                                        .then((response: AxiosResponse) => {
                                            const fileName =
                                                response.headers['content-disposition']
                                                    ?.split(';')[1]
                                                    ?.split('=')[1]
                                                    ?.split('.')[0] || 'export';
                                            downloadFile(response.data, fileName, response.data.type);

                                            dispatch(remove());
                                            dispatch(
                                                create({
                                                    type: 'success',
                                                    message: `A fájl sikeresen előállítva!`,
                                                }),
                                            );
                                        })
                                        .catch(() => {
                                            dispatch(remove());
                                            dispatch(
                                                create({
                                                    type: 'error',
                                                    message: `Hiba történt a fájl előállítása során`,
                                                }),
                                            );
                                        });
                                }
                            } else {
                                dispatch(remove());
                                dispatch(
                                    create({
                                        type: 'error',
                                        message: `Hiba történt a fájl előállítása során`,
                                    }),
                                );
                            }
                        } else {
                            checker();
                        }
                    }, 2000);

                checker();
            })
            .catch((error: AxiosError) => {
                setLoading(false);
                console.log(error);
            });
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup ref={anchorRef}>
                    <LoadingButton
                        startIcon={<InputIcon style={{ transform: 'rotate(180deg)' }} />}
                        loading={loading}
                        color="success"
                        variant="contained"
                        onClick={handleToggle}
                    >
                        Exportálás
                        <ArrowDropDown />
                    </LoadingButton>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal {...props}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper className={classes.paperClass}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {splitButtonOptions.map((option: string, index: number) => (
                                            <MenuItem
                                                className={classes.textMenuItem}
                                                key={option}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}

export default SplitButton;

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import useMediaQuery from '@mui/material/useMediaQuery';

type TMainPage = 'admin' | 'unit';

export default function MainPage({ type }: { type: TMainPage }) {
    const { layoutName } = useSelector((state: RootState) => state.auth);
    const matches = useMediaQuery('(min-width:600px)');

    const mainHeader = matches ? 'h4' : 'h5';
    const subHeader = matches ? 'h5' : 'h6';

    const mainText: Record<TMainPage, string> = {
        admin: 'Jelenleg a hírlevél- és űrlapkezelő rendszer adminisztrációs felületén vagy.',
        unit: 'Jelenleg a hírlevél- és űrlapkezelő rendszer alábbi területi egységében vagy: ' + layoutName,
    };

    return (
        <Grid item container spacing={2} display={'block'}>
            <Grid item>
                <Typography variant={mainHeader} style={{ color: 'black' }}>
                    Főoldal
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={subHeader}>{mainText[type]}</Typography>
            </Grid>

            <Grid item>
                <Typography variant={subHeader}>Válassz a bal oldali menüpontok közül!</Typography>
            </Grid>
        </Grid>
    );
}

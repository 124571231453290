import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Loading } from '@silinfo/front-end-template';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { changeMultipleTaskDialog, setGroups, setRefresh } from '../../../../../store/Unit/addressList';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import addressListService from '../../../../../services/Unit/addressListService';
import { Option } from '../../../../../components/Form/Form';
import * as React from 'react';
import { create } from '../../../../../store/notification';
import { SubmitButton } from '@silinfo/front-end-template';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface CheckboxAutocompleteProps {
    type: 'ADDRESS' | 'GROUP';
    selectedAddresses: Option[];
    checkBoxValue: Option[];
    setCheckboxValue: React.Dispatch<React.SetStateAction<Option[]>>;
}
export function CheckboxAutocomplete(props: CheckboxAutocompleteProps) {
    const { groups, multipleTaskGroups } = useSelector((state: RootState) => state.addressList);
    const { selectedAddresses, type, checkBoxValue, setCheckboxValue } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        setCheckboxValue(type === 'ADDRESS' ? selectedAddresses : []);
    }, [selectedAddresses, setCheckboxValue, type]);
    return (
        <Autocomplete
            multiple
            id="checkboxes-tags"
            options={type === 'ADDRESS' ? selectedAddresses : groups}
            fullWidth
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.label}
                </li>
            )}
            renderInput={(params) => <TextField margin={'none'} {...params} fullWidth />}
            ListboxProps={{
                style: {
                    maxHeight: '250px',
                },
            }}
            value={type === 'ADDRESS' ? checkBoxValue : multipleTaskGroups}
            onChange={(_, values) => {
                if (type === 'ADDRESS') {
                    setCheckboxValue(values);
                } else {
                    dispatch(setGroups(values));
                }
            }}
        />
    );
}

interface IAddressListMultipleTasksDialog {
    selected: (number | string)[];
}

function AddressListMultipleTasksDialog(props: IAddressListMultipleTasksDialog) {
    const { multipleTaskDialog, multipleTaskDialogType, multipleTaskGroups } = useSelector(
        (state: RootState) => state.addressList,
    );
    const dispatch = useDispatch();
    const { selected } = props;
    const [selectedAddresses, setSelectedAddresses] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const initCheckBoxValue: Option[] = [];
    const [checkBoxValue, setCheckboxValue] = useState(initCheckBoxValue);

    useEffect(() => {
        if (multipleTaskDialog) {
            setLoading(true);
            addressListService
                .getSelectedAddresses(selected)
                .then((res) => setSelectedAddresses(res.data))
                .finally(() => setLoading(false));
        }
    }, [multipleTaskDialog, selected]);
    function handleClose() {
        dispatch(changeMultipleTaskDialog());
        dispatch(setGroups([]));
    }

    function formatOnSend(answer: Option[]) {
        return answer.map((a) => parseInt(a.value));
    }

    function handleSubmit() {
        setLoading(true);

        const objectToSend = {
            groups: formatOnSend(multipleTaskGroups),
            addresses: formatOnSend(checkBoxValue),
        };

        (multipleTaskDialogType === 'ADD_GROUP'
            ? addressListService.connectAddressesToGroups(objectToSend)
            : addressListService.detachAddressesFromGroups(objectToSend)
        )
            .then(() => dispatch(create({ type: 'success', message: 'Sikeres művelet.' })))
            .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen művelet.' })))
            .finally(() => {
                dispatch(setGroups([]));
                dispatch(changeMultipleTaskDialog());
                setLoading(false);
                dispatch(setRefresh());
            });
    }

    return (
        <Dialog open={multipleTaskDialog} onClose={handleClose} maxWidth={'xl'}>
            <Grid item container>
                <Grid item xs={12}>
                    <DialogTitle>
                        {multipleTaskDialogType === 'ADD_GROUP'
                            ? 'Kijelöltek hozzáadása csoporthoz'
                            : 'Kijelöltek törlése csoportból'}
                    </DialogTitle>
                </Grid>
                {!loading ? (
                    <Grid item container xs={12} padding={'1em'}>
                        <DialogContent style={{ padding: 0 }}>
                            {multipleTaskDialogType === 'ADD_GROUP' ? (
                                <Typography mb={2} variant={'h5'}>
                                    Válaszd ki a csoportokat, amelyekhez hozzáadod az alábbi címzetteket!
                                </Typography>
                            ) : (
                                <Typography mb={2} variant={'h5'}>
                                    Válaszd ki a csoportokat, amelyeket eltávolítod az alábbi címzettektől!
                                </Typography>
                            )}

                            <Typography mb={2} variant={'body1'}>
                                Csoportok
                            </Typography>
                            <CheckboxAutocomplete
                                type={'GROUP'}
                                selectedAddresses={selectedAddresses}
                                checkBoxValue={checkBoxValue}
                                setCheckboxValue={setCheckboxValue}
                            />
                            <Divider style={{ marginTop: 10, marginBottom: 10 }} variant="middle" />
                            <Typography mb={2} variant={'body1'}>
                                Érintett címek
                            </Typography>
                            <CheckboxAutocomplete
                                type={'ADDRESS'}
                                selectedAddresses={selectedAddresses}
                                checkBoxValue={checkBoxValue}
                                setCheckboxValue={setCheckboxValue}
                            />
                        </DialogContent>
                        <Grid item xs={12}>
                            <DialogActions>
                                <SubmitButton onClick={handleSubmit}>Mentés</SubmitButton>
                                <Button variant="outlined" onClick={handleClose}>
                                    Bezár
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                ) : (
                    <Loading noHeight />
                )}
            </Grid>
        </Dialog>
    );
}

export default AddressListMultipleTasksDialog;

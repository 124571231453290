import Button from '@mui/material/Button';
import { useState } from 'react';
import ImportValidationErrorDialog from './ImportValidationErrorDialog';
import { useStyles } from './utils';

const ErrorMessage = ({ importId }: { importId: number }) => {
    const classes = useStyles();
    const [importValidationId, setImportValidationId] = useState<number>(0);

    return (
        <>
            Hiba a validáció során, a hibalista megtekintéséhez kattints{' '}
            <Button
                className={classes.tableCellLink}
                onClick={() => {
                    setImportValidationId(0);
                    setTimeout(() => {
                        setImportValidationId(importId);
                    });
                }}
            >
                ide
            </Button>
            <ImportValidationErrorDialog id={importValidationId} />
        </>
    );
};

export default ErrorMessage;

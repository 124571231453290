import { clientEndpoints } from '../../../utils/clientEndpoints';
import StatisticsCampaignPage from '../../../pages/Unit/Statistics/Campaign';

const StatisticsCampaign = {
    path: clientEndpoints.unit_statistics_campaign,
    children: [
        {
            path: '',
            element: <StatisticsCampaignPage />,
            name: 'unit_statistics_campaign',
        },
        {
            path: ':campaignId',
            element: <StatisticsCampaignPage />,
            name: 'unit_statistics_campaign_show',
        },
    ],
};

export default StatisticsCampaign;

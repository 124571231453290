import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';

/**
 * Azért szűrjük ki itt %VEZETEKNEV% és %KERESZTNEV% mezőket,
 * mert nem tartoznak a rendszerszintű üzenetmezőkhöz,
 * de vannak helyek, ahol közösen vannak használva, pl. szűrők és táblázat oszlopai
 */
export default function useFilterFlaggedMessageFields() {
    const { flaggedMessageFields } = useSelector((state: RootState) => state.messageTemplates);
    const { view } = useSelector((state: RootState) => state.auth);
    const location = useLocation();

    const ownAddressFieldsCondition =
        location.pathname.includes('own') && view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;

    const commonAddressFieldsCondition = view.unitCampaignManagement === ViewUnitCampaignManagementType.Common;

    const filteredFlaggedMessageFields =
        ownAddressFieldsCondition || commonAddressFieldsCondition
            ? flaggedMessageFields.filter(
                  (item) => item.messageId !== '%VEZETEKNEV%' && item.messageId !== '%KERESZTNEV%',
              )
            : flaggedMessageFields;

    return filteredFlaggedMessageFields;
}

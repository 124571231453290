import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import api from '../../api';

const centralEmailLogService = {
    ...crudTemplate(endpoints.central.emailLogs.main),
    units: () => api.get(endpoints.central.emailLogs.units),
};

export type CentralEmailLogServiceType = typeof centralEmailLogService;

export default centralEmailLogService;

import { CancelButton, Link } from '@silinfo/front-end-template';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const BackToLoginButton = () => (
    <Link to={clientEndpoints.login}>
        <CancelButton fullWidth size="large">
            Vissza a bejelentkezésre
        </CancelButton>
    </Link>
);

export default BackToLoginButton;

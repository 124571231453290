export const endpoints = {
    login: process.env.REACT_APP_BACKEND_URL + '/login_check',
    me: process.env.REACT_APP_BACKEND_URL + '/me',
    selectOwner: process.env.REACT_APP_BACKEND_URL + '/me/select-owner/{ownerId}',
    searchOwner: process.env.REACT_APP_BACKEND_URL + '/me/search-owner',
    ckeditorUpload: process.env.REACT_APP_BACKEND_URL + '/file-upload-ckeditor',
    pureUpload: `${process.env.REACT_APP_BACKEND_URL}/pure-upload/:type`,
    accessTree: process.env.REACT_APP_BACKEND_URL + '/access-tree',
    fileDownload: process.env.REACT_APP_BACKEND_URL + '/file-download',
    unsubscribe: process.env.REACT_APP_BACKEND_URL + '/unsubscribe/:unitId/:token',
    messageView: `${process.env.REACT_APP_BACKEND_URL}/message-view/{unitId}/{token}`,
    status: process.env.REACT_APP_BACKEND_URL + '/status',
    downloadHandbookAdmin: process.env.REACT_APP_BACKEND_URL + '/handbooks/felhasznaloi_kezikonyv_admin/download',
    downloadHandbookUnit:
        process.env.REACT_APP_BACKEND_URL +
        '/handbooks/felhasznaloi_kezikonyv_felhasznaloi_kezikonyv_teruleti_egyseg/download',
    address: {
        addressGroups: {
            main: process.env.REACT_APP_BACKEND_URL + '/unit/address-groups',
        },
        messageFields: {
            main: process.env.REACT_APP_BACKEND_URL + '/unit/message-fields',
            incrementSerial: process.env.REACT_APP_BACKEND_URL + '/unit/message-fields/{id}/increment-serial',
            decrementSerial: process.env.REACT_APP_BACKEND_URL + '/unit/message-fields/{id}/decrement-serial',
            changeVisible: process.env.REACT_APP_BACKEND_URL + '/unit/message-fields/{id}/change-visible',
        },
    },
    backendVersion: process.env.REACT_APP_BACKEND_URL + '/version',
    central: {
        emailActivation: {
            resend: process.env.REACT_APP_BACKEND_URL + '/central/users/{userId}/resend-activation-email',
            send: process.env.REACT_APP_BACKEND_URL + '/central/users/{id}/send-activation-email',
            activate: process.env.REACT_APP_BACKEND_URL + '/central/users/activate',
            isTokenValid: process.env.REACT_APP_BACKEND_URL + '/central/users/token/valid',
            resetPassword: `${process.env.REACT_APP_BACKEND_URL}/central/users/reset-password`,
        },
        units: {
            main: process.env.REACT_APP_BACKEND_URL + '/central/units',
            incrementSerial: process.env.REACT_APP_BACKEND_URL + '/central/units/{id}/increment-serial',
            decrementSerial: process.env.REACT_APP_BACKEND_URL + '/central/units/{id}/decrement-serial',
        },
        unit: {
            list: process.env.REACT_APP_BACKEND_URL + '/central/unit/list',
            options: process.env.REACT_APP_BACKEND_URL + '/central/unit/options',
            select: process.env.REACT_APP_BACKEND_URL + '/central/unit/select',
        },
        users: {
            main: process.env.REACT_APP_BACKEND_URL + '/central/users',
            getRoles: process.env.REACT_APP_BACKEND_URL + '/central/users/get-roles',
            getUnits: process.env.REACT_APP_BACKEND_URL + '/central/users/get-units',
            sendPasswordReminder: `${process.env.REACT_APP_BACKEND_URL}/central/users/send-password-reminder`,
            changePassword: `${process.env.REACT_APP_BACKEND_URL}/central/users/change-password`,
        },
        settings: {
            main: process.env.REACT_APP_BACKEND_URL + '/central/settings',
            ownCampaignValidEmailDomains: `${process.env.REACT_APP_BACKEND_URL}/central/settings/own-campaign-valid-email-domains`,
        },
        superAdmin: {
            main: process.env.REACT_APP_BACKEND_URL + '/central/super-admin',
        },
        systemMessages: {
            main: `${process.env.REACT_APP_BACKEND_URL}/central/system-messages`,
            getMessageFields: `${process.env.REACT_APP_BACKEND_URL}/central/system-messages/message-fields`,
        },
        emailLogs: {
            main: process.env.REACT_APP_BACKEND_URL + '/central/email-logs',
            units: process.env.REACT_APP_BACKEND_URL + '/central/email-logs/unit/list',
            loggerText: process.env.REACT_APP_BACKEND_URL + '/central/email-logs/{id}/logger-text',
        },
        systemLogs: {
            main: process.env.REACT_APP_BACKEND_URL + '/central/system-logs',
            units: process.env.REACT_APP_BACKEND_URL + '/central/system-logs/unit/list',
            events: process.env.REACT_APP_BACKEND_URL + '/central/system-logs/event/list',
            types: process.env.REACT_APP_BACKEND_URL + '/central/system-logs/type/list',
        },
    },
    unit: {
        exportList: {
            main: process.env.REACT_APP_BACKEND_URL + '/unit/export',
            check: process.env.REACT_APP_BACKEND_URL + '/check-export/{type}/{id}',
            inProgress: process.env.REACT_APP_BACKEND_URL + '/unit/export/in-progress',
            download: process.env.REACT_APP_BACKEND_URL + '/download-export/{type}/{id}',
        },
        emailPostedAttachments: {
            download: `${process.env.REACT_APP_BACKEND_URL}/unit/email-posted-attachments/{id}/download`,
        },
        address: {
            addressList: {
                main: process.env.REACT_APP_BACKEND_URL + '/unit/addresses',
                fields: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/fields',
                campaigns: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/fields/campaign',
                onlyGroup: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/{groupId}/address',
                addressGroups: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/address-groups',
                firstNameLastNameFlags:
                    process.env.REACT_APP_BACKEND_URL + '/unit/addresses/first-name-last-name-flags',
                deleteAddress: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/{id}',
                selectedAddresses: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/selected',
                subscribed: `${process.env.REACT_APP_BACKEND_URL}/unit/addresses/subscribed/{sessionId}`,
                import: {
                    list: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/import/list',
                    start: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/import/start',
                    status: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/import/status/{id}',
                    errorList: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/import/errlist/{id}',
                    downloadSampleFile: `${process.env.REACT_APP_BACKEND_URL}/unit/addresses/import/download-sample-file`,
                    downloadUploadedFile: `${process.env.REACT_APP_BACKEND_URL}/unit/addresses/import/download-uploaded-file/{id}`,
                },
                deleteMultiple: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/multiple-delete',
                connectAddressesToGroups: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/address-groups/connect',
                detachAddressesFromGroups: process.env.REACT_APP_BACKEND_URL + '/unit/addresses/address-groups/detach',
            },
        },
        template: {
            messageTemplates: {
                main: process.env.REACT_APP_BACKEND_URL + '/unit/message-templates',
                clone: process.env.REACT_APP_BACKEND_URL + '/unit/message-templates/{id}/clone',
                getMessageFields: process.env.REACT_APP_BACKEND_URL + '/unit/message-templates/message-fields',
                pdf: {
                    create: process.env.REACT_APP_BACKEND_URL + '/unit/message-templates/pdf/create',
                    download: process.env.REACT_APP_BACKEND_URL + '/unit/message-templates/pdf/download',
                },
                sendTestMail: `${process.env.REACT_APP_BACKEND_URL}/unit/message-templates/send-test-mail`,
                autocomplete: `${process.env.REACT_APP_BACKEND_URL}/unit/message-templates/autocomplete`,
                validate: `${process.env.REACT_APP_BACKEND_URL}/unit/message-templates/validate`,
            },
            forms: {
                main: process.env.REACT_APP_BACKEND_URL + '/unit/forms',
                preview: (id: string | number) => process.env.REACT_APP_BACKEND_URL + '/unit/forms/preview/' + id,
                duplicate: (id: string | number) => process.env.REACT_APP_BACKEND_URL + `/unit/forms/${id}/duplicate`,
                cache: `${process.env.REACT_APP_BACKEND_URL}/unit/forms/cache`,
                autocomplete: `${process.env.REACT_APP_BACKEND_URL}/unit/forms/autocomplete`,
            },
        },
        statistics: {
            aggregate: {
                main: process.env.REACT_APP_BACKEND_URL + '/unit/statistics/aggregate',
                getCampaigns: process.env.REACT_APP_BACKEND_URL + '/unit/statistics/aggregate/get-campaigns',
            },
            campaign: {
                getCampaigns: process.env.REACT_APP_BACKEND_URL + '/unit/statistics/campaign/get-campaigns',
                emailsOpened: process.env.REACT_APP_BACKEND_URL + '/unit/statistics/campaign/emails-opened',
                emailsSent: process.env.REACT_APP_BACKEND_URL + '/unit/statistics/campaign/emails-sent',
                formsFilled: process.env.REACT_APP_BACKEND_URL + '/unit/statistics/campaign/forms-filled',
            },
            grouped: {
                main: process.env.REACT_APP_BACKEND_URL + '/unit/statistics/grouped',
                getCampaigns: process.env.REACT_APP_BACKEND_URL + '/unit/statistics/grouped/get-campaigns',
            },
            other: {
                getNumberOfEmails: process.env.REACT_APP_BACKEND_URL + '/unit/statistics/other/get-number-of-emails',
                sendgridGetLast: {
                    info: process.env.REACT_APP_BACKEND_URL + '/unit/statistics/other/sendgrid-get-last/info',
                    update: process.env.REACT_APP_BACKEND_URL + '/unit/statistics/other/sendgrid-get-last/update',
                },
            },
        },
        administration: {
            settings: {
                main: process.env.REACT_APP_BACKEND_URL + '/unit/settings',
                getUploadFileMaxSize: process.env.REACT_APP_BACKEND_URL + '/unit/settings/upload-file-max-size',
                ownCampaignValidEmailDomains: `${process.env.REACT_APP_BACKEND_URL}/unit/settings/own-campaign-valid-email-domains`,
                default: process.env.REACT_APP_BACKEND_URL + '/unit/settings/default/{id}',
            },
            emailLog: {
                main: process.env.REACT_APP_BACKEND_URL + '/unit/email-log',
                campaigns: process.env.REACT_APP_BACKEND_URL + '/unit/email-log/campaigns',
                loggerText: process.env.REACT_APP_BACKEND_URL + '/unit/email-log/{id}/logger-text',
            },
            emails: {
                main: process.env.REACT_APP_BACKEND_URL + '/unit/emails',
                changeStatus: process.env.REACT_APP_BACKEND_URL + '/unit/emails/{id}/change-status/{type}',
                userSearch: (term: string) =>
                    process.env.REACT_APP_BACKEND_URL + '/unit/emails/form/user-search?term=' + term,
            },
            users: {
                main: process.env.REACT_APP_BACKEND_URL + '/unit/users',
                getUnitRoles: process.env.REACT_APP_BACKEND_URL + '/unit/users/get-unit-roles',
            },
        },
    },
    campaigns: {
        campaigns: {
            main: process.env.REACT_APP_BACKEND_URL + '/unit/campaigns',
            all: process.env.REACT_APP_BACKEND_URL + '/campaigns/all',
            sent: process.env.REACT_APP_BACKEND_URL + '/unit/campaigns/sent',
            previousCampaigns: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/prev-campaigns`,
            previousCampaign: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/prev-campaigns/{id}`,
            templates: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/templates`,
            forms: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/forms`,
            senders: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/emails/senders`,
            columns: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/{campaignId}/filled-forms/columns`,
            sender: {
                signs: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/emails/senders/{id}/signs`,
            },
            repliers: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/emails/repliers`,
            saveAutomatic: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/automatic-save`,
            save: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/save`,
            validate: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/validate`,
            sendTest: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/send-test`,
            autocomplete: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/autocomplete`,
            autocompletePreviousCampaigns: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/prev-campaigns/autocomplete`,
            addressList: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/{campaignId}/address-list`,
            senderOptions: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/emails/senders/autocomplete`,
            replierOptions: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/emails/repliers/autocomplete`,
        },
        addressGroup: {
            groupsWithEmailPosted: process.env.REACT_APP_BACKEND_URL + '/unit/address-groups/all-with-emails',
            options: process.env.REACT_APP_BACKEND_URL + '/unit/address-groups/autocomplete',
        },
        emailPosted: {
            main: process.env.REACT_APP_BACKEND_URL + '/unit/campaigns/:campaignId/email-posted',
            getOne: process.env.REACT_APP_BACKEND_URL + '/unit/campaigns/email-posted/:emailPostedId',
        },
        filledForms: {
            main: process.env.REACT_APP_BACKEND_URL + '/unit/campaigns/:campaignId/filled-forms',
            initiateExport:
                process.env.REACT_APP_BACKEND_URL + '/unit/campaigns/:campaignId/filled-forms/initiate-export',
            checkExport:
                process.env.REACT_APP_BACKEND_URL + '/unit/campaigns/:campaignId/filled-forms/check-export/:id',
            downloadExport:
                process.env.REACT_APP_BACKEND_URL + '/unit/campaigns/:campaignId/filled-forms/download-export/:id',
        },
        form: {
            addressList: {
                save: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/address-list/save`,
                saveMany: `${process.env.REACT_APP_BACKEND_URL}/unit/campaigns/form/address-list/save-many`,
            },
        },
    },
    test: {
        dummy: process.env.REACT_APP_BACKEND_DUMMY_URL + '/test/status.php',
        symfony: process.env.REACT_APP_BACKEND_URL + '/status/database',
    },
    logout: process.env.REACT_APP_BACKEND_URL + '/me/logout',
    superadmin: {
        setting: {
            main: process.env.REACT_APP_BACKEND_URL + '/settings',
        },
    },
    form: {
        main: (unitId: number, token: string) => `${process.env.REACT_APP_BACKEND_URL}/form/${unitId}/${token}`,
        view: (unitId: number, id: string) => `${process.env.REACT_APP_BACKEND_URL}/form/view/${unitId}/${id}`,
    },
};

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Input } from '@silinfo/front-end-template';
import BtnCellRenderer from './BtnCellRenderer';
import NameCellRenderer from './NameCellRenderer';
import ValueCellRenderer from './ValueCellRenderer';

export const filterFields: Input[] = [
    {
        name: 'key',
        label: 'Kulcs',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'name',
        label: 'Megnevezés',
        format: { xs: 12, md: 6, lg: 4 },
    },
].map((elem) => ({ ...elem }));

export const formFields: Input[] = [];

export const columns = (editUrl: string): GridColDef[] => [
    {
        headerName: 'Kulcs',
        field: 'key',
    },
    {
        headerName: 'Megnevezés',
        field: 'name',
        renderCell: (params: GridRenderCellParams) => <NameCellRenderer params={params} />,
        width: 300,
    },
    {
        headerName: 'Érték',
        field: 'value',
        renderCell: (params: GridRenderCellParams) => <ValueCellRenderer params={params} />,
    },
    { headerName: 'Módosító', field: 'editor' },
    { headerName: 'Módosítás ideje', field: 'updateDate' },
    {
        headerName: 'Műveletek',
        field: 'operations',
        renderCell: (params: GridRenderCellParams) => <BtnCellRenderer params={params} editUrl={editUrl} />,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
    },
];

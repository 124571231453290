import NewCampaignPage from '../../../pages/Unit/Campaign/NewCampaign';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const NewCampaign = {
    path: clientEndpoints.unit_new_campaign,
    children: [
        {
            path: '',
            element: <NewCampaignPage />,
            name: 'unit_new_campaign',
        },
    ],
};

export default NewCampaign;

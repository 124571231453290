import Chip from '@mui/material/Chip';
import { GridRenderCellParams } from '@mui/x-data-grid';

const COLOR_MAP: Record<string, string> = {
    finished: '#35A899',
    processing: '#0288d1',
    initiated: '#0288d1',
};

export default function StatusCellRenderer(params: GridRenderCellParams) {
    const { status, statusName } = params.row;
    const color = status in COLOR_MAP ? COLOR_MAP[status] : 'black';

    return <Chip label={statusName} sx={{ backgroundColor: color, color: 'white' }} />;
}

import { Accordion, createInitialValues, FieldGenerator, Loading, Page } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import addressGroupsService from '../../../services/Unit/addressGroups';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import { formFields } from './fields';
import { IAddressGroupsType } from './types';
import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ViewUnitCampaignManagementType } from '../../../store/auth';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { PROJECT_NAME } from '../../../utils/AppConst';
import { checkPageAccess, checkPageAccessByEntityOwner } from '../../../utils/AccessManagementHelper';
/*
const useStyles = makeStyles(() => ({
    truncate: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'black',
    },
    info: {
        margin: '8px',
    },
}));
*/

export default function AddressGroupsForm(props: { ownOnly?: boolean }) {
    const { ownOnly } = props;
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(createInitialValues(formFields) as IAddressGroupsType);
    const { user, view } = useSelector((state: RootState) => state.auth);
    const back = ownOnly ? clientEndpoints.unit_own_address_groups : clientEndpoints.unit_address_groups;
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Csoportok',
        breadcrumbs: {
            addresses: 'Címek',
            [back]: 'Csoportok',
            form: `Csoport ${id ? 'szerkesztése' : 'hozzáadása'}`,
        },
    };
    const handleSubmit = (form: IAddressGroupsType) => {
        return id
            ? addressGroupsService.replace(id, {
                  ...form,
                  ...{
                      ownerId:
                          ownOnly && view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                              ? user?.id
                              : null,
                  },
              })
            : addressGroupsService.create({
                  ...form,
                  ...{
                      ownerId:
                          ownOnly && view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                              ? user?.id
                              : null,
                  },
              });
    };
    const checkEntityAccess = useCallback(
        (res: AxiosResponse) => checkPageAccessByEntityOwner(res, ownOnly || false),
        [ownOnly],
    );
    // const classes = useStyles();

    checkPageAccess(ownOnly || false, false);

    useEffect(() => {
        if (id) {
            setLoading(true);
            addressGroupsService
                .get(id)
                .then(checkEntityAccess)
                .then((response: AxiosResponse<IAddressGroupsType>) => setInitialValues(response.data))
                .finally(() => setLoading(false));
        }
    }, [id, checkEntityAccess]);

    if (loading) {
        return <Loading />;
    }

    return (
        <Page header={header}>
            <Accordion title={header.title}>
                <Form fields={[]} initialValues={initialValues} onSubmit={handleSubmit} back={back}>
                    {(formikProps) => {
                        return (
                            <>
                                {FieldGenerator({
                                    ...formFields[0],
                                    ...formikProps,
                                    props: { ...formFields[0].props },
                                })}
                                {FieldGenerator({
                                    ...formFields[1],
                                    ...formikProps,
                                    props: { ...formFields[1].props },
                                })}
                                <Grid item container display={'flex'} alignItems={'center'}>
                                    {FieldGenerator({
                                        ...formFields[2],
                                        ...formikProps,
                                        props: { ...formFields[2].props },
                                    })}
                                    <Grid item>
                                        <Tooltip
                                            title={
                                                'Amennyiben ez az opció beállításra kerül, a csoportban lévő címzettek nem tudnak leiratkozni a számunkra kiküldött e-mailben található leiratkozási linkre kattintva.'
                                            }
                                        >
                                            <div>
                                                <Info />
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                </Grid>

                                {formikProps.values.prohibitionOfUnsubscription === '1' &&
                                    FieldGenerator({
                                        ...formFields[3],
                                        ...formikProps,
                                        props: { ...formFields[3].props },
                                    })}
                            </>
                        );
                    }}
                </Form>
            </Accordion>
        </Page>
    );
}

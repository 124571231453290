import { Input } from '@silinfo/front-end-template';

export const filterFields: Input[] = [
    {
        label: 'Kiküldés ideje (-tól)',
        name: 'sentDateFrom',
        type: 'date',
        format: { xs: 12, md: 6, lg: 6 },
    },
    {
        label: 'Kiküldés ideje (-ig)',
        name: 'sentDateUntil',
        type: 'date',
        format: { xs: 12, md: 6, lg: 6 },
    },
];

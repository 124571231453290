import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CancelButton, FieldGenerator } from '@silinfo/front-end-template';
import { FormikErrors, useFormikContext } from 'formik';
import { Children, useState } from 'react';
import { CampaignTestDataType } from '../../../../../services/campaigns/campaigns';
import useOpen from '../../../Statistics/hooks/useOpen';
import { IMessageTemplatesFormType } from '../types';
import { getMessageFields, testPdfGen } from './utils';
import { ITestCampaignDialog } from './types';

export default function PDFGeneration(props: ITestCampaignDialog): JSX.Element {
    const { currentValue, messageTemplates, i } = props;
    const [loading, setLoading] = useState(false);
    const { isOpen, close, open } = useOpen();
    const fProps = useFormikContext<IMessageTemplatesFormType>();
    const currentValueMessageFields = getMessageFields(currentValue);
    const messageFields = messageTemplates.filter((elem) => currentValueMessageFields.includes(elem.messageId));

    const handleClick = async () => {
        await testPdfGen(currentValue, setLoading);
        close();
    };

    return (
        <>
            <Grid item xs={12}>
                <LoadingButton
                    loading={loading}
                    variant="outlined"
                    color="success"
                    sx={{ my: 2 }}
                    startIcon={<DownloadIcon />}
                    onClick={messageFields.length > 0 ? open : handleClick}
                >
                    Teszt pdf generálás
                </LoadingButton>
            </Grid>
            <Dialog open={isOpen} fullWidth maxWidth="lg">
                <DialogTitle>Teszt PDF generálása</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" sx={{ width: '100%', mt: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Sablonban szereplő mezők értékének megadása
                                </Typography>
                                {Children.toArray(
                                    messageFields.map((field) =>
                                        FieldGenerator({
                                            ...fProps,
                                            label: field.columnName,
                                            name: `attachments.${i}.fields.${field.id}`,
                                            type: 'text',
                                            format: {
                                                xs: 12,
                                            },
                                            props: {
                                                error: !!fProps.errors[
                                                    `attachments.${i}.fields.${field.id}` as keyof FormikErrors<CampaignTestDataType>
                                                ],
                                                helperText:
                                                    fProps.errors[
                                                        `attachments.${i}.fields.${field.id}` as keyof FormikErrors<CampaignTestDataType>
                                                    ],
                                                placeholder: field.columnName,
                                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                    fProps.setFieldValue(
                                                        `attachments.${i}.fields.${field.id}`,
                                                        e.target.value,
                                                    );
                                                },
                                                value: fProps.values.attachments[field.id],
                                            },
                                        }),
                                    ),
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item container spacing={2} sx={{ mt: 1 }}>
                            <Grid item>
                                <LoadingButton loading={loading} variant="contained" onClick={handleClick}>
                                    Generálás
                                </LoadingButton>
                            </Grid>
                            <Grid item>
                                <CancelButton onClick={close}>Mégsem</CancelButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import StorageIcon from '@mui/icons-material/Storage';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import api from '../../api';
import { endpoints } from '../../utils/endPoints';
import { AxiosResponse } from 'axios';
import { setAccessTree } from '../../store/accessManagement';
import { RootState } from '../../store';
import { getUserLayouts } from '../../utils/AccessManagementHelper';
import { clientEndpoints } from '../../utils/clientEndpoints';
import MCCLoading from '../MCCLoading';
import { setLayoutName, setViewLayout, ViewLayoutType } from '../../store/auth';

export default function SwitchLayout(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { accessTreeLoading } = useSelector((state: RootState) => state.accessManagement);
    const { user } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState(false);
    const handleLayoutSelect = useCallback(
        (type: ViewLayoutType) => {
            switch (type) {
                case ViewLayoutType.Central:
                    dispatch(setViewLayout(ViewLayoutType.Central));
                    dispatch(setLayoutName('Admin'));
                    navigate(clientEndpoints.home);
                    break;

                case ViewLayoutType.Unit:
                    dispatch(setViewLayout(ViewLayoutType.Unit));
                    navigate(clientEndpoints.select_unit);
                    break;
            }
        },
        [dispatch, navigate],
    );

    useEffect(() => {
        if (accessTreeLoading) {
            api.get(endpoints.accessTree).then((res: AxiosResponse) => {
                dispatch(setAccessTree(res.data));
            });
        }
    }, [accessTreeLoading, dispatch]);

    useEffect(() => {
        setLoading(true);

        if (user) {
            const layouts = getUserLayouts(user);

            if (layouts.length <= 1) {
                handleLayoutSelect(layouts.length ? layouts[0] : ViewLayoutType.Central);
            }
        }

        setLoading(false);
    }, [user, navigate, dispatch, handleLayoutSelect]);

    if (loading) return <MCCLoading />;

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography textAlign="center" variant="h4">
                    Kérjük, válassz nézetet
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={() => {
                        handleLayoutSelect(ViewLayoutType.Central);
                    }}
                    sx={{
                        padding: '8px',
                        height: 56,
                    }}
                    endIcon={<AdminPanelSettings />}
                >
                    Admin
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={() => {
                        handleLayoutSelect(ViewLayoutType.Unit);
                    }}
                    sx={{
                        padding: '8px',
                        height: 56,
                    }}
                    endIcon={<StorageIcon />}
                >
                    Területi egység
                </Button>
            </Grid>
        </Grid>
    );
}

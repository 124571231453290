import { clientEndpoints } from '../../../utils/clientEndpoints';
import StatisticsAggregatePage from '../../../pages/Unit/Statistics/Aggregate';

const StatisticsAggregate = {
    path: clientEndpoints.unit_statistics_aggregate,
    children: [
        {
            path: '',
            element: <StatisticsAggregatePage />,
        },
    ],
};

export default StatisticsAggregate;

import { Input } from '@silinfo/front-end-template';

export const formFields: Input[] = [
    {
        label: 'Megnevezés',
        name: 'name',
        format: {
            xs: 12,
        },
    },
];

export const filterFields: Input[] = [
    {
        label: 'Megnevezés',
        name: 'name',
        format: {
            xs: 12,
        },
    },
];

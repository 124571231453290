import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FormikListPage, Option } from '@silinfo/front-end-template';
import React, { useCallback, useMemo } from 'react';
import messageFieldsService from '../../../services/address/messageFields';
import ConfirmationDialog, { IConfirmationDialog } from './ConfirmationDialog';
import { filterFields } from './fields';
import { tableColumns } from './table';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { AppViewType, AuthUser, getOtherOwnerCondition, ViewUnitCampaignManagementType } from '../../../store/auth';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PROJECT_NAME } from '../../../utils/AppConst';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const filter = {
    fields: filterFields,
};

export function getOwnerId(
    view: AppViewType,
    ownOnly = false,
    user?: AuthUser,
    selectedOwner?: Option<number, string>,
): string | number {
    if (ownOnly) {
        if (view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && user && user.id) {
            return user.id;
        } else if (view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner && selectedOwner) {
            return selectedOwner?.value;
        }
    }

    return 'null';
}

export default function MessageFields(props: { ownOnly?: boolean }) {
    const { ownOnly } = props;
    const [refresh, setRefresh] = React.useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'), {
        noSsr: true,
    });
    const { view, user, selectedOwner } = useSelector((state: RootState) => state.auth);
    const doRefresh = useCallback(() => setRefresh((prev) => !prev), []);
    const [confirmationState, setConfirmationState] = React.useState<IConfirmationDialog>({
        message: '',
        refresh: doRefresh,
    });
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Üzenetmezők',
        breadcrumbs: {
            addresses: 'Címek',
            [ownOnly ? clientEndpoints.unit_own_message_fields : clientEndpoints.unit_message_fields]: 'Üzenetmezők',
        },
    };
    const handleConfirmationStateChange = (key: string, value: unknown) =>
        setConfirmationState((prevState) => ({
            ...prevState,
            [key]: value,
        }));

    const otherOwnerCondition = getOtherOwnerCondition();
    const columns = useMemo(
        () =>
            tableColumns(
                setConfirmationState,
                doRefresh,
                ([undefined, ViewUnitCampaignManagementType.Common].includes(view.unitCampaignManagement) ||
                    !!ownOnly) &&
                    !otherOwnerCondition,
                matches,
            ),
        [doRefresh, matches, ownOnly, view, otherOwnerCondition],
    );

    return (
        <Grid container spacing={2}>
            {view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && !ownOnly && !otherOwnerCondition && (
                <Grid item xs={12}>
                    <Alert severity="info">
                        Mivel saját kampány módban használod a rendszert, a közös üzenetmezőket csak olvasási módban
                        tudod megtekinteni.
                    </Alert>
                </Grid>
            )}
            {otherOwnerCondition && selectedOwner && (
                <Grid item xs={12}>
                    <Alert severity="info">
                        {`Mivel ${selectedOwner.label} saját kampányadatait látod, ezért az üzenetmezőket csak olvasási módban tudod megtekinteni.`}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <ConfirmationDialog state={confirmationState} setState={handleConfirmationStateChange} />
                <FormikListPage
                    autoload
                    header={header}
                    service={{
                        filter: (search) =>
                            messageFieldsService.filter({
                                ...search,
                                ...{ ownerId: getOwnerId(view, ownOnly || false, user, selectedOwner) },
                            }),
                    }}
                    columns={columns}
                    defaultSort={{ serial: 'asc' }}
                    newLabel={
                        ([undefined, ViewUnitCampaignManagementType.Common].includes(view.unitCampaignManagement) ||
                            ownOnly) &&
                        !otherOwnerCondition
                            ? 'Üzenetmező hozzáadása'
                            : undefined
                    }
                    filter={filter}
                    responseKey="data"
                    refresh={JSON.stringify([
                        refresh,
                        view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && ownOnly,
                    ])}
                    tableProps={{
                        initialState: { pinnedColumns: { right: ['operations'] } },
                        columnBuffer: columns.length,
                        disableVirtualization: true,
                        disableColumnMenu: false,
                    }}
                />
            </Grid>
        </Grid>
    );
}

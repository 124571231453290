import ContentCopy from '@mui/icons-material/ContentCopy';
import { makeStyles } from '@mui/styles';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableButton, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import messageTemplatesService from '../../../../services/Unit/messageTemplates';
import { create } from '../../../../store/notification';

const useStyles = makeStyles(() => ({
    button: {
        marginRight: '5px',
    },
}));

type BtnCellRendererProps = {
    params: GridRenderCellParams;
    setRefresh: Dispatch<SetStateAction<boolean>>;
    setSortByInsert: Dispatch<SetStateAction<boolean>>;
};

const BtnCellRenderer = ({ params, setRefresh, setSortByInsert }: BtnCellRendererProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    //TODO: itt attól függ melyik van használatban
    return (
        <>
            <TableEditButton tooltip={'Szerkesztés'} className={classes.button} target={`${params.row.id}/edit`} />
            <TableButton
                tooltip="Duplikálás"
                color="primary"
                onClick={() =>
                    messageTemplatesService
                        .clone(params.row.id)
                        .then(() => {
                            setSortByInsert(true);
                            dispatch(create({ type: 'success', message: `Sikeres másolás!` }));
                            setRefresh((prev) => !prev);
                        })
                        .catch(() => dispatch(create({ type: 'error', message: 'A másolás sikertelen!' })))
                }
                className={classes.button}
            >
                <ContentCopy />
            </TableButton>
            {params.row.isDeletable && (
                <TableDeleteButton
                    tooltip={'Törlés'}
                    deleteAction={() => {
                        messageTemplatesService
                            .delete(params.row.id)
                            .then(() => {
                                dispatch(create({ type: 'success', message: `Sikeres törlés!` }));
                                setRefresh((prev) => !prev);
                            })
                            .catch(() => dispatch(create({ type: 'error', message: 'A törlés sikertelen!' }))); //TODO: sikeres törlés
                    }}
                    confirmationMessage={'Biztosan törlöd a következő elemet:'}
                    confirmationElement={params.row.name}
                    className={classes.button}
                />
            )}
        </>
    );
};

export default BtnCellRenderer;

import Tooltip from '@mui/material/Tooltip';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Input, Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { Dispatch, SetStateAction } from 'react';
import ActionCellRenderer from '../../../../components/ActionCellRenderer';
import i18n from '../../../../i18n';
import BtnCellRenderer from './BtnCellRenderer';
import StatusCellRenderer from './StatusCellRenderer';
import ViewButtonRenderer from './ViewButtonRenderer';
import { ICampaignFormField } from './types';

export const statuOptions: Option[] = [
    { value: 'sent', label: 'Kiküldött' },
    { value: 'scheduled', label: 'Ütemezett' },
    { value: 'draft', label: 'Piszkozat' },
    { value: 'sending', label: 'Kiküldés alatt' },
];

export const filterFields = (precedingCampaigns: Option<string, string>[]): Input[] => [
    {
        name: 'precedingCampaign',
        label: 'Megelőző kampány',
        type: 'multiselect',
        options: precedingCampaigns,
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: 'name',
        label: 'Elnevezés',
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        type: 'date',
        label: 'Kiküldés ideje (-tól)',
        name: 'submitDateFrom',
        format: { xs: 12, md: 4, lg: 4 },
        props: {
            inputProps: { min: '1970-01-01', max: '2099-12-31' },
        },
    },
    {
        type: 'date',
        label: 'Kiküldés ideje (-ig)',
        name: 'submitDateUntil',
        format: { xs: 12, md: 4, lg: 4 },
        props: {
            inputProps: { min: '1970-01-01', max: '2099-12-31' },
        },
    },
    {
        name: 'formTemplate',
        label: 'Űrlap',
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        type: 'date',
        label: 'Űrlap kitöltési határidő (-tól)',
        name: 'formTemplateValidityFrom',
        format: { xs: 12, md: 4, lg: 4 },
        props: {
            inputProps: { min: '1970-01-01', max: '2099-12-31' },
        },
    },
    {
        type: 'date',
        label: 'Űrlap kitöltési határidő (-ig)',
        name: 'formTemplateValidityTo',
        format: { xs: 12, md: 4, lg: 4 },
        props: {
            inputProps: { min: '1970-01-01', max: '2099-12-31' },
        },
    },
    {
        name: 'messageTemplate',
        label: 'Üzenetsablon',
        format: { xs: 12, md: 4, lg: 4 },
    },
    {
        label: 'Létrehozó',
        name: 'author',
    },
    {
        label: 'Létrehozás ideje (-tól)',
        name: 'insertDateFrom',
        type: 'datetime-local',
    },
    {
        label: 'Létrehozás ideje (-ig)',
        name: 'insertDateTo',
        type: 'datetime-local',
    },
    {
        label: 'Módosító',
        name: 'editor',
    },
    {
        label: 'Módosítás ideje (-tól)',
        name: 'updateDateFrom',
        type: 'datetime-local',
    },
    {
        label: 'Módosítás ideje (-ig)',
        name: 'updateDateTo',
        type: 'datetime-local',
    },
    {
        name: 'status',
        label: 'Státusz',
        type: 'multiselect',
        options: statuOptions,
        format: { xs: 12, md: 4, lg: 4 },
    },
];

export const tableColumns = (matches: boolean, setRefresh: Dispatch<SetStateAction<boolean>>) => [
    {
        headerName: 'Megelőző kampány',
        field: 'precedingCampaign',
        width: 200,
    },
    {
        headerName: 'Elnevezés',
        field: 'name',
        width: 250,
    },
    {
        headerName: 'Kiküldés ideje',
        field: 'sentAt',
        width: 160,
        renderCell: (params: GridRenderCellParams) => <>{params.row.submitDate || params.value}</>,
    },
    {
        headerName: 'Üzenetsablon',
        field: 'messageTemplate',
        valueGetter: (params: GridValueGetterParams) => params.value?.name,
        width: 160,
    },
    {
        headerName: 'Űrlap',
        field: 'formTemplate',
        width: 160,
    },
    {
        headerName: 'Űrlap kitöltési határidő',
        field: 'formTemplateValidity',
        width: 160,
    },
    {
        headerName: 'Létrehozó',
        field: 'author',
        width: 160,
    },
    {
        headerName: 'Létrehozás ideje',
        field: 'insertDate',
        width: 160,
    },
    {
        headerName: 'Módosító',
        field: 'editor',
        width: 160,
    },
    {
        headerName: 'Módosítás ideje',
        field: 'updateDate',
        width: 160,
    },
    {
        headerName: 'Státusz',
        field: 'status',
        renderCell: (params: GridRenderCellParams) => <StatusCellRenderer params={params} />,
        width: 120,
    },
    {
        headerName: 'Műveletek',
        field: 'operations',
        width: matches ? 200 : 90,
        renderCell: (params: GridRenderCellParams) => (
            <ActionCellRenderer>
                <BtnCellRenderer params={params} setRefresh={setRefresh} />
            </ActionCellRenderer>
        ),
        sortable: false,
    },
];
export const defaultFields: ICampaignFormField[] = [
    { label: 'E-mail cím', name: 'email' },
    { label: 'Kitöltés ideje', name: 'insertDate' },
    {
        label: 'Műveletek',
        name: 'operations',
        renderCell: ViewButtonRenderer,
    },
].map((field) => ({ ...field, visible: true, default: true }));

/**
 * The default fields for the listPage table
 */
export function transformToTableFields(fields: ICampaignFormField[]): GridColDef[] {
    return fields
        .filter((elem) => elem.visible)
        .map((field) => ({
            headerName: field.label,
            field: field.name,
            minWidth: 150,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tooltip
                        title={
                            params.row[field.name] === 'deleted_user'
                                ? i18n.t('modadmin.campaign.campaignForm.table.deleted_user')
                                : params.row[field.name]
                        }
                        placement="bottom"
                        className={'cds'}
                    >
                        {params.row[field.name] === 'deleted_user' ? (
                            <i>{i18n.t('modadmin.campaign.campaignForm.table.deleted_user')}</i>
                        ) : (
                            <span>{params.row[field.name]}</span>
                        )}
                    </Tooltip>
                );
            },
            ...field,
            ...((field.name === 'email' || field.name === 'insertDate') && { sortable: true }),
        }));
}

import { FormikListPage } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { columns, filterFields } from './fields';
import { PROJECT_NAME } from '../../../utils/AppConst';
import systemLogService from '../../../services/Central/systemLogService';
import MCCLoading from '../../../components/MCCLoading';

export default function SystemLog() {
    const [typeOptions, setTypeOptions] = useState<Option[]>([]);
    const [eventOptions, setEventOptions] = useState<Option[]>([]);
    const [unitOptions, setUnitOptions] = useState<Option[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Rendszernapló',
        breadcrumbs: {
            admin: 'Adminisztráció',
            'clientEndpoints.central_system_logs': 'Rendszernapló',
        },
    };

    useEffect(() => {
        axios
            .all([
                systemLogService.types().then((res) => {
                    setTypeOptions(res.data);
                }),
                systemLogService.units().then((res) => {
                    setUnitOptions(res.data);
                }),
                systemLogService.events().then((res) => {
                    setEventOptions(res.data);
                }),
            ])
            .finally(() => setLoading(false));
    }, []);

    if (loading) return <MCCLoading />;

    return (
        <FormikListPage
            autoload
            header={header}
            service={systemLogService}
            columns={columns()}
            defaultSort={{ createdAt: 'desc' }}
            filter={{ fields: filterFields(typeOptions, unitOptions, eventOptions) }}
            responseKey="data"
        />
    );
}

import { clientEndpoints } from '../../../utils/clientEndpoints';
import StatisticsGroupedPage from '../../../pages/Unit/Statistics/Grouped';

const StatisticsGrouped = {
    path: clientEndpoints.unit_statistics_grouped,
    children: [
        {
            path: '',
            element: <StatisticsGroupedPage />,
            name: 'unit_statistics_grouped',
        },
    ],
};

export default StatisticsGrouped;

import { IForm } from '../../utils/AppConst';
import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import api from '../../api';
import { AxiosResponse } from 'axios';

export interface Form extends IForm {
    value: string;
    key: string;
    keyTranslated: string;
    helperText: string;
}

export type OwnCampaignValidEmailDomainsType = {
    value: string[];
};

const settingsService = {
    ...crudTemplate(endpoints.central.settings.main),
    getOwnCampaignValidEmailDomains: (): Promise<AxiosResponse<OwnCampaignValidEmailDomainsType>> =>
        api.get(endpoints.central.settings.ownCampaignValidEmailDomains),
};

export type SettingsServiceType = typeof settingsService;

export default settingsService;

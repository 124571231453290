import useMediaQuery from '@mui/material/useMediaQuery';
import { FormikListPage } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MCCLoading from '../../../components/MCCLoading';
import usersService from '../../../services/Central/userService';
import { RootState } from '../../../store';
import { ViewLayoutType } from '../../../store/auth';
import { createListPageHeader } from '../../../utils/Headers';
import { GroupedOption } from '../../Unit/Users/types';
import { filterFields, tableFields, transformRoleOptions } from './fields';
import unitsService, { IUnitsForm } from '../../../services/Central/units';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';

export default function Users() {
    const header: HeaderProps = createListPageHeader('administration', 'users');
    const [roleOptions, setRoleOptions] = useState<GroupedOption[]>([]);
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const matches = useMediaQuery('(min-width:900px)', { noSsr: true });
    const { user } = useSelector((state: RootState) => state.auth);
    const [unitOptions, setUnitOptions] = useState<Option[]>([]);

    useEffect(() => {
        if (user) {
            setPageLoading(true);
            axios
                .all([
                    usersService
                        .getRoles()
                        .then((response: AxiosResponse) => setRoleOptions(transformRoleOptions(response.data, user))),
                    unitsService.filter({}).then((res) =>
                        setUnitOptions(
                            res.data.data.map((unit: IUnitsForm) => ({
                                label: unit.name,
                                value: unit.id?.toString() || '',
                            })),
                        ),
                    ),
                ])
                .finally(() => setPageLoading(false));
        }
    }, [user]);

    if (pageLoading) return <MCCLoading />;

    return (
        <FormikListPage
            service={usersService}
            header={header}
            autoload={true}
            filter={{
                fields: filterFields(roleOptions, unitOptions),
            }}
            responseKey="data"
            columns={tableFields(matches, setRefresh, ViewLayoutType.Central)}
            refresh={refresh}
            defaultSort={{ email: 'asc' }}
            newLabel={'Felhasználó hozzáadása'}
            tableProps={{
                initialState: {
                    pinnedColumns: { right: ['operations'] },
                    sorting: {
                        sortModel: [
                            {
                                field: 'email',
                                sort: 'asc',
                            },
                        ],
                    },
                },
            }}
        />
    );
}

import Chip from '@mui/material/Chip';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { CampaignStatus } from '../../../../services/campaigns/campaigns';

export type CellRendererProps = {
    params: GridRenderCellParams;
};

//TODO: tooltip

export default function StatusCellRenderer({ params }: CellRendererProps) {
    const status = params.value;

    if (status === 0 || status === undefined || status === null || status === 'scheduled') {
        return <Chip color="error" label="Ütemezett" />;
    }

    if (status === CampaignStatus.ScheduledInstant) {
        return <Chip color="error" label="Kiküldés alatt" />;
    }

    if (status === 'draft') {
        return <Chip color="warning" label="Piszkozat" />;
    }

    if (status === 'sending') {
        return <Chip color="info" label="Kiküldés alatt" />;
    }

    return <Chip color="success" label="Kiküldött" />;
}

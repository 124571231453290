import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useRef, useState } from 'react';
import usersService, { ChangePasswordForm } from '../../services/Central/userService';
import { transformApiErrors } from '../../utils/AppConst';
import { HttpResponseStatusCode } from '../../utils/HttpResponseStatusCode';
import { create } from '../../store/notification';
import { useDispatch } from 'react-redux';

export default function ChangePasswordDialog(props: {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    onError: () => void;
}): JSX.Element {
    const { open, onClose, onSuccess, onError } = props;
    const formRef = useRef<FormikProps<ChangePasswordForm>>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const changePassword = (form: ChangePasswordForm, { setErrors }: FormikHelpers<ChangePasswordForm>) => {
        setIsLoading(true);
        usersService
            .changePassword(form)
            .then(() => {
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres jelszóváltoztatás!',
                    }),
                );
                onSuccess();
            })
            .catch((error) => {
                if (error?.response?.status === HttpResponseStatusCode.UNPROCESSABLE_ENTITY) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                } else {
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Hiba a jelszóváltoztatás során!',
                        }),
                    );
                    onError();
                }
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Jelszóváltoztatás</DialogTitle>
            <DialogContent style={{ paddingTop: '24px' }}>
                <Formik
                    initialValues={{ password: '', newPassword: '', newPasswordConfirmation: '' }}
                    onSubmit={changePassword}
                    innerRef={formRef}
                >
                    {({ errors, touched }) => (
                        <Form style={{ width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field as="div" field="email">
                                        <TextField
                                            required
                                            name="password"
                                            label="Jelenlegi jelszó"
                                            margin="normal"
                                            error={!!(errors.password && touched.password)}
                                            helperText={touched.password && errors.password}
                                            fullWidth
                                            autoFocus
                                            type="password"
                                        />
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field as="div" field="newPassword">
                                        <TextField
                                            required
                                            name="newPassword"
                                            label="Új jelszó"
                                            margin="normal"
                                            error={!!(errors.newPassword && touched.newPassword)}
                                            helperText={touched.newPassword && errors.newPassword}
                                            fullWidth
                                            type="password"
                                        />
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field as="div" field="newPasswordConfirmation">
                                        <TextField
                                            required
                                            name="newPasswordConfirmation"
                                            label="Új jelszó megint"
                                            margin="normal"
                                            error={
                                                !!(errors.newPasswordConfirmation && touched.newPasswordConfirmation)
                                            }
                                            helperText={
                                                touched.newPasswordConfirmation && errors.newPasswordConfirmation
                                            }
                                            fullWidth
                                            type="password"
                                        />
                                    </Field>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        if (formRef.current) {
                            formRef.current.handleSubmit();
                        }
                    }}
                    loading={isLoading}
                >
                    Mehet
                </LoadingButton>
                <LoadingButton color="primary" variant="outlined" onClick={() => onClose()}>
                    Vissza
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

import { GridColDef } from '@mui/x-data-grid';
import { Input } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';

export const filterFields = (campaignOptions: Option[]): Input[] => [
    {
        name: 'campaigns',
        label: 'Kampány',
        type: 'multiselect',
        format: { xs: 12, sm: 12, lg: 12 },
        options: campaignOptions,
    },
    {
        label: 'Összes címzett (-tól)',
        name: 'totalEmailsMin',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Összes címzett (-ig)',
        name: 'totalEmailsMax',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Hibás címzett (-tól)',
        name: 'totalWrongEmailsMin',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Hibás címzett (-ig)',
        name: 'totalWrongEmailsMax',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Emailt megtekintettek száma (-tól)',
        name: 'totalSeenMin',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Emailt megtekintettek száma (-ig)',
        name: 'totalSeenMax',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Kitöltött űrlapok száma (-tól)',
        name: 'totalFIllTimesMin',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
    {
        label: 'Kitöltött űrlapok száma (-ig)',
        name: 'totalFIllTimesMax',
        type: 'text',
        format: { xs: 12, md: 6, lg: 3 },
        props: {
            type: 'number',
        },
    },
];

export const tableFields: GridColDef[] = [
    { headerName: 'Kampány', field: 'campaignName' },
    { headerName: 'Összes címzett', field: 'totalEmails' },
    { headerName: 'Hibás címzett', field: 'totalWrongEmails' },
    {
        headerName: 'Emailt megtekintettek száma',
        field: 'totalSeen',
    },
    {
        headerName: 'Kitöltött űrlapok száma',
        field: 'totalFillTimes',
    },
].map((column) => ({ flex: 1, minWidth: 150, ...column }));

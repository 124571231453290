import Grid from '@mui/material/Grid';
import { FormikListPage } from '@silinfo/front-end-template';
import Header, { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../../../components/MCCLoading';
import addressGroupsService from '../../../../services/Unit/addressGroups';
import addressListService, { addressListOnlyGroupService } from '../../../../services/Unit/addressListService';
import { RootState } from '../../../../store';
import { addFields } from '../../../../store/Unit/addressList';
import { IForm, PROJECT_NAME, initialInfo } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import AddressFieldDialogButton from './AddressFieldDialogButton';
import { IAddressesField } from './types';
import SplitButton from '../../../../components/Form/SplitButton';
import { InfoAlert } from '../../../../utils/Components';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';
import { getFilteredFilterFields, getFilteredTableFields } from '.';

interface ResponseField {
    id: number;
    key: string;
    label: string;
}

function transformData(data: ResponseField[], flags: { firstName: number; lastName: number }): IAddressesField[] {
    return data.map((item) => {
        return {
            id: item.id,
            name: item.key,
            label: item.label,
            visible: false || item.key === 'item_' + flags.firstName || item.key === 'item_' + flags.lastName,
            default: false,
        };
    });
}

type Params = {
    groupId: string;
};

export interface IGroupData {
    name: string;
}

export default function IndexOnlyGroup(props: { ownOnly?: boolean }) {
    const { ownOnly } = props;
    const { groupId } = useParams<Params>();
    const [loading, setLoading] = useState(false);
    const [groupData, setGroupData] = useState<IGroupData>({ name: '' });
    const dispatch = useDispatch();
    const { tableFields, filterFields } = useSelector((state: RootState) => state.addressList);
    const { view } = useSelector((state: RootState) => state.auth);
    const location = useLocation();
    const ownAddressFieldsCondition =
        location.pathname.includes('own') && view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;
    const filteredTableFields = getFilteredTableFields(ownAddressFieldsCondition, tableFields);
    const filteredFilterFields = getFilteredFilterFields(ownAddressFieldsCondition, filterFields);
    const [info, setInfo] = useState<IForm>(initialInfo());

    useEffect(() => {
        setLoading(true);
        if (groupId) {
            axios
                .all([
                    addressGroupsService.get2(groupId, true),
                    addressListService.getMessageFields(),
                    addressListService.firstNameLastNameFlags(),
                ])
                .then(
                    axios.spread((group, fields, flags) => {
                        setGroupData(group.data);
                        dispatch(addFields(transformData(fields.data, flags.data)));
                    }),
                )
                .finally(() => setLoading(false));
        }
    }, [dispatch, groupId]);

    if (!groupId) {
        return <>Undefined groupId</>;
    }

    const service = {
        filter: (form: IForm) => {
            setInfo({ ...form, groups: [groupId] });
            return addressListOnlyGroupService.filter(form, groupId);
        },
    };

    if (loading) {
        return <MCCLoading />;
    }

    const breadcrumbs = {
        addresses: 'Címek',
        [clientEndpoints[`unit${ownOnly ? '_own' : ''}_address_groups`]]: 'Csoportok',
        addressesOnlyGroup: groupData?.name || 'Unknown', //idk miért piros
    };

    const headerOnlyGroup: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Csoportok',
        breadcrumbs: {},
    };

    const columns = filteredTableFields.filter((item) => item.field !== 'operations').map((item) => ({ ...item }));

    const exportContext = {
        initiateExport: addressListService.initiateExport,
        checkExport: addressListService.checkExport,
        downloadExport: addressListService.downloadExport,
        filter: info,
    };

    return (
        <Grid container spacing={2}>
            <Header {...{ ...headerOnlyGroup, breadcrumbs }} />
            <InfoAlert
                backText={'Vissza a csoportok listájára'}
                backLink={ownOnly ? clientEndpoints.unit_own_address_groups : clientEndpoints.unit_address_groups}
            >
                <Box>
                    Kiválasztott csoport: <strong>{groupData?.name}</strong>
                </Box>
            </InfoAlert>
            <Grid item xs={12}>
                <FormikListPage
                    autoload
                    header={headerOnlyGroup}
                    service={service}
                    columns={columns}
                    defaultSort={{ email: 'asc' }}
                    filter={{
                        fields: filteredFilterFields,
                    }}
                    responseKey="data"
                    customButtons={
                        <>
                            <AddressFieldDialogButton />
                            <Grid item>
                                <SplitButton placement="top" exportContext={exportContext} />
                            </Grid>
                        </>
                    }
                />
            </Grid>
        </Grid>
    );
}

import { AutoloadTable } from '@silinfo/front-end-template';
import addressListService from '../../../../../services/Unit/addressListService';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useEffect } from 'react';
import { addFields } from '../../../../../store/Unit/campaignView';
import { transformData } from '../../../Addresses/AddressList/fields';
import AddressFieldDialogButton from './AddressFieldDialogButton';
import campaignsService from '../../../../../services/campaigns/campaigns';

export default function AddressListTable({ campaignId }: { campaignId: number }) {
    const dispatch = useDispatch();
    const { tableFields, fieldsAdded } = useSelector((state: RootState) => state.campaignView);

    useEffect(() => {
        if (!fieldsAdded) {
            addressListService.getMessageFields().then((response) => dispatch(addFields(transformData(response.data))));
        }
    }, [dispatch, fieldsAdded]);

    const columns = tableFields.map((item) => ({ ...item }));

    return (
        <AutoloadTable
            title={'Címzettek'}
            columns={columns}
            filter={(form) => campaignsService.addressList(form, campaignId)}
            responseKey="data"
            components={{
                Toolbar: () => (
                    <Grid item container xs={12} spacing={2} style={{ padding: '8px' }}>
                        <Grid item xs={12} textAlign="right">
                            <AddressFieldDialogButton />
                        </Grid>
                    </Grid>
                ),
            }}
            initialState={{
                pagination: {
                    pageSize: 10,
                },
            }}
            rowsPerPageOptions={[10, 25, 50, 100]}
        />
    );
}

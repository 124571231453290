import { endpoints } from '../../utils/endPoints';
import { crudTemplate, exportTemplate } from '../templates';
import api from '../../api';
import { IForm } from '../../utils/AppConst';
import {
    IAddressDeleteCheckbox,
    IAddressDeleteMultipleCheckbox,
} from '../../pages/Unit/Addresses/AddressList/AddressDeleteDialog';

const addressListService = {
    ...crudTemplate(endpoints.unit.address.addressList.main),
    ...exportTemplate(endpoints.unit.address.addressList.main),
    getMessageFields: (query?: Record<string, unknown>) =>
        api.get(endpoints.unit.address.addressList.fields, {
            params: query,
        }),
    getAddressGroups: (query?: { ownerId?: number | string }) =>
        api.get(endpoints.unit.address.addressList.addressGroups, {
            params: query,
        }),
    firstNameLastNameFlags: () => api.get(endpoints.unit.address.addressList.firstNameLastNameFlags),
    connectAddressesToGroups: (form: { groups: number[]; addresses: number[] }) =>
        api.post(endpoints.unit.address.addressList.connectAddressesToGroups, form),
    detachAddressesFromGroups: (form: { groups: number[]; addresses: number[] }) =>
        api.post(endpoints.unit.address.addressList.detachAddressesFromGroups, form),
    deleteAddress: (id: number, data: IAddressDeleteCheckbox) =>
        api.delete(endpoints.unit.address.addressList.deleteAddress.replace('{id}', id?.toString()), {
            data: data,
            params: { form: 1 },
        }),
    getSelectedAddresses: (ids: (string | number)[]) =>
        api.get(endpoints.unit.address.addressList.selectedAddresses, {
            params: ids,
        }),
    import: (form: FormData, onUploadProgress: (progressEvent: { loaded: number; total: number }) => void) => {
        return api.post(endpoints.unit.address.addressList.import.start, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        });
    },
    getImportStatus: (id: number) =>
        api.get(endpoints.unit.address.addressList.import.status.replace('{id}', id.toString())),
    getImportErrorList: (id: number) =>
        api.get(endpoints.unit.address.addressList.import.errorList.replace('{id}', id.toString())),
    deleteMultiple: (addresses: IAddressDeleteMultipleCheckbox) =>
        api.post(endpoints.unit.address.addressList.deleteMultiple, addresses),
    downloadSampleFile: (ownerId: string) =>
        api.get(endpoints.unit.address.addressList.import.downloadSampleFile + '?ownerId=' + ownerId, {
            responseType: 'blob',
        }),
    getImportList: (form: IForm) => api.get(endpoints.unit.address.addressList.import.list, { params: form }),
    downloadUploadedFile: (id: number) =>
        api.get(endpoints.unit.address.addressList.import.downloadUploadedFile.replace('{id}', id.toString()), {
            responseType: 'blob',
        }),
    subscribed: (sessionId: string, form: IForm) =>
        api.get(endpoints.unit.address.addressList.subscribed.replace('{sessionId}', sessionId), { params: form }),
};

export const addressListOnlyGroupService = {
    filter: (form: IForm, groupId: number | string) =>
        api.get(endpoints.unit.address.addressList.onlyGroup.replace('{groupId}', groupId.toString()), {
            params: form,
        }),
};

export default addressListService;

import SuperAdminPage from '../../pages/Central/SuperAdmin';
import SuperAdminForm from '../../pages/Central/SuperAdmin/FormPage';
import { clientEndpoints } from '../../utils/clientEndpoints';

const SuperAdmin = {
    path: clientEndpoints.central_super_admin,
    children: [
        {
            path: '',
            element: <SuperAdminPage />,
            name: 'central_super_admin',
        },
        {
            path: ':id/edit',
            element: <SuperAdminForm />,
            name: 'central_super_admin_edit',
        },
    ],
};

export default SuperAdmin;

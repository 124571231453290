import { GridColDef } from '@mui/x-data-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Input } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import {
    createDefaultFilterFields,
    defaultFields,
    defaultTableFields,
} from '../../pages/Unit/Addresses/AddressList/fields';
import { IAddressesField } from '../../pages/Unit/Addresses/AddressList/types';

export interface IAddressListState {
    fields: IAddressesField[];
    filterFields: Input[];
    tableFields: GridColDef[];
    refresh: boolean;
    multipleTaskDialog: boolean;
    multipleTaskDialogType: MultipleTaskDialogType;
    groups: Option[];
    selectedRows: number;
    multipleTaskGroups: Option[];
}
export type MultipleTaskDialogType = 'DELETE' | 'DELETE_GROUP' | 'ADD_GROUP';
const initGroups: Option[] = [];

const fields = defaultFields();
const filterFields = createDefaultFilterFields();

const initialState: IAddressListState = {
    fields,
    filterFields,
    tableFields: defaultTableFields,
    refresh: false,
    multipleTaskDialog: false,
    multipleTaskDialogType: 'DELETE',
    groups: initGroups,
    multipleTaskGroups: initGroups,
    selectedRows: 0,
};

const addressListSlice = createSlice({
    name: 'addressList',
    initialState,
    reducers: {
        addFields(state, action: PayloadAction<IAddressesField[]>) {
            state.fields = [...action.payload, ...fields];

            addressListSlice.caseReducers.setFilterFields(state, action, true);
            addressListSlice.caseReducers.setTableFields(state, action, true);
        },
        setFields(state, action: PayloadAction<IAddressesField[]>) {
            state.fields = action.payload;
        },
        setRefresh(state) {
            state.refresh = !state.refresh;
        },
        setTableFields(state, action: PayloadAction<IAddressesField[]>, isInitial?: boolean) {
            const tableFieldsCopy = (isInitial ? initialState : state).tableFields.map((field) => ({ ...field }));

            let fieldIndex = 1;

            action.payload.forEach((field) => {
                if (!field.default) {
                    const index = tableFieldsCopy.findIndex((tableField) => tableField.field === field.name);
                    if (index === -1 && field.visible) {
                        tableFieldsCopy.splice(fieldIndex, 0, {
                            ...field,
                            headerName: field.label,
                            field: field.name,
                            minWidth: 100,
                        });
                    }

                    fieldIndex++;

                    if (index !== -1 && !field.visible) {
                        tableFieldsCopy.splice(index, 1);
                    }
                }
            });

            /**
             * Itt kivesszük a műveletek oszlopot és a tömb végére tesszük
             */
            const operationsIndex = tableFieldsCopy.findIndex((field) => field.field === 'operations');
            if (operationsIndex !== -1) {
                const operationsField = tableFieldsCopy.splice(operationsIndex, 1);
                tableFieldsCopy.push(...operationsField);
            }

            state.tableFields = tableFieldsCopy.map((field) => ({ ...field, flex: 1 }));
        },
        setFilterFields(state, action: PayloadAction<IAddressesField[]>, isInitial?: boolean) {
            const filterFieldsCopy = (isInitial ? initialState : state).filterFields.map((field) => ({
                ...field,
            }));

            let fieldIndex = 1;

            action.payload.forEach((field) => {
                if (!field.default) {
                    const index = filterFieldsCopy.findIndex((filterField) => filterField.name === field.name);
                    if (index === -1 && field.visible && field.label) {
                        filterFieldsCopy.splice(fieldIndex, 0, {
                            ...field,
                            label: field.label,
                            name: field.name,
                            format: { xs: 12, md: 6, lg: 3 },
                        });
                    }

                    fieldIndex++;

                    if (index !== -1 && !field.visible) {
                        filterFieldsCopy.splice(index, 1);
                    }
                }
            });

            state.filterFields = filterFieldsCopy as typeof state.filterFields;
        },
        setAddressGroupOptions(state, action: PayloadAction<Option[]>) {
            const index = state.filterFields.findIndex((filterField) => filterField.name === 'groups');
            const field = state.filterFields[index];

            if (index !== -1 && (field.type === 'select' || field.type === 'multiselect')) {
                field.options = action.payload;
            }

            state.groups = action.payload;
        },
        changeMultipleTaskDialog(state) {
            state.multipleTaskDialog = !state.multipleTaskDialog;
        },
        setMultipleTaskDialogType(state, action: PayloadAction<MultipleTaskDialogType>) {
            state.multipleTaskDialogType = action.payload;
        },
        setGroups(state, action: PayloadAction<Option[]>) {
            state.multipleTaskGroups = action.payload;
        },
        setSelectedRows(state, action: PayloadAction<number>) {
            state.selectedRows = action.payload;
        },
    },
});

export const {
    addFields,
    setFields,
    setTableFields,
    setFilterFields,
    setAddressGroupOptions,
    changeMultipleTaskDialog,
    setMultipleTaskDialogType,
    setRefresh,
    setGroups,
    setSelectedRows,
} = addressListSlice.actions;
export default addressListSlice.reducer;

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Accordion, CancelButton, CKEditor, Page, SubmitButton } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MCCLoading from '../../../../components/MCCLoading';
import settingsService from '../../../../services/Unit/settingsService';
import { create } from '../../../../store/notification';
import colors from '../../../../styles/colors';
import { PROJECT_NAME, transformApiErrors } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { ErrorResponse } from '../../../../utils/Interfaces/interfaces';
import { ISettingsForm } from './types';
import { initialForm, useStyles } from './utils';

export default function SettingsForm() {
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState<ISettingsForm>(initialForm);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [infoOpen, setInfoOpen] = useState<boolean>(true);
    const { id } = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const varKeysThatHasDefaultValue = [
        'unsubscribed_text',
        'unsubscribe_text',
        'unsubscribe_disabled_text',
        'message_view_text',
    ];
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Beállítások',
        breadcrumbs: {
            admin: 'Adminisztráció',
            [clientEndpoints.unit_settings]: 'Beállítások',
            [clientEndpoints.unit_settings_edit.replace(':id', String(id))]: 'Szerkesztés',
        },
    };

    useEffect(() => {
        if (id) {
            setLoading(true);
            settingsService
                .get(id)
                .then((response) => setInitialValues(response.data))
                .finally(() => setLoading(false));
        }
    }, [id]);

    const handleSubmit = (form: ISettingsForm, { setErrors }: FormikHelpers<ISettingsForm>) => {
        if (id) {
            setFormLoading(true);

            settingsService
                .update(id, form)
                .then(() => {
                    dispatch(
                        create({
                            type: 'success',
                            message: 'Sikeres szerkesztés',
                            redirect: clientEndpoints.unit_settings,
                        }),
                    );
                })
                .catch((error: ErrorResponse) => {
                    if (error.response?.status === 422) {
                        setErrors(transformApiErrors(error.response?.data.violations));
                    }
                    dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
                })
                .finally(() => setFormLoading(false));
        }
    };

    const handleDefault = () => {
        if (id) {
            setFormLoading(true);

            settingsService
                .default(id)
                .then(() => {
                    dispatch(
                        create({
                            type: 'success',
                            message: 'Beállítás sikeresen alapértelmezettre állítva!',
                            redirect: clientEndpoints.unit_settings,
                        }),
                    );
                })
                .catch(() => {
                    dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
                })
                .finally(() => setFormLoading(false));
        }
    };

    if (loading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={header.title}>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {({ errors, touched, values, setFieldValue }) => {
                        return (
                            <Form>
                                <Grid container spacing={2}>
                                    {infoOpen && values.helperText && (
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    backgroundColor: colors.insert,
                                                    padding: '8px',
                                                }}
                                            >
                                                <Grid item xs={12} container direction="row">
                                                    <Grid item xs={11}>
                                                        <Typography className={classes.title}>Info</Typography>
                                                    </Grid>
                                                    <Grid container item xs={1} direction="row-reverse">
                                                        <Typography
                                                            className={classes.xButton}
                                                            onClick={() => setInfoOpen(false)}
                                                        >
                                                            X
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Divider />
                                                <Typography className={classes.smallerTitle}>
                                                    {values.helperText}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Field as="div" field="value">
                                            {values.type !== 'ckeditor' ? (
                                                <TextField
                                                    name="value"
                                                    label={values.keyTranslated}
                                                    error={Boolean(errors.value && touched.value)}
                                                    helperText={touched.value && errors.value}
                                                    value={values.value}
                                                    onChange={(e) => setFieldValue('value', e.target.value)}
                                                    fullWidth
                                                />
                                            ) : (
                                                <CKEditor
                                                    name="value"
                                                    format={{ xs: 12 }}
                                                    data={values.value || ''}
                                                    onChange={(_: unknown, editor: { getData: () => string }) =>
                                                        setFieldValue('value', editor.getData())
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={6} md={2}>
                                            <SubmitButton type="submit" fullWidth loading={formLoading}>
                                                Mentés
                                            </SubmitButton>
                                        </Grid>
                                        {varKeysThatHasDefaultValue.includes(values.key) && (
                                            <Grid item xs={6} md={2}>
                                                <SubmitButton
                                                    type="button"
                                                    fullWidth
                                                    loading={formLoading}
                                                    onClick={handleDefault}
                                                >
                                                    Alapértelmezett
                                                </SubmitButton>
                                            </Grid>
                                        )}
                                        <Grid item xs={6} md={2}>
                                            <CancelButton
                                                type="button"
                                                fullWidth
                                                onClick={() => navigate(clientEndpoints.unit_settings)}
                                            >
                                                Mégsem
                                            </CancelButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Accordion>
        </Page>
    );
}

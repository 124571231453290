import Grid from '@mui/material/Grid';
import { GridCallbackDetails, GridSelectionModel } from '@mui/x-data-grid-pro';
import { MuiTable } from '@silinfo/front-end-template/lib/esm/components/Table';
import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import addressListService from '../../../../services/Unit/addressListService';
import campaignsService from '../../../../services/campaigns/campaigns';
import { RootState, store } from '../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';
import { IForm } from '../../../../utils/AppConst';
import { CheckboxMemo } from '../../Addresses/AddressList/CheckboxMemo';
import { MemoizedTableCell } from '../../Addresses/AddressList/MemoizedTableCell';
import AddressFilter from './AddressFilter';
import Header from './Header';
import { Info } from './types';
import {
    defaultSort,
    initInfo,
    isOwnParams,
    paginatorInfoBuild,
    transferFilterToFilterData,
    transferFilterToOrder,
} from './utils';

const OptimizedAddressFilter = memo(AddressFilter);
const fixTableProps = {
    server: true,
    keepNonExistentRowsSelected: true,
    disableVirtualization: true,
    checkboxSelection: true,
    disableSelectionOnClick: true,
};
/**
 *
 * @param header - objektum, amit a Header vár (lásd Header dok.)
 * @param filter - objektum, amit a Filter vár (lásd Filter dok.)
 * @param table - objektum, amit a Table vár (lásd Table dok.)
 */
export default function AddressListPage({
    setSelected,
    selected,
    sessionId,
}: {
    setSelected: Dispatch<SetStateAction<GridSelectionModel>>;
    selected: GridSelectionModel;
    sessionId: string;
}) {
    const isOwn = store.getState().auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [info, setInfo] = useState<Info>(initInfo);
    const [addresses, setAddresses] = useState([]);
    const [checkedCount, setCheckedCount] = useState<number>(0);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [filterForm, setFilterForm] = useState<IForm>(isOwnParams(isOwn));
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const { tableFields, refresh } = useSelector((state: RootState) => state.addressList);
    const columns = tableFields.map((item) => ({ ...item })).filter((item) => item.field !== 'operations');

    const handleResponse = useCallback((response: AxiosResponse) => {
        const totalItems = response.data.metadata?.allCount || 0;
        setIsChecked(!!response.data.allChecked);
        setInfo((prev) => ({
            ...prev,
            count: totalItems,
            metadata: response.data.metadata || {
                'allCount': totalItems,
                'filteredCount': totalItems,
                'lastPage': totalItems ? Math.ceil(totalItems / prev.perpage) : 1,
                'page': prev.page,
                'perpage': prev.perpage,
                'rowCount': totalItems,
            },
        }));
        setData(response.data.data);
    }, []);

    const service = useMemo(
        () => ({
            filter: (form: IForm) => {
                setLoading(true);
                setFilterForm(form);
                return addressListService
                    .subscribed(sessionId, form)
                    .then((res) => {
                        setAddresses(res.data.data);
                        setCheckedCount(res.data.metadata.checked);
                        setSelected(
                            res.data.data.filter((item: IForm) => item.checked === true).map((item: IForm) => item.id),
                        );
                        handleResponse(res);
                        return res;
                    })
                    .finally(() => setLoading(false));
            },
        }),
        [sessionId, setSelected, handleResponse],
    );

    const fetchData = useCallback(
        (form: Info & { filter: Record<string, unknown> }) => {
            setLoading(true);
            service.filter({
                ...form.filter,
                ...paginatorInfoBuild(form),
                isOwn,
                showAddressType: filterForm.showAddressType || isOwnParams(isOwn).showAddressType,
            });
        },
        [filterForm.showAddressType, isOwn, service],
    );

    useEffect(() => {
        if (firstRender) {
            fetchData({
                ...initInfo,
                filter: { showAddressType: [isOwn ? 'onlyOwn' : 'onlyCommon'], isOwn: isOwn ? 'true' : 'false' },
            });
            setFirstRender(false);
        }
    }, [fetchData, refresh, isOwn, firstRender]);

    const setValueByKey = (key: string, value: unknown) => {
        setInfo((prev) => ({ ...prev, [key]: value }));
    };

    const TableHeader = useCallback(
        () => (
            <Header
                checkedCount={checkedCount}
                setCheckedCount={setCheckedCount}
                isChecked={isChecked}
                addresses={addresses}
                setIsChecked={setIsChecked}
                setSelected={setSelected}
                filterForm={filterForm}
                sessionId={sessionId}
                showAddressType={filterForm.showAddressType as string[]}
                isOwn={isOwn}
            />
        ),
        [checkedCount, isChecked, addresses, setSelected, filterForm, sessionId, isOwn],
    );

    return (
        <>
            <OptimizedAddressFilter service={service} setValueByKey={setValueByKey} />
            <Grid item xs={12}>
                <MuiTable
                    {...{
                        ...fixTableProps,
                        columns,
                        loading,
                        rows: data,
                        info,
                        setInfo: setValueByKey,
                        initialState: {
                            sorting: {
                                sortModel: [
                                    {
                                        field: Object.keys(defaultSort)[0],
                                        sort: Object.values(defaultSort)[0],
                                    },
                                ],
                            },
                            showAddressType: [isOwn ? 'onlyOwn' : 'onlyCommon'],
                            isOwn: isOwn ? 'true' : 'false',
                        },
                        columnBuffer: columns.length,
                        refresh,
                        components: {
                            Toolbar: TableHeader,
                            Cell: MemoizedTableCell,
                            BaseCheckbox: CheckboxMemo,
                        },
                        onSelectionModelChange: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => {
                            const selectedRows = selectionModel.filter((id) => !selected.includes(id));
                            const unselectedRows = selected.filter((id) => !selectionModel.includes(id));
                            setSelected(selectionModel);
                            const handleSelection = (type: 'selected' | 'unselected') => {
                                const rows = type === 'selected' ? selectedRows : unselectedRows;
                                if (rows.length < 1) return;
                                const commonParams = {
                                    session: sessionId,
                                    checked: type === 'selected',
                                    filterData: transferFilterToFilterData(filterForm),
                                };
                                if (rows.length === 1) {
                                    campaignsService
                                        .saveFormAddressList({
                                            address: +rows[0],
                                            ...commonParams,
                                        })
                                        .then((res) => setIsChecked(res.data.allChecked));

                                    setCheckedCount((prev) => prev + (type === 'selected' ? 1 : -1));
                                } else {
                                    campaignsService
                                        .saveManyFormAddressList({
                                            page: details.api.state.pagination.page + 1,
                                            perpage: info.perpage ?? 25,
                                            order: transferFilterToOrder(filterForm),
                                            isOwn: isOwn,
                                            showAddressType: (filterForm.showAddressType as string[]) || [
                                                isOwn ? 'onlyOwn' : 'onlyCommon',
                                            ],
                                            ...commonParams,
                                        })
                                        .then((res) => {
                                            setCheckedCount(res.data.checked);
                                            setIsChecked(res.data.allChecked);
                                        });
                                }
                            };
                            handleSelection('selected');
                            handleSelection('unselected');
                        },
                        selectionModel: selected,
                        searchfunc: fetchData,
                    }}
                />
            </Grid>
        </>
    );
}

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FieldGenerator } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { FormikProps } from 'formik';
import { Children } from 'react';
import Autocomplete from '../../../../components/Autocomplete';
import { formFields } from './../fields';
import { IEmailsFormType } from './../types';
import DynamicForm from '../../../../components/DynamicForm/DynamicForm';
import { dynamicFields } from './utils';
import emailsService from '../../../../services/Unit/emails';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';

export default function BaseForm(props: FormikProps<IEmailsFormType>) {
    const { view } = useSelector((state: RootState) => state.auth);

    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <FormControl component="fieldset" sx={{ width: '100%', mt: 3 }}>
                    <Typography variant="h5" gutterBottom>
                        Alapadatok
                    </Typography>
                    {Children.toArray(
                        formFields.map((field) =>
                            FieldGenerator({
                                ...field,
                                ...props,
                                props: {
                                    ...field.props,
                                    disabled: view.unitCampaignManagement === ViewUnitCampaignManagementType.Own,
                                },
                            }),
                        ),
                    )}
                    {view.unitCampaignManagement !== ViewUnitCampaignManagementType.Own && (
                        <Autocomplete
                            textFieldProps={{ label: 'Hozzárendelt felhasználók' }}
                            getter={(term) => emailsService.userSearch(term)}
                            createOptions={(data: Option[]) => data}
                            autocompleteProps={{
                                onChange: (_, values) => {
                                    props.setFieldValue('assignedUsers', values);
                                },
                                multiple: true,
                                value: props.values.assignedUsers,
                            }}
                        />
                    )}
                </FormControl>
            </Grid>
            <DynamicForm
                formKey="signs"
                title="Aláírások"
                noItemText="Nincs hozzárendelt aláírás."
                itemText="Aláírás"
                fields={dynamicFields}
            />
        </Grid>
    );
}

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Checkbox, Loading, SubmitButton } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { create } from '../../../../store/notification';
import addressListService from '../../../../services/Unit/addressListService';
import { setRefresh } from '../../../../store/Unit/addressList';
import { Option } from '../../../../components/Form/Form';

export interface AddressDeleteDialogProps {
    open: boolean;
    handleClose: () => void;
    selected: number | (string | number)[];
}

export interface IAddressDeleteCheckbox {
    imported_data: number;
    sent_email_content: number;
    filled_forms: number;
}

export interface IAddressDeleteMultipleCheckbox {
    addresses: (string | number)[];
    options: {
        imported_data: number;
        sent_email_content: number;
        filled_forms: number;
    };
}

const checkboxes: Option[] = [
    { value: 'imported_data', label: 'Importált adatok' },
    { value: 'sent_email_content', label: 'Küldött email tartalom' },
    { value: 'filled_forms', label: 'Kitöltött űrlapok' },
];

function AddressDeleteDialog(props: AddressDeleteDialogProps) {
    const { handleClose, open, selected } = props;

    const initialState = {
        imported_data: 0,
        sent_email_content: 0,
        filled_forms: 0,
    };
    const [checkboxForm, setCheckBoxForm] = useState<IAddressDeleteCheckbox>(initialState);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    function handleCancel() {
        handleClose();
        setCheckBoxForm(initialState);
    }

    const handleSubmit = () => {
        setLoading(true);
        (typeof selected === 'number'
            ? addressListService.deleteAddress(selected, checkboxForm)
            : addressListService.deleteMultiple({
                  addresses: selected,
                  options: checkboxForm,
              })
        )
            .then(() => dispatch(create({ type: 'success', message: 'Sikeres törlés.' })))
            .catch(() => dispatch(create({ type: 'error', message: 'Hiba az opciók betöltése során' })))
            .finally(() => {
                dispatch(setRefresh());
                handleClose();
                setCheckBoxForm(initialState);
                setLoading(false);
            });
    };

    function handleCheckboxClick(e: React.ChangeEvent<HTMLInputElement>) {
        const name = e.target.name as keyof IAddressDeleteCheckbox;

        setCheckBoxForm((prev) => ({ ...prev, [name]: prev[name] === 0 ? 1 : 0 }));
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid item container>
                <Grid item xs={12}>
                    <DialogTitle>Címzett törlése</DialogTitle>
                </Grid>
                {!loading ? (
                    <>
                        <Grid item container xs={12} padding={'1em'}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Kérjük jelöld ki, hogy a címzetthez kapcsolódóan mely adatokat szeretnéd törölni az
                                    alábbiak közül! Figyelem, a törlés nem vonható vissza!
                                </Typography>
                            </Grid>
                            {checkboxes.map((checkbox) => (
                                <Grid item xs={12} key={checkbox.value}>
                                    <Checkbox
                                        label={checkbox.label}
                                        checkbox={{
                                            checked: !!checkboxForm[checkbox.value as keyof IAddressDeleteCheckbox],
                                            name: checkbox.value,
                                            onChange: handleCheckboxClick,
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            <DialogActions>
                                <SubmitButton
                                    variant={'contained'}
                                    style={{ backgroundColor: 'red' }}
                                    onClick={handleSubmit}
                                >
                                    Törlés
                                </SubmitButton>
                                <Button variant="outlined" onClick={handleCancel}>
                                    Bezár
                                </Button>
                            </DialogActions>
                        </Grid>
                    </>
                ) : (
                    <Loading noHeight />
                )}
            </Grid>
        </Dialog>
    );
}

export default AddressDeleteDialog;

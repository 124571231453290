import UnitsPage from '../../pages/Central/Units';
import UnitsForm from '../../pages/Central/Units/Form';
import { clientEndpoints } from '../../utils/clientEndpoints';

const Units = {
    path: clientEndpoints.central_units,
    children: [
        {
            path: '',
            element: <UnitsPage />,
            name: 'central_units',
        },
        {
            path: 'new',
            element: <UnitsForm />,
            name: 'central_units_create',
        },
        {
            path: 'edit/:id',
            element: <UnitsForm />,
            name: 'central_units_edit',
        },
    ],
};

export default Units;

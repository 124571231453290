import SettingsPage from '../../../pages/Unit/Administration/Settings';
import SettingsForm from '../../../pages/Unit/Administration/Settings/Form';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const Settings = {
    path: clientEndpoints.unit_settings,
    children: [
        {
            path: '',
            element: <SettingsPage />,
            name: 'unit_settings',
        },
        {
            path: ':id/edit',
            element: <SettingsForm />,
            name: 'unit_settings_edit',
        },
    ],
};

export default Settings;

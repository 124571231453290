import { makeStyles } from '@mui/styles';
import { ISettingsForm } from './types';

const initialForm: ISettingsForm = {
    id: 0,
    key: '',
    type: '',
    value: '',
    keyTranslated: '',
    helperText: '',
};

const useStyles = makeStyles(() => ({
    title: {
        fontSize: 'larger',
        color: 'white',
        marginLeft: '4px',
    },
    smallerTitle: {
        padding: '4px',
        color: 'white',
    },
    xButton: {
        fontWeight: 'bolder',
        color: 'white',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

export { initialForm, useStyles };

import { Input } from '@silinfo/front-end-template';
import { YES_NO_OPTIONS } from '../../../utils/AppConst';

export const filterFields: Input[] = [
    {
        name: 'name',
        label: 'Név',
        format: { xs: 12, md: 6, lg: 3 },
    },
    {
        name: 'address',
        label: 'E-mail cím',
        format: { xs: 12, md: 6, lg: 3 },
    },
    {
        name: 'isSender',
        label: 'Küldő',
        type: 'multiselect',
        options: YES_NO_OPTIONS,
        format: { xs: 12, md: 6, lg: 3 },
    },
    {
        name: 'isReply',
        label: 'Válasz',
        type: 'multiselect',
        options: YES_NO_OPTIONS,
        format: { xs: 12, md: 6, lg: 3 },
    },
];

export const formFields: Input[] = [
    {
        name: 'name',
        label: 'Név',
        format: { xs: 12 },
    },
    {
        name: 'address',
        label: 'E-mail cím',
        format: { xs: 12 },
    },
    {
        name: 'isSender',
        label: 'Küldő',
        type: 'checkbox',
        format: { xs: 12 },
        options: {
            checked: true,
            unChecked: false,
        },
    },
    {
        name: 'isReply',
        label: 'Válasz',
        type: 'checkbox',
        format: { xs: 12 },
        options: {
            checked: true,
            unChecked: false,
        },
    },
];

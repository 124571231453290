import messageTemplatesService from '../../../../../services/Unit/messageTemplates';
import { store } from '../../../../../store';
import { create } from '../../../../../store/notification';
import { downloadFile } from '../../../../../utils/AppConst';
import { IGeneratedFile } from '../types';

const getMessageFields = (value: IGeneratedFile): string[] => {
    const allStrings = [value.header, value.content, value.footer, value.fileNameGen].join(' ');
    const regex = /%[A-Z_0-9ÁÉÍÓÖŐÚÜŰ]+%/g;
    return allStrings.match(regex) || [];
};

const testPdfGen = async (currentValue: IGeneratedFile, setLoading: (val: boolean) => void) => {
    setLoading(true);
    try {
        const response = await messageTemplatesService.testPdfGen(currentValue);
        try {
            const response_1 = await messageTemplatesService.testPdfDownload(response.data.data);

            downloadFile(
                response_1.data,
                response.data.data.fileName || currentValue.fileNameGen || Date.now() + '.pdf',
                'application/pdf',
            );
        } finally {
            setLoading(false);
        }
    } catch {
        setLoading(false);
        store.dispatch(create({ type: 'error', message: 'Hiba történt a fájl előállítása közben!' }));
    }
};

export { getMessageFields, testPdfGen };

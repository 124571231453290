import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Accordion, CKEditor, FieldGenerator } from '@silinfo/front-end-template';
import { FormikErrors, FormikProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import ReactComplexEditor from '../../../../../components/ComplexEditor';
import { getJWTToken } from '../../../../../utils/AuthHelper';
import { endpoints } from '../../../../../utils/endPoints';
import { IMessageTemplatesFormType } from '../types';
import { messageFieldProps, useStyles } from '../utils';
import DynamicFormPart from './DynamicFormPart';
import { MessageTemplatesMessageFields } from '../BaseFormPage';
import useOpen from '../../../Statistics/hooks/useOpen';
import { IMessageFieldEditor } from './types';
import MessageFieldsDialog from './MessageFieldsDialog';

export default function BaseForm(
    props: FormikProps<IMessageTemplatesFormType> & {
        messageFields: MessageTemplatesMessageFields[];
        globalErrors: FormikErrors<IMessageTemplatesFormType>;
        complex?: boolean;
        readonly?: boolean;
    },
) {
    const { globalErrors, errors, setErrors, complex, readonly, messageFields, ...rest } = props;
    const { setFieldValue } = rest;
    const { isOpen, close, open } = useOpen();
    const classes = useStyles();
    const [editor, setEditor] = useState<IMessageFieldEditor | null>(null);

    useEffect(() => {
        setErrors({ ...errors, ...globalErrors });
    }, [globalErrors, errors, setErrors]);

    const handleComplexChange = useCallback(
        (html: string) => {
            setFieldValue('content', html);
        },
        [setFieldValue],
    );

    const EditMode = complex ? (
        <ReactComplexEditor id="grapesjs-react" setter={handleComplexChange} content={rest.values.content} />
    ) : (
        <>
            <CKEditor
                name={`content`}
                format={{ xs: 12 }}
                style={{ maxHeight: '10vh' }}
                data={props.values?.content || ''}
                onChange={(_: unknown, editor: { getData: () => string }) =>
                    props.setFieldValue(`content`, editor.getData())
                }
                onReady={(ed: IMessageFieldEditor) => {
                    setEditor(ed);
                    const customButton = document.createElement('button');
                    customButton.className = 'ck ck-button';
                    customButton.innerText = 'Üzenetmező beszúrása';
                    customButton.setAttribute('data-cke-tooltip-text', 'Üzenetmező beszúrása');

                    const separator = document.createElement('span');
                    separator.className = 'ck ck-toolbar__separator';
                    customButton.addEventListener('click', (e) => {
                        e.preventDefault();
                        open();
                    });
                    // append to the toolbar
                    document.querySelector('.ck-toolbar__items')?.appendChild(separator);
                    document.querySelector('.ck-toolbar__items')?.appendChild(customButton);
                }}
                isImageUpload
                token={getJWTToken()}
                imageUploadUrl={endpoints.pureUpload.replace(':type', 'ckeditor')}
            />
            <MessageFieldsDialog open={isOpen} editor={editor} handleClose={close} />
        </>
    );

    return (
        <Grid item container spacing={3}>
            <Grid container item xs={12}>
                {FieldGenerator({
                    label: 'Név',
                    name: 'name',
                    type: 'text',
                    format: {
                        xs: 12,
                    },
                    props: {
                        error: !!props?.errors['name'] || !!props?.globalErrors['name'],
                        helperText: props?.errors['name'] || props?.globalErrors['name'],
                        disabled: readonly,
                    },
                    ...props,
                })}
                <Grid container spacing={1} direction="row" alignItems="center" justifyContent="center" item xs={12}>
                    {FieldGenerator({
                        label: 'Címzett neve',
                        name: 'recipientName',
                        type: 'text',
                        format: {
                            xs: 12,
                        },
                        ...props,
                        ...messageFieldProps('recipientName', props, globalErrors, readonly),
                    })}
                </Grid>
                <Grid container spacing={1} direction="row" alignItems="center" justifyContent="center" item xs={12}>
                    {FieldGenerator({
                        label: 'Tárgy',
                        name: 'subject',
                        type: 'text',
                        format: {
                            xs: 12,
                        },
                        ...props,
                        ...messageFieldProps('subject', props, globalErrors, readonly),
                    })}
                </Grid>
                <Grid className={classes.richTextEditor} item container xs={12}>
                    {readonly ? null : (
                        <Grid item xs={12}>
                            <Typography>Tartalom</Typography>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        style={props?.errors['content'] ? { border: '0.5px solid red', borderStyle: 'solid' } : {}}
                    >
                        {readonly ? (
                            <Accordion title="Tartalom">
                                <div dangerouslySetInnerHTML={{ __html: rest.values.content }} />
                            </Accordion>
                        ) : (
                            EditMode
                        )}
                    </Grid>
                    {props?.errors['content'] ? (
                        <Grid item xs={12}>
                            <Typography color="error" style={{ margin: '4px 14px 0 14px' }} variant="caption">
                                {props?.errors['content']}
                            </Typography>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
            <DynamicFormPart messageFields={messageFields} readonly={readonly} />
        </Grid>
    );
}

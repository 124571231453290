import { Accordion, FieldGenerator, Loading, Page } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import messageFieldsService from '../../../services/address/messageFields';
import { RootState } from '../../../store';
import { ViewUnitCampaignManagementType } from '../../../store/auth';
import { PROJECT_NAME } from '../../../utils/AppConst';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import { formFields, initialFormValues } from './fields';
import { IMessageFieldsFormType } from './types';
import { checkPageAccess, checkPageAccessByEntityOwner } from '../../../utils/AccessManagementHelper';

export default function MessageFieldsForm(props: { ownOnly?: boolean }) {
    const { ownOnly } = props;
    const { id } = useParams();
    const back = ownOnly ? clientEndpoints.unit_own_message_fields : clientEndpoints.unit_message_fields;
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState<IMessageFieldsFormType>(initialFormValues);
    const [flag, setFlag] = useState<number | undefined | null>(undefined);
    const { user, view } = useSelector((state: RootState) => state.auth);
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Üzenetmezők',
        breadcrumbs: {
            addresses: 'Címek',
            [back]: 'Üzenetmezők',
            form: `Üzenetmező ${id ? 'szerkesztése' : 'hozzáadása'}`,
        },
    };
    const checkEntityAccess = useCallback(
        (res: AxiosResponse) => checkPageAccessByEntityOwner(res, ownOnly || false),
        [ownOnly],
    );

    checkPageAccess(ownOnly || false, false);

    useEffect(() => {
        if (id) {
            setLoading(true);
            messageFieldsService
                .get(id)
                .then(checkEntityAccess)
                .then((response: AxiosResponse<IMessageFieldsFormType>) => {
                    setFlag(response.data.flag);
                    return setInitialValues(response.data);
                })
                .finally(() => setLoading(false));
        }
    }, [id, checkEntityAccess]);

    const handleSubmit = (form: IMessageFieldsFormType) => {
        const data = {
            ...form,
            ...{
                ownerId:
                    ownOnly && view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ? user?.id : null,
            },
        };

        return id ? messageFieldsService.replace(id, data) : messageFieldsService.create(data);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Page header={header}>
            <Accordion title={header.title}>
                <Form fields={[]} initialValues={initialValues} onSubmit={handleSubmit} back={back}>
                    {(formikProps) => {
                        return (
                            <>
                                {FieldGenerator({
                                    ...formFields[0],
                                    ...formikProps,
                                    props: { ...formFields[0].props, disabled: !!flag },
                                })}
                                {FieldGenerator({
                                    ...formFields[1],
                                    ...formikProps,
                                    props: { ...formFields[1].props, disabled: !!flag },
                                })}
                                {FieldGenerator({
                                    ...formFields[2],
                                    ...formikProps,
                                    props: { ...formFields[2].props },
                                })}
                                {FieldGenerator({
                                    ...formFields[3],
                                    ...formikProps,
                                    props: { ...formFields[3].props, disabled: !!flag },
                                })}
                            </>
                        );
                    }}
                </Form>
            </Accordion>
        </Page>
    );
}

import useMediaQuery from '@mui/material/useMediaQuery';
import { FormikListPage } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import MCCLoading from '../../../components/MCCLoading';
import usersService from '../../../services/Unit/userService';
import { ViewLayoutType } from '../../../store/auth';
import { createListPageHeader } from '../../../utils/Headers';
import { filterFields, tableFields, transformUnitRoleOptions } from '../../Central/Users/fields';
import { GroupedOption } from './types';

export default function Users() {
    const header: HeaderProps = createListPageHeader('administration_unit', 'users');
    const [roleOptions, setRoleOptions] = useState<GroupedOption[]>([]);
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const matches = useMediaQuery('(min-width:900px)', { noSsr: true });

    useEffect(() => {
        setPageLoading(true);
        usersService
            .getUnitRoles()
            .then((response: AxiosResponse) => {
                setRoleOptions(transformUnitRoleOptions(response.data));
            })
            .finally(() => setPageLoading(false));
    }, []);

    if (pageLoading) return <MCCLoading />;

    return (
        <FormikListPage
            service={usersService}
            header={header}
            autoload={true}
            filter={{
                fields: filterFields(roleOptions),
            }}
            responseKey="data"
            columns={tableFields(matches, setRefresh, ViewLayoutType.Unit)}
            defaultSort={{ email: 'asc' }}
            newLabel={'Felhasználó hozzáadása'}
            refresh={refresh}
        />
    );
}

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export interface IImportValidationErrorDialogContentErrorMessage {
    row: number;
    message: string;
}

export interface IImportValidationErrorDialogContent {
    errors: IImportValidationErrorDialogContentErrorMessage[];
}

export default function ImportValidationErrorDialogContent(props: IImportValidationErrorDialogContent): JSX.Element {
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sor</TableCell>
                            <TableCell>Hibaüzenet</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.errors.map((error) => (
                            <TableRow key={error.row}>
                                <TableCell>{error.row}</TableCell>
                                <TableCell>{error.message}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

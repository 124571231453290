import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams } from '@mui/x-data-grid';
import instance from '../../../api/downloadAPI';
import { endpoints } from '../../../utils/endPoints';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function DownloadCellRenderer(params: GridRenderCellParams) {
    const { status, type, id, fileName } = params.row;
    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
        setLoading(true);
        try {
            const response = await instance.get(
                endpoints.unit.exportList.download.replace('{type}', type).replace('{id}', id),
                { responseType: 'blob' },
            );

            const contentType = response.headers['content-type'];

            const blob = new Blob([response.data], { type: contentType });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            setLoading(false);
        }
    };

    if (status === 'finished') {
        return (
            <IconButton color="primary" onClick={handleDownload}>
                {loading ? <CircularProgress size={24} /> : <DownloadIcon />}
            </IconButton>
        );
    }

    return null;
}

import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import API from '../../api/';
import downloadAPI from '../../api/downloadAPI';
import { fixDateRangeFields, IForm } from '../../utils/AppConst';

const findOneEmailPosted = (emailPostedId: number) => {
    return API.get(endpoints.campaigns.emailPosted.getOne.replace(':emailPostedId', emailPostedId?.toString()))
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
};

const groupsWithEmailPosted = (campaignId: string) => {
    return API.get(endpoints.campaigns.addressGroup.groupsWithEmailPosted, { params: { campaignId } })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
};

const filterEmailPosted = (campaignId: number, form: unknown) => {
    return API.get(endpoints.campaigns.emailPosted.main.replace(':campaignId', campaignId?.toString()), {
        params: form,
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
};

const filterFilledForms = (campaignId: number, form: unknown) => {
    return API.get(endpoints.campaigns.filledForms.main.replace(':campaignId', campaignId?.toString()), {
        params: form,
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
};
const campaignsEmailPostedService = {
    ...crudTemplate(endpoints.campaigns.emailPosted.main),
    filterEmailPosted,
    findOneEmailPosted,
    groupsWithEmailPosted,
    filterFilledForms,
    initiateExportFormsFilled: (campaignId: number, form: IForm, filter: IForm) =>
        API.post(
            endpoints.campaigns.filledForms.initiateExport.replace(':campaignId', campaignId?.toString()),
            { ...form },
            { params: fixDateRangeFields(filter) },
        ),
    checkExportFormsFilled: (campaignId: number, exportId: string | number) =>
        API.get(
            endpoints.campaigns.filledForms.checkExport
                .replace(':campaignId', campaignId?.toString())
                .replace(':id', exportId?.toString()),
        ),
    downloadExportFormsFilled: (campaignId: number, exportId: string | number) =>
        downloadAPI.get(
            endpoints.campaigns.filledForms.downloadExport
                .replace(':campaignId', campaignId?.toString())
                .replace(':id', exportId?.toString()),
        ),
};

export default campaignsEmailPostedService;

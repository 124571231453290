import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SubmitButton } from '@silinfo/front-end-template';
import { ActionButtons } from './ActionButtons';

type Code = 403 | 404 | 500 | 502 | 503;

interface IError {
    code: Code;
    message: string;
}

const errors: IError[] = [
    {
        code: 403,
        message: 'A megadott oldalhoz nincs jogosultsága.',
    },
    {
        code: 404,
        message: 'A megadott oldal nem található.',
    },
    {
        code: 500,
        message: 'Szerverhiba történt.',
    },
    {
        code: 502,
        message: 'A szerver jelenleg karbantartás alatt áll. Kérjük, próbáld újra később.',
    },
    {
        code: 503,
        message: 'A szerver jelenleg karbantartás alatt áll. Kérjük, próbáld újra később.',
    },
];

export default function Error({ statusCode }: { statusCode: Code }) {
    const { code, message } = errors.find((e) => e.code === statusCode) as IError;

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography align="center" variant="h1">
                    {code}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center" variant="h4">
                    {message}
                </Typography>
            </Grid>
            {statusCode === 502 ? (
                <Grid item xs={12}>
                    <SubmitButton
                        onClick={() => {
                            window.location.href = '/';
                        }}
                        fullWidth
                        size="large"
                    >
                        Újratöltés
                    </SubmitButton>
                </Grid>
            ) : (
                <ActionButtons />
            )}
        </Grid>
    );
}

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Accordion, FormikFilter, Loading, Page } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import statisticsOtherService from '../../../../services/Unit/statistics/statisticsOtherService';
import { RootState } from '../../../../store';
import { IForm, PROJECT_NAME } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { getOwnerIdStatistics } from '../Aggregate';
import { filterFields } from './fields';

const useStyles = makeStyles(() => ({
    textGrid: {
        paddingTop: '8px',
    },
    boldText: {
        fontWeight: 'bold',
        paddingRight: '8px',
    },
}));

export default function StatisticsOther() {
    const { user, view, selectedOwner } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState<boolean>(false);
    const [numberOfEmails, setNumberOfEmails] = useState<number>(0);
    const classes = useStyles();

    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Egyéb statisztikák',
        breadcrumbs: {
            statistics: 'Statisztikák',
            [clientEndpoints.unit_statistics_campaign]: 'Egyéb statisztikák',
        },
    };

    const handleSubmit = useCallback(
        (filter: IForm) => {
            setLoading(true);
            statisticsOtherService
                .getNumberOfEmails({
                    ...filter,
                    ...{ ownerId: getOwnerIdStatistics(view, user, selectedOwner) },
                })
                .then((response) => setNumberOfEmails(response.data))
                .finally(() => setLoading(false));
        },
        [view, user, selectedOwner],
    );

    useEffect(() => {
        handleSubmit({});
    }, [handleSubmit]);

    const filter = {
        fields: filterFields,
        onSubmit: handleSubmit,
    };

    return (
        <Page header={header}>
            <FormikFilter {...filter} />
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Accordion title={'Egyéb statisztikák'} disableClosing defaultExpanded>
                        {loading ? (
                            <Loading noHeight />
                        ) : (
                            <Grid container direction="column">
                                <Grid container direction="row" className={classes.textGrid}>
                                    <Typography className={classes.boldText}>Kiküldött üzenetek száma:</Typography>
                                    <Typography>{`${numberOfEmails} darab`}</Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Accordion>
                </Grid>
            </Grid>
        </Page>
    );
}

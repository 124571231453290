import { clientEndpoints } from '../../../utils/clientEndpoints';
import EmailLogPage from '../../../pages/Unit/Administration/EmailLog';
import EmailLogShow from '../../../pages/Unit/Campaign/CampaignList/EmailLogShow';
const EmailLog = {
    path: clientEndpoints.unit_email_log,
    children: [
        {
            path: '',
            element: <EmailLogPage type={'unit'} />,
            name: 'unit_email_log',
        },
        {
            path: ':emailPostedId',
            element: <EmailLogShow type={'unit_email_log'} />,
            name: 'unit_email_log_show',
        },
    ],
};

export default EmailLog;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageTemplatesMessageFields } from '../../pages/Unit/Templates/MessageTemplates/BaseFormPage';

export interface MessageTemplatesState {
    messageFields: MessageTemplatesMessageFields[];
}

const initalMessageFields: MessageTemplatesMessageFields[] = [];

const messageTemplatesSlice = createSlice({
    name: 'messageTemplates',
    initialState: {
        messageFields: initalMessageFields,
        ownMessageFields: initalMessageFields,
        commonMessageFields: initalMessageFields,
        flaggedMessageFields: initalMessageFields,
    },
    reducers: {
        setMessageFields(state, action: PayloadAction<MessageTemplatesMessageFields[]>) {
            state.messageFields = action.payload;
        },
        setOwnMessageFields(state, action: PayloadAction<MessageTemplatesMessageFields[]>) {
            state.ownMessageFields = action.payload;
        },
        setCommonMessageFields(state, action: PayloadAction<MessageTemplatesMessageFields[]>) {
            state.commonMessageFields = action.payload;
        },
        setFlaggedMessageFields(state, action: PayloadAction<MessageTemplatesMessageFields[]>) {
            state.flaggedMessageFields = action.payload;
        },
    },
});

export const { setMessageFields, setOwnMessageFields, setCommonMessageFields, setFlaggedMessageFields } =
    messageTemplatesSlice.actions;
export default messageTemplatesSlice.reducer;

import CentralRoles from './CentralRoles';
import { UserFormProps } from './types';
import UnitRoles from './UnitRoles';

export default function FormRoles(props: UserFormProps) {
    return (
        <>
            <CentralRoles {...props} />
            <UnitRoles {...props} />
        </>
    );
}

import { Input } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import ContentCellRenderer from './ContentCellRenderer';

export const filterFields = (typeOptions: Option[], unitOptions: Option[], eventOptions: Option[]): Input[] => [
    {
        name: 'unit',
        label: 'Területi egység',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'multiselect' as const,
        options: unitOptions,
    },
    {
        name: 'actor',
        label: 'Felhasználó',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: 'type',
        label: 'Típus',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'multiselect' as const,
        options: typeOptions,
    },
    {
        name: 'action',
        label: 'Esemény',
        format: { xs: 12, md: 6, lg: 4 },
        type: 'multiselect' as const,
        options: eventOptions,
    },
    {
        type: 'datetime-local',
        label: 'Időpont (-tól)',
        name: 'createdAfter',
        format: { xs: 12, md: 6, lg: 3 },
    },
    {
        type: 'datetime-local',
        label: 'Időpont (-ig)',
        name: 'createdBefore',
        format: { xs: 12, md: 6, lg: 3 },
    },
];

export const formFields: Input[] = [];

export const columns = () =>
    [
        { headerName: 'Területi egység', field: 'unit', sortable: false },
        { headerName: 'Időpont', field: 'createdAt' },
        { headerName: 'Felhasználó', field: 'user', valueGetter: (params: GridValueGetterParams) => params.row.actor },
        { headerName: 'Esemény', field: 'actionTrans', sortable: false },
        { headerName: 'Típus', field: 'typeTrans', sortable: false },
        {
            headerName: 'Tartalom',
            field: 'content',
            sortable: false,
            renderCell: (params: GridRenderCellParams) => <ContentCellRenderer params={params} />,
        },
    ].map((elem) => ({ flex: 1, minWidth: 150, ...elem }));

import API from '../../../api';
import { StatisticsGroupedTable } from '../../../pages/Unit/Statistics/Grouped/types';
import { endpoints } from '../../../utils/endPoints';
import { crudTemplate, ICrudTemplate } from '../../templates';
import { IStatisticsService } from './statisticsAggregateService';

const statisticsGroupedService: IStatisticsService & ICrudTemplate<StatisticsGroupedTable> = {
    ...crudTemplate(endpoints.unit.statistics.grouped.main),
    getCampaigns: () => API.get(endpoints.unit.statistics.grouped.getCampaigns),
};

export default statisticsGroupedService;

import MessageFieldsPage from '../../../pages/Address/MessageFields';
import MessageFieldsForm from '../../../pages/Address/MessageFields/Form';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const OwnMessageFieldsPage = () => <MessageFieldsPage ownOnly={true} />;
const OwnMessageFieldsForm = () => <MessageFieldsForm ownOnly={true} />;

const OwnMessageFields = {
    path: clientEndpoints.unit_own_message_fields,
    children: [
        {
            path: '',
            element: <OwnMessageFieldsPage />,
            name: 'unit_own_message_fields',
        },
        {
            path: 'new',
            element: <OwnMessageFieldsForm />,
            name: 'unit_own_message_fields_create',
        },
        {
            path: ':id/edit',
            element: <OwnMessageFieldsForm />,
            name: 'unit_own_message_fields_edit',
        },
    ],
};

export default OwnMessageFields;

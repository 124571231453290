import Grid from '@mui/material/Grid';
import { Accordion, Page } from '@silinfo/front-end-template';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import MCCLoading from '../../../components/MCCLoading';
import { initialUnitRoleOptions, UnitRoles } from '../../../pages/Central/Users/types';
import unitsService from '../../../services/Central/units';
import settingsService from '../../../services/Unit/settingsService';
import usersService from '../../../services/Unit/userService';
import { RootState } from '../../../store';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import { IUnitsFormType } from '../../Central/Units/types';
import { title } from '../../Central/Users/fields';
import UsersForm from './Form';
import { UsersUnitFormType, initialForm } from './types';

export default function UsersFormPage() {
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [form, setForm] = useState<UsersUnitFormType>(initialForm);
    const [roleOptions, setRoleOptions] = useState<UnitRoles[]>(initialUnitRoleOptions);
    const [selectedUnit, setSelectedUnit] = useState<IUnitsFormType>({ id: 0, name: '', serial: 0 });
    const { id } = useParams();
    const { view } = useSelector((state: RootState) => state.auth);
    const [ownCampaignValidEmailDomains, setOwnCampaignValidEmailDomains] = useState<string[]>([]);
    const header = {
        project: 'MCC Hírlevél- és űrlapkezelő rendszer',
        title: 'Felhasználókezelés',
        breadcrumbs: {
            administration: 'Adminisztráció',
            [clientEndpoints.unit_users]: 'Felhasználókezelés',
            form: title(id),
        },
    };

    useEffect(() => {
        if (view.unit) {
            if (id) {
                setPageLoading(true);
                axios
                    .all([
                        usersService.getUnitRoles().then((response) => setRoleOptions(response.data)),
                        usersService.get(id).then((response) => setForm(response.data)),
                        unitsService
                            .get(view.unit)
                            .then((response: AxiosResponse<IUnitsFormType>) => setSelectedUnit(response.data)),
                        settingsService
                            .getOwnCampaignValidEmailDomains()
                            .then((res) => setOwnCampaignValidEmailDomains(res.data.value)),
                    ])
                    .finally(() => setPageLoading(false));
            } else {
                setPageLoading(true);
                axios
                    .all([
                        usersService.getUnitRoles().then((response) => setRoleOptions(response.data)),
                        unitsService
                            .get(view.unit)
                            .then((response: AxiosResponse<IUnitsFormType>) => setSelectedUnit(response.data)),
                        settingsService
                            .getOwnCampaignValidEmailDomains()
                            .then((res) => setOwnCampaignValidEmailDomains(res.data.value)),
                    ])
                    .finally(() => setPageLoading(false));
            }
        }
    }, [id, view]);

    const handleSubmit = (form: UsersUnitFormType) => (id ? usersService.update(id, form) : usersService.create(form));

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Grid item xs={12}>
                <Accordion title={title(id)} defaultExpanded disableClosing>
                    <Form onSubmit={handleSubmit} initialValues={form} fields={[]} back={clientEndpoints.unit_users}>
                        {(props) => (
                            <UsersForm
                                unit={selectedUnit}
                                unitRoleOptions={roleOptions}
                                ownCampaignValidEmailDomains={ownCampaignValidEmailDomains}
                                {...props}
                            />
                        )}
                    </Form>
                </Accordion>
            </Grid>
        </Page>
    );
}

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridAddIcon } from '@mui/x-data-grid';
import { useFormikContext } from 'formik';
import { IForm } from '../../utils/AppConst';
import AccordionForm from './AccordionForm';
import { AccordionFormProps, DynamicFormType, DynamicInput } from './types';

export default function DynamicForm<T extends DynamicFormType>(props: {
    formKey: string;
    title: string;
    noItemText: string;
    itemText: string;
    fields: DynamicInput[] | ((id: string | number) => DynamicInput[]);
    initialValue?: IForm;
    children?: (childrenProps: AccordionFormProps) => React.ReactNode;
    readonly?: boolean;
}) {
    const { formKey, title, noItemText, itemText, fields, children, initialValue, readonly } = props;
    const formikProps = useFormikContext<T>();
    const values = formikProps.values[formKey];
    const evaluedFields = typeof fields === 'function' ? fields(values.length) : fields;

    const createNewInstance = () => {
        let result: Record<string, unknown> = { tmpId: Date.now() };

        if (!initialValue) {
            evaluedFields.forEach((field) => {
                result[field.name] = '';
            });
        } else {
            result = { ...result, ...initialValue };
        }

        formikProps.setFieldValue(formKey, [...values, result]);
    };

    return (
        <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
                <FormControl component="fieldset" sx={{ width: '100%', mt: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        {title}
                    </Typography>
                    {values?.map((sign, i) => {
                        const tmpId = Date.now();
                        if (!sign.tmpId) {
                            formikProps.setFieldValue(formKey + `.${i}.tmpId`, tmpId);
                        }
                        const id = sign.id || sign.tmpId || tmpId;
                        return (
                            <AccordionForm
                                key={id}
                                id={id}
                                i={i}
                                fields={typeof fields === 'function' ? fields(id) : fields}
                                formKey={formKey}
                                value={sign}
                                itemText={itemText}
                                readonly={readonly}
                            >
                                {(formProps) => children?.(formProps)}
                            </AccordionForm>
                        );
                    })}
                </FormControl>
            </Grid>
            {values?.length < 1 ? (
                <Grid item xs={12}>
                    <Typography color="GrayText">
                        <i>{noItemText}</i>
                    </Typography>
                </Grid>
            ) : null}
            {readonly ? null : (
                <Grid item xs={12} alignItems="center" justifyContent="center">
                    <Button
                        variant="outlined"
                        color="success"
                        sx={{ my: 2 }}
                        startIcon={<GridAddIcon />}
                        onClick={createNewInstance}
                    >
                        {(formikProps.values?.signs?.length > 0 ? 'További' : '') + ' ' + itemText + ' hozzáadása'}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Option } from '@silinfo/front-end-template';
import Cookies from 'universal-cookie';
import { store } from '.';
import {
    LAYOUT_TYPE_COOKIE,
    SELECTED_UNIT_COOKIE,
    SELECTED_UNIT_CAMPAIGN_MANAGEMENT,
    LAYOUT_NAME_COOKIE,
    SELECTED_OWNER_COOKIE,
} from '../utils/AppConst';
import { IUser } from '../utils/Interfaces/interfaces';
import { CentralRole, UnitRole } from '../utils/Roles';

const cookies = new Cookies();

export type AuthUser = IUser & { selectedUnit: number | null };
export enum ViewUnitCampaignManagementType {
    Common,
    Own,
    OtherOwner,
}
export enum ViewLayoutType {
    Central = 'central',
    Unit = 'unit',
}
export type AppViewType = {
    layout?: ViewLayoutType;
    unit?: number;
    unitCampaignManagement?: ViewUnitCampaignManagementType;
};

export interface IAuthState {
    user?: AuthUser;
    userLoading: boolean;
    view: AppViewType;
    layoutName?: string;
    selectedOwner?: Option<number, string>;
}

export function isViewSelected() {
    const { user, view } = store.getState().auth;

    return (
        view.layout === ViewLayoutType.Central ||
        (view.layout === ViewLayoutType.Unit &&
            view.unit !== undefined &&
            ((!user?.roles?.unit?.[view.unit]?.includes(UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN) &&
                !user?.roles?.central?.includes(CentralRole.ROLE_SUPER_ADMIN)) ||
                view.unitCampaignManagement !== undefined))
    );
}

export function getUnitCampaignManagementSelectedType(): ViewUnitCampaignManagementType {
    const { view } = store.getState().auth;

    return view.unitCampaignManagement !== undefined
        ? view.unitCampaignManagement
        : ViewUnitCampaignManagementType.Common;
}

export function getOtherOwnerCondition(): boolean {
    const { view, selectedOwner } = store.getState().auth;

    if (view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner && selectedOwner) {
        return true;
    }

    return false;
}

const initUser: AuthUser = {
    email: '',
    firstName: '',
    lastName: '',
    roles: {
        central: [],
        unit: {},
    },
    selectedUnit: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        view: {
            layout: cookies.get(LAYOUT_TYPE_COOKIE),
            unit:
                cookies.get(SELECTED_UNIT_COOKIE) !== undefined
                    ? parseInt(cookies.get(SELECTED_UNIT_COOKIE))
                    : undefined,
            unitCampaignManagement:
                cookies.get(SELECTED_UNIT_CAMPAIGN_MANAGEMENT) !== undefined
                    ? parseInt(cookies.get(SELECTED_UNIT_CAMPAIGN_MANAGEMENT))
                    : undefined,
        },
        user: initUser,
        userLoading: true,
        layoutName: cookies.get(LAYOUT_NAME_COOKIE),
        selectedOwner: cookies.get(SELECTED_OWNER_COOKIE),
    } as IAuthState,
    reducers: {
        setUser(state: IAuthState, action: PayloadAction<AuthUser>) {
            state.user = action.payload;
            state.userLoading = false;
            //TODO: set user for sentry
        },
        clearUser(state: IAuthState) {
            state.user = initUser;
        },
        setUserLoading(state: IAuthState, action: PayloadAction<boolean>) {
            state.userLoading = action.payload;
        },
        setViewLayout(state: IAuthState, action: PayloadAction<ViewLayoutType>) {
            state.view.layout = action.payload;

            cookies.set(LAYOUT_TYPE_COOKIE, action.payload, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });

            //clear unit
            state.view.unit = undefined;

            cookies.remove(SELECTED_UNIT_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });

            localStorage.removeItem(SELECTED_UNIT_COOKIE);

            //clear unitCampaignManagement
            state.view.unitCampaignManagement = undefined;

            cookies.remove(SELECTED_UNIT_CAMPAIGN_MANAGEMENT, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });

            //clear selected owner
            cookies.remove(SELECTED_OWNER_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
            localStorage.removeItem(SELECTED_OWNER_COOKIE);
        },
        clearViewLayout(state: IAuthState) {
            state.view.layout = undefined;

            cookies.remove(LAYOUT_TYPE_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });

            //clear unit
            state.view.unit = undefined;

            cookies.remove(SELECTED_UNIT_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });

            localStorage.removeItem(SELECTED_UNIT_COOKIE);

            //clear unitCampaignManagement
            state.view.unitCampaignManagement = undefined;

            cookies.remove(SELECTED_UNIT_CAMPAIGN_MANAGEMENT, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });

            //clear selected owner
            cookies.remove(SELECTED_OWNER_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
            localStorage.removeItem(SELECTED_OWNER_COOKIE);

            //clear layout name
            state.layoutName = undefined;

            cookies.remove(LAYOUT_NAME_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
        },
        setViewUnit(state: IAuthState, action: PayloadAction<number>) {
            state.view.unit = action.payload;

            cookies.set(SELECTED_UNIT_COOKIE, action.payload, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });

            localStorage.setItem(SELECTED_UNIT_COOKIE, action.payload.toString());

            //clear unitCampaignManagement
            state.view.unitCampaignManagement = undefined;

            cookies.remove(SELECTED_UNIT_CAMPAIGN_MANAGEMENT, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });

            //clear selected owner
            cookies.remove(SELECTED_OWNER_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
            localStorage.removeItem(SELECTED_OWNER_COOKIE);
        },
        clearViewUnit(state: IAuthState) {
            state.view.unit = undefined;

            cookies.remove(SELECTED_UNIT_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });

            localStorage.removeItem(SELECTED_UNIT_COOKIE);

            //clear unitCampaignManagement
            state.view.unitCampaignManagement = undefined;

            cookies.remove(SELECTED_UNIT_CAMPAIGN_MANAGEMENT, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });

            //clear selected owner
            cookies.remove(SELECTED_OWNER_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
            localStorage.removeItem(SELECTED_OWNER_COOKIE);

            //clear layout name
            state.layoutName = undefined;

            cookies.remove(LAYOUT_NAME_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
        },
        setViewUnitCampaignManagement(state: IAuthState, action: PayloadAction<ViewUnitCampaignManagementType>) {
            state.view.unitCampaignManagement = action.payload;

            cookies.set(SELECTED_UNIT_CAMPAIGN_MANAGEMENT, action.payload, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
        },
        clearViewUnitCampaignManagement(state: IAuthState) {
            state.view.unitCampaignManagement = undefined;

            cookies.remove(SELECTED_UNIT_CAMPAIGN_MANAGEMENT, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
        },
        clearView(state: IAuthState) {
            state.view = {
                layout: undefined,
                unit: undefined,
                unitCampaignManagement: undefined,
            };

            cookies.remove(LAYOUT_TYPE_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
            cookies.remove(SELECTED_UNIT_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
            localStorage.removeItem(SELECTED_UNIT_COOKIE);

            cookies.remove(SELECTED_UNIT_CAMPAIGN_MANAGEMENT, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });

            //clear selected owner
            cookies.remove(SELECTED_OWNER_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
            localStorage.removeItem(SELECTED_OWNER_COOKIE);

            //clear layout name
            state.layoutName = undefined;

            cookies.remove(LAYOUT_NAME_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
        },
        setLayoutName(state: IAuthState, action: PayloadAction<string>) {
            state.layoutName = action.payload;

            cookies.set(LAYOUT_NAME_COOKIE, action.payload, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
        },
        clearLayoutName(state: IAuthState) {
            state.layoutName = undefined;

            cookies.remove(LAYOUT_NAME_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
        },
        setSelectedOwner(state: IAuthState, action: PayloadAction<Option<number, string>>) {
            state.selectedOwner = action.payload;

            cookies.set(SELECTED_OWNER_COOKIE, action.payload, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
        },
        clearSelectedOwner(state: IAuthState) {
            state.selectedOwner = undefined;

            cookies.remove(SELECTED_OWNER_COOKIE, {
                domain: process.env.REACT_APP_COOKIES_DOMAIN,
                path: '/',
            });
        },
    },
});

export const {
    setUser,
    setUserLoading,
    setViewLayout,
    clearViewLayout,
    setViewUnit,
    clearViewUnit,
    setViewUnitCampaignManagement,
    clearViewUnitCampaignManagement,
    clearView,
    setLayoutName,
    clearLayoutName,
    clearUser,
    setSelectedOwner,
    clearSelectedOwner,
} = authSlice.actions;
export default authSlice.reducer;

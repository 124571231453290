import { ISystemLog } from './types';

const initialEmailLog: ISystemLog = {
    id: -1,
    actor: '',
    action: '',
    actionTrans: '',
    type: '',
    typeTrans: '',
    changes: [],
    info: [],
};

export { initialEmailLog };

import Grid from '@mui/material/Grid';
import { TableButton } from '@silinfo/front-end-template';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid';

type ContentCellRendererProps = {
    params: GridRenderCellParams;
    isCentral: boolean;
};
export default function ContentCellRenderer(props: ContentCellRendererProps) {
    const { params, isCentral } = props;
    const navigate = useNavigate();
    return (
        <Grid container spacing={1}>
            <Grid item>
                <TableButton
                    tooltip={'E-mail megtekintése'}
                    onClick={() =>
                        isCentral
                            ? navigate(
                                  clientEndpoints.central_email_log_show.replace(
                                      ':emailPostedId',
                                      params.row.id.toString(),
                                  ),
                              )
                            : navigate(
                                  clientEndpoints.unit_email_log_show.replace(
                                      ':emailPostedId',
                                      params.row.id.toString(),
                                  ),
                              )
                    }
                >
                    <EmailIcon />
                </TableButton>
            </Grid>
        </Grid>
    );
}

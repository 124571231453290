import Grid from '@mui/material/Grid';
import { Accordion, Checkbox, FieldGenerator, Option } from '@silinfo/front-end-template';
import { UnitRole } from '../../../utils/Roles';
import { IUnitsFormType } from '../../Central/Units/types';
import { SpecialTooltipLabel } from '../../Central/Users/UnitRoles';
import CustomDisabledCheckboxUnit from './CustomDisabledCheckboxUnit';
import RolesTitle from './RolesTitle';
import { UnitUserFormProps } from './types';

interface UnitCheckboxProps {
    unit: IUnitsFormType;
    roleItem: Option<string, string>;
    props: UnitUserFormProps;
    disabled?: boolean;
}

function shouldHideRole(
    roleItem: Option<string, string>,
    props: UnitUserFormProps,
    ownCampaignValidEmailDomains: string[],
): boolean {
    return (
        ((roleItem.value === UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN ||
            roleItem.value === UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS) &&
            !ownCampaignValidEmailDomains.some((emailDomain) =>
                (props.values.email || '').toString().endsWith(emailDomain),
            )) ||
        (roleItem.value === UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS &&
            props.values.roles?.[UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN] != '1')
    );
}

function DisabledCheckbox({ unit, roleItem, props }: UnitCheckboxProps) {
    return (
        <Grid container item xs={12} key={`${unit.label}-${roleItem.label}--outer`}>
            <CustomDisabledCheckboxUnit role={roleItem} {...props} />
        </Grid>
    );
}

function NormalCheckbox({ unit, roleItem, props }: UnitCheckboxProps) {
    const isOtherAllowUseCommonCampaigns = roleItem.value === UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS;

    return (
        <Grid container item xs={12} key={`${unit.label}-${roleItem.label}--outer`}>
            {FieldGenerator({
                ...props,
                label: roleItem.label,
                checkboxLabel: isOtherAllowUseCommonCampaigns ? (
                    <SpecialTooltipLabel label={roleItem.label} />
                ) : (
                    roleItem.label
                ),
                name: `roles.${roleItem.value}`,
                type: 'checkbox',
                format: { xs: 12 },
                props: {
                    checked: props.values.roles?.[roleItem.value] == '1',
                },
            })}
        </Grid>
    );
}

export default function UnitRolesAccordion(props: UnitUserFormProps) {
    const { unit, unitRoleOptions, rolesView, ownCampaignValidEmailDomains } = props;

    return (
        <Grid item xs={12} key={unit.id}>
            <Accordion title={unit.name} defaultExpanded={true} disableClosing={true}>
                <Grid container item xs={12} spacing={2} direction="row">
                    {unitRoleOptions.map((role) => {
                        return (
                            <>
                                <Grid container item xs={12} md={rolesView ? 12 : 6} alignSelf="flex-start">
                                    <RolesTitle
                                        role={role}
                                        rolesView={rolesView}
                                        ownCampaignValidEmailDomains={ownCampaignValidEmailDomains}
                                    />
                                    {role.value.map((roleItem) => {
                                        if (
                                            shouldHideRole(roleItem, props, ownCampaignValidEmailDomains) &&
                                            !rolesView
                                        ) {
                                            if (props.values.roles?.[roleItem.value] == '1') {
                                                props.setFieldValue(`roles.${roleItem.value}`, '0');
                                            }

                                            return (
                                                <Checkbox
                                                    key={`${unit.label}-${role.label}-${roleItem.label}-checkbox`}
                                                    checkbox={{
                                                        disabled: true,
                                                    }}
                                                    label={
                                                        roleItem.value ===
                                                        UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS ? (
                                                            <SpecialTooltipLabel label={roleItem.label} />
                                                        ) : (
                                                            roleItem.label
                                                        )
                                                    }
                                                />
                                            );
                                        }

                                        return rolesView ? (
                                            <DisabledCheckbox
                                                key={`${unit.label}-${role.label}-${roleItem.label}-checkbox`}
                                                unit={unit}
                                                roleItem={roleItem}
                                                props={props}
                                            />
                                        ) : (
                                            <NormalCheckbox
                                                key={`${unit.label}-${role.label}-${roleItem.label}-checkbox`}
                                                unit={unit}
                                                roleItem={roleItem}
                                                props={props}
                                            />
                                        );
                                    })}
                                </Grid>
                            </>
                        );
                    })}
                </Grid>
            </Accordion>
        </Grid>
    );
}

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../components/MCCLoading';
import emailsService, { EmailView } from '../../services/Unit/emails';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    messageViewContainer: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    messageViewContainerIframe: {
        flexGrow: 1,
        border: 'none',
        margin: 0,
        padding: 0,
    },
}));

export default function MessageView() {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { unitId, token } = useParams() as { unitId: string; token: string };
    const [email, setEmail] = useState<EmailView>();

    useEffect(() => {
        setIsLoading(true);

        emailsService
            .view(+unitId, token)
            .then((res) => {
                setEmail(res.data);
            })
            .finally(() => setIsLoading(false));
    }, [unitId, token]);

    return (
        <>
            {isLoading && <MCCLoading />}
            {!isLoading && (
                <div className={classes.messageViewContainer}>
                    <iframe className={classes.messageViewContainerIframe} srcDoc={email?.content || ''}></iframe>
                </div>
            )}
        </>
    );
}

import { IForm } from '../../../../utils/AppConst';

export interface CampaignListFilter {
    precedingCampaigns: Array<number>;
    campaignName: string;
    campaignSubmitDateFrom: string;
    campaignSubmitDateTo: string;
    form: string;
    messageTemplate: string;
    status: Array<string>;
}

export interface CampaignListTable {
    precedingCampaignName: string;
    campaignName: string;
    campaignSubmitDate: string;
    form: string;
    messageTemplate: string;
    status: string;
}

export interface Entity {
    id: number;
    name: string;
}

export interface AddressEntity {
    id: number;
    email: string;
}

interface ICampaignForm {
    id: number;
    deadline: string;
    sameTokenCount: number;
    name: string;
}

export interface CampaignFormType extends IForm {
    id: number;
    precedingCampaign: Entity;
    name: string;
    messageTemplate: Entity;
    addressList: AddressEntity;
    submitDate: string;
    sentAt?: string;
    senderAddress: string;
    replyAddress: string;
    form: ICampaignForm | null;
}

export const initialCampaignForm: CampaignFormType = {
    id: 0,
    precedingCampaign: {
        id: 0,
        name: '',
    },
    name: '',
    messageTemplate: {
        id: 0,
        name: '',
    },
    addressList: {
        id: 0,
        email: '',
    },
    submitDate: '',
    senderAddress: '',
    replyAddress: '',
    form: null,
};

export interface ICampaignFormField {
    id?: number;
    name: string;
    label: string;
    visible?: boolean;
    default?: boolean;
}

export interface ICampaign {
    id: number;
    name: string;
    submitDate: string;
    submitType: string;
}

export const initialCampaign: ICampaign = {
    id: 0,
    name: '',
    submitDate: '',
    submitType: '',
};

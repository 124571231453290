import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CancelButton } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApiAutocomplete from '../../pages/Unit/Campaign/NewCampaign/ApiAutocomplete';
import profileService from '../../services/profileService';
import { setSelectedOwner, setViewUnitCampaignManagement, ViewUnitCampaignManagementType } from '../../store/auth';
import { clientEndpoints } from '../../utils/clientEndpoints';
import { endpoints } from '../../utils/endPoints';

export default function SelectOwnerCampaignManagement() {
    const [ownerId, setOwnerId] = useState<number | number[] | null>(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = useCallback((value: number | number[]) => setOwnerId(value), []);

    const handleSubmit = useCallback(() => {
        if (!ownerId) return;
        setLoading(true);
        profileService
            .selectOwner(ownerId)
            .then((response: AxiosResponse) => {
                dispatch(setViewUnitCampaignManagement(ViewUnitCampaignManagementType.OtherOwner));
                dispatch(setSelectedOwner(response.data.selectedOwner));
                navigate(clientEndpoints.home);
            })
            .finally(() => setLoading(false));
    }, [ownerId, navigate, dispatch]);

    const handleBack = useCallback(() => navigate(clientEndpoints.select_unit_campaign_management), [navigate]);

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography textAlign="center" variant="h6">
                    Kérjük válassz felhasználót, akinek a saját kampányadatait szeretnéd megtekinteni!
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ApiAutocomplete
                    url={endpoints.searchOwner}
                    label={'Felhasználó'}
                    name={'user'}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    disabled={!ownerId}
                    onClick={handleSubmit}
                    fullWidth
                >
                    Belépés
                </LoadingButton>
            </Grid>
            <Grid item xs={12} md={6}>
                <CancelButton onClick={handleBack} fullWidth>
                    Vissza
                </CancelButton>
            </Grid>
        </Grid>
    );
}

import SystemMessagesPage from '../../pages/Central/SystemMessages';
import SystemMessagesForm from '../../pages/Central/SystemMessages/Form';
import { clientEndpoints } from '../../utils/clientEndpoints';

const SystemMessages = {
    path: clientEndpoints.central_system_messages,
    children: [
        {
            path: '',
            element: <SystemMessagesPage />,
            name: 'central_system_messages',
        },
        {
            path: ':id/edit',
            element: <SystemMessagesForm />,
            name: 'central_system_messages_edit',
        },
    ],
};

export default SystemMessages;

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormRoles from './FormRoles';
import { UserFormProps } from './types';
import UserFormCore from './UserFormCore';
import { useEffect } from 'react';
import { UnitRole } from '../../../utils/Roles';

export default function Form(props: UserFormProps) {
    const { values, setFieldValue, ownCampaignValidEmailDomains } = props;

    useEffect(() => {
        if (!ownCampaignValidEmailDomains.some((emailDomain) => values.email.endsWith(emailDomain))) {
            Object.keys(values.roles).forEach((unit) =>
                Object.keys(values.roles[unit]).forEach((role) => {
                    if (role === UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN && values.roles[unit][role] !== '0') {
                        setFieldValue(`roles.${unit}`, {
                            ...values.roles[unit],
                            [role]: '0',
                        });
                    }
                }),
            );
        }
    }, [values.email, values.roles, setFieldValue, ownCampaignValidEmailDomains]);

    return (
        <Grid container item xs={12} spacing={2}>
            <UserFormCore {...props} />
            <Grid item xs={12}>
                <Typography variant="h6" style={{ marginTop: '8px' }}>
                    Jogosultságok:
                </Typography>
            </Grid>
            <FormRoles {...props} />
        </Grid>
    );
}

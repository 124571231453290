import superAdminService from '../../../services/Central/superAdminService';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import SystemSettingsForm from '../SystemSettings/Form';

export default function FormPage() {
    return (
        <SystemSettingsForm
            title="Szuperadmin"
            clientUrl={clientEndpoints.central_super_admin}
            clientEditUrl={clientEndpoints.central_super_admin_edit}
            service={superAdminService}
        />
    );
}

import { Input } from '@silinfo/front-end-template';

export const fields: Input[] = [
    {
        name: 'name',
        label: 'Név',
        format: { xs: 12, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Utolsó módosítás dátuma (-tól)',
        name: 'updateDateFrom',
        format: { xs: 12, md: 6, lg: 4 },
    },
    {
        type: 'datetime-local',
        label: 'Utolsó módosítás dátuma (-ig)',
        name: 'updateDateTo',
        format: { xs: 12, md: 6, lg: 4 },
    },
];

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { Field } from 'formik';
import colors from '../../../styles/colors';
import { UserFormProps } from './types';

export type CustomDisabledCheckboxProps = {
    unit?: Option<number, string>;
    role: Option<string, string>;
} & UserFormProps;

export const useCheckboxStyles = makeStyles(() => ({
    checkbox: {
        '&.Mui-disabled': {
            color: colors.primaryYellow,
        },
        color: colors.primaryYellow,
    },
}));

export default function CustomDisabledCheckbox({ unit, role, ...props }: CustomDisabledCheckboxProps) {
    const classes = useCheckboxStyles();
    return (
        <Field as="div" name={unit ? `roles.${unit.value}.${role.value}` : `roles.central.${role.value}`}>
            {
                <Grid item container xs={12} direction="row" alignItems={'center'}>
                    <Checkbox
                        className={classes.checkbox}
                        inputProps={{
                            name: unit ? `roles.${unit.value}.${role.value}` : `roles.central.${role.value}`,
                        }}
                        checked={
                            unit
                                ? props.values.roles?.[unit.value]?.[role.value] == '1'
                                : props.values.roles.central?.[role.value] == '1'
                        }
                        disabled={props.rolesView}
                    />
                    <Typography>{role.label}</Typography>
                </Grid>
            }
        </Field>
    );
}

import { Accordion, Loading, Page } from '@silinfo/front-end-template';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Form from '../../../../components/Form/Form';
import emailsService from '../../../../services/Unit/emails';
import { createFormPageHeader } from '../../../../utils/Headers';
import { IEmailsFormType } from './../types';
import BaseForm from './BaseForm';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';

const initialValues: IEmailsFormType = {
    id: 0,
    name: '',
    address: '',
    isReply: false,
    isSender: false,
    assignedUsers: [],
    signs: [],
};

const transformData = (data: IEmailsFormType): IEmailsFormType => ({
    ...data,
    assignedUsers: data.assignedUsers.map((user) => (typeof user === 'object' ? user.value : user)),
});

export default function EmailsForm() {
    const { id } = useParams();
    const header = createFormPageHeader('administration_unit', 'emails', id ? Number(id) : null);
    const { view, user } = useSelector((state: RootState) => state.auth);
    const back =
        view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
            ? clientEndpoints.unit_own_emails
            : clientEndpoints.unit_emails;
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<IEmailsFormType>(initialValues);

    useEffect(() => {
        if (id) {
            setLoading(true);
            axios
                .all([emailsService.get(id).then((res: AxiosResponse) => setForm(res.data))])
                .finally(() => setLoading(false));
        }
    }, [id]);

    const handleSubmit = (form: IEmailsFormType) => {
        const formData = {
            ...form,
            ...{
                ownerId: view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ? user?.id : null,
            },
        };

        return id ? emailsService.update(id, formData) : emailsService.create(formData);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Page header={header}>
            <Accordion title={header.title}>
                <Form
                    fields={[]}
                    initialValues={form}
                    onSubmit={handleSubmit}
                    back={back}
                    submitTransformData={transformData}
                >
                    {(props) => <BaseForm {...props} />}
                </Form>
            </Accordion>
        </Page>
    );
}

import axios, { AxiosError } from 'axios';
import { history, store } from '../store';
import { decrement, increment } from '../store/loading';
import { create } from '../store/notification';
import { NO_REDIRECT_URL_KEYS } from '../utils/AppConst';
import { getJWTToken, logout } from '../utils/AuthHelper';
import { clientEndpoints } from './../utils/clientEndpoints';

const instance = axios.create({
    headers: {
        //     'Access-Control-Allow-Origin': '*', sajnos cors errort okoz
        'Content-Type': 'application/json',
        //    'X-Requested-With': 'XMLHttpRequest', sajnos cors errort okoz
    },
    responseType: 'json',
    withCredentials: true,
});

instance.interceptors.request.use(
    (config) => {
        store.dispatch(increment(config.url || ''));
        if (!config.headers?.Authorization) {
            const AUTH_TOKEN = getJWTToken();
            if (AUTH_TOKEN)
                config.headers = Object.assign({}, config.headers, {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                });

            const XSwitchUser = localStorage.getItem('X-Switch-User');
            if (XSwitchUser)
                config.headers = Object.assign({}, config.headers, {
                    'X-Switch-User': XSwitchUser,
                });
        }

        return config;
    },
    (error) => Promise.reject(error),
);

const handleErrorRedirect = (status?: number): string => {
    if (!status && status !== 0) return '';
    switch (status) {
        case 403:
            return '/403';
        case 404:
            return '/404';
        case 0:
        case 502:
            return '/502';
        case 503:
            return '/503';
        default:
            return '';
    }
};

const handleErrorMessage = (status?: number, detail?: string): string => {
    if (detail) return detail;

    if (!status) return '';

    switch (status) {
        case 500:
            return 'Az érintett funkció karbantartás alatt áll. Kérjük, próbáld újra később!';
        case 0:
        case 502:
        case 503:
            return 'A szerver karbantartás alatt áll. Kérjük, néhány perc múlva próbáld újra!';
        default:
            return '';
    }
};

// Add a response interceptor
instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        store.dispatch(decrement(response.config.url || ''));
        return response;
    },
    // remember to make this async as the store action will
    // need to be awaited
    async function (error: AxiosError<{ message: string; detail: string }, string>) {
        //return Promise.reject(error);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        store.dispatch(decrement(error.config.url || ''));

        const matchesNoDirectUrl = NO_REDIRECT_URL_KEYS.some((key) => {
            const url = clientEndpoints[key];
            const urlParts = url.split('/').slice(1);
            const configUrlParts = window.location.pathname.split('/').slice(1);

            for (let i = 0; i < urlParts.length; i++) {
                if (urlParts[i][0] !== ':' && urlParts[i] !== configUrlParts[i]) return false;
            }
            return true;
        });

        if (!matchesNoDirectUrl) {
            if (error?.response?.status === 401) {
                logout();
                history.push('/login');

                return Promise.reject(error);
            } else if (error?.response?.status === 444) {
                //ha nem egyezik a kiválasztott unit a sessonben elmentett unittal
                logout();
                history.push('/login');

                return Promise.reject(error);
            } else {
                const redirect = handleErrorRedirect(error.response?.status);
                if (redirect && (error.response?.status === 503 || !error.response?.data?.message)) {
                    history.push(redirect, { error });
                }
                const message = handleErrorMessage(error.response?.status, error.response?.data?.detail);
                if (message) {
                    store.dispatch(create({ message, type: 'error' }));
                }
            }
        }

        return Promise.reject(error);
    },
);

instance.interceptors.request.use((config) => {
    if (config.method && config.method?.toLocaleUpperCase() === 'PATCH') {
        config.headers = {
            ...config.headers,
            'Content-Type': 'application/merge-patch+json',
        };
    }
    return config;
});

export default instance;

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { TextField } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import campaignsService from '../../../../../services/campaigns/campaigns';
import { store } from '../../../../../store';
import { setRefresh } from '../../../../../store/Unit/addressList';
import { ViewUnitCampaignManagementType } from '../../../../../store/auth';
import { endpoints } from '../../../../../utils/endPoints';
import ApiAutocomplete from '../ApiAutocomplete';
import { NewCampaignFormType } from '../types';
import { initialForm } from '../utils';
import PrevCampaignInput from './PrevCampaignInput';
import WithFormPart from './WithFormPart';
import { BasicDataFormProps } from './types';
import OptionRenderer from './OptionRenderer';

const useStyles = makeStyles(() => ({
    infoBox: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '0.8rem',
    },
}));

interface ITemplateOption extends Option<number, string> {
    hasUnsubscribeLink: boolean;
    own: boolean;
    withForm: boolean;
    withSign: boolean;
}

export default function BasicDataForm(props: BasicDataFormProps) {
    const isOwn = store.getState().auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;

    const dispatch = useDispatch();
    const classes = useStyles();
    const [isSynchronized, setIsSynchronized] = useState<boolean>(false);
    const [hasUnsubscribeLink, setHasUnsubscribeLink] = useState(true);
    const { setValues, values } = props;
    const [withForm, setWithForm] = useState<boolean>(values.messageTemplate?.withForm ?? false);
    const sessionId = values.session;
    const { id } = useParams();
    const prevCampaignDefaultValue = useMemo(
        () => (props.values.prevCampaign === null ? { label: '', value: 0 } : props.values.prevCampaign),
        [props.values.prevCampaign],
    );
    const valueOfPrevCampaign = prevCampaignDefaultValue.value;

    const handleMessageTemplateChange = useCallback(
        (_: SyntheticEvent, value: ITemplateOption) => {
            let changes: Partial<NewCampaignFormType> = {
                messageTemplateId: value.value,
            };
            const withForm = value.withForm;
            const withSign = value.withSign;
            changes['withForm'] = withForm;
            changes['withSign'] = withSign;
            changes.messageTemplate = {
                id: value.value,
                name: value.label,
                hasUnsubscribeLink: value.hasUnsubscribeLink,
                own: value.own,
                withForm: value.withForm,
                withSign: value.withSign,
            };
            setHasUnsubscribeLink(value.hasUnsubscribeLink);

            setWithForm(withForm);

            if (!withForm) {
                changes = {
                    ...changes,
                    formsId: initialForm.formsId,
                    formValidity: initialForm.formValidity,
                    tokenCountSpend: initialForm.tokenCountSpend,
                };
            }

            if (!withSign) {
                changes = {
                    ...changes,
                    senderSignId: initialForm.senderSignId,
                };
            }

            setValues({ ...values, ...changes });
        },
        [setValues, values],
    );
    const handlePrevCampaignFormChange = useCallback(
        (value: number) => {
            if (typeof sessionId !== 'string') {
                return;
            }
            setWithForm(false);

            if (+value !== 0) {
                campaignsService.previousCampaign(+value, { sessionId }).then((res) => {
                    const formToSet = {
                        prevCampaignId: value,
                        form: { value: res.data.formTemplate.id, label: res.data.formTemplate.name, own: false },
                        formsId: res.data.formTemplate.id,
                        formValidity: res.data.formTemplateValidity,
                        tokenCountSpend: res.data.tokenCountSpend,
                        senderEmail: res.data.sender,
                        senderEmailId: res.data.sender.value,
                        replyEmail: res.data.reply,
                        replyEmailId: res.data.reply.value,
                    };
                    setValues({ ...values, ...formToSet });
                    dispatch(setRefresh());
                });
            } else {
                const formToSet = {
                    prevCampaignId: initialForm.prevCampaignId,
                    formsId: initialForm.formsId,
                    formValidity: initialForm.formValidity,
                    tokenCountSpend: initialForm.tokenCountSpend,
                    senderEmailId: initialForm.senderEmailId,
                    replyEmailId: initialForm.replyEmailId,
                };
                setValues({ ...values, ...formToSet });

                campaignsService
                    .saveManyFormAddressList({
                        session: sessionId,
                        page: 'null',
                        checked: false,
                    })
                    .then(() => {
                        dispatch(setRefresh());
                    });
            }
        },
        [sessionId, setValues, values, dispatch],
    );

    useEffect(() => {
        if (valueOfPrevCampaign && id && !isSynchronized) {
            setIsSynchronized(true);
        }
    }, [id, isSynchronized, valueOfPrevCampaign]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PrevCampaignInput isOwn={isOwn} onChange={handlePrevCampaignFormChange} values={props.values} />
            </Grid>
            <Grid item style={{ paddingTop: 0 }}>
                <Typography component="p" className={classes.infoBox}>
                    Amennyiben itt egy kampány kiválasztásra kerül, új űrlap már nem választható ki, és nem generálódik
                    új token a címzetteknek
                </Typography>
            </Grid>

            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ApiAutocomplete
                        url={
                            endpoints.unit.template.messageTemplates.autocomplete +
                            (isOwn ? '?isOwner=true' : '?isOwner=false')
                        }
                        label={'Üzenetsablon'}
                        name={'messageTemplateId'}
                        onAutoCompleteChange={handleMessageTemplateChange}
                        prevCampaign={!!props.values.prevCampaignId}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!!props.errors.messageTemplateId}
                                helperText={props.errors.messageTemplateId}
                                label="Üzenetsablon"
                            />
                        )}
                        defaultValue={
                            props.values.messageTemplate
                                ? {
                                      label: props.values.messageTemplate?.name ?? '',
                                      value: props.values.messageTemplate?.id ?? 0,
                                      hasUnsubscribeLink:
                                          props.values.messageTemplate?.hasUnsubscribeLink === false ? false : true,
                                      own: props.values.messageTemplate?.own || false,
                                      withForm: props.values.messageTemplate?.withForm,
                                      withSign: props.values.messageTemplate?.withSign,
                                  }
                                : undefined
                        }
                        renderOption={isOwn ? OptionRenderer : undefined}
                    />
                </Grid>
                {props.values.messageTemplate?.hasUnsubscribeLink === false || !hasUnsubscribeLink ? (
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            Figyelem, a kiválasztott üzenetsablon nem tartalmaz leiratkozási linket!
                        </Alert>
                    </Grid>
                ) : null}
            </Grid>

            <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Kampány elnevezése"
                    error={!!props.errors.name}
                    helperText={props.errors.name}
                    onBlur={(e) => props.setFieldValue('name', e.target.value)}
                    color="secondary"
                    defaultValue={props.values.name}
                    size={'small'}
                    fullWidth
                />
            </Grid>
            {withForm || props.values.prevCampaignId !== 0 ? <WithFormPart isOwn={isOwn} /> : null}
        </Grid>
    );
}

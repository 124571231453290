import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Accordion, Link } from '@silinfo/front-end-template';
import { FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import colors from '../../../../../styles/colors';
import { clientEndpoints } from '../../../../../utils/clientEndpoints';
import { CampaignFormType } from '../types';
import AddressListTable from './AddressListTable';
import { Theme } from '@mui/material/styles';
import { GridRenderCellParams } from '@mui/x-data-grid';
import StatusCellRenderer from '../StatusCellRenderer';

const useStyles = makeStyles((theme: Theme) => ({
    boldText: {
        fontWeight: 'bold',
        marginTop: '8px',
    },
    button: {
        marginRight: '8px',
    },
    buttonContainer: {
        marginTop: '16px',
    },
    blueButton: {
        backgroundColor: colors.new,
        '&:hover': {
            backgroundColor: colors.newHover,
        },
    },
    link: {
        color: theme.palette.primary.main,
        textTransform: 'none',
        textDecoration: 'underline',
    },
}));

export default function FormPage(props: FormikProps<CampaignFormType>) {
    const classes = useStyles();
    const navigate = useNavigate();

    /**
     * TODO: Jogosultságkezelés: üzenetsablonhoz linkeléskor
     * TODO: jogkezelés üzenetmezők lekérésekor
     */

    return (
        <>
            <Grid item xs={12}>
                <Accordion title={'Alapadatok'} disableClosing defaultExpanded>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography className={classes.boldText}>Megelőző kampány</Typography>
                            <Typography>
                                {props.values.precedingCampaign.name ? (
                                    <Link
                                        to={clientEndpoints.unit_campaigns_view.replace(
                                            ':id',
                                            props.values.precedingCampaign.id.toString(),
                                        )}
                                        className={classes.link}
                                    >
                                        {props.values.precedingCampaign.name}
                                    </Link>
                                ) : (
                                    '-'
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.boldText}>Elnevezés</Typography>
                            <Typography>{props.values.name || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.boldText}>Üzenetsablon</Typography>
                            <Typography>
                                {props.values.messageTemplate?.name ? (
                                    <Link
                                        to={clientEndpoints.unit_campaigns_message_templates_show
                                            .replace(':campaignId', props.values.id.toString())
                                            .replace(':id', props.values.messageTemplate.id.toString())}
                                        className={classes.link}
                                    >
                                        {props.values.messageTemplate.name}
                                    </Link>
                                ) : (
                                    '-'
                                )}
                            </Typography>
                        </Grid>
                        {props.values.form ? (
                            <Grid item container xs={12}>
                                <Grid item xs={12}>
                                    <Typography className={classes.boldText}>Űrlap</Typography>
                                    <Typography>
                                        {props.values.form.id ? (
                                            <Link
                                                to={clientEndpoints.unit_campaigns_forms_show
                                                    .replace(':campaignId', props.values.id.toString())
                                                    .replace(':id', props.values.form.id.toString())}
                                                className={classes.link}
                                            >
                                                {props.values.form.name}
                                            </Link>
                                        ) : (
                                            '-'
                                        )}
                                    </Typography>
                                    <Typography>
                                        Kitölthető eddig: {new Date(props.values.form.deadline).toLocaleString() || '-'}
                                    </Typography>
                                    <Typography>
                                        Kitölthető ennyiszer: {props.values.form.sameTokenCount || '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Grid>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <AddressListTable campaignId={props.values.id} />
            </Grid>
            <Grid item xs={12}>
                <Accordion title={'Küldéshez kapcsolódó adatok'} disableClosing defaultExpanded>
                    <>
                        <Grid item xs={12}>
                            <Typography className={classes.boldText}>Státusz</Typography>
                            <StatusCellRenderer params={{ value: props.values.status } as GridRenderCellParams} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.boldText}>Tervezett kiküldés ideje</Typography>
                            <Typography>
                                {props.values.submitDate
                                    ? new Date(props.values.submitDate).toLocaleString()
                                    : 'Azonnal'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.boldText}>Tényleges kiküldés ideje</Typography>
                            <Typography>
                                {props.values.sentAt
                                    ? new Date(props.values.sentAt).toLocaleString()
                                    : 'A kampány még nem került kiküldésre'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.boldText}>Küldő e-mail cím</Typography>
                            <Typography>{props.values.senderAddress || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.boldText}>Válasz e-mail cím</Typography>
                            <Typography>{props.values.replyAddress || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.buttonContainer}>
                            <Button
                                className={classes.button}
                                variant="outlined"
                                onClick={() => navigate(clientEndpoints.unit_campaigns)}
                            >
                                Vissza
                            </Button>
                        </Grid>
                    </>
                </Accordion>
            </Grid>
        </>
    );
}

import GroupsIcon from '@mui/icons-material/Groups';
import InsightsOutlined from '@mui/icons-material/InsightsOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { TableButton, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../store';
import { checkAccess } from '../../../../utils/AccessManagementHelper';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import FeedIcon from '@mui/icons-material/Feed';
import { IUser } from '../../../../utils/Interfaces/interfaces';
import { CellRendererProps } from './StatusCellRenderer';
import campaignsService, { CampaignStatus, CampaignSubmitType } from '../../../../services/campaigns/campaigns';
import { useDispatch } from 'react-redux';
import { create } from '../../../../store/notification';
import { Dispatch, SetStateAction } from 'react';
import { AppViewType, ViewUnitCampaignManagementType } from '../../../../store/auth';

export const useStyles = makeStyles(() => ({
    blueButton: {
        backgroundColor: 'white',
        color: '#0288d1',
    },
}));

const editableStatus = ['draft', 'scheduled'];

export default function BtnCellRenderer({
    params,
    setRefresh,
}: CellRendererProps & { setRefresh: Dispatch<SetStateAction<boolean>> }) {
    const navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();

    const { user, view } = useSelector((state: RootState) => state.auth) as { user: IUser; view: AppViewType };
    const { accessTree } = useSelector((state: RootState) => state.accessManagement);

    /**
     * We need to check if the logged in user is either superadmin or has "ROLE_STATISTICS_CAMPAIGN" role
     */
    const hasAccessToCampaignStatistics = checkAccess('unit_statistics_campaign', accessTree, user, view.layout);

    const isSentStatus = params.row.status === 1 || params.row.status === 'sent';
    const isDeletable = (item: { submitType: string; status: string; lockedDate: string | null }): boolean => {
        return (
            (item.status === CampaignStatus.Scheduled && item.submitType === CampaignSubmitType.Scheduled) ||
            (item.status === CampaignStatus.Draft && item.lockedDate === null)
        );
    };

    return (
        <>
            <Grid container spacing={1}>
                {view.unitCampaignManagement !== ViewUnitCampaignManagementType.OtherOwner &&
                    editableStatus.includes(params.row.status) && (
                        <Grid item>
                            <TableEditButton
                                target={clientEndpoints.unit_campaigns_edit.replace(':id', params.row.id.toString())}
                            />
                        </Grid>
                    )}
                {isSentStatus && (
                    <Grid item>
                        <TableButton
                            className={classes.blueButton}
                            tooltip={'Kampány megtekintése'}
                            onClick={() =>
                                navigate(clientEndpoints.unit_campaigns_view.replace(':id', params.row.id.toString()))
                            }
                        >
                            <VisibilityIcon />
                        </TableButton>
                    </Grid>
                )}
                {hasAccessToCampaignStatistics && isSentStatus && (
                    <Grid item>
                        <TableButton
                            tooltip={'Kampánystatisztika megtekintése'}
                            color="info"
                            onClick={() =>
                                navigate(
                                    clientEndpoints.unit_statistics_campaign_show.replace(
                                        ':campaignId',
                                        params.row.id.toString(),
                                    ),
                                )
                            }
                        >
                            <InsightsOutlined />
                        </TableButton>
                    </Grid>
                )}
                <Grid item>
                    <TableButton
                        tooltip={'Címzettek'}
                        color="secondary"
                        onClick={() =>
                            navigate(clientEndpoints.unit_campaigns_recipients.replace(':id', params.row.id.toString()))
                        }
                    >
                        <GroupsIcon />
                    </TableButton>
                </Grid>
                {params.row.formTemplate && isSentStatus && (
                    <Grid item>
                        <TableButton
                            tooltip={'Űrlap kitöltések'}
                            color="info"
                            onClick={() =>
                                navigate(
                                    clientEndpoints.unit_campaigns_form_fills.replace(':id', params.row.id.toString()),
                                )
                            }
                        >
                            <FeedIcon />
                        </TableButton>
                    </Grid>
                )}
                <Grid item>
                    {view.unitCampaignManagement !== ViewUnitCampaignManagementType.OtherOwner &&
                        isDeletable(params.row) && (
                            <TableDeleteButton
                                deleteAction={() => {
                                    campaignsService
                                        .delete(params.row.id)
                                        .then(() => {
                                            dispatch(create({ type: 'success', message: 'Sikeres törlés' }));
                                            setRefresh((prev) => !prev);
                                        })
                                        .catch((e) =>
                                            dispatch(
                                                create({
                                                    type: 'error',
                                                    message: e.response.data.message ?? 'Sikertelen törlés',
                                                }),
                                            ),
                                        );
                                }}
                                confirmationMessage={'Biztosan törlöd a következő elemet:'}
                                confirmationElement={params.row.name}
                            />
                        )}
                </Grid>
            </Grid>
        </>
    );
}

import { FormPreview, IForm } from '@silinfo/form-builder-2';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../components/MCCLoading';
import Logo from '../../images/MCC_logo_for_form.png';
import { endpoints } from '../../utils/endPoints';
import useFetch from '../../utils/useFetch';
import { handleSubmit } from './utils';
import { useNavigate } from 'react-router-dom';
import { clientEndpoints } from '../../utils/clientEndpoints';

export default function FormFiller() {
    const navigate = useNavigate();
    const { unitId, token } = useParams();
    const { data, loading, error } = useFetch<{ form: IForm; name: string; successMessage: string }, { error: string }>(
        endpoints.form.main(+(unitId || 0), token || ''),
        '',
    );
    const [form, setForm] = useState<Record<string, unknown>>({});
    const onSubmit = handleSubmit(+(unitId || 0), token || '');

    useEffect(() => {
        if (error.response?.status === 500) {
            navigate(clientEndpoints.server_error);
        }
    }, [error, navigate]);

    if (loading || error.response?.status === 404) {
        return <MCCLoading />;
    }

    return (
        <FormPreview
            image={Logo}
            name={data?.name || ''}
            form={data?.form || { pages: {} }}
            error={error?.response?.data.error || ''}
            formToSet={form}
            setForm={setForm}
            onSubmit={onSubmit}
            notificationSuccess={data?.successMessage}
        />
    );
}

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { CancelButton, createInitialValues, FieldGenerator, Input, Link, Loading } from '@silinfo/front-end-template';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from 'formik';
import { Children, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ObjectShape } from 'yup/lib/object';
import { IViolations } from '../../services/ResponseInterfaces';
import { RootState } from '../../store';
import { create } from '../../store/notification';
import { transformApiErrors } from '../../utils/AppConst';
import Yup from '../../utils/yup';

export interface Option<T = string> {
    label: string;
    value: T;
}

type IOptions<T> = Partial<{
    [key in keyof T]: Option[];
}>;

const Form = <T extends Record<string, unknown>, D extends object>({
    fields,
    initialValues,
    onSubmit,
    validationSchema,
    back,
    children,
    loadOptions,
    getterFunction,
    optionLabel,
    optionValue,
    hideButtons,
    submitTransformData,
    urlParam = 'id',
    readonly = false,
    footerButtons,
    errorMessage,
    successMessage,
}: {
    fields: Input[] | ((options: IOptions<T>) => Input[]);
    initialValues?: T;
    onSubmit: (values: D | T, formikHelpers: FormikHelpers<T>) => void | Promise<unknown>;
    validationSchema?: Yup.ObjectSchema<ObjectShape>;
    back?: string;
    children?: (props: FormikProps<T>) => JSX.Element;
    loadOptions?: { [key in keyof Partial<T>]: () => Promise<AxiosResponse<T>> };
    getterFunction?: (id: number | string) => Promise<AxiosResponse<T>>;
    optionValue?: string;
    optionLabel?: string;
    hideButtons?: boolean;
    submitTransformData?: (values: T) => D; // Küldés előtt adatok transformálása
    urlParam?: string;
    readonly?: boolean;
    footerButtons?: JSX.Element;
    errorMessage?: string;
    successMessage?: string;
}) => {
    const { count } = useSelector((state: RootState) => state.loading);
    const dispatch = useDispatch();
    const defaultOptions: IOptions<T> = {};
    const [options, setOptions] = useState<IOptions<T>>(defaultOptions);
    const id = useParams()[urlParam];
    const realFields = fields instanceof Function ? fields(options) : fields;
    const [initialValuesToPass, setInitialValuesToPass] = useState<T>(initialValues || createInitialValues(realFields));
    const [firstLoading, setFirstLoading] = useState(!!loadOptions);
    const [pageLoading, setPageLoading] = useState(!!getterFunction);

    const createOptions = useCallback(
        (
            array: Record<string, string>[],
            value: string = optionValue || '@id',
            label: string = optionLabel || 'name',
        ): Option[] => array.map((elem) => ({ label: elem[label], value: elem[value] })),
        [optionLabel, optionValue],
    );

    useEffect(() => {
        if (loadOptions) {
            axios
                .all(Object.values(loadOptions).map((loader) => loader && loader()))
                .then((data) => {
                    data.map((elem, i) =>
                        setOptions((prev) => ({
                            ...prev,
                            [Object.keys(loadOptions)[i]]: createOptions(
                                (elem.data['hydra:member'] || elem.data) as Record<string, string>[],
                                elem.data['hydra:member'] ? '@id' : 'id',
                            ),
                        })),
                    );
                })
                .finally(() => setFirstLoading(false));
        } else {
            setFirstLoading(false);
        }

        if (getterFunction && id) {
            getterFunction(id)
                .then((response) => setInitialValuesToPass((prev) => ({ ...prev, ...response.data })))
                .finally(() => setPageLoading(false));
        } else {
            setPageLoading(false);
        }
    }, [createOptions, getterFunction, id, loadOptions]);

    const handleSubmit = (values: T, formikHelpers: FormikHelpers<T>) => {
        const transformatedValues = submitTransformData ? submitTransformData(values) : values;
        const result = onSubmit(transformatedValues, formikHelpers);
        if (result instanceof Promise) {
            result
                .then(() =>
                    dispatch(
                        create({
                            type: 'success',
                            message: successMessage || 'Sikeres mentés!',
                            ...(back ? { redirect: back } : {}),
                        }),
                    ),
                )
                .catch((error: AxiosError<{ violations: IViolations[] }>) => {
                    if (error.response?.status === 422) {
                        console.log(
                            error.response?.data.violations,
                            transformApiErrors<T>(error.response?.data.violations),
                            'hiba',
                        );
                        formikHelpers.setErrors(transformApiErrors<T>(error.response?.data.violations));
                    }
                    dispatch(
                        create({
                            type: 'error',
                            message: errorMessage || 'Hiba a mentés során.',
                        }),
                    );
                });
        }
    };

    if (pageLoading) return <Loading noHeight />;

    return (
        <Formik
            initialValues={initialValuesToPass}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnBlur={!!validationSchema}
            validateOnChange={!!validationSchema}
        >
            {(props) =>
                firstLoading ? (
                    <Loading noHeight />
                ) : (
                    <FormikForm noValidate>
                        <Grid container spacing={2}>
                            {Children.toArray(
                                realFields.map((field) =>
                                    FieldGenerator({
                                        ...field,
                                        ...props,
                                        props: { ...field.props, disabled: readonly },
                                    }),
                                ),
                            )}
                            {children && children(props)}
                            {!hideButtons && (
                                <Grid item container spacing={2}>
                                    <Grid item>
                                        {!readonly ? (
                                            <LoadingButton loading={count > 0} variant="contained" type="submit">
                                                Mentés
                                            </LoadingButton>
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                    {back && (
                                        <Grid item>
                                            <Link to={back}>
                                                <CancelButton style={readonly ? { marginLeft: '-18px' } : {}}>
                                                    Vissza
                                                </CancelButton>
                                            </Link>
                                        </Grid>
                                    )}
                                    {footerButtons}
                                </Grid>
                            )}
                        </Grid>
                    </FormikForm>
                )
            }
        </Formik>
    );
};

export default Form;

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { SubmitButton } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../store';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';
import { setFields, setFilterFields, setTableFields } from '../../../../store/Unit/addressList';
import colors from '../../../../styles/colors';
import Content from './Content';
import { IAddressesField } from './types';

export interface AddressFieldDialogProps {
    open: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles(() => ({
    defaultButton: {
        backgroundColor: colors.error,
        '&:hover': {
            backgroundColor: colors.errorHover,
        },
    },
}));

function AddressFieldDialog(props: AddressFieldDialogProps) {
    const { open, handleClose } = props;
    const { fields } = useSelector((state: RootState) => state.addressList);
    const { view } = useSelector((state: RootState) => state.auth);
    const location = useLocation();
    const ownAddressFieldsCondition =
        location.pathname.includes('own') && view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;
    const filteredFields = ownAddressFieldsCondition
        ? fields.filter((item) => item.flag !== 4 && item.flag !== 5)
        : fields;
    const [form, setForm] = useState<IAddressesField[]>(filteredFields);
    const dispatch = useDispatch();
    const classes = useStyles();

    /**
     * MENTÉS:
     * Elmenti a változtatásokat a redux state-ben és megjeleníti az extra táblázat oszlopokat és szűrő mezőket, bezárja a dialógust
     */
    function handleSubmit() {
        dispatch(setFields(form));
        dispatch(setTableFields(form));
        dispatch(setFilterFields(form));
        handleClose();
    }

    /**
     * BEZÁR: elveti a változtatásokat és bezárja a dialógust
     */
    function handleCancel() {
        setForm(filteredFields);
        handleClose();
    }

    /**
     *  ALAPÉRTELMEZETT: visszaállítja a kezdőállapotot (táblázat + szűrők)
     */
    function handleDefault() {
        const fieldsToMap = ownAddressFieldsCondition ? filteredFields : fields;
        setForm(fieldsToMap.map((item) => ({ ...item, visible: item.default || item.flag === 4 || item.flag === 5 })));
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid item container>
                <Grid item xs={12}>
                    <DialogTitle>Oszlopok megjelenítése/elrejtése</DialogTitle>
                </Grid>
                <Grid item container xs={12}>
                    <DialogContent>
                        <Content form={form} setForm={setForm} />
                    </DialogContent>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        <SubmitButton onClick={handleSubmit}>Mentés</SubmitButton>
                        <Button variant="outlined" onClick={handleCancel}>
                            Bezár
                        </Button>
                        <SubmitButton className={classes.defaultButton} onClick={handleDefault}>
                            Alapértelmezett
                        </SubmitButton>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default AddressFieldDialog;

import { Accordion, Page } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import MCCLoading from '../../../components/MCCLoading';
import systemMessagesService from '../../../services/Central/systemMessages';
import { setMessageFields } from '../../../store/Unit/messageTemplates';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import { createFormPageHeader } from '../../../utils/Headers';
import BaseForm from './BaseForm';
import { IMessageTemplatesFormType, initialMessageTemplatesForm } from './../../Unit/Templates/MessageTemplates/types';

export interface MessageTemplatesMessageFields {
    id: number;
    messageId: string;
    description: string;
}

export default function SystemMessagesForm() {
    const { id } = useParams();
    const header = createFormPageHeader('administration', 'systemMessages', Number(id));
    const [form, setForm] = useState<IMessageTemplatesFormType>(initialMessageTemplatesForm);
    const [pageLoading, setPageLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setPageLoading(true);
        systemMessagesService.getMessageFields().then((res: AxiosResponse) => dispatch(setMessageFields(res.data)));

        if (id) {
            setPageLoading(true);
            systemMessagesService
                .get(id)
                .then((res: AxiosResponse) => setForm(res.data))
                .finally(() => setPageLoading(false));
        }
    }, [id, dispatch]);

    const handleSubmit = (form: IMessageTemplatesFormType) => (id ? systemMessagesService.update(id, form) : undefined);

    if (pageLoading) return <MCCLoading />;

    return (
        <Page header={header}>
            <Accordion title={header.title}>
                <Form
                    fields={[]}
                    onSubmit={handleSubmit}
                    back={clientEndpoints.central_system_messages}
                    initialValues={form}
                >
                    {(props) => <BaseForm {...props} />}
                </Form>
            </Accordion>
        </Page>
    );
}

import Chip from '@mui/material/Chip';

interface SeenCellRendererProps {
    seen?: number | null;
}

export default function SeenCellRenderer({ seen }: SeenCellRendererProps) {
    if (seen === 0 || seen === undefined || seen === null) {
        return <Chip color="error" label="Nem" />;
    }

    return <Chip color="success" label={`Igen (${seen})`} />;
}

import { FormPreview, IForm } from '@silinfo/form-builder-2';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MCCLoading from '../../components/MCCLoading';
import Logo from '../../images/MCC_logo_for_form.png';
import { RootState } from '../../store';
import { clientEndpoints } from '../../utils/clientEndpoints';
import { endpoints } from '../../utils/endPoints';
import useFetch from '../../utils/useFetch';

export default function FormView() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { view } = useSelector((state: RootState) => state.auth);
    const { data, loading, error } = useFetch<
        { form: IForm; name: string; successMessage: string; data: Record<string, unknown> },
        { error: string }
    >(endpoints.form.view(view.unit ?? 0, id ?? ''), '');

    useEffect(() => {
        if (error.response?.status === 500) {
            navigate(clientEndpoints.server_error);
        }
    }, [error, navigate]);

    if (loading || error.response?.status === 404) {
        return <MCCLoading />;
    }

    return (
        <FormPreview
            image={Logo}
            name={data?.name || ''}
            form={data?.form || { pages: {} }}
            error={error?.response?.data.error || ''}
            formToSet={data.data}
            readonly
            setForm={console.log}
            onSubmit={() => {
                return Promise.resolve();
            }}
            notificationSuccess={data?.successMessage}
        />
    );
}

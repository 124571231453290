import { Accordion, createInitialValues, Loading, Page } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import unitsService from '../../../services/Central/units';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import { createFormPageHeader } from '../../../utils/Headers';
import { formFields } from './fields';
import { IUnitsFormType } from './types';

export default function UnitsForm() {
    const { id } = useParams();
    const header = createFormPageHeader('administration', 'units', id ? Number(id) : null);
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState<IUnitsFormType>(createInitialValues(formFields));
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            setLoading(true);

            unitsService
                .get(id)
                .then((response: AxiosResponse<IUnitsFormType>) => setInitialValues(response.data))
                .finally(() => setLoading(false));
        }
    }, [id]);

    const checker = (resolve: (value: unknown) => void) => {
        setTimeout(async () => {
            try {
                await unitsService.checkStatus();
                resolve('OK');
                setLoading(false);
                navigate(clientEndpoints.central_units);
            } catch {
                checker(resolve);
            }
        }, 2000);
    };

    const handleCreate = async (form: IUnitsFormType) => {
        try {
            await unitsService.create(form);
            setLoading(true);
            return new Promise((resolve) => checker(resolve));
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const handleSubmit = (form: IUnitsFormType) => {
        if (id) {
            return unitsService.replace(id, form);
        }

        return handleCreate(form);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Page header={header}>
            <Accordion title={header.title}>
                <Form
                    fields={formFields}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    back={clientEndpoints.central_units}
                />
            </Accordion>
        </Page>
    );
}

import UsersForm from '../../../pages/Unit/Users/FormPage';
import UsersPage from '../../../pages/Unit/Users';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const Users = {
    path: clientEndpoints.unit_users,
    children: [
        {
            path: '',
            element: <UsersPage />,
            name: 'unit_users',
        },
        {
            path: 'new',
            element: <UsersForm />,
            name: 'unit_users_create',
        },
        {
            path: 'edit/:id',
            element: <UsersForm />,
            name: 'unit_users_edit',
        },
    ],
};

export default Users;

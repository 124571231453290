import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import ErrorStatusDialog from './ErrorStatusDialog';

const useStyles = makeStyles(() => ({
    button: {
        cursor: 'pointer',
    },
}));

export default function ErrorStatusDialogButton(props: { id: number; isCentral: boolean }) {
    const { id, isCentral } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const handleOpen = useCallback(() => setOpen(true), [setOpen]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    const dialogProps = {
        id: id,
        isCentral: isCentral,
        open: open,
        handleClose: handleClose,
    };

    return (
        <>
            <Chip onClick={handleOpen} label={`Hibás`} color="error" className={classes.button} />
            <ErrorStatusDialog {...dialogProps} />
        </>
    );
}

import { AxiosResponse } from 'axios';
import API from '../../../api';
import { IForm } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';

export type StatisticsOtherServiceSendgridGetLastInfoType = {
    lastUpdate: string;
    run: boolean;
};

export type StatisticsOtherServiceSendgridGetLastUpdateType = {
    success: boolean;
};

const statisticsOtherService = {
    getNumberOfEmails: (filter: IForm) =>
        API.get(endpoints.unit.statistics.other.getNumberOfEmails, { params: filter }),
    sendgridGetLast: {
        info: (): Promise<AxiosResponse<StatisticsOtherServiceSendgridGetLastInfoType>> =>
            API.get(endpoints.unit.statistics.other.sendgridGetLast.info),
        update: (): Promise<AxiosResponse<StatisticsOtherServiceSendgridGetLastUpdateType>> =>
            API.post(endpoints.unit.statistics.other.sendgridGetLast.update),
    },
};

export default statisticsOtherService;

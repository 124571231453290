import Cookies from 'universal-cookie';
import { store } from '../store';
import { clearView, clearUser } from '../store/auth';
import { IUser } from './Interfaces/interfaces';

const cookies = new Cookies();

export const logout = () => {
    store.dispatch(clearView());
    removeJWTToken();
    removeUser();
    removeImpersonationToken();
    store.dispatch(clearUser());
};

export const isLogin = () => {
    return !!getJWTToken() && !!getUser();
};

export const authUser = () => {
    return getUser() ? JSON.parse(getUser()) : null;
};

export const hasPermission = (permissionToCheck: string) => {
    if (!isLogin() || !authUser()) {
        return false;
    }

    return (
        authUser().permissions &&
        authUser().permissions.filter((permission: string) => {
            return permission === permissionToCheck;
        }).length > 0
    );
};

export const getImpersonationToken = () => {
    return cookies.get('impersonation_token');
};

export const setImpersonationToken = (token: string | null) => {
    cookies.set('impersonation_token', token, {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: '/',
    });
};

export const removeImpersonationToken = () => {
    cookies.remove('impersonation_token', {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: '/',
    });
};

export const getJWTToken = (): string | undefined => {
    return cookies.get(process.env.REACT_APP_COOKIES_NAME || '');
};

export const setJWTToken = (token: string | null) => {
    cookies.set(process.env.REACT_APP_COOKIES_NAME || '', token, {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: '/',
    });
};

export const removeJWTToken = () => {
    cookies.remove(process.env.REACT_APP_COOKIES_NAME || '', {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: '/',
    });
};

export const getUser = () => {
    return localStorage.getItem('user') ?? '{}';
};

export const setUser1 = (user: IUser) => {
    localStorage.setItem('user', JSON.stringify(user));
};

export const removeUser = () => {
    localStorage.removeItem('user');
};

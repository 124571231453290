import AddressListPage from '../../../pages/Unit/Addresses/AddressList';
import AddressListForm from '../../../pages/Unit/Addresses/AddressList/Form';
import AddressListImport from '../../../pages/Unit/Addresses/AddressList/Import';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const OwnAddressListPage = () => <AddressListPage ownOnly={true} />;
const OwnAddressListForm = () => <AddressListForm ownOnly={true} />;

const OwnAddressList = {
    path: clientEndpoints.unit_own_address_list,
    children: [
        {
            path: '',
            element: <OwnAddressListPage />,
            name: 'unit_own_address_list',
        },
        {
            path: 'new',
            element: <OwnAddressListForm />,
            name: 'unit_own_address_list_create',
        },
        {
            path: ':id/edit',
            element: <OwnAddressListForm />,
            name: 'unit_own_address_list_edit',
        },
        {
            path: 'import',
            element: <AddressListImport ownOnly={true} />,
            name: 'unit_own_address_list_import',
        },
    ],
};

export default OwnAddressList;

import { IForm, IInfo } from '../../../../utils/AppConst';

export interface IMessageTemplatesType {
    name: string;
}

export interface IMessageTemplatesFilterType extends IMessageTemplatesType, IForm {
    flag: string;
}

export interface IGeneratedFile {
    id?: number | string;
    tmpId?: number | string;
    type: 'generated_file';
    header: string;
    content: string;
    footer: string;
    margin: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    };
    fileNameGen: string;
}

export interface IUploadedFile {
    id?: number | string;
    tmpId?: number | string;
    type: 'uploaded_file';
    filePath: string;
    fileName: string;
}

export type TAttachment = IUploadedFile | IGeneratedFile;

export interface IMessageTemplatesFormType extends IMessageTemplatesType, IForm {
    fieldName: string;
    content: string;
    subject: string;
    recipientName: string;
    attachments: TAttachment[];
    type: string;
}

export const initialMessageTemplatesFilter: IInfo<IMessageTemplatesFilterType> = {
    filter: {
        flag: ['0'],
    },
    sort: {},
    page: 1,
    perpage: 25,
    metadata: {},
};

export const initialMessageTemplatesForm: IMessageTemplatesFormType = {
    name: '',
    content: '',
    subject: '',
    recipientName: '',
    fieldName: '',
    attachments: [],
    type: 'simple',
};

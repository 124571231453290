import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Loading } from '@silinfo/front-end-template';
import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Form from '../../../components/Form/Form';
import centralSettingsService from '../../../services/Central/settingsService';
import unitsService from '../../../services/Central/units';
import centralUserService from '../../../services/Central/userService';
import unitSettingsService from '../../../services/Unit/settingsService';
import unitUserService from '../../../services/Unit/userService';
import usersService from '../../../services/Central/userService';
import { RootState } from '../../../store';
import { ViewLayoutType } from '../../../store/auth';
import { IUnitsFormType } from '../Units/types';
import { initialForm, initialRoleOptions, IUsersForm, RoleOptions } from './types';
import UserRolesDialogForm from './UserRolesDialogForm';

export interface DialogProps {
    id: number;
    open: boolean;
    handleClose: () => void;
}

export default function UserRolesDialog(props: DialogProps) {
    const { view } = useSelector((state: RootState) => state.auth);
    const { id, open, handleClose } = props;
    const [loading, setLoading] = useState(false);
    const [roleOptions, setRoleOptions] = useState<RoleOptions>(initialRoleOptions);
    const [units, setUnits] = useState<Option<number>[]>([]);
    const [form, setForm] = useState<IUsersForm>(initialForm);
    const [selectedUnit, setSelectedUnit] = useState<IUnitsFormType>({ id: 0, name: '', serial: 0 });
    const [ownCampaignValidEmailDomains, setOwnCampaignValidEmailDomains] = useState<string[]>([]);
    const getRoles =
        view.layout === ViewLayoutType.Central ? centralUserService.getRoles : unitUserService.getUnitRoles;
    const getUser = view.layout === ViewLayoutType.Central ? centralUserService.get : unitUserService.get;
    const settingsService = view.layout === ViewLayoutType.Central ? centralSettingsService : unitSettingsService;

    useEffect(() => {
        if (open) {
            setLoading(true);
            axios
                .all([
                    getRoles().then((response: AxiosResponse) => setRoleOptions(response.data)),
                    usersService.getUnits().then((response: AxiosResponse) => setUnits(response.data)),
                    getUser(id).then((response: AxiosResponse) => setForm(response.data)),
                    view.unit && view.layout === ViewLayoutType.Unit
                        ? unitsService.get(view.unit).then((response) => setSelectedUnit(response.data))
                        : null,
                    settingsService
                        .getOwnCampaignValidEmailDomains()
                        .then((res) => setOwnCampaignValidEmailDomains(res.data.value)),
                ])
                .finally(() => setLoading(false));
        }
    }, [open, id, getRoles, getUser, view, settingsService]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid item container>
                <Grid item xs={12}>
                    <DialogTitle>Jogosultságok</DialogTitle>
                </Grid>
                <Grid item container xs={12}>
                    <DialogContent>
                        {loading ? (
                            <Loading noHeight />
                        ) : (
                            <Form onSubmit={() => console.log()} initialValues={form} fields={[]} hideButtons>
                                {(props) => (
                                    <UserRolesDialogForm
                                        roleOptions={roleOptions}
                                        units={units}
                                        rolesView
                                        layoutType={view.layout}
                                        selectedUnit={selectedUnit}
                                        ownCampaignValidEmailDomains={ownCampaignValidEmailDomains}
                                        {...props}
                                    />
                                )}
                            </Form>
                        )}
                    </DialogContent>
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>
                            Bezár
                        </Button>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
}

import Grid from '@mui/material/Grid';
import { TextField } from '@silinfo/front-end-template';
import { useFormikContext } from 'formik';
import { endpoints } from '../../../../../utils/endPoints';
import ApiAutocomplete from '../ApiAutocomplete';
import { NewCampaignFormType } from '../types';
import OptionRenderer from './OptionRenderer';
import { WithFormPartProps } from './types';

export default function WithFormPart({ isOwn }: WithFormPartProps) {
    const formikProps = useFormikContext<NewCampaignFormType>();

    return (
        <>
            <Grid item xs={12}>
                <ApiAutocomplete
                    url={endpoints.unit.template.forms.autocomplete + (isOwn ? '?isOwner=true' : '?isOwner=false')}
                    label={'Űrlap'}
                    name={'formsId'}
                    onAutoCompleteChange={(_, value) => {
                        formikProps.setFieldValue('formsId', value?.value || '');
                        formikProps.setFieldValue('form', value);
                    }}
                    disabled={formikProps.values.prevCampaignId !== 0}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={!!formikProps.errors.formsId}
                            helperText={formikProps.errors.formsId}
                            label="Űrlap"
                        />
                    )}
                    defaultValue={formikProps.values.form ?? undefined}
                    disableClearable
                    renderOption={OptionRenderer}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="formValidity"
                    label="Űrlap kitöltési határidő"
                    error={!!formikProps.errors.formValidity}
                    helperText={formikProps.errors.formValidity}
                    onBlur={(e) => formikProps.setFieldValue('formValidity', e.target.value)}
                    defaultValue={formikProps.values.formValidity || ''}
                    type="datetime-local"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="tokenCountSpend"
                    label="Hányszor tölthető ki az űrlap ezzel a tokennel"
                    error={!!formikProps.errors.tokenCountSpend}
                    helperText={formikProps.errors.tokenCountSpend}
                    onBlur={(e) => formikProps.setFieldValue('tokenCountSpend', e.target.value)}
                    defaultValue={formikProps.values.tokenCountSpend || ''}
                    type="number"
                />
            </Grid>
        </>
    );
}

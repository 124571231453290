import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ViewLayoutType } from '../../../store/auth';
import { UnitUserFormProps } from '../../Unit/Users/types';
import UnitRolesAccordion from '../../Unit/Users/UnitRolesAccordion';
import FormRoles from './FormRoles';
import { UnitRoles, UserFormProps } from './types';

export default function UserRolesDialogForm(props: UserFormProps) {
    return (
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6" style={{ marginTop: '8px' }}>
                    {props.values.lastName} {props.values.firstName} ({props.values.email})
                </Typography>
            </Grid>
            {props.layoutType === ViewLayoutType.Unit && props.selectedUnit ? (
                <UnitRolesAccordion
                    {...(props as unknown as UnitUserFormProps)}
                    unit={props.selectedUnit}
                    unitRoleOptions={props.roleOptions as unknown as UnitRoles[]}
                />
            ) : (
                <FormRoles {...props} />
            )}
        </Grid>
    );
}

import { endpoints } from '../../utils/endPoints';
import { crudTemplate } from '../templates';
import api from '../../api';
import { IUsersForm } from '../../pages/Central/Users/types';

export type PasswordReminderForm = {
    email: string;
};

export type ChangePasswordForm = {
    password: string;
    newPassword: string;
    newPasswordConfirmation: string;
};

const usersService = {
    ...crudTemplate<IUsersForm>(endpoints.central.users.main),
    getRoles: () => api.get(endpoints.central.users.getRoles),
    getUnits: () => api.get(endpoints.central.users.getUnits),
    sendPasswordReminder: (form: PasswordReminderForm) => api.post(endpoints.central.users.sendPasswordReminder, form),
    changePassword: (form: ChangePasswordForm) => api.post(endpoints.central.users.changePassword, form),
};

export default usersService;

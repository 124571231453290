import LoginOutlined from '@mui/icons-material/LoginOutlined';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import { LoadingButton as Button } from '@mui/lab';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Input } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import instance from '../../../api';
import Form from '../../../components/Form/Form';
import MCCLoading from '../../../components/MCCLoading';
import OffLayout from '../../../components/OffLayout';
import emailActivationService from '../../../services/Central/emailActivationService';
import { RootState } from '../../../store';
import { IForm, UserTokenType } from '../../../utils/AppConst';
import { setUser } from '../../../store/auth';
import { logout } from '../../../utils/AuthHelper';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import { endpoints } from '../../../utils/endPoints';
import BackToLoginButton from './BackToLoginButton';
import BackToLoginComponent from './BackToLoginComponent';
import useOpen from '../../Unit/Statistics/hooks/useOpen';

export interface PasswordForm extends IForm {
    token: string;
    password: string;
    passwordConfirmation: string;
}

const fields: Input[] = [
    {
        type: 'text',
        name: 'password',
        label: 'Jelszó',
        props: {
            type: 'password',
            required: true,
        },
        format: {
            xs: 12,
        },
    },
    {
        type: 'text',
        name: 'passwordConfirmation',
        label: 'Jelszó ismét',
        props: {
            type: 'password',
            required: true,
        },
        format: {
            xs: 12,
        },
    },
];

function Routing() {
    const [loading, setLoading] = useState<boolean>(true);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const { token } = useParams() as { token: string }; //mindig van token, különben nem ez a komponens renderelődn
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const initialValues: PasswordForm = { token, password: '', passwordConfirmation: '' };
    const { user } = useSelector((state: RootState) => state.auth);
    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
    const { isOpen: refresh, toggleOpen: toggle } = useOpen();
    useEffect(() => {
        setLoading(true);
        instance
            .get(endpoints.me)
            .then((res) => {
                dispatch(setUser(res.data));
                setLoading(false);
            })
            .catch(() => {
                emailActivationService
                    .isTokenValid({ token: token, type: UserTokenType.Activation })
                    .then(() => setIsTokenValid(true))
                    .catch(() => setIsTokenValid(false))
                    .finally(() => setLoading(false));
            });
    }, [token, dispatch, refresh]);

    function handleSubmit(form: PasswordForm) {
        setFormLoading(true);
        return emailActivationService.activate(form).finally(() => setFormLoading(false));
    }

    if (loading) {
        return <MCCLoading />;
    }

    if (user?.email) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography textAlign="center" variant="h5">
                        Bejelentkezve nem hajtható végre az aktiválás!
                    </Typography>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        size="large"
                        sx={{
                            padding: '8px',
                            height: 56,
                            width: 300,
                        }}
                        endIcon={<LoginOutlined />}
                        onClick={() => {
                            navigate(clientEndpoints.home);
                        }}
                        loading={loading}
                    >
                        Vissza a főoldalra
                    </Button>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                    <Button
                        fullWidth
                        color="primary"
                        variant="outlined"
                        size="large"
                        sx={{
                            padding: '8px',
                            height: 56,
                            width: 300,
                        }}
                        endIcon={<LogoutOutlined />}
                        onClick={() => {
                            setLoading(true);
                            logout();
                            toggle();
                        }}
                        loading={loading}
                    >
                        Kijelentkezés
                    </Button>
                </Grid>
            </Grid>
        );
    }

    if (!isTokenValid) {
        return (
            <BackToLoginComponent
                message={' Az aktivációs kulcs nem létezik, már felhasználásra került vagy lejárt.'}
            />
        );
    }

    return (
        <Form
            fields={fields}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            successMessage="Sikeres aktiválás!"
            errorMessage="Hiba az aktiválás során."
            hideButtons
            back={clientEndpoints.login}
        >
            {() => (
                <>
                    <Grid item xs={12}>
                        <LoadingButton
                            loading={formLoading}
                            disabled={formLoading}
                            fullWidth
                            type="submit"
                            size="large"
                            variant="contained"
                        >
                            Aktiváció
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12}>
                        <BackToLoginButton />
                    </Grid>
                </>
            )}
        </Form>
    );
}

export default function Activation() {
    return (
        <OffLayout>
            <Routing />
        </OffLayout>
    );
}

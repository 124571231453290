import { GridSelectionModel } from '@mui/x-data-grid';
import { Loading } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useFormikContext } from 'formik';
import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import instance from '../../../../api';
import { RootState } from '../../../../store';
import { addFields, setSelectedRows } from '../../../../store/Unit/addressList';
import { ViewUnitCampaignManagementType } from '../../../../store/auth';
import { endpoints } from '../../../../utils/endPoints';
import AddressListMultipleTasksDialog from './../../Addresses/AddressList/AddressListMultipleTasks/AddressListMultipleTasksDialog';
import { transformData } from './../../Addresses/AddressList/fields';
import AddressListPage from './AddressListPage';
import { NewCampaignFormType } from './types';

const OptimizedAddressListPage = memo(AddressListPage);

export default function SelectRecipientForm({
    setSelected,
    selected,
    sessionId,
}: {
    setSelected: Dispatch<SetStateAction<GridSelectionModel>>;
    selected: GridSelectionModel;
    sessionId: string;
}) {
    const { view } = useSelector((state: RootState) => state.auth);
    const isOwn = view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const formikProps = useFormikContext<NewCampaignFormType>();
    const { showAddressType } = formikProps.values.filter;

    useEffect(() => {
        dispatch(setSelectedRows(selected.length));
    }, [dispatch, selected]);

    useEffect(() => {
        instance
            .get(endpoints.unit.address.addressList.campaigns, {
                params: {
                    isOwn,
                    showAddressType: showAddressType || [isOwn ? 'onlyOwn' : 'onlyCommon'],
                },
            })
            .then((res: AxiosResponse) => dispatch(addFields(transformData(res.data))))
            .finally(() => setLoading(false));
    }, [dispatch, isOwn, showAddressType]);

    if (loading) {
        return <Loading noHeight />;
    }

    return (
        <>
            <OptimizedAddressListPage sessionId={sessionId} setSelected={setSelected} selected={selected} />
            <AddressListMultipleTasksDialog selected={selected} />
        </>
    );
}

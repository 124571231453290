import addressListService from '../../../../../services/Unit/addressListService';
import { FormikListPage } from '@silinfo/front-end-template';
import ImportStatusCellRenderer from './ImportStatusCellRenderer';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import ImportValidationErrorDialog from './ImportValidationErrorDialog';
import { useDispatch, useSelector } from 'react-redux';
import { create } from '../../../../../store/notification';
import { RootState } from '../../../../../store';
import { IForm } from '../../../../../utils/AppConst';
import { ViewUnitCampaignManagementType } from '../../../../../store/auth';

const useStyles = makeStyles((theme: Theme) => ({
    tableCellLink: {
        color: theme.palette.text.primary,
        textTransform: 'none',
        textDecoration: 'underline',
    },
}));

export default function ImportList(): JSX.Element {
    const { view } = useSelector((state: RootState) => state.auth);
    const { user } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const [importId, setImportId] = useState<number>(0);
    const classes = useStyles();
    const downloadUploadedFile = (id: number, name: string) => {
        addressListService.downloadUploadedFile(id).then((resp) => {
            if (resp.status === 204) {
                dispatch(
                    create({
                        type: 'error',
                        message: 'A fájl nem található.',
                    }),
                );

                return;
            }

            const blob = resp.data;
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = name;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };
    const showValidationErrors = (id: number): void => {
        setImportId(0);
        setTimeout(() => {
            setImportId(id);
        });
    };
    const columns: GridColDef[] = [
        { headerName: '#', field: 'id' },
        { headerName: 'Importálás kezdete', field: 'insertDate' },
        { headerName: 'Importálás vége', field: 'endDate' },
        {
            headerName: 'Feltöltött fájl',
            field: 'fileName',
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    className={classes.tableCellLink}
                    onClick={() => downloadUploadedFile(params.row.id, params.value)}
                >
                    {params.value}
                </Button>
            ),
        },
        {
            headerName: 'Hibalista',
            field: 'error_list',
            align: 'center',
            renderCell: (params: GridRenderCellParams) => {
                return params.row.status === 'error_validation' ? (
                    <Button className={classes.tableCellLink} onClick={() => showValidationErrors(params.row.id)}>
                        hibalista
                    </Button>
                ) : (
                    '-'
                );
            },
            headerAlign: 'center',
        },
        {
            headerName: 'Státusz',
            field: 'status',
            renderCell: ImportStatusCellRenderer,
            minWidth: 50,
            align: 'center',
            sortable: false,
            headerAlign: 'center',
        },
    ];

    return (
        <>
            <FormikListPage
                autoload
                header={{
                    breadcrumbs: {},
                    project: '',
                    title: '',
                }}
                service={{
                    filter: (search: IForm) => {
                        return addressListService.getImportList(
                            view.unitCampaignManagement === ViewUnitCampaignManagementType.Own
                                ? { ...search, ...{ ownerId: user?.id } }
                                : { ...search },
                        );
                    },
                }}
                columns={columns}
                defaultSort={{ id: 'desc' }}
                responseKey="data"
            />
            <ImportValidationErrorDialog id={importId} />
        </>
    );
}

import useMediaQuery from '@mui/material/useMediaQuery';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FormikListPage } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ActionCellRenderer from '../../../components/ActionCellRenderer';
import emailsService from '../../../services/Unit/emails';
import { RootState } from '../../../store';
import { ViewUnitCampaignManagementType } from '../../../store/auth';
import { createListPageHeader } from '../../../utils/Headers';
import BtnCellRenderer from './BtnCellRenderer';
import { filterFields } from './fields';
import SwitchCellRenderer from './SwitchCellRenderer';

export default function Emails() {
    const [refresh, setRefresh] = useState<boolean>(false);
    const header = createListPageHeader('administration_unit', 'emails');
    const matches = useMediaQuery('min-width:900px', { noSsr: true });
    const { view, user } = useSelector((state: RootState) => state.auth);
    const filter = {
        fields: filterFields,
    };

    const columns = [
        { headerName: 'Név', field: 'name' },
        { headerName: 'E-mail cím', field: 'address' },
        { headerName: 'Küldő', field: 'isSender', renderCell: SwitchCellRenderer },
        { headerName: 'Válasz', field: 'isReply', renderCell: SwitchCellRenderer },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams) => (
                <ActionCellRenderer>
                    <BtnCellRenderer params={params} setRefresh={setRefresh} />
                </ActionCellRenderer>
            ),
            sortable: false,
            minWidth: matches ? 200 : 90,
        },
    ];

    return (
        <FormikListPage
            autoload
            header={header}
            service={{
                filter: (search) =>
                    emailsService.filter({
                        ...search,
                        ...{
                            ownerId:
                                view.unitCampaignManagement === ViewUnitCampaignManagementType.Own ? user?.id : 'null',
                        },
                    }),
            }}
            columns={columns}
            defaultSort={{ address: 'asc' }}
            newLabel={
                view.unitCampaignManagement !== ViewUnitCampaignManagementType.Own ? 'E-mail hozzáadása' : undefined
            }
            filter={filter}
            refresh={refresh}
            responseKey="data"
            tableProps={{
                initialState: {
                    pinnedColumns: { right: ['operations'] },
                    sorting: {
                        sortModel: [
                            {
                                field: 'address',
                                sort: 'asc',
                            },
                        ],
                    },
                },
            }}
        />
    );
}

import { IForm, IInfo } from '../../../../utils/AppConst';

export interface StatisticsGroupedFilter extends IForm {
    campaigns: number[];
    totalEmailsMin?: number;
    totalEmailsMax?: number;
    totalWrongEmailsMin?: number;
    totalWrongEmailsMax?: number;
    totalSeenMin?: number;
    totalSeenMax?: number;
    totalFillTimesMin?: number;
    totalFillTimesMax?: number;
}

export const initialStatisticalGroupedFilter: StatisticsGroupedFilter = {
    campaigns: [],
};

export interface StatisticsGroupedTable extends Record<string, string> {
    id: string;
    campaignName: string;
    totalEmails: string;
    totalWrongEmails: string;
    totalSeen: string;
    totalFillTimes: string;
}

export const chartOptions = {
    title: 'A megjelenített kampányokra vonatkozó statisztikai adatok',
    chartArea: { width: '50%' },
    isStacked: true,
    hAxis: {
        minValue: 0,
    },
    vAxis: {
        title: 'Kampány',
    },
};

export const arraysForChart: Record<string, string[]> = {
    campaignName: ['Kampány'],
    totalEmails: ['Összes címzett'],
    totalWrongEmails: ['Hibás címzett'],
    totalSeen: ['Emailt megtekintettek száma'],
    totalFillTimes: ['Kitöltött űrlapok száma'],
};

export const initialInfo: IInfo<IForm> = {
    filter: {},
    sort: {},
    page: 1,
    perpage: 10,
    metadata: {},
};

import PeopleIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { theme } from '../../utils/theme';
import Tooltip from '@mui/material/Tooltip';

interface BaseChipProps {
    color: 'primary' | 'success';
    Icon: OverridableComponent<SvgIconTypeMap>;
    title: string;
}

const BaseChip = ({ color, Icon, title }: BaseChipProps) => (
    <Tooltip title={title}>
        <Icon
            className="borderRadius"
            sx={{ padding: '2px', borderRadius: '50%', bgcolor: theme.palette[color].main, color: '#fff !important' }}
        />
    </Tooltip>
);

const OwnChip = () => <BaseChip title="Saját" color="primary" Icon={PersonIcon} />;
const CommonChip = () => <BaseChip title="Közös" color="success" Icon={PeopleIcon} />;

export { OwnChip, CommonChip };

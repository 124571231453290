import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import instance from '../../../api';
import { IForm, PROJECT_NAME } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';
import StatusCellRenderer from './StatusCellRenderer';
import DownloadCellRenderer from './DownloadCellRenderer';

const service = {
    filter: (form: IForm) => instance.get(endpoints.unit.exportList.main, { params: form }),
};

const header = {
    breadcrumbs: {
        exportList: 'Exportlista',
    },
    title: 'Exportlista',
    project: PROJECT_NAME,
};

const columns: GridColDef[] = [
    {
        field: 'processStartAt',
        headerName: 'Időpont',
        align: 'center' as const,
        headerAlign: 'center' as const,
        valueGetter: (params: GridValueGetterParams) => {
            const date = new Date(params.row.processStartAt);
            return params.row.processStartAt ? date.toLocaleString() : <i>Nincs dátum</i>;
        },
    },
    {
        field: 'typeName',
        headerName: 'Típus',
    },
    {
        field: 'statusName',
        headerName: 'Státusz',
        renderCell: StatusCellRenderer,
        align: 'center' as const,
        headerAlign: 'center' as const,
    },
    {
        field: 'download',
        headerName: 'Letöltés',
        renderCell: DownloadCellRenderer,
        align: 'center' as const,
        headerAlign: 'center' as const,
    },
].map((c) => ({ ...c, flex: 1 }));

export { columns, header, service };

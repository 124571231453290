import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import { UnitRoles, IUsersForm } from './types';
import { Option } from '../../../components/Form/Form';
import { UnitRole } from '../../../utils/Roles';

interface RolesTitleProps {
    unit: Option<number>;
    role: UnitRoles;
    rolesView?: boolean;
    ownCampaignValidEmailDomains: string[];
}

export default function RolesTitle({ unit, role, rolesView, ownCampaignValidEmailDomains }: RolesTitleProps) {
    const props = useFormikContext<IUsersForm>();
    const isUserCanSetRole = (user: IUsersForm, role: string) =>
        role !== UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN ||
        ownCampaignValidEmailDomains.some((emailDomain) => (user.email || '').toString().endsWith(emailDomain));

    return (
        <Grid item xs={12}>
            <Typography variant="h6" style={{ marginTop: '8px' }}>
                {role.label}{' '}
                {!rolesView && (
                    <Switch
                        checked={role.value.every(
                            (roleItem) =>
                                !isUserCanSetRole(props.values, roleItem.value) ||
                                props.values.roles?.[unit.value]?.[roleItem.value] == '1',
                        )}
                        onChange={(e) =>
                            props.setFieldValue(
                                `roles.${unit.value}`,
                                role.value.reduce(
                                    (acc, roleItem) =>
                                        !isUserCanSetRole(props.values, roleItem.value)
                                            ? acc
                                            : {
                                                  ...acc,
                                                  [roleItem.value]: e.target.checked ? '1' : '0',
                                              },
                                    { ...props.values.roles[unit.value] },
                                ),
                            )
                        }
                    />
                )}
            </Typography>
        </Grid>
    );
}

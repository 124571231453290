import Grid from '@mui/material/Grid';
import { TableButton } from '@silinfo/front-end-template';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid';

type ContentCellRendererProps = {
    params: GridRenderCellParams;
};
export default function ContentCellRenderer(props: ContentCellRendererProps) {
    const { params } = props;
    const navigate = useNavigate();
    return (
        <Grid container spacing={1}>
            <Grid item>
                <TableButton
                    tooltip={'Megtekintése'}
                    onClick={() =>
                        navigate(clientEndpoints.central_system_logs_show.replace(':id', params.row.id.toString()))
                    }
                >
                    <VisibilityIcon />
                </TableButton>
            </Grid>
        </Grid>
    );
}

import { FormikListPage } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import superAdminService from '../../../services/Central/superAdminService';
import { PROJECT_NAME } from '../../../utils/AppConst';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import { columns, filterFields } from '../SystemSettings/fields';

export default function SystemSettings() {
    const header: HeaderProps = {
        project: PROJECT_NAME,
        title: 'Szuperadmin',
        breadcrumbs: {
            admin: 'Adminisztráció',
            [clientEndpoints.central_super_admin]: 'Szuperadmin',
        },
    };

    const filter = {
        fields: filterFields,
    };

    return (
        <FormikListPage
            autoload
            header={header}
            service={superAdminService}
            columns={columns(clientEndpoints.central_super_admin_edit)}
            defaultSort={{ key: 'asc' }}
            filter={filter}
            responseKey="data"
            tableProps={{
                initialState: { pinnedColumns: { right: ['operations'] } },
            }}
        />
    );
}

import { Option } from '@silinfo/front-end-template/lib/esm/components/Form';
import { IForm } from '../../../../utils/AppConst';

//types

interface CampaignStatisticsBaseType {
    campaignName: string;
    id: number;
}

export interface EmailsOpenedType extends CampaignStatisticsBaseType {
    notOpened: number;
    opened: number;
}

export interface EmailsSentType {
    delivered: number;
    notDelivered: number;
    processing: number;
    noInformation: number;
}

export interface FormsFilledType {
    filled: number;
    notFilled: number;
}

export interface StatisticsCampaignFilter extends IForm {
    campaigns: Array<number>;
    statisticsType: Array<string>;
}

// constants

export enum CampaignStatisticsType {
    EmailsOpened = 'emails-opened',
    EmailsSent = 'emails-sent',
    FormsFilled = 'forms-filled',
}

export const CAMPAIGN_STATISTICS_TYPES = [
    CampaignStatisticsType.EmailsOpened,
    CampaignStatisticsType.EmailsSent,
    CampaignStatisticsType.FormsFilled,
];

export const statisticsTypes: Option[] = [
    { value: CampaignStatisticsType.EmailsOpened, label: 'Megnyitott e-mailek' },
    { value: CampaignStatisticsType.EmailsSent, label: 'Kézbesített e-mailek' },
    { value: CampaignStatisticsType.FormsFilled, label: 'Űrlap kitöltések' },
];

export const initialStatisticalCampaignFilterState: StatisticsCampaignFilter = {
    campaigns: [],
    statisticsType: CAMPAIGN_STATISTICS_TYPES,
};

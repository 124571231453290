import OffLayout from '../../components/OffLayout';
import { Outlet } from 'react-router-dom';
import Unsubscribe from '../../pages/Unsubscribe';

const unsubscribe = {
    path: '/unsubscribe',
    element: (
        <OffLayout>
            <Outlet />
        </OffLayout>
    ),
    children: [
        {
            path: ':unitId/:token',
            element: <Unsubscribe />,
        },
    ],
};

export default unsubscribe;

import { FormikListPage } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import React, { useMemo, useCallback } from 'react';
import unitsService from '../../../services/Central/units';
import { createListPageHeader } from '../../../utils/Headers';
import { filterFields } from './fields';
import { tableColumns } from './table';
import ConfirmationDialog, { IConfirmationDialog } from './ConfirmationDialog';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Units() {
    const header: HeaderProps = createListPageHeader('administration', 'units');
    const [refresh, setRefresh] = React.useState(false);
    const doRefresh = useCallback(() => setRefresh((prev) => !prev), []);
    const [confirmationState, setConfirmationState] = React.useState<IConfirmationDialog>({
        message: '',
        refresh: doRefresh,
    });
    const matches = useMediaQuery('(min-width:900px)', { noSsr: true });

    const handleConfirmationStateChange = (key: string, value: unknown) =>
        setConfirmationState((prevState) => ({
            ...prevState,
            [key]: value,
        }));

    const columns = useMemo(() => tableColumns(setConfirmationState, doRefresh, matches), [doRefresh, matches]);

    return (
        <>
            <ConfirmationDialog state={confirmationState} setState={handleConfirmationStateChange} />
            <FormikListPage
                autoload
                header={header}
                service={unitsService}
                columns={columns}
                defaultSort={{ serial: 'asc' }}
                newLabel="Területi egység hozzáadása"
                filter={{
                    fields: filterFields,
                }}
                responseKey="data"
                refresh={refresh}
                tableProps={{
                    initialState: { pinnedColumns: { right: ['operations'] } },
                }}
            />
        </>
    );
}

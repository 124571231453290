import EmailsPage from '../../../pages/Unit/Emails';
import EmailsForm from '../../../pages/Unit/Emails/form';
import { clientEndpoints } from '../../../utils/clientEndpoints';

const Emails = {
    path: clientEndpoints.unit_emails,
    children: [
        {
            path: '',
            element: <EmailsPage />,
            name: 'unit_emails',
        },
        {
            path: 'new',
            element: <EmailsForm />,
            name: 'unit_emails_create',
        },
        {
            path: ':id/edit',
            element: <EmailsForm />,
            name: 'unit_emails_edit',
        },
    ],
};

export default Emails;

import InputIcon from '@mui/icons-material/Input';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import { FormikListPage, Link, Option } from '@silinfo/front-end-template';
import { HeaderProps } from '@silinfo/front-end-template/lib/esm/components/Header/Header';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SplitButton from '../../../../components/Form/SplitButton';
import MCCLoading from '../../../../components/MCCLoading';
import addressListService from '../../../../services/Unit/addressListService';
import { RootState } from '../../../../store';
import { addFields, setAddressGroupOptions, setSelectedRows } from '../../../../store/Unit/addressList';
import { ViewUnitCampaignManagementType, getOtherOwnerCondition } from '../../../../store/auth';
import { IForm, PROJECT_NAME, initialInfo } from '../../../../utils/AppConst';
import { clientEndpoints } from '../../../../utils/clientEndpoints';
import { getOwnerId } from '../../../Address/MessageFields';
import AddressDeleteDialog from './AddressDeleteDialog';
import AddressFieldDialogButton from './AddressFieldDialogButton';
import AddressListMultipleTasksDialog from './AddressListMultipleTasks/AddressListMultipleTasksDialog';
import AddressListToolbar from './AddressListToolbar';
import { CheckboxMemo } from './CheckboxMemo';
import { MemoizedTableCell } from './MemoizedTableCell';
import { transformData } from './fields';
import { IAddressesField } from './types';

function getHeader(ownOnly?: boolean): HeaderProps {
    return {
        project: PROJECT_NAME,
        title: 'Címlista',
        breadcrumbs: {
            addresses: 'Címek',
            [ownOnly ? clientEndpoints.unit_own_address_list : clientEndpoints.unit_address_list]: 'Címlista',
        },
    };
}

function OtherOwnerAlert({
    otherOwnerCondition,
    selectedOwner,
}: {
    otherOwnerCondition: boolean;
    selectedOwner?: Option<number, string>;
}) {
    if (otherOwnerCondition && selectedOwner) {
        return (
            <Grid item xs={12}>
                <Alert severity="info">
                    {`Mivel ${selectedOwner.label} saját kampányadatait látod, ezért a címeket csak olvasási módban tudod megtekinteni.`}
                </Alert>
            </Grid>
        );
    }
    return <></>;
}

export function getFilteredTableFields(ownAddressFieldsCondition: boolean, tableFields: GridColDef[]): GridColDef[] {
    return ownAddressFieldsCondition
        ? tableFields.filter((item) => item.headerName !== 'Vezetéknév' && item.headerName !== 'Keresztnév')
        : tableFields;
}

export function getFilteredFilterFields(
    ownAddressFieldsCondition: boolean,
    filterFields: IAddressesField[],
): IAddressesField[] {
    return ownAddressFieldsCondition
        ? filterFields.filter((item) => item.label !== 'Vezetéknév' && item.label !== 'Keresztnév')
        : filterFields;
}

export default function AddressList(props: { ownOnly?: boolean }) {
    const { ownOnly } = props;
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<IForm>(initialInfo());
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { view, user, selectedOwner } = useSelector((state: RootState) => state.auth);
    const { tableFields, refresh, filterFields } = useSelector((state: RootState) => state.addressList);
    const location = useLocation();
    const ownAddressFieldsCondition =
        location.pathname.includes('own') && view.unitCampaignManagement === ViewUnitCampaignManagementType.Own;
    const filteredTableFields = getFilteredTableFields(ownAddressFieldsCondition, tableFields);
    const filteredFilterFields = getFilteredFilterFields(ownAddressFieldsCondition, filterFields);
    const [selected, setSelected] = useState<(number | string)[]>([]);
    const service = {
        filter: (form: IForm) => {
            setInfo(form);
            return addressListService.filter(form);
        },
    };
    const header = getHeader(ownOnly);
    const otherOwnerCondition = getOtherOwnerCondition();

    const addressGroupFilter = useMemo(
        () => ({
            ownerId: getOwnerId(view, ownOnly || false, user, selectedOwner),
        }),
        [view, ownOnly, user, selectedOwner],
    );

    useEffect(() => {
        dispatch(setSelectedRows(selected.length));
    }, [dispatch, selected]);

    const exportContext = {
        initiateExport: addressListService.initiateExport,
        checkExport: addressListService.checkExport,
        downloadExport: addressListService.downloadExport,
        filter: info,
    };

    useEffect(() => {
        if (!loading) return;
        axios
            .all<unknown>([
                addressListService
                    .getAddressGroups(addressGroupFilter)
                    .then((res: AxiosResponse) => dispatch(setAddressGroupOptions(res.data))),
                addressListService
                    .getMessageFields({
                        ownerId: getOwnerId(view, ownOnly ?? false, user, selectedOwner),
                        needFlags: 'true',
                    })
                    .then((res) => dispatch(addFields(transformData(res.data)))),
            ])
            .finally(() => setLoading(false));
    }, [dispatch, addressGroupFilter, view, ownOnly, user, selectedOwner, loading]);

    const Toolbar = useCallback(
        () =>
            selected.length > 0 && !otherOwnerCondition ? (
                <AddressListToolbar selected={selected} setOpen={setOpen} />
            ) : (
                <></>
            ),
        [selected, otherOwnerCondition],
    );

    if (loading) return <MCCLoading />;

    const columns = filteredTableFields
        .filter(
            (item) =>
                item.field !== 'operations' ||
                (([undefined, ViewUnitCampaignManagementType.Common].includes(view.unitCampaignManagement) ||
                    !!ownOnly) &&
                    !otherOwnerCondition),
        )
        .map((item) => ({ ...item }));

    return (
        <Grid container spacing={2}>
            {view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && !ownOnly && !otherOwnerCondition && (
                <Grid item xs={12}>
                    <Alert severity="info">
                        Mivel saját kampány módban használod a rendszert, a közös címlistát csak olvasási módban tudod
                        megtekinteni.
                    </Alert>
                </Grid>
            )}
            <OtherOwnerAlert otherOwnerCondition={otherOwnerCondition} selectedOwner={selectedOwner} />
            <Grid item xs={12}>
                <FormikListPage
                    autoload
                    filter={{
                        fields: filteredFilterFields,
                    }}
                    header={header}
                    service={{
                        filter: (search) =>
                            service.filter({
                                ...search,
                                ...{ ownerId: getOwnerId(view, ownOnly || false, user, selectedOwner) },
                            }),
                    }}
                    columns={columns}
                    defaultSort={{ email: 'asc' }}
                    tableProps={{
                        columnBuffer: columns.length,
                        disableVirtualization: true,
                        checkboxSelection: !otherOwnerCondition,
                        onSelectionModelChange: setSelected,
                        selectionModel: selected,
                        components: {
                            Toolbar,
                            Cell: MemoizedTableCell,
                            BaseCheckbox: CheckboxMemo,
                        },
                        initialState: {
                            pinnedColumns: { right: ['operations'] },
                            sorting: {
                                sortModel: [
                                    {
                                        field: 'email',
                                        sort: 'asc',
                                    },
                                ],
                            },
                        },
                    }}
                    refresh={JSON.stringify([
                        refresh,
                        view.unitCampaignManagement === ViewUnitCampaignManagementType.Own && ownOnly,
                    ])}
                    responseKey="data"
                    customButtons={
                        <>
                            <AddressFieldDialogButton />
                            {([undefined, ViewUnitCampaignManagementType.Common].includes(
                                view.unitCampaignManagement,
                            ) ||
                                ownOnly) &&
                                !otherOwnerCondition && (
                                    <Grid item>
                                        <Link
                                            to={
                                                ownOnly
                                                    ? clientEndpoints.unit_own_address_list_import
                                                    : clientEndpoints.unit_address_list_import
                                            }
                                        >
                                            <Button variant="contained" color="secondary" startIcon={<InputIcon />}>
                                                Importálás
                                            </Button>
                                        </Link>
                                    </Grid>
                                )}
                            <Grid item>
                                <SplitButton placement="top" exportContext={exportContext} />
                            </Grid>
                        </>
                    }
                    newLabel={
                        ([undefined, ViewUnitCampaignManagementType.Common].includes(view.unitCampaignManagement) ||
                            ownOnly) &&
                        !otherOwnerCondition
                            ? 'Címzett hozzáadása'
                            : undefined
                    }
                />
                <AddressListMultipleTasksDialog selected={selected} />
                <AddressDeleteDialog open={open} handleClose={() => setOpen(false)} selected={selected} />
            </Grid>
        </Grid>
    );
}

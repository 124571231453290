import { TableEditButton } from '@silinfo/front-end-template';
import { GridCellRendererProps } from '../../Unit/Addresses/AddressList/BtnCellRenderer';

const BtnCellRenderer = ({ params, editUrl }: GridCellRendererProps & { editUrl: string }) => {
    return (
        <>
            <TableEditButton
                tooltip={'Szerkesztés'}
                style={{ marginRight: '5px' }}
                target={editUrl.replace(':id', params.row.id.toString())}
            />
        </>
    );
};

export default BtnCellRenderer;

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableButton, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import unitUsersService from '../../../services/Unit/userService';
import centralUsersService from '../../../services/Central/userService';
import { create } from '../../../store/notification';
import colors from '../../../styles/colors';
import { clientEndpoints } from '../../../utils/clientEndpoints';
import { theme } from '../../../utils/theme';
import UserRolesButton from './UserRolesButton';
import EmailIcon from '@mui/icons-material/Email';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import emailActivationService from '../../../services/Central/emailActivationService';
import { ViewLayoutType } from '../../../store/auth';
import { RootState } from '../../../store';

type UserBtnCellRendererProps = {
    params: GridRenderCellParams;
    type: ViewLayoutType;
    setRefresh: Dispatch<SetStateAction<boolean>>;
};

const getEditUrl = (type: ViewLayoutType, id: string) => {
    switch (type) {
        case ViewLayoutType.Central:
            return clientEndpoints.central_users_edit.replace(':id', id);
        case ViewLayoutType.Unit:
            return clientEndpoints.unit_users_edit.replace(':id', id);
        default:
            return '';
    }
};

const getUserService = (type: ViewLayoutType) => {
    switch (type) {
        case ViewLayoutType.Central:
            return centralUsersService;
        case ViewLayoutType.Unit:
            return unitUsersService;
        default:
            return null;
    }
};

const useStyles = makeStyles(() => ({
    deleteButton: {
        background: theme.palette.common.white,
        borderColor: colors.red,
        color: colors.red,
        boxShadow: theme.palette.common.black,
        '&:hover': {
            background: colors.whiteHover,
        },
    },
}));

const BtnCellRenderer = ({ params, type, setRefresh }: UserBtnCellRendererProps) => {
    const editUrl = getEditUrl(type, params.row.id.toString());
    const service = getUserService(type);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);
    const userCanSendActivateEmail =
        params.row.isEnabled &&
        !params.row.isActivationEmailSent &&
        !params.row.isActivated &&
        params.row.deletedAt === null;
    const userCanResendActivateEmail =
        params.row.isEnabled &&
        params.row.isActivationEmailSent &&
        !params.row.isActivated &&
        params.row.deletedAt === null;
    const isSelf = user?.email === params.row.email;

    return (
        <Grid container spacing={1} justifyContent="center">
            <Grid item>
                <TableEditButton tooltip={'Szerkesztés'} target={editUrl} />
            </Grid>
            <Grid item>
                <UserRolesButton params={params} />
            </Grid>
            {service && !isSelf && (
                <Grid item>
                    <TableDeleteButton
                        tooltip={'Törlés'}
                        className={classes.deleteButton}
                        deleteAction={() => {
                            service
                                .delete(params.row.id)
                                .then(() => {
                                    dispatch(create({ type: 'success', message: `Sikeres törlés!` }));
                                    setRefresh((prev) => !prev);
                                })
                                .catch(() => dispatch(create({ type: 'error', message: 'A törlés sikertelen!' })));
                        }}
                        confirmationMessage={'Biztosan törlöd a következő felhasználót:'} //TODO: username email
                        confirmationElement={`${params.row.lastName} ${params.row.firstName} (${params.row.email})`}
                    />
                </Grid>
            )}
            {userCanSendActivateEmail && (
                <Grid item>
                    <TableButton
                        tooltip="Aktiváló e-mail kiküldése"
                        onClick={() => {
                            emailActivationService
                                .send(params.row.id)
                                .then(() => {
                                    dispatch(
                                        create({ type: 'success', message: `Sikeres aktivációs e-mail kiküldés!` }),
                                    );
                                    setRefresh((prev) => !prev);
                                })
                                .catch(() =>
                                    dispatch(
                                        create({
                                            type: 'error',
                                            message: 'Az aktivációs e-mail kiküldése sikertelen!',
                                        }),
                                    ),
                                );
                        }}
                    >
                        <EmailIcon />
                    </TableButton>
                </Grid>
            )}
            {userCanResendActivateEmail && (
                <Grid item>
                    <TableButton
                        tooltip="Aktiváló e-mail újraküldése"
                        onClick={() => {
                            emailActivationService
                                .resend(params.row.id)
                                .then(() => {
                                    dispatch(
                                        create({ type: 'success', message: `Sikeres aktivációs e-mail újraküldés!` }),
                                    );
                                    setRefresh((prev) => !prev);
                                })
                                .catch(() =>
                                    dispatch(
                                        create({
                                            type: 'error',
                                            message: 'Az aktivációs e-mail újraküldése sikertelen!',
                                        }),
                                    ),
                                );
                        }}
                    >
                        <ForwardToInboxIcon />
                    </TableButton>
                </Grid>
            )}
        </Grid>
    );
};

export default BtnCellRenderer;

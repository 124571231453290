import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@silinfo/front-end-template';
import * as React from 'react';
import {
    ForwardedRef,
    SyntheticEvent,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import api from '../../../../api';
import { Option } from '../../../../components/Form/Form';
import { useDebounce } from '../../../../utils/useDebounce';

const EPSILON = 5;

type ApiAutocompleteProps<T extends Option<number>> = {
    url: string;
    label: string;
    name: string;
    onAutoCompleteChange?: (event: SyntheticEvent, value: T) => void;
    defaultValue?: T;
    onChange?: (value: number | number[]) => void;
    renderInput?: (params: AutocompleteRenderInputParams) => JSX.Element;
    disableClearable?: boolean;
    prevCampaign?: boolean;
    renderOption?: (liProps: React.HTMLAttributes<HTMLLIElement>, option: T) => JSX.Element;
    initialOptions?: T[]; //TODO: implement this on owner select page
    disabled?: boolean;
    multiple?: boolean;
    isFirstMount?: boolean;
    error?: boolean;
    helperText?: string;
};
type ListBoxProps = React.HTMLAttributes<HTMLUListElement>;
type NullableUlElement = HTMLUListElement | null;

// Egyedi ListboxComponent kell, hogy amikor új elemek kerülnek a lista aljára, ne ugorjon fel a tetejére
const ListBox = forwardRef(function ListBoxBase(props: ListBoxProps, ref: ForwardedRef<HTMLUListElement>) {
    const { children, ...rest } = props;

    const innerRef = useRef<HTMLUListElement>(null);

    useImperativeHandle<NullableUlElement, NullableUlElement>(ref, () => innerRef.current);

    return (
        <ul {...rest} ref={innerRef} role="list-box">
            {children}
        </ul>
    );
});
const emptyObject = {
    value: 0,
    label: '',
};

// Material UI Autocomplete component with server side search and throttling
function ApiAutocomplete<T extends Option<number>>(props: ApiAutocompleteProps<T>) {
    const emptyValue = React.useMemo(() => (props.multiple ? [] : emptyObject), [props.multiple]);
    const initialValue = props.defaultValue || emptyValue;
    const { renderInput } = props;
    const [options, setOptions] = useState<T[]>([]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(initialValue);
    const [inputValue, setInputValue] = useState('');
    const [prevCampaign, setPrevCampaign] = useState<boolean>(!!props.prevCampaign);
    const [isFirstMount, setIsFirstMount] = useState(
        typeof props.isFirstMount !== typeof undefined ? props.isFirstMount : true,
    );
    const [prevUrl, setPrevUrl] = useState(props.url);
    const fetch = useDebounce(
        useCallback(
            (inputValue) => {
                setLoading(true);
                api.get(props.url, {
                    params: { q: inputValue, offset: 0, limit: 20 + options.length, withForm: props.prevCampaign },
                })
                    .then((res) => {
                        setOptions(res.data);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            },
            [options.length, props.prevCampaign, props.url],
        ),
        300,
    );

    useEffect(() => {
        setValue(props.defaultValue !== undefined ? props.defaultValue : emptyValue);
    }, [emptyValue, props.defaultValue]);

    const init = useCallback(() => {
        setOptions([]);
        setValue(emptyValue);
        setInputValue('');
        fetch('');
    }, [emptyValue, fetch]);

    useEffect(() => {
        if (props.prevCampaign && prevCampaign !== props.prevCampaign) {
            setPrevCampaign(props.prevCampaign);
            init();
        } else if (prevUrl !== props.url) {
            setPrevUrl(props.url);
            init();
        }
    }, [init, prevCampaign, prevUrl, props.prevCampaign, props.url]);

    useEffect(() => {
        if (isFirstMount) {
            setIsFirstMount(false);
            fetch('');
        }
    }, [fetch, isFirstMount]);

    return (
        <Autocomplete
            size="small"
            disableClearable={props.disableClearable}
            disablePortal
            autoComplete
            filterOptions={(x) => x}
            fullWidth
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={value}
            options={options}
            loadingText={'Betöltés...'}
            onChange={(event, newValue) => {
                const value = (newValue === null ? emptyValue : newValue) as T;

                if (props.onAutoCompleteChange) {
                    props.onAutoCompleteChange(event, value);
                }

                if (props.onChange) {
                    props.onChange(Array.isArray(value) ? value.map((elem) => elem.value) : value.value);
                }
                setValue(value);
            }}
            noOptionsText={'Nincsenek opciók.'}
            loading={loading}
            getOptionLabel={(option) => option.label}
            ListboxComponent={ListBox}
            ListboxProps={{
                role: 'listbox', // MUI Új verzióban beveszi már, addig egyedi ListboxComponent kell, hogy amikor új elemek kerülnek a lista aljára, ne ugorjon fel a tetejére
                onScroll: (event: React.SyntheticEvent) => {
                    const listBoxNode = event.currentTarget;
                    if (
                        listBoxNode.scrollTop + listBoxNode.clientHeight + EPSILON >= listBoxNode.scrollHeight &&
                        !loading
                    ) {
                        fetch(inputValue);
                    }
                },
            }}
            renderOption={(liProps, option) =>
                option.value === options.slice(-1)[0].value && loading ? (
                    <MenuItem {...liProps}>
                        <Grid container spacing={2}>
                            <Grid item>{option.label}</Grid>

                            <Grid item>
                                <CircularProgress color={'secondary'} size={22} />
                            </Grid>
                        </Grid>
                    </MenuItem>
                ) : (
                    props.renderOption?.(liProps, option as T) || <MenuItem {...liProps}>{option.label}</MenuItem>
                )
            }
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
                setOptions([]);
                fetch(newInputValue);
            }}
            renderInput={
                renderInput
                    ? renderInput
                    : (params) => (
                          <TextField
                              {...params}
                              error={props.error}
                              helperText={props.helperText}
                              label={props.label}
                          />
                      )
            }
            disabled={props.disabled}
            multiple={props.multiple}
        />
    );
}

const MemoizedApiAutocomplete = React.memo(ApiAutocomplete) as <T extends Option<number>>(
    props: ApiAutocompleteProps<T>,
) => JSX.Element;

export default MemoizedApiAutocomplete;

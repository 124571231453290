import _ from 'lodash';
import '../App.css';
import { adminMainMenus } from '../components/Layout/adminMenu';
import { IMainMenu } from '../components/Layout/types';
import { unitMainMenus } from '../components/Layout/unitMenu';
import { history, store } from '../store';
import { AccessTreeType } from '../store/accessManagement';
import {
    AuthUser,
    ViewLayoutType,
    ViewUnitCampaignManagementType,
    getUnitCampaignManagementSelectedType,
    AppViewType,
} from '../store/auth';
import { IUser } from '../utils/Interfaces/interfaces';
import {
    clientEndpoints,
    otherOwnerCampaignClientEndpointKeys,
    ownCampaignClientEndpointKeys,
} from './clientEndpoints';
import { CentralRole, UnitRole } from './Roles';
import { AxiosResponse } from 'axios';

function hasCentralAccess(accessTree: AccessTreeType, menuKey: string, user: IUser) {
    const roles = accessTree.central[menuKey] ?? [];
    return (
        user.roles?.central?.includes(CentralRole.ROLE_SUPER_ADMIN) ||
        user.roles?.central?.some((role) => roles.includes(role))
    );
}

function hasUnitAccess(accessTree: AccessTreeType, menuKey: keyof typeof clientEndpoints, user: IUser) {
    const { view } = store.getState().auth;

    if (view.unit === undefined) return false;

    const accessTreeUnit = accessTree.unit[menuKey];

    const unitRoles = user.roles?.unit?.[view.unit] ?? [];
    const centralRoles = user.roles?.central ?? [];

    let roles = accessTreeUnit ? [...accessTreeUnit] : [];

    if (view.unitCampaignManagement === ViewUnitCampaignManagementType.Common) {
        roles = roles?.filter(
            (role) =>
                role !== UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN &&
                role !== UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS,
        );
    } else if (
        view.unitCampaignManagement === ViewUnitCampaignManagementType.Own &&
        !centralRoles?.includes(CentralRole.ROLE_SUPER_ADMIN) &&
        unitRoles.includes(UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN) &&
        !unitRoles.includes(UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS)
    ) {
        roles = roles?.filter((role) => role === UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN);
    } else if (view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner) {
        /**
         * TODO: kellene kezelni az otherOwner view-t
         */
        roles = roles?.filter((role) => role === UnitRole.ROLE_OTHER_OTHER_USERS_VIEW_OWN_CAMPAIGN_DATA);
    }

    const hasPermission =
        centralRoles?.includes(CentralRole.ROLE_SUPER_ADMIN) || unitRoles?.some((role) => roles.includes(role));
    const hasExtraPermission = getHasExtraPermission(menuKey, view, unitRoles, centralRoles);
    return hasPermission && hasExtraPermission;
}

function getHasExtraPermission(
    menuKey: keyof typeof clientEndpoints,
    view: AppViewType,
    unitRoles: string[],
    centralRoles: string[],
) {
    return (
        !ownCampaignClientEndpointKeys.includes(menuKey) ||
        (view.unitCampaignManagement === ViewUnitCampaignManagementType.Own &&
            (unitRoles?.includes(UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN) ||
                centralRoles?.includes(CentralRole.ROLE_SUPER_ADMIN))) ||
        (view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner &&
            (unitRoles?.includes(UnitRole.ROLE_OTHER_OTHER_USERS_VIEW_OWN_CAMPAIGN_DATA) ||
                centralRoles?.includes(CentralRole.ROLE_SUPER_ADMIN)))
    );
}

export function checkAccess(
    menuKey: keyof typeof clientEndpoints | undefined,
    accessTree: AccessTreeType,
    user: IUser,
    layoutType?: ViewLayoutType,
): boolean {
    if (!menuKey) {
        return false;
    }

    switch (layoutType) {
        case ViewLayoutType.Central:
            return hasCentralAccess(accessTree, menuKey, user);
        case ViewLayoutType.Unit:
            return hasUnitAccess(accessTree, menuKey, user);
        default:
            return false;
    }
}

export function curriedCheckAccess(menuKey: keyof typeof clientEndpoints) {
    const { view, user } = store.getState().auth;
    const { accessTree } = store.getState().accessManagement;
    if (!(user && accessTree && view.layout)) {
        return false;
    }
    return checkAccess(menuKey, accessTree, user, view.layout);
}

export function getMenus(accessTree: AccessTreeType, user: IUser, layoutType: ViewLayoutType): IMainMenu {
    /**
     * If the layoutType is central, we need to filter the adminMainMenus:
     * We need to take all the roles from user.central.roles and get their according route names from accessTree.central.
     * Then we need to filter the adminMainMenus.superAdmin.subMenus by the route names.
     *
     * If the layoutType is unit, we need to filter the unitMainMenus:
     * We take all the roles from user.unit[selectedUnit].roles and get their according route names from accessTree.unit.
     * Then we go through the objects of unitMainMenus and filter the subMenus by the route names.
     *
     * If the layoutType is undefined, we redirect to the login page and clear the selected unit and layout type from the cookies.
     */

    switch (layoutType) {
        case ViewLayoutType.Central: {
            const menu = _.cloneDeep(adminMainMenus);

            menu.superadmin.subMenus = menu.superadmin.subMenus.filter((item) =>
                checkAccess(item.name, accessTree, user, layoutType),
            );

            /**
             * If there are no subMenus in a menu, we remove the menu item:
             */
            if (menu.superadmin.subMenus.length === 0) {
                delete menu.superadmin;
            }

            return menu;
        }
        case ViewLayoutType.Unit: {
            const auth = store.getState().auth;
            const menu = _.cloneDeep(unitMainMenus(auth));

            for (const key in menu) {
                menu[key].subMenus = menu[key].subMenus.filter((item) =>
                    checkAccess(item.name, accessTree, user, layoutType),
                );
            }

            if (auth.view.unitCampaignManagement === ViewUnitCampaignManagementType.OtherOwner) {
                for (const key in menu) {
                    menu[key].subMenus = menu[key].subMenus.filter((item) =>
                        otherOwnerCampaignClientEndpointKeys.includes(item.name),
                    );
                }
            }

            /**
             * If there are no subMenus in a menu, we remove the menu item:
             */
            for (const key in menu) {
                if (menu[key].subMenus.length === 0) {
                    delete menu[key];
                }
            }

            return menu;
        }
        default:
            return {};
    }
}

export function userHasRolesInOnlyOneType(user: AuthUser): ViewLayoutType | undefined {
    /**
     * If the user has ROLE_SUPER_ADMIN in user.roles.central, he can access everything
     */
    if (user.roles?.central?.includes(CentralRole.ROLE_SUPER_ADMIN)) {
        return undefined;
    }

    /**
     * If the user has roles in both central and unit, we return undefined
     */
    if (user.roles?.central && user.roles?.unit) {
        return undefined;
    }

    /**
     * If the user has roles in central, we return "Central"
     */
    if (user.roles?.central && !user.roles?.unit) {
        return ViewLayoutType.Central;
    }

    /**
     * If the user has roles in unit, we return "Unit"
     */
    if (user.roles?.unit && !user.roles?.central) {
        return ViewLayoutType.Unit;
    }

    return undefined;
}

export function getUserLayouts(user: AuthUser): ViewLayoutType[] {
    let layouts: ViewLayoutType[] = [];

    if (user.roles?.central?.includes(CentralRole.ROLE_SUPER_ADMIN) || (user.roles?.central && user.roles?.unit)) {
        layouts = [ViewLayoutType.Central, ViewLayoutType.Unit];
    } else if (user.roles?.central) {
        layouts = [ViewLayoutType.Central];
    } else if (user.roles?.unit) {
        layouts = [ViewLayoutType.Unit];
    }

    return layouts;
}

export function userIsSuperAdmin(user: AuthUser): boolean {
    return user.roles?.central?.includes(CentralRole.ROLE_SUPER_ADMIN) || false;
}

export function userHasCommonCampaignRoleInUnit(user: AuthUser): boolean {
    if (!user.selectedUnit) {
        return false;
    }

    return (
        (user.roles?.unit?.[user.selectedUnit]?.includes(UnitRole.ROLE_OTHER_ALLOW_USE_COMMON_CAMPAIGNS) &&
            user.roles?.unit?.[user.selectedUnit]?.includes(UnitRole.ROLE_OTHER_HANDLE_OWN_CAMPAIGN)) ||
        false
    );
}

/**
 * TODO: refactor: reverse these conditions
 */
export function onlyOwnCampaignCondition(user: AuthUser): boolean {
    return !userIsSuperAdmin(user) && !userHasCommonCampaignRoleInUnit(user);
}

/**
 * A function that checks if the user has any role in central
 */
export function userHasCentralRole(user: AuthUser): boolean {
    return user.roles?.central?.length > 0 || false;
}

/**
 * A function that checks if the user has ANY role in ANY unit
 */
export function userHasUnitRole(user: AuthUser): boolean {
    return user.roles?.unit && Object.keys(user.roles?.unit).length > 0;
}

export function onlyCommonCampaignCondition(): boolean {
    return getUnitCampaignManagementSelectedType() === ViewUnitCampaignManagementType.Common;
}

export function othersOwnCampaignViewCondition(user: AuthUser): boolean {
    if (!user.selectedUnit) return false;

    return (
        user.roles?.unit?.[user.selectedUnit]?.includes(UnitRole.ROLE_OTHER_OTHER_USERS_VIEW_OWN_CAMPAIGN_DATA) ||
        userIsSuperAdmin(user) ||
        false
    );
}

/**
 * A function that checks if a user has a specific role in a specific unit, or in the central.
 *
 * params:
 * user: AuthUser | undefined - if there is no user, we return false
 * role?: string - the role we want to check, if it is not defined, we check if any role exists
 * unitId: number | null - the unitId we want to check, if it is null we check the selected unit,
 *  if it is undefined we check the central roles
 * rolesNotToCheck: array - an array of roles that we do not want to check
 */
export function userHasRole(
    user: AuthUser | undefined,
    role?: string,
    unitId?: number | null,
    rolesNotToCheck: string[] = [],
): boolean {
    if (!user) return false;

    if (userIsSuperAdmin(user)) {
        return true;
    }

    const centralCheck = unitId === undefined;
    const unitIdTemp = unitId === null ? user.selectedUnit : unitId;

    if (!centralCheck && (unitIdTemp === undefined || unitIdTemp === null)) {
        return false;
    }

    let userRolesTemp: string[] = [];

    if (centralCheck) {
        userRolesTemp = user.roles?.central || [];
    } else {
        userRolesTemp = unitIdTemp ? user.roles?.unit?.[unitIdTemp] : [];
    }

    if (rolesNotToCheck.length > 0) {
        userRolesTemp = userRolesTemp?.filter((item) => !rolesNotToCheck.includes(item));
    }

    if (!role) {
        return userRolesTemp?.length > 0;
    }

    return userRolesTemp?.includes(role) || false;
}

export function userHasUnitRoleByIdAndRole(unitId: number, role: string, user?: AuthUser): boolean {
    return user?.roles?.unit?.[unitId]?.includes(role) || false;
}

export function checkPageAccess(ownOnly: boolean, readonly: boolean): void {
    const { view } = store.getState().auth;
    const isLayoutSetToOwnView =
        view.unitCampaignManagement &&
        [ViewUnitCampaignManagementType.Own, ViewUnitCampaignManagementType.OtherOwner].includes(
            view.unitCampaignManagement,
        );

    if (isLayoutSetToOwnView && !ownOnly && !readonly) {
        //in own view and see common but not in read only (pl. in edit mode)
        history.push(clientEndpoints.access_denied);
    }
}

export function hasPageAccessByEntityOwner(ownerId: number | null, ownOnly: boolean): boolean {
    const { view } = store.getState().auth;
    const isLayoutSetToOwnView =
        view.unitCampaignManagement !== undefined &&
        [ViewUnitCampaignManagementType.Own, ViewUnitCampaignManagementType.OtherOwner].includes(
            view.unitCampaignManagement,
        );

    return (
        (ownerId === null && !ownOnly && !isLayoutSetToOwnView) || (ownerId !== null && ownOnly && isLayoutSetToOwnView)
    );
}

export function checkPageAccessByEntityOwner(res: AxiosResponse, ownOnly: boolean): AxiosResponse {
    if (!hasPageAccessByEntityOwner(res.data.ownerId, ownOnly)) {
        history.push(clientEndpoints.access_denied);
    }

    return res;
}
